import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('deliveryAddress', 'deliveryAddresses');

const customActions = {
  async LOAD_deliveryAddresses({ commit, rootGetters }) {
    if (rootGetters['app/isOffline']) {
      const localData = await this.$db.getAll('deliveryAddresses');
      commit('SET_deliveryAddresses', localData);
      return localData;
    }
    const data = await odata.o(rootGetters)
      .get('deliveryAddresses')
      .query({ $filter: 'IsDeleted eq false' });
    commit('SET_deliveryAddresses', data);
    return data;
  },
  async LOAD_deliveryAddressesById({ rootGetters }, deliveryAddressId) {
    const data = await odata.o(rootGetters)
      .get('deliveryAddresses')
      .query({ $filter: `IsDeleted eq false AND DeliveryAddressId eq ${deliveryAddressId}` });

    return data[0];
  },
};

export default { ...actions, ...customActions };
