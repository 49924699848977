<template>
  <v-file-input
    v-model="file"
    label="Import Favourites"
    ref="file"
    :rules="fileRules"
    :loading="isSaving"
    hide-details
    class="favourites-import"
  >
    <template v-slot:prepend>
      <v-icon>{{ mdiFileImport }}</v-icon>
    </template>
    <template v-slot:append>
      <v-btn
        color="primary"
        text
        @click="importFavourites"
        filled
        hide-details
      >
        Upload
      </v-btn>
    </template>
  </v-file-input>
</template>

<script>
import { mdiFileImport } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mapActions } from 'vuex';

export default {
  name: 'ImportFavourites',
  mixins: [loading],
  data() {
    return {
      mdiFileImport,
      file: null,
      fileRules: [
        (v) => !!v || 'File is required',
      ],
    };
  },
  computed: {
    divisionId() {
      return this.$store.state.app.divisionId;
    },
  },
  // watch: {
  //   async file(newValue) {
  //     if (newValue) {
  //       await this.importFavourites();
  //     }
  //   },
  // },
  methods: {
    ...mapActions('userFiles', ['CREATE_userFile']),
    async importFavourites() {
      try {
        this.isSaving = true;
        const result = await this.CREATE_userFile(this.file);
        if (this.divisionId) {
          await this.$http.post('api/imports/division-favourites', {
            fileId: result.id,
            divisionId: this.divisionId,
          });
        } else {
          await this.$http.post('api/imports/favourites', {
            fileId: result.id,
          });
        }
        this.$root.$emit('toast:success', 'Favourites imported successfully');
        this.$emit('imported');
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'There was an error importing the favourites');
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
<style>
.favourites-import {
  margin-top: -1px;
}
</style>
