<template>
  <v-row :data-cy="`mobileStockItem-row-${index}`" no-gutters class="row-item">
    <v-col cols="2" style="margin-top: auto; margin-bottom: auto">
      <v-img contain height="70" :src="stockItem.photo" />
    </v-col>
    <v-col cols="6" md="8" sm="8" class="pr-1">
      <div>
        {{ stockItem.description }}
      </div>
      <div class="align-mobile-bottom">
        <div class="font-smaller">
          R {{ formatCurrency(detail.price) }} / {{ stockItem.unitOfMeasure }}
          <span v-if="detail.tax > 0">(R {{ formatCurrency(detail.tax) }} VAT)</span>
        </div>
      </div>
    </v-col>
    <v-col cols="4" md="2" sm="2">
      <QuantityButtonCart v-if="isCheckout" :clearOnFocus="!isCheckout"
        :value="localAmount" @input="update" @remove="$emit('remove', stockItem)" />
      <QuantityButtonBasic v-else :stockItem="stockItem" @add="add" />
        <div v-if="detail.qty > 0" class="font-smaller mt-2">
          {{ detail.qty }} items in stock
        </div>
        <div v-else class="font-smaller mt-2">
          <span class="red--text">Out of stock</span>
        </div>
      <v-btn
        v-if="showAlternatives"
        small
        block
        outlined
        color="primary"
        :to="categoryLink"
      >
        Alternatives
      </v-btn>
      <div
        v-if="detail.qty === 0 && showAlternatives"
        class="mt-2"></div>
      <BuyInStock
        v-if="detail.qty === 0 && showAlternatives"
        :stockItem="stockItem"
        class="mt-2"
        :small="true"
      />
      <span v-if="showTotal" class="display-block text-center font-bold mt-2">
        R {{ formatCurrency(total) }}
      </span>
      <div class="mb-2" />
      <Favourite
        v-if="showFavourites && activeCustomer"
        :identifier="stockItem.itemCode.toString()"
        :name="`stockItem-${activeCustomer.customerCode}`"
        @change="$emit('favouriteChange')"
        :small="true"
      />
      <div class="mb-2" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import Favourite from './Favourite.vue';
import QuantityButtonBasic from './QuantityButtonBasic.vue';
import QuantityButtonCart from './QuantityButtonCart.vue';
import cartMethods from '../mixins/cartMethods';
import BuyInStock from './BuyInStock.vue';

export default {
  name: 'MobileStockItem',
  mixins: [cartMethods],
  components: {
    BuyInStock,
    Favourite,
    QuantityButtonBasic,
    QuantityButtonCart,
  },
  props: {
    amount: {
      type: Number,
      default: 1,
    },
    isCheckout: {
      type: Boolean,
      default: false,
    },
    stockItem: {
      type: Object,
      default: () => {},
    },
    showAlternatives: {
      type: Boolean,
      default: true,
    },
    showFavourites: {
      type: Boolean,
      default: false,
    },
    showRemove: {
      type: Boolean,
      default: false,
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      localAmount: undefined,
    };
  },
  computed: {
    ...mapGetters('stockItems', ['getStockItemDetail']),
    ...mapGetters('cart', ['getQuantity']),
    ...mapState('customers', ['activeCustomer']),
    categoryLink() {
      let categoryId = 0;
      let depth = 0;
      if (this.stockItem.webCategory4Id) {
        categoryId = this.stockItem.webCategory4Id;
        depth = 4;
      } else if (this.stockItem.webCategory3Id) {
        categoryId = this.stockItem.webCategory3Id;
        depth = 3;
      } else if (this.stockItem.webCategory2Id) {
        categoryId = this.stockItem.webCategory2Id;
        depth = 2;
      } else if (this.stockItem.webCategory1Id) {
        categoryId = this.stockItem.webCategory1Id;
        depth = 1;
      }
      return {
        name: 'shop-ShopStockItemByCategory',
        query: {
          category: categoryId,
          categoryName: 'Alternatives',
          depth,
        },
      };
    },
    detail() {
      return this.getStockItemDetail(this.stockItem);
    },
    total() {
      return this.detail.price * this.getQuantity(this.stockItem);
    },
  },
  watch: {
    amount() {
      this.localAmount = this.amount;
    },
  },
  mounted() {
    this.localAmount = this.amount;
  },
  methods: {
    ...mapMutations('cart', ['cart_UPDATE']),
    update(value) {
      this.cart_UPDATE({
        stockItem: this.stockItem,
        amount: value,
      });
    },
  },
};
</script>
<style scoped>
.align-mobile-bottom {
  margin-top: 25px;
}
</style>
