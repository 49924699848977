<template>
  <v-form>
    <v-select label="Select User" :items="users"></v-select>
    <v-textarea label="Push Notification Message" name="message" maxlength="256"></v-textarea>
    <v-btn color="primary" @click="sendNotification">
      <v-icon>{{ mdiBell }}</v-icon>
      Send Notification
    </v-btn>
  </v-form>
</template>

<script>
import { mdiBell } from '@mdi/js';

export default {
  name: 'SendPushNotifications',
  data() {
    return {
      mdiBell,
      users: ['No users registered'],
    };
  },
  methods: {
    sendNotification() {
      // Implement notification sending logic here
    },
  },
};
</script>
