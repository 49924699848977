/* eslint-disable global-require */
import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapGetters('app', ['displayNameOrUsername']),
    ...mapState('auth', ['username', 'isLoggedIn']),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    menuItems() {
      return [{
        name: 'Catalogue',
        text: 'Product Catalogue',
        imageUrl: this.isMobile ? require('../assets/Shop.png') : require('../assets/Desktop-Shop.png'),
        to: 'shop-ShopCategories',
        appMenuType: 'Home',
        order: 1,
      }, {
        name: 'Accounts',
        text: 'Accounts',
        imageUrl: this.isMobile ? require('../assets/Accounts.png') : require('../assets/Desktop-Accounts.png'),
        to: 'finance-FinanceHome',
        appMenuType: 'Home',
        order: 3,
      }, {
        name: 'CustomerCare',
        text: 'Customer Care',
        imageUrl: this.isMobile ? require('../assets/CustomerCare.png') : require('../assets/Desktop-CustomerCare.png'),
        to: 'shop-CustomerCare',
        appMenuType: 'Home',
        order: 4,
      }];
    },
  },
};
