<template>
  <v-row>
    <v-col cols="8">
      <v-text-field
        ref="quantity"
        label="Quantity"
        class="input-cart-qty"
        x-small
        v-model="amount"
        type="number"
        @focus="quantityClicked"
        @blur="quantityBlurred"
        @keydown.enter.prevent="add"
      >
        <template v-slot:append-outer>
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="4" class="mt-5">
      <v-btn
        depressed
        color="success"
        small
        @click="add">
        <v-icon v-if="isInCart">
          {{ mdiCheck }}
        </v-icon>
        Add to Cart
      </v-btn>
    </v-col>
  </v-row>
</template>
<style scoped>
.input-cart-qty {
  max-width: 300px;
}
</style>
<script>
import { mapGetters } from 'vuex';
import { mdiCheck } from '@mdi/js';

export default {
  name: 'ButtonAddToCart',
  props: {
    stockItem: {
      type: Object,
      default: () => {},
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiCheck,
      amount: 1,
    };
  },
  computed: {
    ...mapGetters('cart', ['displayCart']),
    isInCart() {
      const { displayCart, stockItem } = this;
      return displayCart.some((c) => c.stockItem.itemCode === stockItem.itemCode);
    },
  },
  mounted() {
    this.amount = 1;
    if (this.autoFocus) {
      this.$refs.quantity.focus();
    }
  },
  methods: {
    async add() {
      const { stockItem, amount } = this;
      if (this.isInCart) {
        await this.$nextTick();
        const result = await this.$confirm('This item is already in your cart, are you sure you want to add more?');
        if (result) {
          this.$emit('add', { stockItem, amount });
          this.amount = 1;
        }
      } else {
        this.$emit('add', { stockItem, amount });
        this.amount = 1;
      }
    },
    quantityBlurred() {
      if ((this.amount ?? 0) < 1) {
        this.amount = 1;
      }
    },
    quantityClicked(e) {
      e.target.select();
    },
  },
};
</script>
