<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="3" :loading="isLoading">
          <v-card-title>
            Edit Quote
          </v-card-title>
          <v-card-subtitle v-if="client">
            <active-customer />
          </v-card-subtitle>
          <v-card-text v-if="quote">
            <v-form
              lazy-validation
              v-model="valid"
              @submit.prevent="submit"
              ref="form">
              <v-row>
                <v-col class="text-h6" cols="4">
                  Order No: <b>{{ quote.order.orderNo }}</b>
                </v-col>
                <v-col class="text-h6" cols="4">
                  Delivery Date: <b>{{ quote.order.deliveryDate }}</b>
                </v-col>
                <v-col class="text-h6" cols="4">
                  Requested By: <b>{{ quote.createdBy }}</b>
                </v-col>
              </v-row>
              <TableQuoteCart :cartItems="quoteCart" @change="orderChanged" @remove="removeItem">
                <template v-slot:notes>
                  <v-textarea
                    disabled
                    class="mt-10"
                    outlined
                    label="Notes"
                    v-model="quote.order.notes"
                    hint="e.g. special delivery notes"
                    data-cy="Quote-notes"
                  />
                </template>
                <template v-slot:buttons>
                  <div v-if="quote.approvedBy" class="text-h6">
                    This was already
                    {{ getBool(quote.approved) }} by {{ quote.approvedBy }}
                    at {{ formatDateTime(quote.dateApproved) }}
                  </div>
                  <div v-else class="text-right">
                    <v-btn
                      :disabled="isOnHold || isDirty"
                      class="mr-2"
                      color="error"
                      @click="update(false)"
                    >
                      Decline
                    </v-btn>
                    <v-btn class="mr-2"
                      @click="update(true)"
                      :disabled="isOnHold || isDirty"
                      data-cy="Quote-button-checkout"
                      color="success"
                      type="submit"
                      :loading="isSaving">
                        Approve
                      </v-btn>
                    <v-btn
                      class="mr-2"
                      @click="cancel"
                      v-if="isDirty"
                      data-cy="Quote-button-checkout"
                      color="warning"
                      type="button">
                        Cancel
                    </v-btn>
                    <v-btn
                      class="mr-2"
                      @click="save"
                      v-if="isDirty"
                      data-cy="Quote-button-checkout"
                      color="success"
                      type="button"
                      :loading="isUpdating">
                        Save
                    </v-btn>
                  </div>
                </template>
              </TableQuoteCart>
            </v-form>
            <p v-if="quoteCart.length <= 0" class="ma-5">
              <v-icon small class="primary--text mr-2 mb-1">
                fa-info
              </v-icon>
              Cart is empty
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mapActions, mapMutations, mapState, mapGetters,
} from 'vuex';
import form from '../mixins/form';
import validation from '../mixins/validation';
import ActiveCustomer from '../components/ActiveCustomer.vue';
import TableQuoteCart from '../components/TableQuoteCart.vue';
import cart from '../mixins/cart';
import customerRefresh from '../mixins/customerRefresh';

export default {
  components: {
    ActiveCustomer, TableQuoteCart,
  },
  name: 'EditQuote',
  mixins: [cart, customerRefresh, form, validation],
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      isDirty: false,
      orderNo: '',
      deliveryAddressId: 0,
      deliveryDate: '',
      notes: '',
      costCentre: undefined,
      quote: undefined,
      isUpdating: false,
    };
  },
  computed: {
    ...mapState('stockItems', ['stockItems']),
    ...mapState('customers', ['activeCustomer']),
    ...mapGetters('customers', ['isOnHold']),
    ...mapState('finance', ['client']),
    isApproval() {
      return this.costCentre?.requiresApproval ?? false;
    },
    quoteCart() {
      const { stockItems } = this;
      return this.quote?.order.cart.map((c) => {
        const result = { ...c };
        result.stockItem = stockItems.find((s) => s.itemCode === c.itemCode);
        return result;
      });
    },
    order() {
      const {
        costCentre, orderNo, deliveryAddressId, deliveryDate, orderCart, notes,
      } = this;
      return {
        cart: orderCart,
        customerId: this.activeCustomer.id,
        costCentreId: costCentre?.id,
        orderNo,
        deliveryDate,
        deliveryAddressId,
        orderDetail: this.cart.map((c) => ({
          quantity: c.amount,
          productId: c.stockItem.productId,
        })),
        quoteId: this.$route.query.quoteId,
        notes,
      };
    },
    remaining() {
      const balance = Number.parseFloat(this.client.dcBalance);
      const total = Number.parseFloat(this.total_amount);
      return balance + total;
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('stockItems', ['LOAD_stockItems']),
    ...mapMutations('app', ['SET_title', 'SET_expandedSearch']),
    // ...mapActions('finance', ['LOAD_client']),
    getBool(b) {
      if (b) {
        return 'approved';
      }
      return 'declined';
    },
    isApprover(approvers) {
      return approvers?.split(';').includes(this.userId);
    },
    async orderChanged($event) {
      this.isLoading = true;
      const index = this.quote.order.cart.findIndex((c) => c.id === $event.id);
      this.quote.order.cart[index].amount = $event.amount;
      this.isDirty = true;
      this.isLoading = false;
    },
    /**
     * Executes refresh
     */
    async refresh() {
      this.isLoading = true;
      // await this.LOAD_client();
      const result = await this.$http.get(`/api/quotes/${this.$route.query.quoteId}`);
      this.quote = result.data;
      await this.LOAD_stockItems();
      this.SET_title(this.quote.order.orderNo);
      this.SET_expandedSearch(false);
      this.isLoading = false;
    },
    async update(approved) {
      this.isLoading = true;
      await this.$http.put('/api/quotes', {
        updateType: 'approval',
        approved,
        quoteId: this.$route.query.quoteId,
      });
      this.isLoading = false;
      this.refresh();
    },
    async cancel() {
      await this.refresh();
      this.isDirty = false;
    },
    async save() {
      this.isUpdating = true;
      const { quote } = this;
      await this.$http.put('/api/quotes/update', quote);
      this.isUpdating = false;
      this.isDirty = false;
      this.refresh();
    },
    async removeItem(item) {
      const res = await this.$confirm('Are you sure you want to remove this item?');
      if (res) {
        this.isSaving = true;
        await this.$http.delete(`/api/quotes/cart/${item.id}`);
        this.isSaving = false;
        if (this.isDirty) {
          await this.save();
        }
        await this.refresh();
      }
    },
    /**
     * Executes checkout
     */
    async submit() {
      const isValid = await this.validateForm(this.$refs.form);
      if (isValid) {
        const { order, isApproval } = this;
        let shouldProcess = true;
        if (this.cart.filter((s) => s.stockItem.quantity === 0).length > 0) {
          shouldProcess = await this.$confirm('There are backorder items in this cart, are you sure you want to continue?');
        }
        if (shouldProcess) {
          try {
            this.isSaving = true;
            if (isApproval) {
              await this.$http.post('odata/createQuote', order);
              this.$root.$emit('toast:notify', 'Quote placed successfully');
            }
            this.RESET_CART();
          } catch (error) {
            this.$log.error(error);
            this.$root.$emit('network.error');
          } finally {
            this.isSaving = false;
            this.orderNo = '';
            this.deliveryDate = this.formatDate(new Date());
            this.$router.push({ name: 'shop-ShopHome' });
          }
        }
      }
    },
    /**
     * Executes deliveryAddressChanged
     */
    deliveryAddressChanged(item) {
      this.deliveryAddressId = item.deliveryAddressId;
    },
  },
};
</script>

<style scoped>
.field-qty {
  max-width: 180px;
}
.qty-col {
  min-width: 120px;
}
</style>
