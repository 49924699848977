import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('emailLog', 'emailLogs');

const customActions = {
  async LOAD_emailLogs({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('emailLogs')
      .query({
        $filter: 'IsDeleted eq false',
        $orderby: 'Created desc',
        $top: 4000,
      });
    commit('SET_emailLogs', data);
    return data;
  },
};

export default { ...actions, ...customActions };
