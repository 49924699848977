/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line no-unused-vars
class Event {
  constructor() {
    this.listeners = {};
  }

  on(str, fn) {
    this.listeners[str] = fn;
  }

  once(str, fn) {
    this.listeners[str] = fn;
  }

  addEventListener(str, fn) {
    this.on(str, fn);
  }

  emit(str, data) {
    if (this.listeners[str]) {
      this.listeners[str](data);
    }
  }
}

export default Event;
