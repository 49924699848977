import { render, staticRenderFns } from "./QuantityButtonBasic.vue?vue&type=template&id=813f8116&scoped=true"
import script from "./QuantityButtonBasic.vue?vue&type=script&lang=js"
export * from "./QuantityButtonBasic.vue?vue&type=script&lang=js"
import style0 from "./QuantityButtonBasic.vue?vue&type=style&index=0&id=813f8116&prod&scoped=true&lang=css"
import style1 from "./QuantityButtonBasic.vue?vue&type=style&index=1&id=813f8116&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "813f8116",
  null
  
)

export default component.exports