<template>
  <v-data-table
    :headers="headers"
    :items="vehicles"
    class="elevation-1"
    :loading="isLoading"
    sort-by="licensePlate"
  >
    <template v-slot:top>
      <v-row :class="{ 'p-16': $vuetify.breakpoint.mobile }">
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-btn
            color="primary"
            data-cy="Vehicle-button-new"
            @click="$router.push({
              name: 'fleet-EditVehicle'
            })"
          >
            <v-icon class="mr-2">
              {{ mdiPlus }}
            </v-icon>
            {{ $t('common.buttons.new') }}
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-text-field
            v-model="search"
            :append-icon="mdiMagnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.actions="{ item }">
      <TableBasicButtons :editLink="{
          name: 'fleet-EditVehicle',
          query: {id: item.id}
        }"
        @delete="remove(item)"
      />
    </template>
    <template v-slot:item.coldStorageCertificateRenewalDate="{ item }">
      {{ formatDate(item.coldStorageCertificateRenewalDate) }}
    </template>
    <template v-slot:item.licenseRenewalDate="{ item }">
      {{ formatDate(item.licenseRenewalDate) }}
    </template>
    <template v-slot:item.nextServiceDate="{ item }">
      {{ formatDate(item.nextServiceDate) }}
    </template>
    <template v-slot:item.nextTireChangeDate="{ item }">
      {{ formatDate(item.nextTireChangeDate) }}
    </template>
    <template v-slot:item.refrigerationServiceDate="{ item }">
      {{ formatDate(item.refrigerationServiceDate) }}
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiMagnify, mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListVehicle',
  module: 'Fleet',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiMagnify,
      mdiPlus,
      /**
      * table data
      */
      search: '',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('vehicles', ['vehicles']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('licensePlate'),
          value: 'licensePlate',
        },
        {
          text: this.$$t('make'),
          value: 'make',
        },
        {
          text: this.$$t('model'),
          value: 'model',
        },
        {
          text: this.$$t('fuelType'),
          value: 'fuelType',
        },
        {
          text: this.$$t('nextServiceDate'),
          value: 'nextServiceDate',
        },
        {
          text: this.$$t('nextTireChangeDate'),
          value: 'nextTireChangeDate',
        },
        {
          text: this.$$t('licenseRenewalDate'),
          value: 'licenseRenewalDate',
        },
        {
          text: this.$$t('refrigerationServiceDate'),
          value: 'refrigerationServiceDate',
        },
        {
          text: this.$$t('coldStorageCertificateRenewalDate'),
          value: 'coldStorageCertificateRenewalDate',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_vehicles();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('vehicles', ['LOAD_vehicles', 'DELETE_vehicle']),
    /** Delete Vehicle from the collection
     */
    async remove(vehicle) {
      this.isLoading = true;
      await this.DELETE_vehicle(vehicle.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Vehicle example

'''js
<Vehicle />
'''
</docs>
