<template>
  <v-container fluid>
    <StockItemsLoading v-if="isLoading" />
    <v-row v-else no-gutters>
      <v-col cols="12">
        <div id="container">
          <div v-for="(category, index) in visibleItems" :key="index">
            <div class="text-h5 font-weight-bold ml-3">
              {{ category.name }}
            </div>
            <CategoryCardList
              :category="category"
              :depth="depth"
              @input="selectCategory"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Capacitor } from '@capacitor/core';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import CategoryCardList from '../components/CategoryCardList.vue';
import StockItemsLoading from '../components/StockItemsLoading.vue';
import categories from '../mixins/categories';
import stockItem from '../mixins/stockItem';

export default {
  name: 'ShopCategories',
  components: {
    CategoryCardList,
    StockItemsLoading,
  },
  mixins: [categories, loading, stockItem],
  meta: {
    title: 'Shop',
  },
  data() {
    return {
      categories: [],
      selectedCategory: null,
      depth: null,
      unCategorizedItems: [],
      ps: null,
      visibleItems: [],
    };
  },
  computed: {
    ...mapGetters('webCategories', [
      'getWebCategory1',
      'getWebCategory2',
      'getWebCategory3',
      'getWebCategory4',
    ]),
    isIos() {
      // return true;
      return this.platform === 'ios';
    },
    platform() {
      return Capacitor.getPlatform();
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.refresh();
      },
    },
    categories: {
      immediate: true,
      deep: true,
      handler() {
        this.visibleItems = [];
        this.categories.forEach((item, index) => {
          setTimeout(() => {
            this.visibleItems.push(item);
          }, index * 250);
        });
      },
    },
  },
  async mounted() {
    this.isLoading = true;
    this.SET_title('Shop');
    this.SET_expandedSearch(true);

    await Promise.all([
      (async () => {
        await this.LOAD_webCategory1();
      })(),
      (async () => {
        await this.LOAD_webCategory2();
      })(),
      (async () => {
        await this.LOAD_webCategory3();
      })(),
      (async () => {
        await this.LOAD_webCategory4();
      })(),
      (async () => {
        await this.LOAD_deliveryDates();
      })(),
    ]);
    this.isLoading = false;
    await this.$nextTick();
    await this.refresh();
    // if (this.isIos) {
    //   const container = document.querySelector('#container');
    //   console.debug(container);
    //   let startY = null;
    //   container.ontouchstart = (e) => {
    //     startY = e.changedTouches[0].clientY;
    //   };
    //   container.ontouchmove = (e) => {
    //     const endY = e.changedTouches[0].clientY;
    //     const diffY = startY - endY;
    //     if (Math.abs(diffY) > 10) {
    //       document.body.style.scrollBehavior = 'smooth';
    //       document.body.scrollTo({
    //         top: document.body.scrollTop + diffY,
    //         behavior: 'smooth',
    //       });
    //     }
    //   };
    //   container.ontouchend = (e) => {
    //     const endY = e.changedTouches[0].clientY;
    //     const diffY = startY - endY;
    //     if (Math.abs(diffY) > 10) {
    //       document.body.style.scrollBehavior = 'smooth';
    //       document.body.scrollTo({
    //         top: document.body.scrollTop + diffY,
    //         behavior: 'smooth',
    //       });
    //     }
    //   };
    // }
  },
  methods: {
    ...mapMutations('app', ['SET_title', 'SET_expandedSearch']),
    ...mapActions('deliveryDates', ['LOAD_deliveryDates']),
    ...mapActions('webCategories', [
      'LOAD_webCategory1',
      'LOAD_webCategory2',
      'LOAD_webCategory3',
      'LOAD_webCategory4',
      'UPDATE_stockItemsByWebCategory1',
    ]),
    async refresh() {
      this.$log.debug('refreshing');
      this.isLoading = true;
      this.categories = [];
      if (this.$route.query.category) {
        this.selectedCategory = {
          id: this.$route.query.category,
          name: this.$route.query.categoryName,
        };
        this.depth = parseInt(this.$route.query.depth, 10);
        const { selectedCategory, depth } = this;
        this.SET_title(selectedCategory.name);
        this.SET_expandedSearch(false);
        this.categories = await this.LOAD_categories({
          category: selectedCategory,
          depth: depth - 1,
        });
        this.$log.debug(`Found ${this.categories.length} categories`);
      } else {
        const { getWebCategory1 } = this;
        this.depth = 1;
        this.categories = getWebCategory1;
      }
      this.isLoading = false;
    },
    async selectCategory(category) {
      const { depth } = this;
      try {
        const res = await this.$router.push({
          name: 'shop-ShopCategories',
          query: {
            category: category.id,
            categoryName: category.name,
            depth: depth + 1,
          },
        });
        this.$log.debug(res);
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('error', 'Error selecting category');
      }
    },
  },
};
</script>
<style>
/* #container {
  position: absolute;
} */
</style>
