<template>
  <v-container fluid>
    <Breadcrumbs :history="history" />
    <v-card>
      <v-card-text>
      <v-skeleton-loader
        :loading="isLoading"
        transition="expand-transition"
        type="article"
      >
        <FormEmail
          :Email="editEmail"
          :isSaving="isSaving"
          @save="save"
          @cancel="$router.push({
            name: 'shop-ListEmail',
            query: { customerCode: editEmail.customerCode },
          })" />
      </v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormEmail from '../components/FormEmail.vue';

export default {
  components: {
    FormEmail,
  },
  name: 'EditEmail',
  module: 'Shop',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing Email. Default to {}.
      */
      editEmail: {
        customerCode: this.$route?.query.customerCode,
      },
      /**
       * Query Id for Email
       */
      emailId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('emails', ['emails', 'email']),
    history() {
      return [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Customers',
        disabled: false,
        href: '/#/admin/manage-customers',
        depth: 0,
      }, {
        text: `Emails (${this.editEmail.customerCode})`,
        href: `/#/shop/list-email?customerCode=${this.editEmail.customerCode}`,
        disabled: false,
        depth: 0,
      },
      {
        text: 'Edit',
        disabled: true,
        depth: 0,
      }];
    },
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.emailId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_email(this.emailId);
      this.editEmail = this.email;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('emails', ['LOAD_email', 'CREATE_email', 'UPDATE_email']),
    /** Method used to save the item
     */
    async save(email) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_email(email);
      } else {
        await this.UPDATE_email(email);
      }
      this.isSaving = false;
      this.$router.push({ name: 'shop-ListEmail', query: { customerCode: this.editEmail.customerCode } });
    },
  },
};
</script>

<docs>
# EditEmail example

'''js
<EditEmail :Email="Email" />
'''
</docs>
