import { mapMutations, mapGetters } from 'vuex';
import { mdiMinus } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  mixins: [loading],
  data() {
    return {
      mdiMinus,
      rules: {
        non_zero: (value) => (value <= 0 ? 'Add proper Amount' : true),
      },
    };
  },
  computed: {
    ...mapGetters('cart', ['cart', 'displayCart', 'total_amount', 'total_items']),
    ...mapGetters('pricings', ['pricingCount']),
    ...mapGetters('taxes', ['taxesLoaded']),
  },
  methods: {
    ...mapMutations('cart', [
      'RESET_CART',
      'INCREASE_AMOUNT',
      'DECREASE_AMOUNT',
      'REMOVE_CART',
      'CHANGE_AMOUNT',
      'SET_CART',
    ]),
    getDecreaseIcon(amount) {
      if (amount > 1) {
        return mdiMinus;
      }
      return ' ';
    },
  },
};
