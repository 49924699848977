export default {
  activeCustomer: undefined,
  customers: [],
  customer: undefined,
  restaurantTypes: [
    'Italian Restaurant',
    'Chinese Restaurant',
    'Mexican Restaurant',
    'Indian Restaurant',
    'French Restaurant',
    'Japanese Restaurant',
    'Thai Restaurant',
    'Greek Restaurant',
    'American Diner',
    'Steakhouse',
    'Seafood Restaurant',
    'Vegetarian/Vegan Restaurant',
    'Barbecue Restaurant',
    'Sushi Bar',
    'Pizzeria',
    'Tapas Bar',
    'Food Truck',
    'Fast Food Restaurant',
    'Fine Dining Restaurant',
    'Buffet Restaurant',
    'Hotel',
    'Guesthouse',
    'Coffee shop',
    'Catering',
    'Old Age home',
    'School',
    'Pub restaurant',
  ],

  positions: [
    'General Manager',
    'Chef',
    'Procurement Manager',
    'Food & Beverage Manager',
    'Store Manager',
  ],
};
