<template>
  <div class="w-100 d-flex align-center flex-column" style="margin-bottom: 100px">
    <div class="text-h5 text-bold fade-in text-center"
      :class="{ 'text--left' : isMobile }">
      Welcome, {{ displayNameOrUsername }}!
    </div>
    <div class="text-h6 fade-in text-center">
      Where would you like to go?
    </div>
    <v-row class="d-flex" data-cy="ShopHome-menu-item" style="width: 75vw">
      <v-col cols="12" md="4"
        v-for="item in menuItems"
        :key="item.name"
        class="col-lg-padding home-menu-item"
      >
        <router-link :to="{ name: item.to }" class="d-flex fill-height">
          <img
            :src="item.imageUrl"
            class="w-100"
          />
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import home from '../mixins/home';

export default {
  name: 'DesktopShopHome.vue',
  mixins: [home],
};
</script>
