<template>
  <v-data-table
    :headers="headers"
    :items="deliveryDates"
    class="elevation-1"
    :loading="isLoading"
    sort-by="excludedDeliveryDate"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-btn
          color="success"
          data-cy="DeliveryDate-button-new"
          @click="
            $router.push({
              name: 'shop-EditDeliveryDate',
            })
          "
        >
          <v-icon class="mr-2">
            {{ mdiPlus }}
          </v-icon>
          {{ $t("common.buttons.new") }}
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.excludedDeliveryDate="{ item }">
      {{ formatDateForDisplay(item.excludedDeliveryDate) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <TableBasicButtons
        :editLink="{
          name: 'shop-EditDeliveryDate',
          query: { id: item.id },
        }"
        @delete="remove(item)"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import dayjs from 'dayjs';

export default {
  name: 'ListDeliveryDate',
  module: 'Shop',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
       * Icons
       */
      mdiPlus,
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Delivery Dates',
        disabled: true,
        depth: 0,
      }],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('deliveryDates', ['deliveryDates']),
    /**
     * Get table column headers
     */
    headers() {
      return [
        {
          text: this.$$t('excludedDeliveryDate'),
          value: 'excludedDeliveryDate',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_deliveryDates();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('deliveryDates', ['LOAD_deliveryDates', 'DELETE_deliveryDate']),
    /** Delete DeliveryDate from the collection
     */
    async remove(deliveryDate) {
      this.isLoading = true;
      await this.DELETE_deliveryDate(deliveryDate.id);
      this.isLoading = false;
    },
    formatDateForDisplay(date) {
      return dayjs(date).format('YYYY-MM-DD (dddd)');
    },
  },
};
</script>

<docs>
# DeliveryDate example

'''js
<DeliveryDate />
'''
</docs>
