<template>
  <v-menu offset-y v-if="isLoggedIn">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on" title="User Account"
        elevation="0"
        height="48">
        <img class="icon" src="@/assets/icon-account-white.svg" />
      </v-btn>
    </template>
    <v-list>
      <v-list-item :to="{ name: 'app-Account' }">
        <v-list-item-title>{{ $t('common.account') }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="logout">
        <v-list-item-title>{{ $t('common.logoff') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mdiAccountOutline, mdiChevronDown } from '@mdi/js';

export default {
  module: 'auth',
  name: 'Account',
  data() {
    return {
      mdiAccountOutline,
      mdiChevronDown,
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('auth', ['username']),
    ...mapState('customers', ['activeCustomer']),
  },
  methods: {
    logout() {
      this.$root.$emit('auth:logoff');
    },
  },
};
</script>
