<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              data-cy="FormStockItemAlternative-customerId"
              filled
              :label="$$t('customerCode')"
              item-text="customerDesc"
              item-value="customerId"
              :items="customers"
              @change="customerChanged"
              v-model="localStockItemAlternative.customerId"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              data-cy="FormStockItemAlternative-stockItemId"
              filled
              label="Stock Item"
              :item-text="i => `${i.itemCode} - ${i.description}`"
              item-value="itemCode"
              :items="stockItems"
              v-model="localStockItemAlternative.itemCode"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              data-cy="FormStockItemAlternative-alternativeStockItemId"
              filled
              label="Alternative Stock Item"
              :item-text="i => `${i.itemCode} - ${i.description}`"
              item-value="itemCode"
              :items="stockItems"
              v-model="localStockItemAlternative.alternativeItemCode"
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormStockItemAlternative-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormStockItemAlternative-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mapState } from 'vuex';

export default {
  name: 'FormStockItemAlternative',
  module: 'Shop',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    StockItemAlternative: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localStockItemAlternative
      */
      localStockItemAlternative: {},
    };
  },
  computed: {
    ...mapState('customers', ['customers']),
    ...mapState('stockItems', ['stockItems']),
  },
  watch: {
    StockItemAlternative() {
      this.localStockItemAlternative = this.StockItemAlternative;
    },
  },
  created() {
    this.localStockItemAlternative = this.StockItemAlternative;
  },
  async mounted() {
    if (this.localStockItemAlternative.customerId) {
      await this.customerChanged(this.localStockItemAlternative.customerId);
    }
  },
  methods: {
    async customerChanged(customerCode) {
      this.loadingDeliveryAddresses = true;
      const url = `/api/deliveries/addresses/${customerCode}`;
      const { data } = await this.$http.get(url);
      this.deliveryAddresses = data;
      this.loadingDeliveryAddresses = false;
    },
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localStockItemAlternative);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormStockItemAlternative example

'''js
<FormStockItemAlternative :StockItemAlternative="StockItemAlternative" />
'''
</docs>
