import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('email', 'emails');

const customActions = {
  async LOAD_emails({ commit, rootGetters }, customerCode) {
    const data = await odata.o(rootGetters)
      .get('emails')
      .query({ $filter: `IsDeleted eq false AND CustomerCode eq '${customerCode}'` });
    commit('SET_emails', data);
    return data;
  },
};

export default { ...actions, ...customActions };
