import axios from 'axios';

export default {
  async LOAD_profile({ commit }) {
    try {
      const response = await axios.get('/api/profiles');
      commit('SET_displayName', response.data.displayName);
    } catch (error) {
      console.error(error);
    }
  },
  LOGOUT({ commit }) {
    commit('SET_username', '');
    commit('SET_token', '');
  },
};
