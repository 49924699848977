<template>
  <v-card style="width: 100%" class="d-flex flex-column">
    <v-card-title class="shop-title">
      {{ stockItem.stockItem.description }}
    </v-card-title>
    <v-img
      contain
      :src="detail.img"
      max-height="380"
    />
    <v-card-text>
      <v-row>
        <v-col cols="12" style="padding: 0; padding-left: 12px">
          R {{ formatCurrency(stockItem.price) }}
          <span v-if="detail.tax > 0">(R {{ formatCurrency(detail.tax) }} VAT)</span>
          / {{ stockItem.unitOfMeasure }}
        </v-col>
        <v-col v-if="detail.qty > 0" cols="12" style="padding: 0; padding-left: 12px">
          {{ stockItem.stockItem.quantity }} items in stock
        </v-col>
        <v-col v-else cols="12" style="padding: 0; padding-left: 12px">
          <span class="red--text">Out of stock</span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-text-field
        ref="quantity"
        label="Quantity"
        :value="stockItem.amount"
        @input="CHANGE_AMOUNT({
          itemCode: stockItem.stockItem.itemCode,
          amount: $event,
        })"
      ></v-text-field>
      <!-- <button-add-to-cart
        :stockItem="stockItem"
        @add="$emit('add', $event)"
        data-cy="btn-add-to-cart"
      /> -->
      <!--
      <v-spacer></v-spacer>
      <v-btn
        small
        class="ml-2"
        color="success" @click="$emit('add', { stockItem, amount })">
        Add to Cart
      </v-btn> -->
    </v-card-actions>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        small
        color="error" @click="REMOVE_CART(stockItem.stockItem.itemCode)">
        Remove
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import form from '../mixins/form';
import validation from '../mixins/validation';
import cart from '../mixins/cart';
import customerRefresh from '../mixins/customerRefresh';
import StockItemMerchandise from './StockItemMerchandise.vue';

export default {
  name: 'CartItemCard',
  mixins: [cart, customerRefresh, form, validation],
  props: {
    stockItem: {
      type: Object,
      default: () => {},
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    StockItemMerchandise,
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
    ...mapGetters('taxes', ['getTax']),
    ...mapGetters('stockItems', ['getStockItemDetail']),
    ...mapState('customers', ['activeCustomer']),
    ...mapState('taxes', ['taxes']),
    detail() {
      return this.stockItem.detail;
    },
  },
  data() {
    return {
      amount: 1,
    };
  },
  mounted() {
    this.amount = 1;
  },
  methods: {
    quantityClicked(e) {
      e.target.select();
    },
    update($evt) {
      this.$emit('change', $evt);
    },
    updateImage(stockItem, $evt) {
      const result = { ...stockItem };
      result.photo = $evt;
      this.$emit('change', result);
    },
  },
};
</script>
