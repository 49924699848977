<template>
  <v-data-table
    :headers="headers"
    :items="emailLogs"
    class="elevation-1"
    :loading="isLoading"
    sort-by="sent"
    :sort-desc="true"
    :search="search"
  >
    <template v-slot:top>
      <v-row no-gutters>
        <v-col cols="6" offset="6">
          <v-text-field
            v-model="search"
            :append-icon="mdiMagnify"
          ></v-text-field>
          <v-btn icon @click="refresh">
            <v-icon>{{ mdiRefresh }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.sent="{ item }">
      {{ formatDateTime(item.sent) }}
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiMagnify, mdiRefresh } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListEmailLog',
  module: 'Shop',
  mixins: [loading],
  data() {
    return {
      mdiRefresh,
      mdiMagnify,
      search: '',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('emailLogs', ['emailLogs']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: 'Customer Code',
          value: 'customerCode',
        },
        {
          text: 'EmailType',
          value: 'emailType',
        },
        {
          text: 'Recipients',
          value: 'recipients',
        },
        {
          text: 'Subject',
          value: 'subject',
        },
        {
          text: 'Time Sent',
          value: 'sent',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('emailLogs', ['LOAD_emailLogs', 'DELETE_emailLog']),
    /** Refresh the collection
     */
    async refresh() {
      this.isLoading = true;
      await this.LOAD_emailLogs();
      this.isLoading = false;
    },
    /** Delete EmailLog from the collection
     */
    async remove(emailLog) {
      this.isLoading = true;
      await this.DELETE_emailLog(emailLog.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# EmailLog example

'''js
<EmailLog />
'''
</docs>
