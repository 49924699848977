<template>
  <CapturePhoto :label="label" @newImage="upload">
  </CapturePhoto>
</template>

<script>
import { mapActions } from 'vuex';
import CapturePhoto from './CapturePhoto.vue';

const baseUrl = process.env.VUE_APP_APIURL;

export default {
  name: 'PhotoUpload',
  components: {
    CapturePhoto,
  },
  props: {
    label: {
      type: String,
      default: 'Add Photo',
    },
  },
  methods: {
    ...mapActions('userFiles', ['CREATE_userFile']),
    fileChanged(file) {
      this.errors = [];
      if (file?.size > this.maxFileSize) {
        this.errors.push(this.errorFileSize);
      } else if (file !== null) {
        this.upload();
      }
    },
    async upload(file) {
      this.isLoading = true;
      const result = await this.CREATE_userFile(file);
      this.$emit('input', `${baseUrl}/auroraApi/files/${result.id}`);
      this.file = null;
      this.isLoading = false;
    },
  },
};
</script>
