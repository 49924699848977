<template>
  <div />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FavouriteService',
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapGetters('favourites', ['count']),
    ...mapGetters('customers', ['activeCustomer']),
  },
  async mounted() {
    this.$root.$on('auth:login', this.refresh);
    this.$root.$on('activeCustomer:change', this.refresh);
    await this.refresh();
  },
  destroyed() {
    this.$root.$off('auth:login', this.refresh);
    this.$root.$off('activeCustomer:change', this.refresh);
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('favourites', ['LOAD_favourites']),
    /**
     * Executes refresh
     */
    async refresh() {
      this.$log.trace('loading favourites');
      await this.LOAD_favourites();
      this.$log.trace(`loaded ${this.count} favourites`);
    },
  },
};
</script>
