<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormRestaurantAdmin
      :RestaurantAdmin="editRestaurantAdmin"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormRestaurantAdmin from '../components/FormRestaurantAdmin.vue';

export default {
  components: {
    FormRestaurantAdmin,
  },
  name: 'EditRestaurantAdmin',
  module: 'RestaurantAdmins',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing RestaurantAdmin. Default to {}.
      */
      editRestaurantAdmin: {},
      /**
       * Query Id for RestaurantAdmin
       */
      restaurantAdminId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('restaurantAdmins', ['restaurantAdmins', 'restaurantAdmin']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.restaurantAdminId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    await Promise.all([
      (async () => {
        await this.loadUsers();
      })(),
      (async () => {
        await this.LOAD_customers();
      })(),
    ]);
    if (this.mode === 'edit') {
      await this.LOAD_restaurantAdmin(this.restaurantAdminId);
      this.editRestaurantAdmin = this.restaurantAdmin;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('customers', ['LOAD_customers']),
    ...mapActions('restaurantAdmins', ['LOAD_restaurantAdmin', 'CREATE_restaurantAdmin', 'UPDATE_restaurantAdmin']),
    ...mapActions('userManagement', ['loadUsers']),
    /** Method used to save the item
     */
    async save(restaurantAdmin) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_restaurantAdmin(restaurantAdmin);
      } else {
        await this.UPDATE_restaurantAdmin(restaurantAdmin);
      }
      this.isSaving = false;
      this.$router.push({ name: 'shop-ListRestaurantAdmin' });
    },
  },
};
</script>

<docs>
# EditRestaurantAdmin example

'''js
<EditRestaurantAdmin :RestaurantAdmin="RestaurantAdmin" />
'''
</docs>
