<template>
  <v-container id="cart-list-categories" fluid class="pa-6">
    <v-row v-if="$vuetify.breakpoint.mdAndUp" class="d-flex">
      <v-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2"
        class="d-flex align-stretch col-sm-padding"
        v-for="(category, index) in categories"
        :key="`category-${index}`"
        :data-cy="`category-${category.name?.toLowerCase()}`"
      >
        <v-card class="fill-height category-card" elevation="1">
          <v-img
            :data-cy="`category-link-${index}`"
            style="cursor: pointer;"
            :src="category.photoUrl"
            @click="selectCategory(category)"
            class="d-flex align-stretch"
            height="150px"
            stretch
          >
            <div class="fill-height w-100 d-flex"
              :class="{ 'category-overlay' : !hideOverlay }">
              <v-card-title
                class="mt-1 mb-0 pl-2 pr-2 white--text"
                style="align-self: end; line-height: 1.2em; padding: 12px;"
                @click="selectCategory(category)"
              >
                {{ category.name }}
              </v-card-title>
            </div>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else class="d-flex" >
      <v-col cols="6" xs="6" sm="4" md="4" lg="2" xl="2"
        class="d-flex flex-row align-stretch pa-2"
        v-for="(category, index) in categories"
        :key="`category-${index}`"
        :data-cy="`category-${category.name?.toLowerCase()}`"
      >
        <v-card class="fill-height category-card w-100" elevation="1">
          <v-img
            :data-cy="`category-link-${index}`"
            style="cursor: pointer;"
            :src="category.photoUrl"
            @click="selectCategory(category)"
            class="d-flex align-stretch"
            :height="$vuetify.breakpoint.mobile ? '120px' : '150px'"
            stretch
          >
            <div class="fill-height w-100 d-flex"
            :class="{ 'category-overlay' : !hideOverlay }">
              <v-card-title
                class="mt-1 mb-0 pl-2 pr-2 white--text"
                style="align-self: end; line-height: 1.2em; padding: 12px;"
                @click="selectCategory(category)"
              >
                {{ category.name }}
              </v-card-title>
            </div>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CardListCategories',
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    depth: {
      type: Number,
      default: 1,
    },
    hideOverlay: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
    imageHeight() {
      if (this.$vuetify.breakpoint.mobile) {
        return '100px';
      }
      return '250px';
    },
  },
  methods: {
    selectCategory(category) {
      const { depth } = this;
      this.$router.push({
        name: 'shop-ShopStockItemByCategory',
        query: {
          category: category.id,
          categoryName: category.name,
          depth,
        },
      });
    },
    updateImage(category, $evt) {
      const result = { ...category };
      result.photoUrl = $evt;
      this.$emit('change', result);
    },
  },
};
</script>
