<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-row>
          <v-col cols="12">
            <v-radio-group
              v-model="localCustomerLocation.customerType"
              row
              :disabled="localCustomerLocation.id !== undefined"
            >
              <v-radio value="new" label="New Customer" />
              <v-radio value="existing" label="Existing Customer" />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="localCustomerLocation.customerType === 'existing'">
          <v-col cols="12">
            <v-autocomplete
              data-cy="FormCustomerLocation-customerCode"
              v-model="localCustomerLocation.customerCode"
              label="Select a Customer"
              item-text="customerDesc"
              item-value="customerCode"
              :items="customers"
              :disabled="localCustomerLocation.id !== undefined"
            />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <v-text-field
              data-cy="FormCustomerLocation-customerName"
              v-model="localCustomerLocation.customerName"
              label="Customer Name"
            />
          </v-col>
        </v-row>
        <div v-if="localCustomerLocation.id">
          <v-row>
            <v-col cols="12">
              <GmapMap
                :center="center"
                :zoom="zoom"
                style="width: 100%; height: 300px"
                @click="mapClicked"
              >
                <GmapMarker
                  :key="1"
                  :position="{ lat: localCustomerLocation.lat, lng: localCustomerLocation.lng }"
                />
              </GmapMap>
              <v-btn
                color="primary"
                @click="getCurrentPosition"
                class="mt-2"
                :loading="isBusy"
                :block="$vuetify.breakpoint.mobile"
              >
                Get my location
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                filled
                :items="positions"
                data-cy="FormCustomerLocation-manager"
                :label="$$t('manager')"
                v-model="localCustomerLocation.manager"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                filled
                :items="restaurantTypes"
                data-cy="FormCustomerLocation-restaurantType"
                :label="$$t('restaurantType')"
                v-model="localCustomerLocation.restaurantType"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                filled
                data-cy="FormCustomerLocation-receivingContact"
                :label="$$t('receivingContact')"
                v-model="localCustomerLocation.receivingContact"
              />
            </v-col>
          </v-row>
          <CustomerLocationTimes
            :customer-location="CustomerLocation"
            time-type="Delivery"
          />
          <div class="mt-10 mb-10" />
          <CustomerLocationTimes
            :customer-location="CustomerLocation"
            time-type="Trading"
          />
          <v-row class="mt-10">
            <v-col cols="12">
              <v-textarea
                filled
                rows="3"
                data-cy="FormCustomerLocation-notes"
                :label="$$t('notes')"
                v-model="localCustomerLocation.notes"
              />
            </v-col>
          </v-row>
          <div class="d-flex justify-end mb-6">
            <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
              data-cy="FormCustomerLocation-button-cancel">
              {{ $t('common.buttons.cancel') }}
            </v-btn>
            <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
              data-cy="FormCustomerLocation-button-save">
                {{ $t('common.buttons.save') }}
              </v-btn>
          </div>
        </div>
        <div v-else>
          <v-btn
            :loading="isSaving"
            color="success"
            @click="save"
          >
            Save
          </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import CustomerLocationTimes from './CustomerLocationTimes.vue';

export default {
  name: 'FormCustomerLocation',
  module: 'Shop',
  components: {
    CustomerLocationTimes,
  },
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    CustomerLocation: {
      type: Object,
      default: undefined,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localCustomerLocation
      */
      localCustomerLocation: {},
      isBusy: false,
      center: { lat: 0, lng: 0 },
      zoom: 9,
    };
  },
  computed: {
    ...mapGetters('customers', ['restaurantTypes']),
    ...mapState('customers', ['customers', 'positions']),
    platform() {
      return Capacitor.getPlatform();
    },
    isWeb() {
      return this.platform === 'web';
    },
    isIos() {
      return this.platform === 'ios';
    },
    isAndroid() {
      return this.platform === 'android';
    },
  },
  watch: {
    CustomerLocation() {
      this.localCustomerLocation = this.CustomerLocation;
      this.center = { lat: this.localCustomerLocation.lat, lng: this.localCustomerLocation.lng };
    },
  },
  created() {
    this.localCustomerLocation = this.CustomerLocation;
    this.center = { lat: this.localCustomerLocation.lat, lng: this.localCustomerLocation.lng };
  },
  methods: {
    save() {
      this.$emit('create', this.localCustomerLocation);
    },
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localCustomerLocation);
      }
    },
    async getCurrentPositionNative() {
      const coordinates = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
      });
      return coordinates;
    },
    async getCurrentPositionWeb() {
      if (navigator.geolocation) {
        return new Promise((resolve, reject) => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => resolve(position),
              (error) => reject(error),
            );
          } else {
            reject(new Error('Geolocation is not supported by this browser.'));
          }
        });
      }
      throw new Error('Geolocation is not supported by this browser.');
    },
    /**
     * Get the users location and then set the lat / lng according
     * to the users location and then recenter the map
     */
    async getCurrentPosition() {
      let position;
      this.isBusy = true;
      try {
        if (this.isWeb) {
          position = await this.getCurrentPositionWeb();
        } else {
          position = await this.getCurrentPositionNative();
        }
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Cannot access your location');
      }
      if (position) {
        this.localCustomerLocation.lng = position.coords.longitude;
        this.localCustomerLocation.lat = position.coords.latitude;
        this.center = {
          lat: this.localCustomerLocation.lat,
          lng: this.localCustomerLocation.lng,
        };
        this.zoom = 18;
      }
      this.isBusy = false;
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
    /**
     * When the map is clicked, it places a pin on the map
     * @param {object} e
     */
    mapClicked(e) {
      this.localCustomerLocation.lng = e.latLng.lng();
      this.localCustomerLocation.lat = e.latLng.lat();
    },
  },
};
</script>

<docs>
# FormCustomerLocation example

'''js
<FormCustomerLocation :CustomerLocation="CustomerLocation" />
'''
</docs>
