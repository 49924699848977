<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              data-cy="FormPreferredDeliveryAddress-customerId"
              filled
              :label="$$t('customerCode')"
              item-text="customerDesc"
              item-value="customerId"
              :items="customers"
              @change="customerChanged"
              v-model="localPreferredDeliveryAddress.customerId"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              data-cy="FormPreferredDeliveryAddress-deliveryAddressId"
              filled
              :label="$$t('deliveryAddress')"
              :items="deliveryAddresses"
              item-text="delAddress01"
              item-value="id"
              :loading="loadingDeliveryAddresses"
              v-model="localPreferredDeliveryAddress.deliveryAddressId"
            >
              <template v-slot:item="{ item }">
                <div style="min-height: 50px; margin-bottom: 15px; width: 100%">
                  <p>{{ item.delAddress01 }}</p>
                  <p>{{ item.delAddress02 }}</p>
                  <p>{{ item.delAddress03 }}</p>
                  <v-divider></v-divider>
                </div>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              :items="users"
              item-text="username"
              item-value="username"
              filled
              data-cy="FormPreferredDeliveryAddress-username"
              :label="$$t('username')"
              v-model="localPreferredDeliveryAddress.username"
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormPreferredDeliveryAddress-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormPreferredDeliveryAddress-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mapState } from 'vuex';

export default {
  name: 'FormPreferredDeliveryAddress',
  module: 'Shop',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    PreferredDeliveryAddress: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      loadingDeliveryAddresses: false,
      deliveryAddresses: [],
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localPreferredDeliveryAddress
      */
      localPreferredDeliveryAddress: {},
    };
  },
  computed: {
    ...mapState('customers', ['customers']),
    ...mapState('userManagement', ['users']),
  },
  watch: {
    PreferredDeliveryAddress() {
      this.localPreferredDeliveryAddress = this.PreferredDeliveryAddress;
    },
  },
  created() {
    this.localPreferredDeliveryAddress = this.PreferredDeliveryAddress;
  },
  async mounted() {
    if (this.localPreferredDeliveryAddress.customerId) {
      await this.customerChanged(this.localPreferredDeliveryAddress.customerId);
    }
  },
  methods: {
    async customerChanged(customerCode) {
      this.loadingDeliveryAddresses = true;
      const url = `/api/deliveries/addresses/${customerCode}`;
      const { data } = await this.$http.get(url);
      this.deliveryAddresses = data;
      this.loadingDeliveryAddresses = false;
    },
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localPreferredDeliveryAddress);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormPreferredDeliveryAddress example

'''js
<FormPreferredDeliveryAddress :PreferredDeliveryAddress="PreferredDeliveryAddress" />
'''
</docs>
