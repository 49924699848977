<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormCategory
      :Category="editCategory"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormCategory from '../components/FormCategory.vue';

export default {
  components: {
    FormCategory,
  },
  name: 'EditCategory',
  module: 'Category',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing Category. Default to {}.
      */
      editCategory: {},
      /**
       * Query Id for Category
       */
      categoryId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('categories', ['categories', 'category']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.categoryId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_category(this.categoryId);
      this.editCategory = this.category;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('categories', ['LOAD_category', 'CREATE_category', 'UPDATE_category']),
    /** Method used to save the item
     */
    async save(category) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_category(category);
      } else {
        await this.UPDATE_category(category);
      }
      this.isSaving = false;
      this.$router.push({ name: 'shop-ListCategory' });
    },
  },
};
</script>

<docs>
# EditCategory example

'''js
<EditCategory :Category="Category" />
'''
</docs>
