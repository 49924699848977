<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <h1>Category</h1>
        <v-text-field
          filled
          data-cy="FormCategory-name"
          :label="$$t('name')"
          v-model="localCategory.name"
        />
        <v-img :src="localCategory.photo" contain height="320" />
        <ImageUpload v-model="localCategory.photo" label="Photo" />
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormCategory-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isLoading"
            data-cy="FormCategory-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import ImageUpload from '@codehq/aurora-app-core/src/components/ImageUpload.vue';

export default {
  name: 'FormCategory',
  module: 'Category',
  components: {
    ImageUpload,
  },
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Category: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localCategory
      */
      localCategory: {},
    };
  },
  watch: {
    Category() {
      this.localCategory = this.Category;
    },
  },
  created() {
    this.localCategory = this.Category;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localCategory);
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormCategory example

'''js
<FormCategory :Category="Category" />
'''
</docs>
