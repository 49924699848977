<template>
  <v-badge
    color="primary"
    left
    transition="slide-x-transition"
    v-if="totalItems > 0"
  >
    <template v-slot:badge>
      {{ totalItems }}
    </template>
    <v-icon class="mr-1" size="26"> {{ mdiArchiveClockOutline }} </v-icon>
  </v-badge>
</template>

<script>
import { mdiArchiveClockOutline } from '@mdi/js';

export default {
  data() {
    return {
      mdiArchiveClockOutline,
      items: [],
      timer: null,
    };
  },
  computed: {
    totalItems() {
      return this.items?.length ?? 0;
    },
  },
  async mounted() {
    this.$root.$on('order:created', () => {
      this.loadData();
    });
    await new Promise((r) => setTimeout(r, 5000));
    await this.loadData();
    this.timer = setInterval(() => {
      this.sendOrders();
    }, 30000);
  },
  destroyed() {
    this.$root.$off('order:created');
    clearInterval(this.timer);
  },
  methods: {
    async loadData() {
      const data = await this.$db.getAll('orders');
      this.items = data;
    },
    sendOrders() {
      if (this.items) {
        this.items.forEach(async (order) => {
          await this.$http.post('api/orders', order);
          this.$db.remove('orders', order.id);
          this.$root.$emit('toast:notify', 'Order placed successfully');
          await this.loadData();
        });
      }
    },
  },
};
</script>
