<template>
  <v-container fluid fill-height>
    <v-row class="fill-height">
      <v-col
        cols="12"
        class="d-flex flex-column align-center justify-center"
        offset-lg="3"
        lg="6"
      >
        <v-card class="w-100" flat style="border: 2px solid #0067b3">
          <v-card-title class="font-weight-bold">Contact Us</v-card-title>
          <v-alert v-if="error" :value="true" type="error">
            There was an error processing your request, please try again later.
          </v-alert>
          <v-card-text>
            <v-form
              lazy-validation
              v-model="valid"
              @submit.prevent="submit"
              ref="form"
            >
              <v-text-field label="Name" v-model="form.name" outlined
                :rules="rules.required" required />
              <v-text-field label="Email" type="email" v-model="form.email" outlined
                :rules="rules.email" required />
              <v-textarea rows="3" label="Message" v-model="form.message" outlined
                :rules="rules.required" required />
              <v-btn block type="submit" color="primary" :loading="isSaving">
                Send message
              </v-btn>
            </v-form>
          </v-card-text>
          <v-alert v-if="success" :value="true" type="success" dismissible>
            Thank you for you feedback, we will be in touch shortly.
          </v-alert>
          <v-btn v-if="success" color="primary"
            :to="{ name: 'shop-ShopHome' }">
            Back Home
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'CustomerCare',
  mixins: [loading],
  data: () => ({
    form: {
      name: '',
      email: '',
      message: '',
    },
    error: null,
    success: false,
    valid: false,
    rules: {
      email: [
        (v) => !!v || 'This field is required',
        (v) => /^[A-z0-9\-.]{3}.*@[A-z0-9]*\.[A-z]{2}.*$/gi.test(v) || 'Please provide valid E-Mail',
      ],
      password: [
        (v) => !!v || 'This field is required',
        (v) => v.length > 7 || 'Password should be longer',
      ],
      required: [
        (v) => !!v || 'This field is required',
      ],
    },
  }),
  computed: {
    ...mapState('auth', ['username']),
  },
  mounted() {
    this.SET_title('Customer Care');
    this.form.email = this.username;
  },
  methods: {
    ...mapMutations('app', ['SET_title', 'SET_expandedSearch']),
    async submit() {
      this.error = null;
      // validate
      if (this.$refs.form.validate()) {
        this.isSaving = true;
        try {
          await this.$http.post('api/contact-us', this.form);
          this.form = {
            name: '',
            email: this.username,
            message: '',
          };
          this.error = null;
          this.success = true;
        } catch (error) {
          this.$log.error(error);
          this.error = error.message;
        }
        this.isSaving = false;
      }
    },
  },
};
</script>
