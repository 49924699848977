import cookies from 'browser-cookies';

export default {
  getToken: (state) => state.token,
  getUsername: (state) => {
    if (state.username) {
      return state.username;
    }
    return JSON.parse(cookies.get('apnetAuth'))?.userName;
  },
  isLoggedIn: (state) => state.username,
  isInRole: (state) => (role) => state.roles.some((r) => r === role),
  tokenRemaining: (state) => {
    const date1 = new Date(state.expires);
    const date2 = new Date();
    return Math.abs(date2 - date1);
  },
  isExpired: (state, getters) => {
    if (getters.tokenRemaining > 60000) {
      return false;
    }
    return true;
  },
};
