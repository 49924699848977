import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('fleetSupplierBooking', 'fleetSupplierBookings');

const customActions = {
  async LOAD_fleetSupplierBookings({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('fleetSupplierBookings')
      .query({ $filter: 'IsDeleted eq false', $expand: 'FleetSupplier,Vehicle', $orderby: 'startDate desc' });
    commit('SET_fleetSupplierBookings', data);
    return data;
  },
};

export default { ...actions, ...customActions };
