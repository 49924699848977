<template>
  <div>
    <CustomerList :items="customers" :show-buttons="false" />
  </div>
</template>

<script>
import CustomerList from '@/components/CustomerList.vue';

export default {
  name: 'ReportSlowCustomers',
  components: {
    CustomerList,
  },
  data() {
    return {
      customers: [],
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      const { data } = await this.$http.get('/odata/customers/GetSlow');
      this.customers = data.value;
    },
  },
};
</script>
