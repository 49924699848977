<template>
  <div>
    <div v-if="isLoading" class="d-block">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      Loading Product
    </div>
    <v-card v-else>
      <v-card-title class="subheading font-weight-bold">
        {{ item.description }}
        <!-- <v-btn text icon color="primary"
          style="position: absolute; right: 5px"
          :to="{
            name: 'shop-StockItemDetail',
            query: { id: item.id },
          }">
          <v-icon>{{ mdiInformationOutline }}</v-icon>
        </v-btn> -->
      </v-card-title>

      <v-divider></v-divider>
      <v-img
        height="128"
        contain
        :src="item.photo"
      />
      <v-card-text>
        R 0.00
      </v-card-text>
      <v-card-actions>
        <v-text-field
          label="Quantity"
          :value="1"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="$emit('toast:notify', 'Added to cart')">
          Add to Cart
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiMagnify, mdiInformationOutline } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ShopStockItem',
  mixins: [loading],
  data() {
    return {
      item: undefined,
      itemCode: this.$route?.query?.itemCode,
      mdiMagnify,
      mdiInformationOutline,
    };
  },
  async mounted() {
    this.isLoading = true;
    this.item = await this.LOAD_stockItem(this.itemCode);
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('stockItems', ['LOAD_stockItem']),
  },
};
</script>
