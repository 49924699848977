<template>
  <div />
</template>

<script>
import { authHub } from '@codehq/aurora-app-core/src';

export default {
  name: 'Auth',
  created() {
    authHub.$on('network:unauthorized', async () => {
      this.$root.$emit('auth:logoff');
    });
  },
};
</script>
