<template>
  <v-container fluid>
    <v-data-table
      data-cy="favourites-list"
      :headers="headers"
      id="data-table"
      :items="items"
      :items-per-page="15"
      :footer-props="footerProps"
      @pagination="page"
      group-by="detail.category"
      class="elevation-3"
      :search="search"
    >
      <template v-slot:group.header="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-btn @click="toggle" x-small icon :ref="group">
              <v-icon color="primary" v-if="isOpen">{{ mdiMinus }}</v-icon>
              <v-icon color="primary" v-else>{{ mdiPlus }}</v-icon>
          </v-btn>
          <span class="mx-5 font-weight-bold">{{ group }}</span>
        </td>
      </template>
      <template v-slot:top>
        <v-progress-linear v-if="isLoading" :indeterminate="true"></v-progress-linear>
        <v-toolbar
          class="mb-1"
        >
          <v-text-field
            clearable
            flat
            solo-inverted
            hide-details
            :prepend-inner-icon="mdiMagnify"
            label="Search"
            v-debounce:500="searchChanged" :debounce-events="'input'"
          />
          <v-spacer></v-spacer>
          <v-btn
            title="Reload favourites"
            class="text-right"
            color="primary"
            icon
            @click="$emit('refresh')">
            <v-icon class="mr-2">{{ mdiRefresh }}</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.photo="{ item }">
        <v-img
          width="92"
          contain
          :src="resizeImage(item)"
        />
      </template>
      <template v-slot:item.detail.price="{ item }">
        R {{ formatCurrency(item.detail.price) }}
      </template>
      <template v-slot:item.detail.tax="{ item }">
        <span v-if="item.detail.tax > 0">R {{ formatCurrency(item.detail.tax) }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.favourites="{ item }">
        <favourite
          :identifier="item.itemCode.toString()"
          :name="`stockItem-${activeCustomer.customerCode}`"
          @change="$emit('favouriteChange')"
        />
      </template>
      <template v-slot:item.actions="{ item }">
        <ButtonAddToCart
          @add="add"
          :stockItem="item"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  mdiMagnify, mdiMinus, mdiPlus, mdiRefresh,
} from '@mdi/js';
import ButtonAddToCart from './ButtonAddToCart.vue';
import Favourite from './Favourite.vue';
import cartMethods from '../mixins/cartMethods';

export default {
  name: 'ListFavouriteItems',
  props: ['stockItems', 'isLoading'],
  components: {
    ButtonAddToCart,
    Favourite,
  },
  mixins: [cartMethods],
  data() {
    return {
      mdiMagnify,
      mdiMinus,
      mdiPlus,
      mdiRefresh,
      search: '',
    };
  },
  computed: {
    ...mapGetters('stockItems', ['getStockItemDetail']),
    ...mapState('customers', ['activeCustomer']),
    detail() {
      return this.getStockItemDetail(this.stockItem);
    },
    footerProps() {
      return { 'items-per-page-options': [15, 25, 50, 100, 500, -1] };
    },
    headers() {
      return [
        {
          text: '',
          value: 'photo',
        },
        {
          text: 'Code',
          align: 'start',
          value: 'itemCode',
          groupable: false,
        },
        {
          text: 'Description',
          align: 'start',
          value: 'description',
          groupable: false,
        },
        {
          text: 'Available',
          align: 'end',
          value: 'detail.qty',
          groupable: false,
        },
        {
          text: 'Price',
          value: 'detail.price',
          align: 'end',
          width: '100px',
        },
        {
          text: 'VAT',
          value: 'detail.tax',
          align: 'end',
        },
        {
          text: '',
          value: 'favourites',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
    items() {
      const { getStockItemDetail, stockItems } = this;
      return stockItems.map((s) => {
        const result = { ...s };
        result.detail = getStockItemDetail(s);
        return result;
      });
    },
  },
  async mounted() {
    await Promise.all([
      (async () => {
        await this.LOAD_webCategory1();
      })(),
      (async () => {
        await this.LOAD_webCategory2();
      })(),
      (async () => {
        await this.LOAD_webCategory3();
      })(),
      (async () => {
        await this.LOAD_webCategory4();
      })(),
      (async () => {
        await this.LOAD_stockLimits();
      })(),
    ]);
    this.LOAD_pricings();
  },
  methods: {
    ...mapActions('pricings', ['LOAD_pricings']),
    ...mapActions('stockItems', ['LOAD_stockLimits']),
    ...mapActions('webCategories', [
      'LOAD_webCategory1',
      'LOAD_webCategory2',
      'LOAD_webCategory3',
      'LOAD_webCategory4',
    ]),
    page() {
      const element = document.getElementsByClassName('breadcrumb')[0];
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    },
    resizeImage(stockItem) {
      const result = `${stockItem?.photo}` ?? '';
      return result;
    },
    searchChanged(val) {
      this.search = val;
    },
  },
};
</script>
