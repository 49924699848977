import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('auth', ['isInRole']),
    ...mapState('auth', ['userId']),
  },
  methods: {
    isApprover(item) {
      if (this.isInRole('Agents') || this.isInRole('Administrators')) {
        return true;
      }
      return item?.approvers?.split(';').includes(this.userId) || false;
    },
  },
};
