<template>
  <v-container fluid fill-height align-center>
    <v-row>
      <v-col cols="12" lg="6" offset-lg="3">
        <v-card flat>
          <v-card-text>
            <v-textarea
              v-model="description"
              label="Describe the product"
              data-cy="ProductRequest-description"
              outlined
            />
            <FileUploader @input="updateImage" />
            <v-img
              v-if="photoUrl"
              :src="photoUrl"
              contain
              max-height="200"
            />
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-row>
              <v-col cols="4">
                <v-btn
                  data-cy="ProductRequest-button-cancel"
                  @click="dialog = false"
                  color="success"
                  block
                >
                  Cancel
                </v-btn>
              </v-col>
              <v-col cols="8">
                <v-btn
                  data-cy="ProductRequest-button-ok"
                  color="primary"
                  block
                  @click="requestProduct">
                    Send request
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex';
import FileUploader from '@/components/FileUploader.vue';

/**
 * @typedef {Object} StockItem
 * @property {number} id
 * @property {string} description
 */

export default {
  name: 'ProductRequest',
  components: {
    FileUploader,
  },
  data() {
    return {
      /** @type {number} */
      description: '',
      photoUrl: undefined,
    };
  },
  mounted() {
    this.SET_title('Product Request');
    this.SET_expandedSearch(false);
  },
  methods: {
    ...mapMutations('app', ['SET_title', 'SET_expandedSearch', 'SET_displayName']),
    /**
     * Sends a request to the server for the specified stock item.
     * @returns {Promise<void>}
     */
    async requestProduct() {
      try {
        const { description, photoUrl } = this;
        await this.$http.post('/api/stockItems/request-product', {
          description,
          photoUrl,
        });
        this.$root.$emit('toast:notify', 'Product Request placed successfully');
        this.description = '';
        this.photoUrl = undefined;
        this.$router.push({ name: 'shop-ShopCategories' });
      } catch (error) {
        console.error(error);
      } finally {
        this.dialog = false;
      }
    },
    /**
      * Updates the photo url.
     * @param {*} $evt
     */
    updateImage($evt) {
      this.photoUrl = $evt;
    },
  },
};
</script>
