import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('pickingListItem', 'pickingListItems');

const customActions = {
  async LOAD_pickingListItems({ commit, rootGetters }, pickingListId) {
    const data = await odata.o(rootGetters)
      .get('pickingListItems')
      .query({ $filter: `IsDeleted eq false AND PickingListId eq ${pickingListId}` });
    commit('SET_pickingListItems', data);
    return data;
  },
};

export default { ...actions, ...customActions };
