<template>
  <v-container fluid>
    <ProgressLinearTimeout v-if="isLoading" />
    <v-row v-if="order && activeCustomer">
      <v-col cols="12" lg="6">
        <div
          class="d-flex align-center"
          :class="{
            'justify-start': isDesktop,
            'justify-center': isMobile,
            'text-center': isMobile,
            'flex-column': isMobile,
          }"
        >
          <img src="../assets/check.png" height="60" width="60" />
          <b class="text-large mr-2">Thank you for your order!</b>
        </div>
        <div v-if="$vuetify.breakpoint.mobile">
          <div class="text-medium text-center">
            Your order has been conﬁrmed and scheduled for delivery on
            <b>{{ order.deliveryDate }}</b>.
            Your items will be delivered to the following address:
            <b>{{ address }}</b>
          </div>
        </div>
        <div v-else>
          <div class="text-large mt-5">
            Your order has been conﬁrmed and scheduled for delivery on:
          </div>
          <div class="text-large font-weight-bold">{{ order.deliveryDate }}</div>
          <div v-if="address" class="text-large mt-5">
            Your items will be delivered to the following address:
          </div>
          <div v-if="address" class="text-large font-weight-bold">{{ address }}</div>
        </div>
        <div class="text-medium background--grey pa-5 mt-5">
          <div class="d-flex align-center justify-space-between">
            <span>Customer Code:</span>
            <span class="text-right">{{ activeCustomer.customerDesc }}</span>
          </div>
          <div class="d-flex align-center justify-space-between">
            <span>Customer Order No:</span>
            <span class="text-right">{{ order.orderNo }}</span>
          </div>
        </div>
        <div class="text-medium background--grey pa-5 mt-5" v-if="order.notes.length > 0">
          <div class="text-medium font-weight-bold">
            Notes:
          </div>
          <div class="text-medium">
            {{ order.notes }}
          </div>
        </div>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card
            color="#eeeeee"
            elevation="0"
          >
            <v-card-title primary-title class="text-h4">
              Order Summary
            </v-card-title>
            <v-card-text>
              <table class="table-summary">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Qty</th>
                    <th>Price</th>
                    <th v-if="!$vuetify.breakpoint.mobile">Tax</th>
                    <th v-if="!$vuetify.breakpoint.mobile">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in displayCart" :key="item.id">
                    <td><b>{{ item.stockItem?.description }}</b></td>
                    <td><span>{{ item.amount }}</span></td>
                    <td><span>R{{ formatCurrency(item.price) }}</span></td>
                    <td v-if="!$vuetify.breakpoint.mobile">
                      <span>R{{ formatCurrency(item.tax) }}</span>
                    </td>
                    <td v-if="!$vuetify.breakpoint.mobile">
                      <span>R{{ formatCurrency(item.total) }}</span>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td :colspan="$vuetify.breakpoint.mobile ? 2 : 4"><b>Subtotal</b></td>
                    <td><b>R{{ formatCurrency(subTotal) }}</b></td>
                  </tr>
                  <tr>
                    <td :colspan="$vuetify.breakpoint.mobile ? 2 : 4"><b>VAT</b></td>
                    <td><b>R{{ formatCurrency(vat) }}</b></td>
                  </tr>
                  <tr>
                    <td :colspan="$vuetify.breakpoint.mobile ? 2 : 4"><b>Total</b></td>
                    <td><b>R{{ formatCurrency(total) }}</b></td>
                  </tr>
                </tfoot>
              </table>
            </v-card-text>
          </v-card>
      </v-col>
      <v-col cols="12">
        <v-btn
          :large="!$vuetify.breakpoint.mobile"
          :block="$vuetify.breakpoint.mobile"
          color="accent"
          elevation="0"
          class="black--text"
          :to="{ name: 'shop-ShopCategories' }"
        >
          Continue shopping
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!isLoading">
    </v-row>
  </v-container>
</template>

<script>
import {
  mapActions, mapMutations, mapGetters, mapState,
} from 'vuex';
import { mdiPlus, mdiTrashCan } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';

export default {
  name: 'OrderConfirmed',
  components: {
    ProgressLinearTimeout,
  },
  mixins: [loading],
  props: {
    cartItems: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      mdiTrashCan,
      orderId: undefined,
      order: undefined,
      deliveryAddress: undefined,
      displayCart: [],
    };
  },
  computed: {
    ...mapGetters('auth', ['isInRole']),
    ...mapGetters('stockItems', ['getStockItemDetail', 'getStockItemById']),
    ...mapState('customers', ['activeCustomer']),
    ...mapState('finance', ['client']),
    address() {
      if (this.deliveryAddress) {
        return `${this.deliveryAddress.delAddress01}, ${this.deliveryAddress.delAddress02}, ${this.deliveryAddress.delAddress03}, ${this.deliveryAddress.delAddress04}, ${this.deliveryAddress.delAddress05}`;
      }
      return '';
    },
    cart() {
      return this.order?.cart;
    },
    headers() {
      let result = [
        {
          text: 'Product',
          value: 'stockItem.description',
        },
        {
          text: 'Qty',
          value: 'amount',
        },
        {
          text: 'Price',
          value: 'price',
        },
        {
          text: 'VAT',
          value: 'tax',
        },
        {
          text: 'Total',
          value: 'total',
        },
      ];
      if (this.$vuetify.breakpoint.lgAndDown) {
        result = result
          .filter((x) => x.value !== 'stockItem.photo'
            && x.value !== 'stockItem.quantity');
      }
      return result;
    },
    isDesktop() {
      return !this.$vuetify.breakpoint.mobile;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    subTotal() {
      return this.displayCart.reduce((a, b) => a + ((b.price * b.amount) || 0), 0);
    },
    vat() {
      return this.displayCart.reduce((a, b) => a + ((b.tax * b.amount) || 0), 0);
    },
    total() {
      return this.subTotal + this.vat;
    },
  },
  mounted() {
    this.SET_title('Order Confirmed');
    this.SET_expandedSearch(true);
    this.orderId = this.$route.query.orderId;
    this.refresh();
  },
  methods: {
    ...mapActions('deliveryAddresses', ['LOAD_deliveryAddressesById']),
    ...mapActions('pricings', ['LOAD_pricings']),
    ...mapActions('stockItems', ['LOAD_stockItem']),
    ...mapMutations('app', ['SET_title', 'SET_expandedSearch']),
    async refresh() {
      try {
        this.isLoading = true;
        const orderResult = await this.$http.get(`api/orders/${this.orderId}`);
        this.order = orderResult.data;
        await Promise.all([
          (async () => {
            // eslint-disable-next-line max-len
            this.deliveryAddress = await this.LOAD_deliveryAddressesById(this.order.deliveryAddressId);
          })(),
          (async () => {
            await this.LOAD_pricings();
          })(),
        ]);

        const cartItems = this.cart;
        this.displayCart = [];

        for (let i = 0; i < cartItems.length; i += 1) {
          const c = cartItems[i];
          const result = { ...c };
          // eslint-disable-next-line no-await-in-loop
          result.stockItem = await this.LOAD_stockItem(c.itemCode);
          const detail = this.getStockItemDetail(result.stockItem);
          result.price = detail.price;
          result.tax = detail.tax;
          result.total = c.amount * (result.price + result.tax);
          result.detail = detail;
          this.displayCart.push(result);
        }
      } catch (error) {
        this.$root.$emit('toast:error', 'Error loading order');
        this.$log.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
