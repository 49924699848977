<template>
  <div>
    <v-calendar
      :events="dates"
      color="primary"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'MaintenanceSchedule',
  computed: {
    /** Vuex states
     */
    ...mapState('vehicles', ['vehicles']),
    dates() {
      return this.vehicles.map((v) => ({
        name: `${v.make} ${v.model} ${v.licensePlate}`,
        start: this.formatDate(v.nextServiceDate),
        end: this.formatDate(v.nextServiceDate),
        color: 'success',
        timed: false,
      }));
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_vehicles();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('vehicles', ['LOAD_vehicles']),
  },
};
</script>
