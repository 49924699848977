<template>
  <v-row>
    <v-col cols="12">
      <PhotoUpload @input="addImage" />
    </v-col>
    <div class="d-flex flex-wrap ml-5 w-100">
      <v-img
        v-for="(v, index) in images"
        :key="index"
        class="mr-2"
        :src="v" :alt="label" width="250" contain
      />
    </div>
  </v-row>
</template>

<script>
import { mdiPlus, mdiMinus } from '@mdi/js';
import PhotoUpload from '@/components/PhotoUpload.vue';

export default {
  name: 'MultipleTextFields',
  components: {
    PhotoUpload,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mdiPlus,
      mdiMinus,
      localValues: [],
    };
  },
  computed: {
    images() {
      const { localValues } = this;
      return localValues.reverse();
    },
    valueString() {
      return this.localValues.join(';');
    },
  },
  watch: {
    value(newValues) {
      this.localValues = newValues.split(';');
    },
  },
  mounted() {
    if (this.value?.length > 0) {
      this.localValues = this.value.split(';');
    }
  },
  methods: {
    addImage(value) {
      this.localValues.push(value);
      this.$emit('input', this.valueString);
    },
    toggleItem(index) {
      if (index === 0) {
        this.localValues.push('');
      } else {
        this.localValues.splice(index, 1);
      }
      this.$emit('input', this.valueString);
    },
  },
};
</script>
