export default {
  mounted() {
    this.$root.$on('sync:completed', async () => {
      await new Promise((r) => setTimeout(r, 1500));
      this.refresh();
    });
  },
  destroyed() {
    this.$root.$off('sync:completed');
  },
};
