<template>
  <div class="nav-list">
    <Breadcrumbs :history="history" />
    <v-container fluid>
      <v-card>
        <v-card-text>
          <v-skeleton-loader
            :loading="isLoading"
            transition="expand-transition"
            type="article"
          >
            <FormDriver
              :Driver="editDriver"
              :isSaving="isSaving"
              @save="save"
              @cancel="$router.push({ name: 'fleet-FleetHome', query: { tab: '1' } })" />
          </v-skeleton-loader>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormDriver from '../components/FormDriver.vue';

export default {
  components: {
    FormDriver,
  },
  name: 'EditDriver',
  module: 'Fleet',
  mixins: [loading],
  data() {
    return {
      /**
       * History for breadcrumbs
       */
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Fleet',
        disabled: false,
        href: '/#/fleet/fleet-home',
        depth: 0,
      }, {
        text: 'Driver',
        disabled: true,
        depth: 0,
      }],
      /**
      * Get/Set editing Driver. Default to {}.
      */
      editDriver: {},
      /**
       * Query Id for Driver
       */
      driverId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('drivers', ['drivers', 'driver']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.driverId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_driver(this.driverId);
      this.editDriver = this.driver;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('drivers', ['LOAD_driver', 'CREATE_driver', 'UPDATE_driver']),
    /** Method used to save the item
     */
    async save(driver) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_driver(driver);
      } else {
        await this.UPDATE_driver(driver);
      }
      this.isSaving = false;
      this.$router.push({ name: 'fleet-FleetHome', query: { tab: '1' } });
    },
  },
};
</script>

<docs>
# EditDriver example

'''js
<EditDriver :Driver="Driver" />
'''
</docs>
