<template>
  <div>
    <v-card :loading="isLoading">
      <v-card-text>
        <FormCustomerLocation
          :CustomerLocation="editCustomerLocation"
          :isSaving="isSaving"
          @create="create"
          @save="save"
          @cancel="$router.push({name: from.name })" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormCustomerLocation from '../components/FormCustomerLocation.vue';

export default {
  components: {
    FormCustomerLocation,
  },
  name: 'EditCustomerLocation',
  module: 'Shop',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing CustomerLocation. Default to {}.
      */
      editCustomerLocation: {
        lat: -28,
        lng: 26,
        customerType: 'existing',
      },
      /**
       * Query Id for CustomerLocation
       */
      customerLocationId: this.$route?.query.id,
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Customer Locations',
        href: '/#/shop/list-customer-location',
        disabled: false,
        depth: 0,
      }, {
        text: 'Edit',
        disabled: true,
        depth: 0,
      }],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('customerLocations', ['customerLocations', 'customerLocation']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.customerLocationId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    await this.refresh();
  },
  watch: {
    $route() {
      this.customerLocationId = this.$route?.query.id;
      this.refresh();
    },
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('customerLocations', ['LOAD_customerLocation', 'CREATE_customerLocation', 'UPDATE_customerLocation']),
    ...mapActions('customerLocationTimes', ['CREATE_customerLocationTime']),
    ...mapActions('customers', ['LOAD_customersMin']),
    /** Method used to create the item
     */
    async create(customerLocation) {
      this.isSaving = true;
      const result = await this.CREATE_customerLocation(customerLocation);
      this.customerLocationId = result.id;
      await this.LOAD_customerLocation(this.customerLocationId);
      this.editCustomerLocation = this.customerLocation;
      // create delivery times
      const daysOfWeek = [
        'Monday',
        'Tuesday',
        'Wednesday', 'Thursday',
        'Friday',
        'Saturday', 'Sunday',
      ];
      const timeTypes = ['Delivery', 'Trading'];
      const promises = daysOfWeek
        .flatMap((day) => timeTypes
          .map((timeType) => this.CREATE_customerLocationTime({
            customerLocationId: this.customerLocation.id,
            timeType,
            day,
          })));
      await Promise.all(promises);
      this.isSaving = false;
      this.$router.push({
        name: 'shop-EditCustomerLocation',
        query: { id: this.customerLocation.id },
      });
    },
    /** Method used to save the item
     */
    async save(customerLocation) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_customerLocation(customerLocation);
      } else {
        await this.UPDATE_customerLocation(customerLocation);
      }
      this.isSaving = false;
      this.$router.push({ name: 'shop-ListCustomerLocation' });
    },
    /** Method used to refresh the item
     */
    async refresh() {
      this.isLoading = true;
      if (this.mode === 'edit') {
        await this.LOAD_customerLocation(this.customerLocationId);
        this.editCustomerLocation = this.customerLocation;
      }
      await this.LOAD_customersMin();
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# EditCustomerLocation example

'''js
<EditCustomerLocation :CustomerLocation="CustomerLocation" />
'''
</docs>
