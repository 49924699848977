<template>
  <v-container fluid>
    <Breadcrumbs :history="history" />
    <v-data-table
      :headers="headers"
      :items="emails"
      class="elevation-1"
      :loading="isLoading"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-btn
            color="success"
            data-cy="Email-button-new"
            @click="$router.push({
              name: 'shop-EditEmail',
              query: {customerCode: $route.query.customerCode}
            })"
          >
            <v-icon class="mr-2">
              {{ mdiPlus }}
            </v-icon>
            {{ $t('common.buttons.new') }}
          </v-btn>
          <v-text-field
            v-model="search"
            :append-icon="mdiMagnify"
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <TableBasicButtons :editLink="{
            name: 'shop-EditEmail',
            query: {id: item.id}
          }"
          @delete="remove(item)"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiMagnify, mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListEmail',
  module: 'Shop',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiMagnify,
      mdiPlus,
      search: '',
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Customers',
        disabled: false,
        href: '/#/admin/manage-customers',
        depth: 0,
      }, {
        text: 'Emails',
        disabled: true,
        depth: 0,
      }],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('emails', ['emails']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('emailType'),
          value: 'emailType',
        },
        {
          text: this.$$t('emailAddress'),
          value: 'emailAddress',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    this.costCentres = await this.LOAD_emails(this.$route.query.customerCode);
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('emails', ['LOAD_emails', 'DELETE_email']),
    /** Delete Email from the collection
     */
    async remove(email) {
      this.isLoading = true;
      await this.DELETE_email(email.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Email example

'''js
<Email />
'''
</docs>
