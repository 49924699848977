<template>
  <v-container fluid class="pa-6">
    <v-data-iterator
      ref="dataTable"
      id="data-operator"
      data-cy="favourites-list"
      :items="stockItems"
      :items-per-page="12"
      :footer-props="footerProps"
      :hide-default-footer="stockItems.length < 12"
      :loading="loading"
      :no-results-text="noResultsText"
      :no-data-text="noDataText"
      @update:page="$vuetify.goTo($refs.dataTable, goToOptions)"
    >
      <template v-slot:default="{ items }">
        <v-row class="d-flex">
          <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="3"
            class="d-flex align-stretch col-sm-padding stock-item-card"
            v-for="(stockItem, index) in items"
            :key="`stockItem-${index}`"
            :data-cy="`stock-item-card-${index}`"
          >
            <StockItemCard :stockItem="stockItem" @add="add"  @change="$emit('change', $event)" />
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import cartMethods from '../mixins/cartMethods';
import StockItemCard from './StockItemCard.vue';

export default {
  name: 'CardIteratorStockItems',
  props: {
    stockItems: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noResultsText: {
      type: String,
      default: '',
    },
    noDataText: {
      type: String,
      default: '',
    },
  },
  mixins: [cartMethods],
  components: {
    StockItemCard,
  },
  data() {
    return {
      goToOptions: {
        duration: 500,
        offset: 300,
        easing: 'easeInOutCubic',
      },
    };
  },
  computed: {
    footerProps() {
      return { 'items-per-page-options': [24, 48, 96, -1] };
    },
  },
  // async mounted() {
  //   await this.LOAD_stockDataSafe();
  // },
  methods: {
    ...mapActions('stockItems', ['LOAD_stockLimits', 'LOAD_stockDataSafe']),
  },
};
</script>
