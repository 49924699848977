<template>
  <v-data-table
    :headers="headers"
    :items="stockItems"
    class="elevation-1"
    :loading="isLoading"
  >
    <template v-slot:item.photo="{ item }">
      <v-img contain height="64" :src="item.photo" />
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn icon color="secondary"
        :to="{
          name: 'shop-EditStockItem',
          query: {id: item.id}
        }">
        <v-icon
          small
          class="mr-2"
          :data-cy="`button-StockItem-edit-${item.id}`"
        >
          {{ mdiPencil }}
        </v-icon>
      </v-btn>
      <v-icon
        small
        @click="remove(item)"
        color="error"
        :data-cy="`button-StockItem-remove-${item.id}`"
      >
        {{ mdiDelete }}
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiDelete, mdiPencil } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListStockItem',
  module: 'StockItem',
  mixins: [loading],
  data() {
    return {
      mdiDelete,
      mdiPencil,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('stockItems', ['stockItems']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: '',
          value: 'photo',
        },
        {
          text: this.$$t('itemCode'),
          value: 'itemCode',
        },
        {
          text: this.$$t('barcode'),
          value: 'barcode',
        },
        {
          text: this.$$t('description'),
          value: 'description',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_stockItems();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('stockItems', ['LOAD_stockItems', 'DELETE_stockItem']),
    /** Delete StockItem from the collection
     */
    async remove(StockItem) {
      this.isLoading = true;
      await this.DELETE_StockItem(StockItem);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# StockItem example

'''js
<StockItem />
'''
</docs>
