export default {
  error: undefined,
  loading: false,
  token: undefined,
  username: undefined,
  locationRequested: false,
  roles: [],
  favouriteListMode: 0,
  isOffline: false,
  lastSync: new Date(1900, 1, 1, 0, 0, 0, 0),
  divisionId: undefined,
  appStyle: {
    mobileColumns: 12,
    tabletColumns: 6,
    desktopColumns: 4,
    titleFontSize: 14,
    titleColour: '#000000',
    useColour: false,
    numBoxes: 1,
    boxColor: 'red',
  },
  deliveryAddress: undefined,
  title: '',
  expandedSearch: true,
  displayName: undefined,
};
