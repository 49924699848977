<template>
  <v-container fluid :class="$vuetify.breakpoint.mobile ? '' : 'pa-6'">
    <v-row v-if="resultType === 'table'">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          item-key="itemCode"
          :loading="isLoading"
        >
          <template v-slot:item.price="{ item }">
            R {{ formatCurrency(item.price) }}
            <span v-if="item.tax > 0">(R {{ formatCurrency(item.tax) }} VAT)</span>
            / {{ item.unitOfMeasure }}
          </template>
          <template v-slot:item.photo="{ item }">
            <v-img
              v-if="item.photo"
              contain
              stretch
              :src="item.photo"
              :width="150"
              :height="150"
            />
            <v-img
              v-else
              contain
              stretch
              src="../assets/default-product-image.png"
              :width="150"
              :height="150"
            />
          </template>
          <template v-slot:item.amount="{ item }">
            <QuantityButtonBasic ref="quantity" v-model="item.amount" />
          </template>
          <template v-slot:item.favourite="{ item }">
            <FavouriteIcon
              v-if="activeCustomer"
              :identifier="item?.stockItem?.itemCode?.toString()"
              :name="`stockItem-${activeCustomer.customerCode}`"
              @change="$emit('favouriteChange')"
            />
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn data-cy="add-to-cart" height="40" block color="primary"
              class="secondary--text w-100"
              style="font-size: 1em !important"
              @click="addItem(item)">
              +
              Add to Cart
            </v-btn>
        </template>

        </v-data-table>
      </v-col>
    </v-row>
    <CardIteratorStockItems
      v-if="resultType === 'grid'"
      :loading="isLoading"
      :stockItems="availableItems"
      no-data-text="No results found"
      @change="updateStockItem"
    />
  </v-container>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { mdiGrid, mdiTable } from '@mdi/js';
import { debounce } from 'lodash';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import cartMethods from '../mixins/cartMethods';
import CardIteratorStockItems from '../components/CardIteratorStockItems.vue';
import FavouriteIcon from '../components/FavouriteIcon.vue';
import QuantityButtonBasic from '../components/QuantityButtonBasic.vue';
import stockItemMixIn from '../mixins/stockItem';
import searchMixin from '../mixins/search';

export default {
  name: 'ShopSearch',
  components: {
    CardIteratorStockItems,
    FavouriteIcon,
    QuantityButtonBasic,
  },
  mixins: [cartMethods, loading, stockItemMixIn, searchMixin],
  data() {
    return {
      mdiGrid,
      mdiTable,
      availableItems: [],
      searchText: this.$route.query.search,
      resultType: this.$route.query.type ?? 'grid',
      headers: [
        { text: '', value: 'favourite' },
        { text: '', value: 'photo' },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'description',
        },
        { text: 'Price', value: 'price' },
        { text: 'Stock', value: 'quantity' },
        { text: 'Quantity', value: 'amount' },
        { text: '', value: 'actions' },
      ],
    };
  },
  computed: {
    ...mapState('stockItems', ['stockItems']),
    ...mapState('customers', ['activeCustomer']),
    ...mapGetters('stockItems', ['getStockItemDetail']),
    items() {
      return this.availableItems.map((item) => ({
        ...item,
        amount: 1,
        price: this.getStockItemDetail(item)?.price,
        stockItem: item,
      }));
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.query.search': function (val) {
      this.searchText = val;
      this.searchAll(val);
    },
  },
  async mounted() {
    this.SET_title('Search');
    this.$root.$on('search', ({ text, type }) => {
      if (type === 'All') {
        const debounceSearch = debounce(() => {
          this.searchAll(text);
          this.searchText = text;
        }, 150);
        debounceSearch();
      }
    });
    this.LOAD_stockLimits();
    this.LOAD_pricings();
  },
  destroyed() {
    this.$root.$off('search');
  },
  methods: {
    ...mapActions('pricings', ['LOAD_pricings']),
    ...mapActions('stockItems', ['LOAD_stockLimits']),
    ...mapMutations('app', ['SET_title']),
    async addItem(item) {
      const { stockItem, amount } = item;
      if (this.isInCart) {
        await this.$nextTick();
        const result = await this.$confirm('This item is already in your cart, are you sure you want to add more?');
        if (result) {
          this.add({ stockItem, amount });
        }
      } else {
        this.add({ stockItem, amount });
      }
    },
    async refresh() {
      await this.searchAll(this.searchText);
    },
    async searchAll(val) {
      this.isLoading = true;
      this.$log.debug('Searching all from web');
      const results = await this.$http.get(`odata/advancedSearch?searchString=${val}`);
      this.availableItems = this.sortResults(results.data.value);
      console.debug(this.availableItems);
      this.isLoading = false;
    },
    sortResults(results) {
      const sortedResults = results.sort((a, b) => {
        if (a.quantity === 0 && b.quantity > 0) return 1;
        if (a.quantity > 0 && b.quantity === 0) return -1;
        return 0;
      });
      return sortedResults;
    },
    updateStockItem(stockItem) {
      const item = {
        itemCode: stockItem.itemCode,
        photo: stockItem.photo,
      };
      this.update(item);
    },
  },
};
</script>
