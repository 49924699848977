<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-row>
          <v-col cols="6">
            <v-autocomplete
              solo
              :items="fleetSuppliers"
              item-text="name"
              item-value="id"
              data-cy="FormFleetSupplierBooking-fleetSupplierId"
              :label="$$t('fleetSupplierId')"
              v-model="localFleetSupplierBooking.fleetSupplierId"
            />
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              solo
              :items="vehicles"
              :item-text="v => `${v.make} ${v.model} (${v.licensePlate})`"
              item-value="id"
              data-cy="FormFleetSupplierBooking-vehicleId"
              :label="$$t('vehicleId')"
              v-model="localFleetSupplierBooking.vehicleId"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <DatePicker
              solo
              data-cy="FormFleetSupplierBooking-startDate"
              :label="$$t('startDate')"
              v-model="localFleetSupplierBooking.startDate"
            />
          </v-col>
          <v-col cols="6">
            <DatePicker
              solo
              data-cy="FormFleetSupplierBooking-endDate"
              :label="$$t('endDate')"
              v-model="localFleetSupplierBooking.endDate"
            />
          </v-col>
        </v-row>
        <v-row>
        </v-row>
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormFleetSupplierBooking-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormFleetSupplierBooking-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import DatePicker from '@/components/DatePicker.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FormFleetSupplierBooking',
  module: 'Fleet',
  components: {
    DatePicker,
  },
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    FleetSupplierBooking: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localFleetSupplierBooking
      */
      localFleetSupplierBooking: {},
    };
  },
  computed: {
    ...mapState('fleetSuppliers', ['fleetSuppliers']),
    ...mapState('vehicles', ['vehicles']),
  },
  watch: {
    FleetSupplierBooking() {
      this.localFleetSupplierBooking = this.FleetSupplierBooking;
    },
  },
  created() {
    this.localFleetSupplierBooking = this.FleetSupplierBooking;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localFleetSupplierBooking);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormFleetSupplierBooking example

'''js
<FormFleetSupplierBooking :FleetSupplierBooking="FleetSupplierBooking" />
'''
</docs>
