import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('preferredDeliveryAddress', 'preferredDeliveryAddresses');

const customActions = {
  async LOAD_preferredDeliveryAddresses({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('preferredDeliveryAddresses')
      .query({ $filter: 'IsDeleted eq false', $expand: 'DeliveryAddress' });
    commit('SET_preferredDeliveryAddresses', data);
    return data;
  },
};

export default { ...actions, ...customActions };
