<template>
  <v-list
    class="nav-list"
    dense
    nav
  >
    <v-list-item
      v-for="(menuItem, index) in items"
      :key="menuItem.name"
      @click="selectMenuItem(index, menuItem.pathName)"
      class="nav-item"
      :class="{ 'selected': menuItem.selected }"
      :disabled="menuItem.selected"
    >
      <v-list-item-icon>
        <img class="icon icon-small" v-if="menuItem.selected" :src="menuItem.iconSelected" />
        <img class="icon icon-small" v-else :src="menuItem.icon" />
            {{ $t(menuItem.name) }}
      </v-list-item-icon>
    </v-list-item>
    <v-list-item
      v-if="isAdmin"
      class="nav-item"
      :class="{ 'selected': selectedIndex === items.length }"
      @click="selectMenuItem(items.length, 'app-Admin')"
    >
      <v-list-item-icon>
        <v-icon :color="selectedIndex === items.length ? 'accent' : 'white'" class="ml-2 mr-4">
          {{ mdiTools }}
        </v-icon>
        Admin
      </v-list-item-icon>
    </v-list-item>
    <v-list-item
      class="nav-item"
      :class="{ 'selected': selectedIndex === items.length + 1 }"
      @click="selectMenuItem(items.length + 1, 'app-Settings')"
    >
      <v-list-item-icon>
        <img
          v-if="selectedIndex === items.length + 1"
          class="icon icon-small"
          src="../assets/icon-settings-selected.svg"
        />
        <img class="icon icon-small" v-else src="../assets/icon-settings.svg" />
        Settings
      </v-list-item-icon>
    </v-list-item>
    <v-list-item
      class="nav-item"
      @click="logout"
    >
      <v-list-item-icon>
        <img
          class="icon icon-small"
          src="../assets/icon-logout.svg"
        />
        {{ $t('common.logoff') }}
      </v-list-item-icon>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex';
import { mdiHome, mdiAccount, mdiTools } from '@mdi/js';
import menuItems from '../menuItems';

export default {
  mixins: [menuItems],
  data() {
    return {
      mdiHome,
      mdiAccount,
      mdiTools,
      selectedIndex: 0,
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
    isAdmin() {
      // return false;
      return this.isInRole('Administrators');
    },
    isAgent() {
      // return false;
      return this.isInRole('Agents');
    },
    items() {
      const { selectedIndex } = this;
      return this.menuItems.map((i, idx) => ({ selected: idx === selectedIndex, ...i }));
    },
  },
  watch: {
    $route(to) {
      this.setMenuIndex(to);
    },
  },
  mounted() {
    this.setMenuIndex(this.$route);
  },
  methods: {
    logout() {
      this.$root.$emit('auth:logoff');
      if (this.$route.path !== '/auth/login') {
        this.$router.push('/auth/login');
      }
    },
    setMenuIndex(to) {
      this.$log.debug(`Set MenuIndex: ${to.name}`);
      const menuItem = this.menuItems.find((i) => i.pathName === to.name);
      this.$log.debug(`Found menu item: ${menuItem?.pathName}`);
      if (menuItem) {
        this.selectedIndex = this.menuItems.indexOf(menuItem);
      } else {
        this.selectedIndex = undefined;
      }
    },
    selectMenuItem(index, pathName) {
      this.$log.debug(`Select MenuIndex: ${index}`);
      this.selectedIndex = index;
      this.$router.push({ name: pathName });
    },
  },
};
</script>

<style scoped>
.v-list-item__content > a {
  justify-content: left;
}
.nav-button {
  justify-content: left;
  margin-left: 7px;
  margin-top: 10px;
}

.nav-item:last-child {
  position: absolute;
  bottom: 20px;
}

.nav-item:nth-last-child(2) {
  position: absolute;
  bottom: 60px;
}
.nav-item {
  color: white !important;
}
.nav-item.selected {
  color: #fed401 !important;
}
.nav-item img {
  margin-left: 10px;
  margin-right: 20px;
}
.nav-list {
  height: calc(100vh - 170px);
}
</style>
