  <template>
    <div>
      <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{ on }">
          <v-btn small color="primary" @click="fetchLogs" v-on="on">View Logs</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Order Logs</span>
          </v-card-title>
          <v-card-text>
          <div v-for="log in logs" :key="log.id">
            <div class="text-h6">{{ log.created }}</div>
            <div class="text-h5">JSON:</div>
            <pre>
              {{ log.json }}
            </pre>
            <div class="text-h5">Response:</div>
            <pre>
              {{ log.response }}
            </pre>
          </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>

<script>
export default {
  name: 'ViewOrderLog',
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      items: [],
      dialog: false,
      logs: '',
    };
  },
  methods: {
    async fetchLogs() {
      try {
        const { data } = await this.$http.get(`/odata/orderLogs?$filter=OrderId eq ${this.order.id}`);
        this.logs = data.value;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
