import { sumBy } from 'lodash';

export default {
  cart: (state) => state.cart,
  total_items: (state) => sumBy(state.cart, (c) => parseInt(c.amount, 10)) ?? 0,
  total_stockItems: (state) => state.cart.length ?? 0,
  getQuantity: (state) => (stockItem) => {
    const item = state.cart.find((c) => c.stockItem.itemCode === stockItem.itemCode);
    return parseInt(item?.amount ?? '0', 10);
  },
  displayCart: (state, getters, rootState, rootGetters) => state.cart?.map((c) => {
    const result = { ...c };
    const getStockItemDetail = rootGetters['stockItems/getStockItemDetail'];
    const detail = getStockItemDetail(result.stockItem);
    result.price = detail.price;
    result.tax = detail.tax;
    result.total = c.amount * (result.price + result.tax);
    result.detail = detail;
    return result;
  }),
  total_amount: (state, getters) => sumBy(getters.displayCart, (c) => c.total),
};
