import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
  },
  async mounted() {
    this.isLoading = true;
    await this.refresh();
    this.isLoading = false;
  },
  methods: {
    ...mapActions('appMenuItems', ['LOAD_appMenuItems', 'UPDATE_appMenuItem']),
    async refresh() {
      this.isLoading = true;
      try {
        await this.LOAD_appMenuItems();
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async updateImage(item, $event) {
      this.isLoading = true;
      // eslint-disable-next-line no-param-reassign
      item.imageUrl = $event;
      try {
        await this.UPDATE_appMenuItem(item);
        await this.LOAD_appMenuItems();
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'There was an error uploading the image, please try again');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
