<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="divisions"
          class="elevation-1"
          :loading="isLoading"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-btn
                class="secondary--text"
                color="#EE1C25"
                data-cy="Division-button-new"
                @click="$router.push({
                  name: 'shop-EditDivision'
                })"
              >
                <v-icon class="mr-2">
                  {{ mdiPlus }}
                </v-icon>
                {{ $t('common.buttons.new') }}
              </v-btn>
              <div class="text-h5 ml-2">Departments</div>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <TableBasicButtons :editLink="{
                name: 'shop-EditDivision',
                query: {id: item.id}
              }"
              @delete="remove(item)"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListDivision',
  module: 'Division',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('divisions', ['divisions']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_divisions();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('divisions', ['LOAD_divisions', 'DELETE_division']),
    /** Delete Division from the collection
     */
    async remove(division) {
      this.isLoading = true;
      await this.DELETE_division(division.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Division example

'''js
<Division />
'''
</docs>
