import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('dailyInspection', 'dailyInspections');

const customActions = {
  async LOAD_dailyInspections({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('dailyInspections')
      .query({
        $filter: 'IsDeleted eq false',
        $expand: 'ClosingVehicleInspection,OpeningVehicleInspection,Vehicle',
      });
    commit('SET_dailyInspections', data);
    return data;
  },
};

export default { ...actions, ...customActions };
