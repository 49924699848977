<template>
  <v-btn
    v-if="!isInstalled && deferredPrompt"
    color="primary"
    elevation="0"
    height="48"
    @click="installPWA()"
  >
    Install
  </v-btn>
</template>

<script>
export default {
  name: 'InstallButton',
  data() {
    return {
      deferredPrompt: undefined,
    };
  },
  computed: {
    isInstalled() {
      return window.matchMedia('(display-mode: standalone)').matches;
      // return navigator.standalone;
    },
  },
  mounted() {
    window.addEventListener('beforeinstallprompt', (e) => {
      this.deferredPrompt = e;
      console.debug(e);
    });
  },
  methods: {
    async installPWA() {
      try {
        // Prompt the user to install the app
        const { deferredPrompt } = this;
        if (deferredPrompt) {
          deferredPrompt.prompt();
          const choice = await deferredPrompt.userChoice;
          if (choice.outcome === 'accepted') {
            console.log('App installed');
          } else {
            console.log('App not installed');
          }
          // Clear the deferred prompt to prevent it from being triggered again
          window.deferredInstallPrompt = null;
        } else {
          console.log('No deferred prompt');
        }
      } catch (err) {
        console.error(err);
      }
    },
  },

};
</script>
