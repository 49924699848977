<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormVehiclePart
      :VehiclePart="editVehiclePart"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormVehiclePart from '../components/FormVehiclePart.vue';

export default {
  components: {
    FormVehiclePart,
  },
  name: 'EditVehiclePart',
  module: 'Fleet',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing VehiclePart. Default to {}.
      */
      editVehiclePart: {},
      /**
       * Query Id for VehiclePart
       */
      vehiclePartId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('vehicleParts', ['vehicleParts', 'vehiclePart']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.vehiclePartId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_vehiclePart(this.vehiclePartId);
      this.editVehiclePart = this.vehiclePart;
    }
    await Promise.all([
      (async () => {
        await this.LOAD_fleetSuppliers();
      })(),
      (async () => {
        await this.LOAD_vehicles();
      })(),
    ]);
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('vehicleParts', ['LOAD_vehiclePart', 'CREATE_vehiclePart', 'UPDATE_vehiclePart']),
    ...mapActions('fleetSuppliers', ['LOAD_fleetSuppliers']),
    ...mapActions('vehicles', ['LOAD_vehicles']),
    /** Method used to save the item
     */
    async save(vehiclePart) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_vehiclePart(vehiclePart);
      } else {
        await this.UPDATE_vehiclePart(vehiclePart);
      }
      this.isSaving = false;
      this.$router.push({ name: 'fleet-EditVehicle', query: { id: vehiclePart.vehicleId } });
    },
  },
};
</script>

<docs>
# EditVehiclePart example

'''js
<EditVehiclePart :VehiclePart="VehiclePart" />
'''
</docs>
