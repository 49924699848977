<template>
  <div v-if="activeCustomer">
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="300px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="isAdmin"
          text
          color="secondary"
          icon
          v-bind="attrs"
          v-on="on"
          title="Change Customer"
        >
          <v-icon color="secondary">{{ mdiSwapHorizontal }}</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title primary-title>
          Change to Customer:
        </v-card-title>
        <v-card-text>
          <v-combobox
            :loading="isLoading"
            :items="items"
            return-object
            :item-text="(c) => `${c.customerDesc} (${c.customerCode})`"
            item-value="customerCode"
            label="Customer"
            @update:search-input="loadData"
            @input="inputChanged"
          ></v-combobox>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="update" :loading="isSaving">
            Change
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <span class="secondary--text">
      {{ activeCustomer.customerDesc }}
    </span>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { mdiSwapHorizontal } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  module: 'auth',
  name: 'ActiveCustomer',
  mixins: [loading],
  data() {
    return {
      mdiSwapHorizontal,
      dialog: false,
      localActiveCustomerUser: undefined,
      items: [],
      search: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
    ...mapState('customers', ['activeCustomer']),
    isAdmin() {
      return this.isInRole('Administrators');
    },
  },
  watch: {
    activeCustomer: {
      immediate: true,
      deep: true,
      handler() {
        this.localActiveCustomerUser = this.activeCustomer;
      },
    },
    localActiveCustomerUser() {
      this.loadData();
    },
  },
  async mounted() {
    await this.refresh();
  },
  created() {
    this.$root.$on('auth:login', async () => {
      await this.refresh();
    });
  },
  methods: {
    ...mapActions('customers', ['LOAD_activeCustomer']),
    ...mapActions('customerUsers', ['UPDATE_customerUser']),
    ...mapActions('pricings', ['LOAD_pricings']),
    ...mapMutations('app', ['SET_divisionId']),
    inputChanged(val) {
      if (val instanceof Object) {
        this.update(val.customerCode);
      }
    },
    async loadData(value) {
      if (value?.length > 1) {
        const response = await this.$http.get(`/odata/customers?$filter=contains(customerDesc,'${value}')`);
        this.items = response.data.value;
      }
    },
    async refresh() {
      if (this.isLoggedIn) {
        await this.LOAD_activeCustomer();
        this.localActiveCustomerUser = this.activeCustomer;
      }
    },
    async update(localActiveCustomerUser) {
      try {
        this.isSaving = true;
        const { activeCustomer } = this;
        const change = {
          previousCustomerCode: activeCustomer.customerCode,
          newCustomerCode: localActiveCustomerUser,
        };
        await this.UPDATE_customerUser(
          {
            id: activeCustomer.customerUserId,
            customerCode: localActiveCustomerUser,
          },
        );
        await new Promise((r) => setTimeout(r, 500));
        await this.LOAD_activeCustomer();
        this.$root.$emit('activeCustomer:change', change);
        this.SET_divisionId(undefined);
        this.$log.info('active customer changed');
        this.$router.push({
          name: 'app-Init',
        });
      } finally {
        this.isSaving = false;
      }
      this.dialog = false;
    },
  },
};
</script>
