<template>
  <div data-cy="stock-items-loading" class="d-flex align-center justify-center w-100 h-100">
    <v-progress-linear :value="progressValue" />
  </div>
</template>

<script>
export default {
  name: 'StockItemsLoading',
  props: {
    timeout: {
      type: Number,
      default: 30000, // 30 seconds
    },
  },
  data: () => ({
    progressValue: 0,
    steps: 0,
    interval: null,
  }),
  mounted() {
    this.startProgress();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    startProgress() {
      if (this.timeout < 1) {
        return;
      }
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        if (this.steps < this.timeout / 2) {
          this.steps += (this.timeout / 50);
        } else {
          this.steps += (this.timeout / 1000);
        }
        this.progressValue = Math.round((this.steps / this.timeout) * 100);
        if (this.steps >= this.timeout) {
          clearInterval(this.interval);
        }
      }, 100);
    },
  },
};
</script>

<style scoped>
.loading-animation {
  max-height: 25vh;
}
</style>
