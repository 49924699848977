<template>
  <div class="card-container-wrapper" ref="cardWrapper">
    <v-btn v-if="showButton && !hideLeftButton" class="less-categories" @click="scrollLeft" icon>
      <img src="../assets/nav-left.png" />
    </v-btn>
    <div class="card-container d-flex mb-5 align-stretch" ref="cardContainer">
      <div class="shop-card mr-2" v-for="(c, index) in items" :key="c.id">
        <v-card
          v-if="c.type === 'category'"
          class="fill-height category-card" elevation="1"
          :loading="isLoading"
          :data-cy="`category-card-${index}`"
        >
          <v-img
            :data-cy="`category-link-${index}`"
            style="cursor: pointer;"
            :src="c.photoUrl"
            @click="selectCategory(category)"
            class="d-flex align-end"
            height="150px"
            stretch
          >
            <v-card-title
              class="background-grey-opague mt-1 mb-0 pa-0 pl-2 pr-2"
              @click="selectCategory(category)"
            >
              {{ c.name }}
            </v-card-title>
          </v-img>
        </v-card>
        <div
          v-else-if="c.type === 'stockItem'"
          class="stock-item-card"
          :data-cy="`stock-item-card-${index}`"
        >
          <StockItemCard :stockItem="c" />
        </div>
      </div>
    </div>
    <v-btn v-if="showButton" class="more-categories" @click="scrollRight" icon>
      <img src="../assets/nav-right.png" />
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiChevronRight } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import categories from '../mixins/categories';
import StockItemCard from './StockItemCard.vue';

export default {
  name: 'CategoryCardList',
  components: {
    StockItemCard,
  },
  mixins: [categories, loading],
  props: {
    category: {
      type: Object,
      required: true,
    },
    depth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      mdiChevronRight,
      otherCategories: [],
      stockItems: [],
      conatinerOffsetWidth: 0,
      containerScrollWidth: 0,
      containerScrollLeft: 0,
    };
  },
  watch: {
    category: {
      immediate: true,
      deep: true,
      handler() {
        this.refresh();
      },
    },
  },
  computed: {
    items() {
      if (this.otherCategories.length > 0) {
        return this.otherCategories.map((c) => ({
          ...c,
          type: 'category',
        }));
      }
      return this.stockItems.map((c) => ({
        ...c,
        type: 'stockItem',
      }));
    },
    hideLeftButton() {
      return this.containerScrollLeft === 0;
    },
    hideRightButton() {
      return this.containerScrollLeft + this.conatinerOffsetWidth > this.conatinerOffsetWidth;
    },
    showButton() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return false;
      }
      if (this.containerScrollWidth === this.conatinerOffsetWidth) {
        return false;
      }
      // if (this.otherCategories.length === 0) {
      //   return false;
      // }
      return this.containerScrollWidth > this.conatinerOffsetWidth;
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('stockItems', [
      'LOAD_stockItemsByWebCategory1',
      'LOAD_stockItemsByWebCategory2',
      'LOAD_stockItemsByWebCategory3',
      'LOAD_stockItemsByWebCategory4',
      'LOAD_stockItemsWithNoCategory']),
    ...mapActions('webCategories', [
      'LOAD_webCategory1',
      'LOAD_webCategory2',
      'LOAD_webCategory3',
      'LOAD_webCategory4',
    ]),
    setScrollButton() {
      if (!this.$refs.cardContainer) {
        return;
      }
      this.conatinerOffsetWidth = this.$refs.cardContainer.offsetWidth;
      this.containerScrollWidth = this.$refs.cardContainer.scrollWidth;
      this.containerScrollLeft = this.$refs.cardContainer.scrollLeft;
    },
    async refresh() {
      this.isLoading = true;
      const { category, depth } = this;
      // this.$log.debug(`Loading category ${category.id} with depth ${depth}`);
      if (depth < 4) {
        this.otherCategories = await this.LOAD_categories({ category, depth });
      }
      this.stockItems = await this.LOAD_categoryProducts({ category, depth });
      this.isLoading = false;
      this.setScrollButton();
    },
    selectCategory(category) {
      this.$emit('input', category);
    },
    scrollLeft() {
      this.$refs.cardContainer.scrollTo({
        left: -this.conatinerOffsetWidth - 50,
        behavior: 'smooth',
      });
      setTimeout(() => {
        this.setScrollButton();
      }, 500);
    },
    scrollRight() {
      const { conatinerOffsetWidth } = this;
      this.$refs.cardContainer.scrollTo({
        left: conatinerOffsetWidth + 50,
        behavior: 'smooth',
      });
      setTimeout(() => {
        this.setScrollButton();
      }, 500);
    },
  },
};
</script>

<style scoped>
.card-container-wrapper {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.less-categories {
  position: absolute;
  top: 65px;
  left: 25px;
  z-index: 1;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.more-categories {
  position: absolute;
  top: 65px;
  right: 25px;
  z-index: 1;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.icon-category {
  font-size: 80px;
}
</style>
