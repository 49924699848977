import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('driver', 'drivers');

const customActions = {
  async LOAD_drivers({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('drivers')
      .query({ $filter: 'IsDeleted eq false', $orderby: 'Name' });
    commit('SET_drivers', data);
    return data;
  },
};

export default { ...actions, ...customActions };
