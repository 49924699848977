export default {
  mounted() {
    this.$root.$on('activeCustomer:change', () => {
      this.refresh();
    });
  },
  destroyed() {
    this.$root.$off('activeCustomer:change');
  },
};
