import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export default {
  /**
  * Install the plugin
  */
  install(Vue) {
    // formatting mixin
    Vue.mixin({
      methods: {
        formatDateTime(dt) {
          return dayjs(dt).format('YYYY-MM-DD HH:mm');
        },
        formatDateTimeSeconds(dt) {
          return dayjs(dt).format('YYYY-MM-DD HH:mm:ss');
        },
        formatCurrency(money, digits = 2) {
          const userLang = navigator.language || navigator.userLanguage;
          const opts = {
            maximumFractionDigits: digits,
            minimumFractionDigits: digits,
          };
          return new Intl.NumberFormat(userLang, opts).format(money); // 'CA$ 100.00'
        },
        formatNumber(money) {
          const userLang = navigator.language || navigator.userLanguage;
          return new Intl.NumberFormat(userLang).format(money ?? 0);
        },
        formatDate(dt) {
          return dayjs(dt).format('YYYY-MM-DD');
        },
        formatFromNow(dt) {
          return dayjs(dt).fromNow();
        },
        formatBoolean(value) {
          return value ? 'Yes' : 'No';
        },
      },
    });
  },
};
