<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    persistent
    :max-width="$vuetify.breakpoint.mobile ? undefined : 415"
  >
    <v-card
      color="primary"
      style="padding: 20px"
      rounded="0"
      centered
      :class="{
        'd-flex flex-column justify-center': $vuetify.breakpoint.mobile,
      }"
    >
      <v-card-title class="white--text text-center">
        Here's a product you might like!
      </v-card-title>
      <v-card-text class="d-flex flex-column align-center">
        <v-row v-for="(item, index) in items" :key="index" class="text-center" no-gutters>
          <v-col cols="12" class="stock-item-card">
            <StockItemCard
              :stockItem="item"
              @added="added(item)"
              @favouriteChange="addToFavourites(item)"
            />
          </v-col>
          <v-col cols="12">
            <v-btn text class="accent--text mb-5 text-decoration-underline" @click="remove(item)">
              Maybe later
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mapActions, mapMutations, mapGetters, mapState,
} from 'vuex';
import { mdiClose } from '@mdi/js';
import StockItemCard from './StockItemCard.vue';

export default {
  name: 'SoftSaleDialog',
  components: {
    StockItemCard,
  },
  data() {
    return {
      mdiClose,
      dialog: false,
    };
  },
  computed: {
    ...mapGetters('stockItems', ['getStockItemDetail']),
    ...mapState('stockItems', ['stockItems']),
    ...mapState('customers', ['activeCustomer']),
    ...mapState('softSaleStockItems', ['softSaleStockItems']),
    items() {
      const softSaleStockItemCodes = this.softSaleStockItems.map((item) => item.itemCode);
      const items = this.stockItems
        .filter((item) => softSaleStockItemCodes.includes(item.itemCode));
      return items.map((item) => ({
        ...item,
        detail: this.detail(item),
        stockItem: item,
      }));
    },
  },
  async mounted() {
    await this.refresh();
    if (this.softSaleStockItems.length > 0) {
      this.dialog = true;
    }
  },
  methods: {
    ...mapActions('softSaleStockItems', ['LOAD_softSaleStockItemsByCustomer', 'DELETE_softSaleStockItem']),
    ...mapActions('softSaleStockItemAudits', ['CREATE_softSaleStockItemAudit']),
    ...mapActions('stockItems', ['LOAD_stockItems']),
    ...mapActions('favourites', ['CREATE_favourite']),
    ...mapMutations('cart', ['ADD_TO_CART', 'REMOVE_CART']),
    added(item) {
      this.$root.$emit('toast:notify', `Added ${item.stockItem.description}`);
      this.remove(item, 'Add to cart');
    },
    async addToFavourites(item) {
      this.$root.$emit('toast:notify', `Added ${item.stockItem.description}`);
      this.remove(item, 'Add to favourites');
    },
    async refresh() {
      try {
        await Promise.all([
          (async () => {
            await this.LOAD_softSaleStockItemsByCustomer(this.activeCustomer?.customerCode);
          })(),
          (async () => {
            await this.LOAD_stockItems();
          })(),
        ]);
      } catch (error) {
        this.$log.error(error);
      }
    },
    async remove(item, reason = 'No thank you') {
      const softSaleStockItem = this.softSaleStockItems.find((s) => s.itemCode === item.itemCode);
      await this.DELETE_softSaleStockItem(softSaleStockItem.id);
      if (this.softSaleStockItems.length === 0) {
        this.dialog = false;
      }
      this.CREATE_softSaleStockItemAudit({
        softSaleStockItemId: softSaleStockItem.id,
        reason,
      });
    },
    detail(item) {
      return this.getStockItemDetail(item);
    },
  },
};
</script>
