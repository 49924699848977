<template>
  <v-btn text class="grey--text"
    title="Cart"
    data-cy="btn-mini-cart"
    :to="{ name: 'shop-Cart' }"
  >
    <v-badge
      color="primary"
      left
      transition="slide-x-transition"
    >
      <template v-slot:badge>
        {{ total_items }}
      </template>
      <v-icon class="mr-1" size="26"> {{ mdiCartOutline }} </v-icon>
      R {{ formatCurrency(total_amount) }}
    </v-badge>
  </v-btn>
</template>

<script>
import { mdiCartOutline, mdiTrashCanOutline } from '@mdi/js';
import cart from '../mixins/cart';

export default {
  mixins: [cart],
  data() {
    return {
      mdiCartOutline,
      mdiTrashCanOutline,
      isOpen: false,
    };
  },
};
</script>
