<template>
  <div>
    <div class="d-block" v-if="isLoading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      Loading Products
    </div>
    <v-data-iterator
      v-else
      :items="stockItems"
      :items-per-page="100"
      :search="search"
      sort-by="description"
      hide-default-footer
    >
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.name"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card>
              <v-card-title class="subheading font-weight-bold">
                {{ item.description }}
                <v-btn text icon color="primary"
                  style="position: absolute; right: 5px"
                  :to="{
                    name: 'shop-StockItemDetail',
                    query: { id: item.itemCode },
                  }">
                  <v-icon>{{ mdiInformationOutline }}</v-icon>
                </v-btn>
              </v-card-title>

              <v-divider></v-divider>
              <v-img
                height="128"
                contain
                :src="item.photo"
              />
              <v-card-text>
                R 0.00
              </v-card-text>
              <v-card-actions>
                <v-text-field
                  label="Quantity"
                  :value="1"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="$emit('toast:notify', 'Added to cart')">
                  Add to Cart
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiMagnify, mdiInformationOutline } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ShopStockItems',
  mixins: [loading],
  data() {
    return {
      categoryId: this.$route?.query?.categoryId,
      search: '',
      mdiMagnify,
      mdiInformationOutline,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('stockItems', ['stockItems']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('supplierId'),
          value: 'supplierId',
        },
        {
          text: this.$$t('itemCode'),
          value: 'itemCode',
        },
        {
          text: this.$$t('description'),
          value: 'description',
        },
        {
          text: this.$$t('categoryId'),
          value: 'categoryId',
        },
        {
          text: this.$$t('barcode'),
          value: 'barcode',
        },
        {
          text: this.$$t('photo'),
          value: 'photo',
        },
        {
          text: this.$$t('beeCertificate'),
          value: 'beeCertificate',
        },
        {
          text: this.$$t('foodCertificate'),
          value: 'foodCertificate',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_stockItems();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('stockItems', ['LOAD_stockItems']),
    /** Delete StockItem from the collection
     */
    async remove(StockItem) {
      this.isLoading = true;
      await this.DELETE_StockItem(StockItem);
      this.isLoading = false;
    },
  },
};
</script>
