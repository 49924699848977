<template>
  <v-text-field
    ref="searchBox"
    v-model="search"
    @input="onInput"
    class="search-box"
    data-cy="input-search"
    clearable
    :label="label"
    solo
    flat
    hide-details
    @click:clear="clearSearch"
  >
    <template v-slot:append>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            data-cy="toggle-search-type"
          >
            <v-img
              v-if="search?.length > 0"
              height="18"
              width="18"
              contain
              src="../../../../assets/icon-search.svg"
            />
            <v-icon
              v-else-if="searchType === 'All'">{{ mdiFeatureSearch }}</v-icon>
            <v-icon v-else-if="searchType === 'Favourites'">{{ mdiHeart }}</v-icon>
            <v-icon v-else-if="searchType === 'Documents'">{{ mdiTextBoxSearchOutline }}</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item data-cy="toggle-search-type-all">
            <v-list-item-title>
              <v-btn @click="searchType = 'All'">
                <v-icon>{{ mdiFeatureSearch }}</v-icon>
                <strong>All Products</strong>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item data-cy="toggle-search-type-favourites">
            <v-list-item-title>
              <v-btn @click="searchType = 'Favourites'">
                <v-icon class="mr-2">{{ mdiHeart }}</v-icon>
                <strong>Favourites</strong>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="searchType === 'Documents'"
            data-cy="toggle-search-type-favourites"
          >
            <v-list-item-title>
              <v-btn @click="searchType = 'Documents'">
                <v-icon class="mr-2">{{ mdiTextBoxSearchOutline }}</v-icon>
                <strong>Accounts</strong>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
import {
  mdiCart, mdiHeart, mdiMagnify, mdiFeatureSearch, mdiTextBoxSearchOutline,
} from '@mdi/js';
import { mapGetters, mapState } from 'vuex';
import searchMixin from '../mixins/search';

export default {
  name: 'GlobalSearch',
  mixins: [searchMixin],
  props: {
    type: {
      type: String,
      default: 'All',
    },
  },
  data: () => ({
    mdiCart,
    mdiHeart,
    mdiMagnify,
    mdiFeatureSearch,
    mdiTextBoxSearchOutline,
    search: '',
    searchType: 'All',
  }),
  computed: {
    ...mapGetters('webCategories', ['allCategories']),
    ...mapState('app', ['isOffline']),
    ...mapState('stockItems', ['stockItems', 'favouriteStockItems']),
    label() {
      switch (this.searchType) {
        case 'All':
          return 'Search Gourmet Foods';
        case 'Favourites':
          return 'Search Favourites';
        case 'Documents':
          return 'Search Documents';
        default:
          return 'Search';
      }
    },
  },
  watch: {
    type() {
      this.searchType = this.type;
    },
  },
  mounted() {
    this.searchType = this.type;
  },
  methods: {
    clearSearch() {
      this.search = '';
      if (this.searchType === 'All' && this.$route.name === 'shop-ShopSearch') {
        this.$router.back();
      }
    },
    onInput() {
      this.$log.debug('onInput');
      this.$root.$emit('search', {
        type: this.searchType,
        text: this.search,
      });
      if (this.search?.length < 2) {
        return;
      }
      switch (this.searchType) {
        default:
        case 'All':
          if (this.$route.name !== 'shop-ShopSearch') {
            this.$router.push({ name: 'shop-ShopSearch', query: { search: this.search, type: 'grid' } });
          }
          break;
        case 'Favourites':
          if (this.$route.name !== 'shop-Favourites') {
            this.$router.push({ name: 'shop-Favourites', query: { search: this.search, type: 'favourites' } });
          }
          break;
        case 'Documents':
          if (this.$route.name !== 'finance-FinanceHome') {
            this.$router.push({ name: 'finance-FinanceHome', query: { search: this.search, type: 'documents' } });
          }
          break;
      }
    },
  },
};
</script>
