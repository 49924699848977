import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { defaultsDeep } from 'lodash';
import { locales as auroraAppCore } from '@codehq/aurora-app-core/src';
import modules from './modules';
import localLocales from './locales';

const moduleLocales = {};
modules.forEach((m) => {
  defaultsDeep(moduleLocales, m.locales);
});

const messages = defaultsDeep(
  localLocales,
  auroraAppCore,
  moduleLocales,
);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages,
});

export { i18n };
export { messages };
