<template>
  <div>
    <v-menu
      v-model="localOpenModal"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="localDate"
          class="date-picker"
          :label="label"
          :rules="combinedRules"
          v-on="on"
          @input="$emit('input', localDate)"
          @click:append="localOpenModal = true"
          :clearable="clearable"
          :filled="filled"
          v-mask="'####-##-##'"
          :append-icon="mdiCalendar"
          :readonly="disableEdit"
          :disabled="disabled"
          background-color="white"
          :solo="solo"
          :outlined="outlined"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="localDate"
        :allowed-dates="allowedDates"
        @input="inputDate()"
      ></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import { mdiCalendar } from '@mdi/js';
import dayjs from 'dayjs';

export default {
  name: 'DatePicker',
  props: {
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: true,
    },
    openModal: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Date',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    allowedDates: {
      type: Function,
      default: null,
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
    oneWeekRule: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiCalendar,
      localOpenModal: this.openModal,
      localDate: this.value,
    };
  },
  computed: {
    combinedRules() {
      const result = [
        ...this.rules,
        (value) => /^\d{4}-\d{2}-\d{2}$/.test(value)
          || `Invalid date format. Use yyyy-mm-dd format. eg. ${dayjs().format('YYYY-MM-DD')}`,
      ];
      if (this.oneWeekRule) {
        result.push((value) => {
          if (!value) return true;
          const inputDate = new Date(value);
          const now = new Date();
          const oneWeekLater = new Date();
          oneWeekLater.setDate(now.getDate() + 7);
          if (now.getHours() < 3
            && dayjs().format('YYYY-MM-DD') === dayjs(inputDate).format('YYYY-MM-DD')
          ) {
            // before 3am on the same day
            return true;
          }

          return (
            (inputDate > now
            && inputDate <= oneWeekLater)
            || 'Date must be in the future and not later than 1 week from today'
          );
        });
      }
      return result;
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.setLocalDate();
      },
    },
  },
  mounted() {
    this.setLocalDate();
  },
  methods: {
    inputDate() {
      this.localOpenModal = false;
      this.$emit('input', this.localDate);
    },
    setLocalDate() {
      if (this.value?.length > 10) {
        this.localDate = this.value.substring(0, 10);
      } else {
        this.localDate = this.value ?? '';
      }
    },
  },
};
</script>

<style>
.date-picker .v-text-field--filled:not(.v-text-field--single-line) input {
  margin-top: 0px !important;
}
</style>
