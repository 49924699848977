<template>
  <div class="nav-list">
    <Breadcrumbs :history="history" />
    <v-container fluid>
      <list-finance-documents arType="Crn" valueProp="credit" />
    </v-container>
  </div>
</template>

<script>
import ListFinanceDocuments from '../components/ListFinanceDocuments.vue';

export default {
  components: { ListFinanceDocuments },
  name: 'CreditNotes',
  data() {
    return {
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Finance',
        disabled: false,
        href: '/#/finance/finance-home',
        depth: 0,
      }, {
        text: 'Credit Notes',
        disabled: true,
        depth: 0,
      }],
    };
  },
};
</script>
