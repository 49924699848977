<template>
  <v-data-table
    :headers="headers"
    :items="dailyInspections"
    class="elevation-1"
    :search="search"
    :loading="isLoading"
    sort-by="created"
    sort-desc
  >
    <template v-slot:top>
      <v-row :class="{ 'p-16': $vuetify.breakpoint.mobile }">
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn :loading="isSaving"
                color="success" data-cy="VehicleInspection-button-new" v-on="on">
                <v-icon class="mr-2">
                  {{ mdiPlus }}
                </v-icon>
                Start a Daily Inspection
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                Start Inspection
              </v-card-title>
              <v-card-text>
                <v-form class="d-flex flex-column">
                  <v-select
                    v-model="dailyInspection.driverId"
                    class="mb-5"
                    :items="drivers"
                    label="Driver"
                    item-text="name"
                    item-value="id"
                  />
                  <v-select
                    v-model="dailyInspection.vehicleId"
                    :items="vehicles"
                    :item-text="v => `${v.make} ${v.model} ${v.licensePlate}`"
                    item-value="id"
                    label="Vehicle"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="dialog = false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="startInspection">
                  Start
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-text-field
            v-model="search"
            :append-icon="mdiMagnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.created="{ item }">
      {{ formatDateTime(item.created) }}
    </template>
    <template v-slot:item.openingVehicleInspectionId="{ item }">
      <v-btn color="primary" :to="{
          name: 'fleet-EditVehicleInspection',
          query: {id: item.openingVehicleInspectionId}
        }">
        View
      </v-btn>
    </template>
    <template v-slot:item.closingVehicleInspectionId="{ item }">
      <v-btn
        v-if="item.closingVehicleInspectionId"
        color="primary"
        :to="{
          name: 'fleet-EditVehicleInspection',
          query: {id: item.closingVehicleInspectionId}
        }">
        View
      </v-btn>
      <v-btn v-else color="primary" @click="startClosingInspection(item)">
        Start Closing Inspection
      </v-btn>
    </template>
    <template v-slot:item.actions="{ item }">
      <TableBasicButtons :editLink="{
          name: 'fleet-EditVehicleInspection',
          query: {id: item.id}
        }"
        @delete="remove(item)"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus, mdiMagnify } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListVehicleInspection',
  module: 'Fleet',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      mdiMagnify,
      dialog: false,
      dailyInspection: {
        driverId: null,
        vehicleId: null,
      },
      /**
       * Search string
       */
      search: '',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('dailyInspections', ['dailyInspections']),
    ...mapState('drivers', ['drivers']),
    ...mapState('vehicles', ['vehicles']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: 'Time Started',
          value: 'created',
        },
        {
          text: this.$$t('vehicleId'),
          value: 'vehicle.licensePlate',
        },
        {
          text: 'Started By',
          value: 'createdBy',
        },
        {
          text: this.$$t('openingVehicleInspectionId'),
          value: 'openingVehicleInspectionId',
        },
        {
          text: this.$$t('closingVehicleInspectionId'),
          value: 'closingVehicleInspectionId',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await Promise.all([
      (async () => {
        await this.LOAD_drivers();
      })(),
      (async () => {
        await this.LOAD_drivers();
      })(),
    ]);
    await this.LOAD_dailyInspections();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('dailyInspections', [
      'LOAD_dailyInspections',
      'CREATE_dailyInspection',
      'DELETE_dailyInspection',
      'UPDATE_dailyInspection',
    ]),
    ...mapActions('drivers', ['LOAD_drivers']),
    ...mapActions('vehicles', ['LOAD_vehicles']),
    ...mapActions('vehicleInspections', ['CREATE_vehicleInspection']),
    async startInspection() {
      this.isSaving = true;
      const { dailyInspection } = this;
      const vehicleInspection = await this.CREATE_vehicleInspection({
        vehicleId: dailyInspection.vehicleId,
      });
      dailyInspection.openingVehicleInspectionId = vehicleInspection.id;
      await this.CREATE_dailyInspection(dailyInspection);
      this.isSaving = false;
      this.$router.push({
        name: 'fleet-EditVehicleInspection',
        query: { id: vehicleInspection.id },
      });
    },
    async startClosingInspection(dailyInspection) {
      const vehicleInspection = await this.CREATE_vehicleInspection({
        vehicleId: dailyInspection.vehicleId,
      });
      await this.UPDATE_dailyInspection({
        id: dailyInspection.id,
        closingVehicleInspectionId: vehicleInspection.id,
      });
      this.isSaving = false;
      this.$router.push({
        name: 'fleet-EditVehicleInspection',
        query: { id: vehicleInspection.id },
      });
    },
    /** Delete VehicleInspection from the collection
     */
    async remove(dailyInspection) {
      this.isLoading = true;
      await this.DELETE_dailyInspection(dailyInspection.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# VehicleInspection example

'''js
<VehicleInspection />
'''
</docs>
