<template>
  <v-container fluid>
    <Breadcrumbs :history="history" />
    <v-data-table
      :headers="headers"
      :items="preferredDeliveryAddresses"
      class="elevation-1"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-btn
            color="success"
            data-cy="PreferredDeliveryAddress-button-new"
            @click="$router.push({
              name: 'shop-EditPreferredDeliveryAddress'
            })"
          >
            <v-icon class="mr-2">
              {{ mdiPlus }}
            </v-icon>
            {{ $t('common.buttons.new') }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.deliveryAddress="{ item }">
          <p>{{ item.deliveryAddress.delAddress01 }}</p>
          <p>{{ item.deliveryAddress.delAddress02 }}</p>
          <p>{{ item.deliveryAddress.delAddress03 }}</p>
      </template>
      <template v-slot:item.actions="{ item }">
        <TableBasicButtons :editLink="{
            name: 'shop-EditPreferredDeliveryAddress',
            query: {id: item.id}
          }"
          @delete="remove(item)"
        />
      </template>
    </v-data-table>
</v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListPreferredDeliveryAddress',
  module: 'Shop',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Deliveries',
        disabled: true,
        depth: 0,
      }],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('preferredDeliveryAddresses', ['preferredDeliveryAddresses']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('customerId'),
          value: 'customerId',
        },
        {
          text: this.$$t('deliveryAddress'),
          value: 'deliveryAddress',
        },
        {
          text: this.$$t('username'),
          value: 'username',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_preferredDeliveryAddresses();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('preferredDeliveryAddresses', ['LOAD_preferredDeliveryAddresses', 'DELETE_preferredDeliveryAddress']),
    /** Delete PreferredDeliveryAddress from the collection
     */
    async remove(preferredDeliveryAddress) {
      this.isLoading = true;
      await this.DELETE_preferredDeliveryAddress(preferredDeliveryAddress.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# PreferredDeliveryAddress example

'''js
<PreferredDeliveryAddress />
'''
</docs>
