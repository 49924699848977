<template>
  <div class="flex-container" data-cy="quantity-button-cart">
    <button
      class="button-blocks button-blocks-left"
      @click="remove()"
      v-if="localValue === 1"
      type="button"
      data-cy="quantity-remove"
    >
      <v-icon>{{ mdiTrashCan }}</v-icon>
    </button>
    <button
      class="button-blocks button-blocks-left"
      v-else
      @click="countDown()"
      data-cy="quantity-down"
      :disabled="localValue === 0"
      type="button">
      <span class="text-sizing">-</span>
    </button>
    <div class="quantity-block">
      <input
        v-model.number="localValue"
        type="number"
        ref="quantityInput"
        data-cy="quantity-input"
        @focus="quantityClicked"
        @input="quantityChanged"
        @keydown.enter.prevent="$emit('add')"
      />
    </div>
    <button
      class="button-blocks button-blocks-right"
      @click="countUp()"
      data-cy="quantity-up"
      type="button">
      <span class="text-sizing">+</span>
    </button>
  </div>
</template>
<style scoped>
.flex-container {
  display: flex;
  align-items: center;
}

.flex-container > * {
  flex-grow: 1;
  flex-basis: 0;
  margin: 0;
}

.quantity-block {
  flex-grow: 1;
}
</style>

<script>
import { mdiTrashCan } from '@mdi/js';

export default {
  name: 'QuantityButtonCart',
  props: {
    value: {
      type: Number,
      default: 1,
    },
    clearOnFocus: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      localValue: 1,
      mdiTrashCan,
    };
  },
  watch: {
    value() {
      this.localValue = this.value;
    },
    localValue() {
      if (this.localValue !== '') {
        this.$emit('input', this.localValue);
      }
    },
  },
  mounted() {
    this.localValue = this.value;
  },
  methods: {
    countDown() {
      this.localValue = parseInt(this.localValue ?? 0, 10) - 1;
    },
    countUp() {
      if (this.localValue === '' || this.localValue === null) {
        this.localValue = 0;
      }
      if (this.limit > 0 && this.localValue >= this.limit) {
        this.$confirm('You have reached the purchase limit for this item', {
          title: 'Purchase limit reached',
          buttonTrueText: 'OK',
          buttonFalseText: '',
        });
        return;
      }
      this.localValue = parseInt(this.localValue ?? 0, 10) + 1;
    },
    quantityClicked() {
      if (this.clearOnFocus) {
        this.localValue = '';
      }
    },
    quantityChanged() {
      if (this.limit > 0 && this.localValue > this.limit) {
        this.localValue = this.limit;
        this.$confirm('You have reached the purchase limit for this item', {
          title: 'Purchase limit reached',
          buttonTrueText: 'OK',
          buttonFalseText: '',
        });
      }
      if (this.localValue < 0) {
        this.localValue = 0;
      }
    },
    focus() {
      setTimeout(() => {
        this.$refs.quantityInput.focus();
      }, 100);
    },
    remove() {
      this.$emit('remove');
    },
  },
};
</script>
<style scoped>
input {
  text-align: center;
  max-width: 30px;
}
.quantity-group {
  border: 2px solid #E0E0E0;
}
.text-sizing {
  font-size: 21px;
}
.quantity-block {
  height: 40px;
  width: 40px;
  padding-top: 7px;
  text-align: center;
  border-bottom: 2px solid #E0E0E0;
  border-top: 2px solid #E0E0E0;
  color: #000000 ;
  background-color: #E0E0E0;
}
.quantity-block > input {
  color: #000000;
}
.button-blocks {
  height: 40px;
  width: 40px;
  color: #000000;
  background-color: #E0E0E0;
  text-align: center;
  border: 3px solid #E0E0E0;
}
.button-blocks-left {
  background-color: #E0E0E0;
  border-radius: 4px 0 0 4px;
}
.button-blocks-right {
  background-color: #E0E0E0;
  border-radius: 0 4px 4px 0;
}
table {
  border-spacing: 0;
}
</style>
