import axios from 'axios';

export default {
  async LOAD_userNotification({ commit }) {
    const userNotifications = await axios.get('/auroraApi/userNotification');
    commit('SET_userNotification', userNotifications.data);
  },
  async UPDATE_userNotification({ commit }, userNotification) {
    await axios.put(`/auroraApi/userNotification/${userNotification.id}`, userNotification);
    commit('SET_userNotification', userNotification);
  },
};
