<template>
  <v-container fluid class="pa-6">
    <v-data-iterator
      id="data-operator"
      data-cy="favourites-list"
      :loading="isLoading"
      :items="stockItems"
      :items-per-page="24"
      :footer-props="footerProps"
      :hide-footer="stockItems.length < 24"
      :search="search"
      no-data-text="No favourites found"
    >
      <template v-slot:header>
        <v-row dense>
          <v-col
            class="d-flex align-center justify-space-between"
            cols="12"
            offset-xl="6"
            xl="6"
            lg="10"
            offset-lg="2"
          >
            <ImportFavourites v-if="isInRole('Administrators')" @imported="refreshFavourites" />
            <div class="mr-2" v-if="divisions.length > 0" />
            <DivisionFilter v-if="divisions.length > 0" />
            <div class="mr-2" />
            <ExportToExcelButton name="Favourites" :data="exportFavourites" height="48" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:default="{ items }">
        <v-row class="d-flex">
          <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="3"
            class="d-flex align-stretch col-sm-padding stock-item-card"
            v-for="(stockItem, index) in items"
            :key="`stockItem-${index}`"
            :data-cy="`stock-item-${index}`"
          >
            <StockItemCard
              :stockItem="stockItem"
              :showAlternative="true"
              @add="add"
              @change="change"
              @favouriteChange="$emit('favouriteChange')"
            />
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { mdiMagnify, mdiRefresh } from '@mdi/js';
import ExportToExcelButton from '@/components/ExportToExcelButton.vue';
import DivisionFilter from './DivisionFilter.vue';
import ImportFavourites from './ImportFavourites.vue';
import StockItemCard from './StockItemCard.vue';
import cartMethods from '../mixins/cartMethods';
import division from './division';

export default {
  name: 'CardListFavouriteItems',
  props: ['stockItems', 'isLoading'],
  mixins: [cartMethods, division],
  components: {
    DivisionFilter,
    ExportToExcelButton,
    ImportFavourites,
    StockItemCard,
  },
  data() {
    return {
      mdiMagnify,
      mdiRefresh,
      search: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
    ...mapGetters('stockItems', ['getStockItemDetail']),
    ...mapState('divisions', ['divisions']),
    exportFavourites() {
      return this.stockItems.map((item) => ({
        ItemCode: item.itemCode,
        Description: item.description,
        'Price exVAT': this.getStockItemDetail(item)?.price,
        UOM: item.unitOfMeasure,
      }));
    },
    footerProps() {
      return { 'items-per-page-options': [24, 48, 96, -1] };
    },
  },
  async mounted() {
    await this.refresh();
    await this.refreshFavourites();
  },
  methods: {
    ...mapActions('pricings', ['LOAD_pricings']),
    ...mapActions('stockItems', [
      'LOAD_stockLimits',
      'LOAD_stockDataSafe',
      'LOAD_divisionFavouriteStockItems',
      'LOAD_favouriteStockItems',
    ]),
    async refresh() {
      // await this.LOAD_stockDataSafe();
    },
    async refreshFavourites() {
      if (this.divisionId) {
        await this.LOAD_divisionFavouriteStockItems(this.divisionId);
      } else {
        await this.LOAD_favouriteStockItems();
      }
    },
    searchChanged(val) {
      this.search = val;
    },
  },
};
</script>
