<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="customerLocations"
      class="elevation-1 nav-list"
      :loading="isLoading"
      :search="search"
    >
      <template v-slot:top>
        <v-row :class="{ 'p-16': $vuetify.breakpoint.mobile }">
          <v-col cols="12" md="6" lg="6" xl="6">
            <v-btn
              color="primary"
              data-cy="CustomerLocation-button-new"
              :class="{ 'ml-5': !$vuetify.breakpoint.mobile }"
              @click="$router.push({
                name: 'shop-EditCustomerLocation'
              })"
            >
              <v-icon class="mr-2">
                {{ mdiPlus }}
              </v-icon>
              {{ $t('common.buttons.new') }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="6" lg="6" xl="6">
            <v-text-field
              label="Search"
              class="mt-5"
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.actions="{ item }">
        <TableBasicButtons :editLink="{
            name: 'shop-EditCustomerLocation',
            query: {id: item.id}
          }"
          @delete="remove(item)"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListCustomerLocation',
  module: 'Shop',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Customer Locations',
        disabled: true,
        depth: 0,
      }],
      /**
       * Search string
       */
      search: '',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('customerLocations', ['customerLocations']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('customerCode'),
          value: 'customerCode',
        },
        {
          text: 'New Customer Name',
          value: 'customerName',
        },
        {
          text: this.$$t('lon'),
          value: 'lng',
        },
        {
          text: this.$$t('lat'),
          value: 'lat',
        },
        {
          text: this.$$t('manager'),
          value: 'manager',
        },
        {
          text: this.$$t('restaurantType'),
          value: 'restaurantType',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_customerLocations();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('customerLocations', ['LOAD_customerLocations', 'DELETE_customerLocation']),
    /** Delete CustomerLocation from the collection
     */
    async remove(customerLocation) {
      this.isLoading = true;
      await this.DELETE_customerLocation(customerLocation.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# CustomerLocation example

'''js
<CustomerLocation />
'''
</docs>
