<template>
  <v-data-table
    :headers="headers"
    :items="suppliers"
    class="elevation-1"
    :loading="isLoading"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-btn
          color="success"
          data-cy="Supplier-button-new"
          :to="{
            name: 'shop-EditSupplier'
          }"
        >
          {{ $t('common.buttons.new') }}
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        :data-cy="`button-Supplier-edit-${item.id}`"
        :to="{
          name: 'shop-EditSupplier',
          query: {id: item.id}
        }"
      >
        {{ mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="remove(item)"
        color="danger"
        :data-cy="`button-Supplier-remove-${item.id}`"
      >
        {{ mdiDelete }}
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiDelete, mdiPencil } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListSupplier',
  module: 'Supplier',
  mixins: [loading],
  data() {
    return {
      mdiDelete,
      mdiPencil,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('suppliers', ['suppliers']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_suppliers();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('suppliers', ['LOAD_suppliers', 'DELETE_supplier']),
    /** Delete Supplier from the collection
     */
    async remove(Supplier) {
      this.isLoading = true;
      await this.DELETE_Supplier(Supplier);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Supplier example

'''js
<Supplier />
'''
</docs>
