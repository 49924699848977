<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
    >
      <template>
        <v-btn color="success">
          Print
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Picking Slip
        </v-card-title>

        <v-card-text>
          <PickingSlip :delivery-info="deliveryInfo"></PickingSlip>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Print
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PickingSlip from './PickingSlip.vue';

export default {
  name: 'PrintText',
  components: {
    PickingSlip,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      lines: [],
    };
  },
  computed: {
    deliveryInfo() {
      return this.order;
    },
    deliveryLines() {
      return this.order.map((item) => ({
        code: item.code,
        description: item.description,
        qty: item.qty,
        unit: item.unit,
        bin: item.bin,
      }));
    },
  },
  methods: {
    async loadLines() {
      const { id } = this.order;
      const { data } = await this.$http.get(`/odata/orders?$filter=Id eq ${id}&$select=Id,Cart&$expand=Cart($select=amount,itemCode,price,tax,total)`);
      this.lines = data;
    },
    open() {
      this.dialog = true;
      this.loadLines();
    },
    printText() {
      // Create a hidden iframe to load the text content for printing
      const iframe = document.createElement('iframe');
      iframe.style.position = 'absolute';
      iframe.style.width = '0';
      iframe.style.height = '0';
      iframe.style.border = '0';
      document.body.appendChild(iframe);

      // Write the text content into the iframe
      const doc = iframe.contentWindow.document;
      doc.open();
      doc.write(`<pre>${this.text}</pre>`);
      doc.close();

      // Print the content of the iframe
      iframe.contentWindow.focus();
      iframe.contentWindow.print();

      // Remove the iframe after printing
      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 1000);
    },
  },
};
</script>

<style scoped>
pre {
  white-space: pre-wrap; /* Ensure the text is wrapped properly */
  word-wrap: break-word; /* Ensure long words are wrapped properly */
}
</style>
