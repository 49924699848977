import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('marketManBuyer', 'marketManBuyers');

const customActions = {
  async UPDATE_marketManBuyer({ commit, rootGetters }, payload) {
    const data = await odata.o(rootGetters)
      .patch(`marketManBuyers('${payload.guid}')`, payload)
      .query();
    commit('SET_marketManBuyer', data);
    return data;
  },
};

export default { ...actions, ...customActions };
