<template>
  <v-data-table
    :headers="headers"
    :items="fleetSuppliers"
    class="elevation-1"
    :loading="isLoading"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-btn
          color="success"
          data-cy="FleetSupplier-button-new"
          @click="$router.push({
            name: 'fleet-EditFleetSupplier'
          })"
        >
          <v-icon class="mr-2">
            {{ mdiPlus }}
          </v-icon>
          {{ $t('common.buttons.new') }}
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.phone="{ item }">
      <a :href="`tel:${item.phone}`">{{ item.phone }}</a>
    </template>
    <template v-slot:item.actions="{ item }">
      <TableBasicButtons :editLink="{
          name: 'fleet-EditFleetSupplier',
          query: {id: item.id}
        }"
        @delete="remove(item)"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListFleetSupplier',
  module: 'Fleet',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('fleetSuppliers', ['fleetSuppliers']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('address'),
          value: 'address',
        },
        {
          text: this.$$t('phone'),
          value: 'phone',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_fleetSuppliers();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('fleetSuppliers', ['LOAD_fleetSuppliers', 'DELETE_fleetSupplier']),
    /** Delete FleetSupplier from the collection
     */
    async remove(fleetSupplier) {
      this.isLoading = true;
      await this.DELETE_fleetSupplier(fleetSupplier.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# FleetSupplier example

'''js
<FleetSupplier />
'''
</docs>
