import axios from 'axios';
import { mapActions, mapState } from 'vuex';
import { useAuth } from '@/use';
import authHub from '../authHub';

export default {
  data() {
    return {
      network: {
        busy: false,
        retries: 0,
      },
    };
  },
  computed: {
    ...mapState('auth', ['token', 'token_expires', 'refresh_token']),
    ...mapState('organizations', ['organization']),
  },
  methods: {
    ...mapActions('auth', ['INIT_auth', 'LOGOUT_auth', 'REFRESH_auth']),
  },
  async created() {
    this.INIT_auth({
      url: process.env.VUE_APP_APIURL,
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
    });
    // eslint-disable-next-line no-unused-vars
    authHub.$on('network:unauthorized', async (n) => {
      if (!this.network.busy) {
        if (this.network.retries > 1) {
          this.$root.$emit('auth:logoff');
        } else {
          // refresh token
          try {
            this.network.busy = true;
            this.network.retries += 1;
            this.$log.debug(`retries: ${this.network.retries}`);
            await this.REFRESH_auth();
          } catch (error) {
            this.$log.error(error);
            setTimeout(() => {
              authHub.$emit('network:unauthorized', {
                source: 'retry',
              });
            }, 2000);
          } finally {
            this.network.busy = false;
          }
        }
      } else {
        this.$log.info('ignoring unauthorized request as the refresh token request is busy');
      }
    });
    this.$root.$on('auth:login', () => {
      this.network.retries = 0;
    });
    this.$root.$on('auth:logoff', () => {
      this.LOGOUT_auth();
    });
    this.$log.debug('setting up axios');
    axios.interceptors.request.use(async (config) => {
      // const { organization } = this;
      const token = await useAuth.getAccessToken();
      const result = { ...config };
      if (token?.length > 0) {
        // eslint-disable-next-line camelcase
        result.headers.Authorization = `Bearer ${token}`;
      } else {
        this.LOGOUT_auth();
      }
      // result.headers.common.organizationid = `${organization?.id}`;
      return config;
    }, (error) => Promise.reject(error));
    axios.interceptors.response.use((response) => response, async (error) => {
      if (error) {
        console.error(error);
        if (error?.response?.status === 401) {
          authHub.$emit('network:unauthorized', {
            source: 'odata',
            data: error,
          });
        }
      } else {
        this.$log.error('Error not found');
      }
      return Promise.reject(error);
    });
    this.$log.debug('axios setup completed');
  },
};
