<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-row>
          <v-col cols="12" md="4" lg="4" xl="4">
            <DatePicker
              :filled="true"
              data-cy="FormDriver-date"
              :label="$$t('date')"
              v-model="localVehiclePart.date"
            />
          </v-col>
          <v-col cols="12" md="4" lg="4" xl="4">
            <v-text-field
              filled
              data-cy="FormVehiclePart-name"
              :label="$$t('name')"
              v-model="localVehiclePart.name"
            />
          </v-col>
          <v-col cols="12" md="4" lg="4" xl="4">
            <v-text-field
              filled
              data-cy="FormVehiclePart-partNumber"
              :label="$$t('partNumber')"
              v-model="localVehiclePart.partNumber"
            />
          </v-col>
          <v-col cols="12" md="4" lg="4" xl="4">
            <v-text-field
              filled
              data-cy="FormVehiclePart-price"
              :label="$$t('price')"
              v-model="localVehiclePart.price"
            />
          </v-col>
          <v-col cols="12" md="4" lg="4" xl="4">
            <v-text-field
              filled
              data-cy="FormVehiclePart-serialNumber"
              :label="$$t('serialNumber')"
              v-model="localVehiclePart.serialNumber"
            />
          </v-col>
          <v-col cols="12" md="4" lg="4" xl="4">
            <v-select
              filled
              :items="states"
              data-cy="FormVehiclePart-state"
              :label="$$t('state')"
              v-model="localVehiclePart.state"
            />
          </v-col>
          <v-col cols="12" md="4" lg="4" xl="4">
            <v-select
              data-cy="FormVehiclePart-supplierId"
              filled
              :label="$$t('supplierId')"
              :items="fleetSuppliers"
              item-text="name"
              item-value="id"
              v-model="localVehiclePart.supplierId"
            />
          </v-col>
          <v-col cols="12" md="4" lg="4" xl="4">
            <v-select
              data-cy="FormVehiclePart-vehicleId"
              filled
              :label="$$t('vehicleId')"
              :items="vehicles"
              :item-text="v => `${v.make} ${v.model} (${v.licensePlate})`"
              item-value="id"
              v-model="localVehiclePart.vehicleId"
              disabled
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-end mb-6">
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormVehiclePart-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import DatePicker from '@/components/DatePicker.vue';

export default {
  name: 'FormVehiclePart',
  module: 'Fleet',
  components: { DatePicker },
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    VehiclePart: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localVehiclePart
      */
      localVehiclePart: {},
    };
  },
  computed: {
    ...mapState('fleetSuppliers', ['fleetSuppliers']),
    ...mapState('vehicles', ['vehicles']),
    states() {
      return [
        'New',
        'Used',
        'Reconditioned',
        'In for repairs',
        'Repaired',
      ];
    },
  },
  watch: {
    VehiclePart() {
      this.localVehiclePart = this.VehiclePart;
    },
  },
  created() {
    this.localVehiclePart = this.VehiclePart;
    this.localVehiclePart.vehicleId = this.$route?.query.vehicleId;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localVehiclePart);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormVehiclePart example

'''js
<FormVehiclePart :VehiclePart="VehiclePart" />
'''
</docs>
