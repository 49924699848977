<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="customerLocationTimes"
      :loading="isLoading"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ timeType }} Times</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="createItem">New Item</v-btn>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-if="editedItem.id"
                        v-model="editedItem.day"
                        :items="days"
                        label="Day"
                      />
                      <v-select
                        v-else
                        v-model="selectedDays"
                        multiple
                        :items="days"
                        label="Day"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.openingTime" label="Opening Time"
                        type="time"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.closingTime" label="Closing Hour"
                        type="time"
                      />
                    </v-col>
                    <!-- Add other fields here -->
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  :loading="isSaving"
                  @click="saveItem">
                    Save
                  </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          {{  mdiPencil }}
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          {{ mdiDelete }}
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiPencil, mdiDelete } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'CustomerLocationTimes',
  mixins: [loading],
  props: {
    customerLocation: {
      type: Object,
      required: true,
    },
    timeType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mdiPencil,
      mdiDelete,
      days: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ],
      selectedDays: [],
      sortBy: 'openingTime',
      sortDesc: false,
      customerLocationTimes: [],
      dialog: false,
      editedItem: {
        customerLocationId: this.customerLocation.id,
        timeType: this.timeType,
      },
      headers: [
        { text: 'Day', value: 'day', sortable: false },
        { text: 'Opening', value: 'openingTime' },
        { text: 'Closing', value: 'closingTime' },
        // Add other header fields here
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    formTitle() {
      return this.editedItem.id ? 'Edit Item' : 'New Item';
    },
  },
  methods: {
    ...mapActions('customerLocationTimes', [
      'LOAD_customerLocationTimes',
      'DELETE_customerLocationTime',
      'CREATE_customerLocationTime',
      'UPDATE_customerLocationTime',
    ]),
    async refresh() {
      this.isLoading = true;
      this.customerLocationTimes = await this.LOAD_customerLocationTimes(
        { customerLocationId: this.customerLocation.id, timeType: this.timeType },
      );
      this.customerLocationTimes.sort((a, b) => {
        const days = {
          Monday: 1,
          Tuesday: 2,
          Wednesday: 3,
          Thursday: 4,
          Friday: 5,
          Saturday: 6,
          Sunday: 7,
        };
        return days[a.day] - days[b.day];
      });
      this.isLoading = false;
    },
    createItem() {
      this.editedItem = {
        customerLocationId: this.customerLocation.id,
        timeType: this.timeType,
      };
      this.dialog = true;
    },
    editItem(item) {
      this.editedItem = { ...item };
      this.dialog = true;
    },
    async deleteItem(item) {
      const result = await this.$confirm('Are you sure you want to delete this?');
      if (result) {
        await this.DELETE_customerLocationTime(item.id);
        await this.refresh();
      }
    },
    closeDialog() {
      this.dialog = false;
      this.editedItem = {};
    },
    async saveItem() {
      this.isSaving = true;
      if (this.editedItem.id) {
        await this.UPDATE_customerLocationTime(this.editedItem);
      } else {
        for (let index = 0; index < this.selectedDays.length; index += 1) {
          const day = this.selectedDays[index];
          const newItem = { ...this.editedItem, day };
          // eslint-disable-next-line no-await-in-loop
          await this.CREATE_customerLocationTime(newItem);
        }
      }
      this.editedItem = {
        customerLocationId: this.customerLocation.id,
        timeType: this.timeType,
      };
      this.selectedDays = [];
      this.isSaving = false;
      this.closeDialog();
      await this.refresh();
    },
  },
  created() {
    this.refresh();
  },
};
</script>
