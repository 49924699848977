export default {
  methods: {
    /**
     * Executes form validation and scrolls to first error
     */
    async validateForm(form) {
      if (form.validate()) {
        return true;
      }
      await this.$nextTick();
      const el = document.querySelector('.v-messages.error--text:first-of-type');
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        this.$log.warn('form invalid, but there are no error messages');
      }
      return false;
    },
  },
};
