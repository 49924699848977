<template>
  <v-container fluid>
    <Breadcrumbs :history="history" />
    <v-skeleton-loader
      :loading="isLoading"
      transition="expand-transition"
      type="article"
    >
      <FormCostCentre
        :CostCentre="editCostCentre"
        :isSaving="isSaving"
        @save="save"
        @cancel="$router.push({
          name: 'shop-ListCostCentre',
          query: { customerCode: $route.query.customerCode }
        })" />
    </v-skeleton-loader>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormCostCentre from '../components/FormCostCentre.vue';

export default {
  components: {
    FormCostCentre,
  },
  name: 'EditCostCentre',
  module: 'CostCentre',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing CostCentre. Default to {}.
      */
      editCostCentre: {
        customerCode: this.$route?.query.customerCode,
      },
      /**
       * Query Id for CostCentre
       */
      costCentreId: this.$route?.query.id,
      /**
      * Breadcrumbs
      */
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: `Customer (${this.$route.query.customerCode})`,
        disabled: false,
        href: '/#/admin/manage-customers',
        depth: 0,
      }, {
        text: 'Cost Centres',
        href: `/#/shop/list-cost-centre?customerCode=${this.$route.query.customerCode}`,
        disabled: false,
        depth: 0,
      }, {
        text: 'Edit',
        disabled: true,
        depth: 0,
      }],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('costCentres', ['costCentres', 'costCentre']),
    ...mapState('customers', ['activeCustomer', 'customers']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.costCentreId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_costCentre(this.costCentreId);
      this.editCostCentre = this.costCentre;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('costCentres', ['LOAD_costCentre', 'CREATE_costCentre', 'UPDATE_costCentre']),
    /** Method used to save the item
     */
    async save(costCentre) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_costCentre(costCentre);
      } else {
        await this.UPDATE_costCentre(costCentre);
      }
      this.isSaving = false;
      this.$router.push({ name: 'shop-ListCostCentre', query: { customerCode: this.$route?.query.customerCode } });
    },
  },
};
</script>

<docs>
# EditCostCentre example

'''js
<EditCostCentre :CostCentre="CostCentre" />
'''
</docs>
