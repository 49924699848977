<template>
  <div>
    <v-select
      ref="select"
      :items="items"
      item-text="delAddress01"
      return-object
      v-model="deliveryAddress"
      label="Delivery Address"
      @change="change"
      filled
      :rules="rules"
      data-cy="deliveryAddress"
      background-color="white"
      :solo="solo"
      :outlined="outlined"
    >
      <template v-slot:prepend-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field v-model="search"
              placeholder="Search"
            />
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
      <template v-slot:item="{ item }">
        <div style="min-height: 50px; margin-bottom: 15px; width: 100%">
          <p>{{ item.delAddress01 }}</p>
          <p>{{ item.delAddress02 }}</p>
          <p>{{ item.delAddress03 }}</p>
          <v-divider></v-divider>
        </div>
      </template>
    </v-select>
    <!-- <div v-if="deliveryAddress">
      <p>{{ deliveryAddress.delAddress02 }}</p>
      <p>{{ deliveryAddress.delAddress03 }}</p>
      <p>{{ deliveryAddress.delAddress04 }}</p>
      <p>{{ deliveryAddress.delAddress05 }}</p>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import customerRefresh from '../mixins/customerRefresh';
import syncRefresh from '../mixins/syncRefresh';

export default {
  name: 'CardDeliveryAddresses',
  props: {
    rules: {
      default: () => [],
      type: Array,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [customerRefresh, loading, syncRefresh],
  data() {
    return {
      deliveryAddress: undefined,
      search: '',
    };
  },
  computed: {
    ...mapState('auth', ['username']),
    ...mapState('deliveryAddresses', ['deliveryAddresses']),
    ...mapState('preferredDeliveryAddresses', ['preferredDeliveryAddresses']),
    displayAddress() {
      if (this.deliveryAddresses.length > 0) {
        return this.deliveryAddress;
      }
      return this.activeCustomer;
    },
    loweredSearch() {
      return this.search?.toLowerCase();
    },
    items() {
      const { deliveryAddresses, loweredSearch } = this;
      if (loweredSearch?.length > 0) {
        return deliveryAddresses
          .filter((d) => d.delAddress01?.toLowerCase().includes(loweredSearch)
              || d.delAddress02?.toLowerCase().includes(loweredSearch)
              || d.delAddress03?.toLowerCase().includes(loweredSearch));
      }
      return deliveryAddresses;
    },
  },
  watch: {
    deliveryAddresses() {
      this.setPreffered();
    },
    preferredDeliveryAddresses() {
      this.setPreffered();
    },
  },
  // inject parent VForm component
  inject: ['form'],
  mounted() {
    this.form.register(this.$refs.select);
    this.$log.debug('select registered');
    this.setPreffered();
  },
  destroyed() {
    this.form.unregister(this.$refs.select);
    this.$log.debug('select unregistered');
  },
  methods: {
    change(item) {
      this.$emit('input', item);
      this.errors = this.form.validate();
    },
    setPreffered() {
      const { username, deliveryAddresses, preferredDeliveryAddresses } = this;
      const preferredDeliveryAddress = preferredDeliveryAddresses
        .find((p) => p.username === username);
      if (preferredDeliveryAddress) {
        const deliveryAddress = deliveryAddresses
          .find((d) => d.id === preferredDeliveryAddress.deliveryAddressId);
        this.deliveryAddress = deliveryAddress;
        this.$forceUpdate();
        this.$emit('input', this.deliveryAddress);
      }
    },
  },
};
</script>
