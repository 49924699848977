<template>
  <v-data-iterator
    ref="dataTable"
    @update:page="$vuetify.goTo($refs.dataTable, goToOptions)"
    id="data-operator"
    :items="stockItems"
    :items-per-page="12"
    :page="pageNumber"
    hide-default-footer
    :search="search"
    data-cy="favourites-list"
    no-results-text=""
    no-data-text="No favourites found"
    :footer-props="{
      'disable-items-per-page': true,
      'items-per-page-options': [],
    }"
  >
    <template v-slot:no-data>
      <p class="ml-5">No favourites found</p>
    </template>
    <template v-slot:header>
      <v-row justify="space-between" class="mt-1 mb-1 mr-3 ml-3">
        <v-btn small class="mr-2 slide-in-left" @click="prevPage" :disabled="pageNumber === 1"
          color="success">
          Previous
        </v-btn>
        <span>
          Page {{ pageNumber }} of {{ pageCount }}
        </span>
        <v-btn small class="slide-in" color="primary"
          @click="nextPage" :disabled="pageNumber >= pageCount">Next</v-btn>
      </v-row>
    </template>
    <template v-slot:default="{ items }">
    <div
      v-for="(stockItem, index) in items"
      :key="`stockItem-${index}`"
      class="mobile-favourite-stock-item"
    >
        <MobileFavouriteStockItem
          :data-cy="`stock-item-${index}`"
          :index="index"
          :showFavourites="true"
          @add="add"
          @favouriteChange="$emit('favouriteChange')"
          :stockItem="stockItem" />
        <hr />
      </div>
    </template>
    <template v-slot:footer>
      <v-row justify="space-between" class="mt-1 mb-5 mr-3 ml-3">
        <v-btn small class="mr-2" @click="prevPage" :disabled="pageNumber === 1"
          color="success">
          Previous
        </v-btn>
        <span>
          Page {{ pageNumber }} of {{ pageCount }}
        </span>
        <v-btn small @click="nextPage" :disabled="pageNumber >= pageCount"
          color="primary">Next</v-btn>
      </v-row>
    </template>
  </v-data-iterator>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  mdiMagnify, mdiRefresh, mdiUndo, mdiRedo, mdiFilter,
} from '@mdi/js';
import MobileFavouriteStockItem from './MobileFavouriteStockItem.vue';
import cartMethods from '../mixins/cartMethods';

export default {
  name: 'MobileListFavouriteItems',
  props: ['stockItems', 'isLoading'],
  mixins: [cartMethods],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    MobileFavouriteStockItem,
  },
  data() {
    return {
      mdiMagnify,
      mdiRefresh,
      mdiUndo,
      mdiRedo,
      mdiFilter,
      search: '',
      pageNumber: 1,
      goToOptions: {
        duration: 500,
        offset: 300,
        easing: 'easeInOutCubic',
      },
      dialog: false,
    };
  },
  computed: {
    ...mapGetters('cart', ['total_stockItems']),
    pageCount() {
      return Math.ceil(this.stockItems?.length / 12);
    },
    pageLimit() {
      return Math.ceil(this.stockItems?.length / 25);
    },
  },
  methods: {
    nextPage() {
      if (this.pageNumber < this.pageCount) {
        this.pageNumber += 1;
      }
    },
    prevPage() {
      if (this.pageNumber > 1) {
        this.pageNumber -= 1;
      }
    },
  },
};
</script>
<style scoped>
.ios .nav-list {
  margin-top: 50px;
}
</style>
