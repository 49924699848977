<template>
  <div>
    <StockItemsLoading v-if="isLoading" />
    <CardListCategories
      v-if="!isLoading && categories.length > 0"
      :categories="categories"
      :depth="depth + 1"
      @change="updateCategory"
    />
    <CardListStockItems
      v-if="!isLoading && categories.length === 0"
      :stockItems="stockItems"
      @change="update"
    />
  </div>
</template>
<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { mdiUndo } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import CardListCategories from '../components/CardListCategories.vue';
import CardListStockItems from '../components/CardListStockItems.vue';
import StockItemsLoading from '../components/StockItemsLoading.vue';
import stockItem from '../mixins/stockItem';

export default {
  name: 'ShopStockItemByCategory',
  module: 'shop',
  components: {
    CardListCategories,
    CardListStockItems,
    StockItemsLoading,
  },
  mixins: [loading, stockItem],
  data() {
    return {
      mdiUndo,
      stockItems: [],
      otherCategories: [],
      category: this.$route.query.category,
      depth: this.$route.query.depth,
      currentScrollY: 10,
      previousScrollY: 1,
    };
  },
  computed: {
    ...mapState('webCategories', ['webCategory1', 'webCategory2', 'webCategory3', 'webCategory4']),
    ...mapGetters('taxes', ['taxesLoaded']),
    categories() {
      const categories = this[`webCategory${this.depth + 1}`] ?? [];
      return categories.filter((w) => this.otherCategories?.includes(w.id)) ?? [];
    },
  },
  async beforeRouteUpdate(to, from, next) {
    await this.setValues(to.query);
    next();
  },
  async created() {
    await this.loadCategories();
    await this.setValues(this.$route.query);
  },
  mounted() {
    this.SET_title('Shop');
    this.LOAD_stockLimits();
    this.LOAD_pricings();
  },
  methods: {
    ...mapActions('pricings', ['LOAD_pricings']),
    ...mapActions('stockItems', [
      'LOAD_stockLimits',
      'LOAD_stockDataSafe',
      'LOAD_stockItemsByWebCategory1',
      'LOAD_stockItemsByWebCategory2',
      'LOAD_stockItemsByWebCategory3',
      'LOAD_stockItemsByWebCategory4',
      'LOAD_stockItemsWithNoCategory',
      'UPDATE_stockItem']),
    ...mapActions('webCategories', [
      'LOAD_webCategory1',
      'LOAD_webCategory2',
      'LOAD_webCategory3',
      'LOAD_webCategory4',
      'UPDATE_stockItemsByWebCategory1',
      'UPDATE_stockItemsByWebCategory2',
      'UPDATE_stockItemsByWebCategory3',
      'UPDATE_stockItemsByWebCategory4',
    ]),
    ...mapActions('taxes', ['LOAD_taxes']),
    ...mapMutations('app', ['SET_title', 'SET_expandedSearch']),
    async loadCategories() {
      this.isLoading = true;
      this.$log.info('loading web categories');
      await Promise.all([
        (async () => {
          await this.LOAD_webCategory1();
        })(),
        (async () => {
          await this.LOAD_webCategory2();
        })(),
        (async () => {
          await this.LOAD_webCategory3();
        })(),
        (async () => {
          await this.LOAD_webCategory4();
        })(),
      ]);
      this.$log.info('loaded web categories');
      this.isLoading = false;
    },
    async setValues(query) {
      this.$log.info('setting values');
      this.depth = parseInt(query.depth ?? '1', 10);
      this.category = query.category ?? '';
      await this.refresh();
    },
    async refresh() {
      try {
        this.isLoading = true;
        const { category, depth } = this;
        this.$log.info(`refreshing category ${category} (${this.categories.length}) with depth of ${depth}`);
        this.stockItems = await this[`LOAD_stockItemsByWebCategory${depth}`](category);
        const cat = `webCategory${this.depth}Id`;
        this.$log.info(`using other category ${cat}`);
        this.otherCategories = [...new Set(this.stockItems
          .filter((s) => s[cat] !== null)
          .map((s) => s[`webCategory${this.depth + 1}Id`]))];

        if (!this.taxesLoaded) {
          this.$log.info('loading taxes');
          await this.LOAD_taxes();
        }
      } catch (error) {
        this.$root.$emit('error', 'Unable to load new stock items');
      } finally {
        this.isLoading = false;
      }
    },
    async updateCategory($event) {
      this.isLoading = true;
      await this[`UPDATE_stockItemsByWebCategory${this.depth + 1}`]($event);
      this.isLoading = false;
      await this.$nextTick();
      await this.loadCategories();
      await this.refresh();
    },
  },
};
</script>
