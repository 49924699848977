import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('stockItems', [
      'UPDATE_stockItem']),
    async update(stockItem) {
      console.debug(stockItem);
      this.isLoading = true;
      await this.UPDATE_stockItem(stockItem);
      this.isLoading = false;
      await this.$nextTick();
      await this.refresh();
    },
  },
};
