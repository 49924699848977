<template>
  <div>
    <Breadcrumbs :history="history" />
    <v-data-table
      :headers="headers"
      :items="orders"
      class="elevation-1"
      :loading="isLoading"
    >
      <template v-slot:item.orderDate="{ item }">
        {{ formatDate(item.orderDate) }}
      </template>
      <template v-slot:item.deliveryDate="{ item }">
        {{ formatDate(item.deliveryDate) }}
      </template>
      <template v-slot:item.orderTotalIncVat="{ item }">
        <span v-if="item.orderTotalIncVat">
          R {{ formatCurrency(item.orderTotalIncVat) }}
        </span>
        <span v-else>
          -
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiDelete, mdiPencil } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListOrder',
  module: 'Orders',
  mixins: [loading],
  data() {
    return {
      mdiDelete,
      mdiPencil,
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Orders',
        disabled: false,
        href: '/#/shop/orders',
        depth: 0,
      }, {
        text: 'History',
        disabled: true,
        depth: 0,
      }],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('orders', ['orders']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('state'),
          value: 'state',
        },
        {
          text: this.$$t('orderDate'),
          value: 'orderDate',
        },
        {
          text: this.$$t('deliveryDate'),
          value: 'deliveryDate',
        },
        {
          text: this.$$t('orderNo'),
          value: 'orderNo',
        },
        {
          text: this.$$t('invoiceNo'),
          value: 'invoiceNo',
        },
        {
          text: this.$$t('orderTotalIncVat'),
          value: 'orderTotalIncVat',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_orders();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('orders', ['LOAD_orders']),
  },
};
</script>

<docs>
# Category example

'''js
<Category />
'''
</docs>
