<template>
  <v-container fluid>
    <v-card>
      <v-card-title primary-title>
      <v-tabs v-model="tab" background-color="white" color="primary">
        <v-tab v-for="tab in tabs" :key="tab" @click="currentTab = tab">{{ tab }}</v-tab>
      </v-tabs>
      </v-card-title>
      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="tab in tabs" :key="tab">
            <component :is="tab.replace(' ', '-').toLowerCase()" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <BookingNotification />
    </v-card>
  </v-container>
</template>

<script>
import title from '@/mixins/title';
import BookingNotification from '../components/BookingNotification.vue';
import Vehicles from './ListVehicle.vue';
import Drivers from './ListDriver.vue';
import Inspections from './ListDailyInspection.vue';
import Suppliers from './ListFleetSupplier.vue';
import Bookings from './ListFleetSupplierBooking.vue';
import MaintenanceSchedule from '../components/MaintenanceSchedule.vue';

export default {
  name: 'FleetHome',
  components: {
    BookingNotification,
    Vehicles,
    Bookings,
    Drivers,
    Inspections,
    MaintenanceSchedule,
    Suppliers,
  },
  mixins: [title],
  data() {
    return {
      tab: parseFloat(this.$route.query.tab || '0', 10),
      tabs: ['Vehicles', 'Drivers', 'Inspections', 'Suppliers', 'Bookings'],
    };
  },
  mounted() {
    this.SET_title('Fleet');
  },
};
</script>
<style>
.v-slide-group__prev--disabled {
  display: none !important;
}
</style>
