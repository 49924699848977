import { uniqBy } from 'lodash';

export default {
  allCategories: (state) => [
    ...state.webCategory1,
    ...state.webCategory2,
    ...state.webCategory3,
    ...state.webCategory4,
  ],
  getWebCategory1: (state) => state.webCategory1,
  getWebCategory2: (state) => (parent) => uniqBy(state.webCategory2
    .filter((c) => c.webCategory1 === parent)
    .map((c) => ({
      webCategory2: c.webCategory2,
      webCategory2Photo: c.webCategory2Photo,
    })), (u) => u.webCategory2),
  getWebCategory3: (state) => (parent) => uniqBy(state.categories
    .filter((c) => c.webCategory2 === parent)
    .map((c) => ({
      webCategory3: c.webCategory3,
      webCategory3Photo: c.webCategory3Photo,
    })), (u) => u.webCategory3),
  getWebCategory4: (state) => (parent) => uniqBy(state.categories
    .filter((c) => c.webCategory3 === parent)
    .map((c) => ({
      webCategory4: c.webCategory4,
      webCategory4Photo: c.webCategory4Photo,
    })), (u) => u.webCategory4),
  findCategory: (state) => (stockItem) => {
    if (!stockItem) return 'No Category';

    if (stockItem.webCategory4Id !== null) {
      return state.webCategory4.find((c) => c.id === stockItem.webCategory4Id)?.name;
    }
    if (stockItem.webCategory3Id !== null) {
      return state.webCategory3.find((c) => c.id === stockItem.webCategory3Id)?.name;
    }
    if (stockItem.webCategory2Id !== null) {
      return state.webCategory2.find((c) => c.id === stockItem.webCategory2Id)?.name;
    }
    if (stockItem.webCategory1Id !== null) {
      return state.webCategory1.find((c) => c.id === stockItem.webCategory1Id)?.name;
    }
    return 'No Category';
  },
};
