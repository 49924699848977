<template>
  <v-alert
    v-if="isOnHold"
    dense
    border="left"
    type="warning"
    :icon="mdiAlertOutline"
    >
      Your account is currently on hold, please contact Gourmet Foods to have it enabled again.
  </v-alert>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mdiAlertOutline } from '@mdi/js';

export default {
  module: 'auth',
  name: 'OnHoldCustomer',
  data() {
    return {
      mdiAlertOutline,
    };
  },
  computed: {
    ...mapGetters('customers', ['isOnHold']),
  },
  async mounted() {
    await this.refresh();
  },
  created() {
    this.$root.$on('auth:login', async () => {
      await this.refresh();
    });
  },
  methods: {
    ...mapActions('customers', ['LOAD_activeCustomer']),
    async refresh() {
      await this.LOAD_activeCustomer();
    },
  },
};
</script>
