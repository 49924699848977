<template>
  <div />
</template>

<script>
import { mapMutations } from 'vuex';
import sync from '../mixins/sync';

export default {
  name: 'SyncService',
  mixins: [sync],
  data() {
    return {
      snackbar: false,
      showClose: false,
      timeout: -1,
    };
  },
  async mounted() {
    this.$root.$on('offline:change', async () => {
      this.snackbar = true;
      if (this.isOffline) {
        await this.$db.openDB();
        await this.refresh();
      } else {
        try {
          await this.clearStores();
          await this.$db.deleteDb();
        } catch (e) {
          this.SET_isOffline(true);
        }
      }
    });
    this.$root.$on('favourites:change', async () => {
      await this.syncFavourites();
    });
    this.$root.$on('activeCustomer:change', async () => {
      this.snackbar = true;
      await this.clearStores();
      await this.refresh();
    });
    if (!this.$route.query?.login) {
      this.snackbar = true;
      await this.init();
      await this.clearStores();
      await this.refresh();
    }
  },
  destroyed() {
    this.$root.$off('activeCustomer:change');
    this.$root.$off('offline:change');
    this.$root.$off('favourites:change');
  },
  methods: {
    ...mapMutations('app', ['SET_isOffline']),
    /**
     * Executes refresh of the data.
     */
    async refresh() {
      this.sync();
    },
    async sync() {
      this.snackbar = true;
      this.timeout = -1;
      await this.syncDb();
      this.$root.$emit('sync:completed');
      this.timeout = 2000;
      this.showClose = true;
    },

  },
};
</script>
