<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          small
        >
          Edit
        </v-btn>
      </template>
    <v-card>
      <v-card-title>
        <span class="headline">Edit Customer: {{ localCustomer.customerDesc }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="localCustomer.customerCode"
                disabled
                label="Customer Code"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="localCustomer.customerDesc"
                disabled
                label="Customer Description"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="localCustomer.hideStockOnHand"
                label="Hide Stock on Hand"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog = false" text>Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="save" color="primary">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CustomerEditDialog',
  props: {
    customer: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      localCustomer: this.customer,
    };
  },
  watch: {
    customer: {
      handler() {
        this.localCustomer = this.customer;
      },
      immediate: true,
    },
  },
  methods: {
    save() {
      this.$emit('save', this.localCustomer);
      this.dialog = false;
    },
  },
};
</script>
