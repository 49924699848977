import { useAuth } from '@/use';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('pricing', 'pricings');

const customActions = {
  async LOAD_pricings({ commit, rootGetters }) {
    // if (rootGetters['app/isOffline']) {
    //   const localData = await this.$db.getAll('pricings');
    //   commit('SET_pricings', localData);
    //   return localData;
    // }
    const token = await useAuth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('pricings')
      .query({ $filter: 'IsDeleted eq false' });
    commit('SET_pricings', data);
    return data;
  },
};

export default { ...actions, ...customActions };
