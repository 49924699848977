<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
    <v-row>
      <v-col cols="12" md="4" lg="3" xl="3">
        <v-text-field
          filled
          data-cy="FormDriver-name"
          :label="$$t('name')"
          v-model="localDriver.name"
        />
      </v-col>
      <v-col cols="12" md="4" lg="3" xl="3">
        <v-text-field
          filled
          data-cy="FormDriver-idNumber"
          :label="$$t('idNumber')"
          v-model="localDriver.idNumber"
        />
      </v-col>
      <v-col cols="12" md="4" lg="3" xl="3">
        <DatePicker
          filled
          data-cy="FormDriver-licenseExpirationDate"
          :label="$$t('licenseExpirationDate')"
          v-model="localDriver.licenseExpirationDate"
        />
      </v-col>
      <v-col cols="12" md="4" lg="3" xl="3">
        <DatePicker
          filled
          data-cy="FormDriver-pdpLicenseExpiry"
          :label="$$t('pdpLicenseExpiry')"
          v-model="localDriver.pdpLicenseExpiry"
        />
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-img
          v-if="localDriver.licensePhoto"
          class="mb-5"
          height="300"
          contain
          :src="localDriver.licensePhoto" />
        <ImageUpload
          data-cy="FormStockItem-licensePhoto"
          v-model="localDriver.licensePhoto"
          :label="$$t('licensePhoto')" />
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-img
          v-if="localDriver.driverPhoto"
          class="mb-5"
          height="300"
          contain
          :src="localDriver.driverPhoto" />
        <ImageUpload
          data-cy="FormStockItem-driverPhoto"
          v-model="localDriver.driverPhoto"
          :label="$$t('driverPhoto')" />
      </v-col>
    </v-row>
      <div class="d-flex justify-end mb-6">
        <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
          data-cy="FormDriver-button-cancel">
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
          data-cy="FormDriver-button-save">
            {{ $t('common.buttons.save') }}
          </v-btn>
      </div>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import DatePicker from '~src/components/DatePicker.vue';

export default {
  name: 'FormDriver',
  module: 'Fleet',
  components: { DatePicker },
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Driver: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
       * History for breadcrumbs
       */
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Drivers',
        disabled: true,
        depth: 0,
      }],
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localDriver
      */
      localDriver: {},
    };
  },
  watch: {
    Driver() {
      this.localDriver = this.Driver;
    },
  },
  created() {
    this.localDriver = this.Driver;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localDriver);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormDriver example

'''js
<FormDriver :Driver="Driver" />
'''
</docs>
