import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('customerLocationTime', 'customerLocationTimes');

const customActions = {
  async LOAD_customerLocationTimes({ commit, rootGetters }, { customerLocationId, timeType }) {
    const data = await odata.o(rootGetters)
      .get('customerLocationTimes')
      .query({ $filter: `IsDeleted eq false AND CustomerLocationId eq ${customerLocationId} AND TimeType eq '${timeType}'` });
    commit('SET_customerLocationTimes', data);
    return data;
  },
  async CREATE_customerLocationTime({ rootGetters }, data) {
    const result = await odata.o(rootGetters)
      .post('customerLocationTimes', data)
      .query();
    return result;
  },
};

export default { ...actions, ...customActions };
