<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <div v-if="!localDelivery.id" class="text-h5">
          Select a Driver
        </div>
        <v-row v-if="!localDelivery.id">
          <v-col v-for="(item) in drivers" :key="item.id" cols="4">
            <v-card class="mt-5">
              <v-card-text>
                <v-img
                  width="100%"
                  height="300"
                  contain
                  :src="item.licensePhoto"></v-img>
                <v-list-item-subtitle v-html="item.name"></v-list-item-subtitle>
                <v-list-item-subtitle>
                  PDP Expires: {{ formatDate(item.pdpLicenseExpiry) }}
                </v-list-item-subtitle>
                <v-divider></v-divider>
              </v-card-text>
              <v-card-actions>
                <v-btn class="mb-5" color="primary" @click="create(item)">
                  Select
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else>
          <GmapMap
              :center="center"
              :zoom="zoom"
              style="width: 100%; height: 300px"
            >
              <GmapInfoWindow
                :position="infoWindowPos"
                :opened="infoWinOpen"
                @closeclick="infoWinOpen=false"
              >
                <div v-html="selectedMarker.description" />
                  <span
                    class="d-block text-subtitle-2 text-right"
                    style="font-size: smaller !important;"
                  >
                    {{ selectedMarker.title }}
                  </span>
              </GmapInfoWindow>
              <GmapMarker
                v-for="(marker, index) in markers"
                :key="index"
                :position="marker.position"
                :title="marker.title"
                @click="toggleInfoWindow(marker, index)"
              >
              </GmapMarker>
            </GmapMap>
          <v-col cols="3">
            <v-autocomplete
              filled
              :items="drivers"
              item-text="name"
              item-value="id"
              data-cy="FormDelivery-driverId"
              :label="$$t('driverId')"
              v-model="localDelivery.driverId"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="selectedOrder"
              data-cy="FormDelivery-orderId"
              filled
              :label="$$t('orderId')"
              :items="orders"
              item-text="externalOrderNo"
              item-value="id"
              @change="orderChanged"
            />
          </v-col>
          <v-col cols="3">
            {{ deliveryText }}
          </v-col>
          <v-col cols="1">
            <v-btn
              color="primary"
              @click="addOrder"
              :disabled="!selectedOrder"
              data-cy="FormDelivery-button-add"
            >
                Add
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              filled
              data-cy="FormDelivery-status"
              :label="$$t('status')"
              v-model="localDelivery.status"
              :items="['Scheduling', 'Delivering', 'Completed']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              filled
              rows="3"
              auto-grow
              data-cy="FormDelivery-notes"
              :label="$$t('notes')"
              v-model="localDelivery.notes"
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormDelivery-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormDelivery-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FormDelivery',
  module: 'Orders',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Delivery: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localDelivery
      */
      localDelivery: {},
      selectedOrder: undefined,
      selectedMarker: {},
      currentMidx: null,
      infoWindowPos: null,
      infoWinOpen: false,
      content: '',
      center: { lat: -33.825370, lng: 18.538130 },
      zoom: 9,
      deliveryText: '',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('drivers', ['drivers']),
    ...mapState('deliveryOrders', ['deliveryOrders']),
    ...mapState('orders', ['orders']),
    deliveryAddress() {
      if (this.selectedOrder) {
        const order = this.orders.find((o) => o.id === this.selectedOrder);
        return order.deliveryAddressId;
      }
      return '';
    },
    markers() {
      return [];
    },
  },
  watch: {
    Delivery() {
      this.localDelivery = this.Delivery;
    },
  },
  created() {
    this.localDelivery = this.Delivery;
  },
  methods: {
    ...mapActions('deliveryAddresses', ['LOAD_deliveryAddressesById']),
    async addOrder() {
      const order = this.orders.find((o) => o.id === this.selectedOrder);
      this.$emit('add', order);
    },
    /** Create a new delivery
     * @param {object} driver
     */
    create(driver) {
      this.localDelivery.driverId = driver.id;
      this.$emit('create', this.localDelivery);
    },
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localDelivery);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
    async orderChanged(orderId) {
      const order = this.orders.find((o) => o.id === orderId);
      this.deliveryText = 'Searching...';
      const { deliveryAddressId } = order;
      const { data } = await this.$http.get(`/api/deliveries/addresses/${order.customerId}`);
      const deliveryAddress = data
        .find((da) => da.deliveryAddressId === deliveryAddressId);
      if (deliveryAddress === undefined) {
        this.deliveryText = 'No delivery address found';
        return;
      }
      const searchText = `${deliveryAddress.delAddress01}, ${deliveryAddress.delAddress02}, ${deliveryAddress.delAddress03}`;
      const location = await this.queryLocation(searchText);
      if (location.length > 0) {
        this.center = location[0].geometry.location;
        this.markers.push({
          position: location[0].geometry.location,
          title: location[0].name,
          description: location[0].formatted_address,
        });
      } else {
        this.$log.error('No location found');
        this.deliveryText = 'No location found';
      }
      this.$log.info('orderChanged');
    },
    async queryLocation(description) {
      const key = process.env.VUE_APP_GOOGLE_API_KEY;
      const params = new URLSearchParams({
        description,
        fields: 'photos,formatted_address,name,rating,opening_hours,geometry',
        key,
      });

      try {
        this.isLoading = true;
        const { data } = await this.$http.get(`/api/google-query/maps?${params.toString()}`);
        return data;
      } catch (error) {
        console.error('Error searching Google Maps:', error);

        return [];
      } finally {
        this.isLoading = false;
      }
    },
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.selectedMarker = marker;

      // check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        // if different marker set infowindow to open and reset current marker index
        this.currentMidx = idx;
        this.infoWinOpen = true;
        this.$log.info('opened info window');
      }
    },
  },
};
</script>

<docs>
# FormDelivery example

'''js
<FormDelivery :Delivery="Delivery" />
'''
</docs>
