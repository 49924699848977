<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          filled
          data-cy="FormVehicle-licensePlate"
          :label="$$t('licensePlate')"
          v-model="localVehicle.licensePlate"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          filled
          :items="makes"
          data-cy="FormVehicle-make"
          :label="$$t('make')"
          v-model="localVehicle.make"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          filled
          data-cy="FormVehicle-model"
          :label="$$t('model')"
          v-model="localVehicle.model"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          :items="fuelTypes"
          filled
          data-cy="FormVehicle-fuelType"
          :label="$$t('fuelType')"
          v-model="localVehicle.fuelType"
        />
      </v-col>
      <v-col cols="12" md="2">
        <DatePicker
          filled
          data-cy="FormVehicle-licenseRenewalDate"
          :label="$$t('licenseRenewalDate')"
          v-model="localVehicle.licenseRenewalDate"
        />
      </v-col>
      <v-col cols="12" md="2">
        <DatePicker
          filled
          data-cy="FormVehicle-nextServiceDate"
          :label="$$t('nextServiceDate')"
          v-model="localVehicle.nextServiceDate"
        />
      </v-col>
      <v-col cols="12" md="2">
        <DatePicker
          filled
          data-cy="FormVehicle-nextTireChangeDate"
          :label="$$t('nextTireChangeDate')"
          v-model="localVehicle.nextTireChangeDate"
        />
      </v-col>
      <v-col cols="12" md="3">
        <DatePicker
          filled
          data-cy="FormVehicle-coldStorageCertificateRenewalDate"
          :label="$$t('coldStorageCertificateRenewalDate')"
          v-model="localVehicle.coldStorageCertificateRenewalDate"
        />
      </v-col>
      <v-col cols="12" md="3">
        <DatePicker
          filled
          data-cy="FormVehicle-refrigerationServiceDate"
          :label="$$t('refrigerationServiceDate')"
          v-model="localVehicle.refrigerationServiceDate"
        />
      </v-col>
      <v-col cols="12" md="4">
        <FileUpload
          data-cy="FormStockItem-coldStorageCertificate"
          v-model="localVehicle.coldStorageCertificate"
          :label="$$t('coldStorageCertificate')" />
        <v-btn
          color="success"
          small
          v-if="localVehicle.coldStorageCertificate"
          :href="localVehicle.coldStorageCertificate">
          Download Certificate
        </v-btn>
      </v-col>
    </v-row>
    <div class="d-flex justify-end mb-6">
      <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
        data-cy="FormVehicle-button-cancel">
        {{ $t('common.buttons.cancel') }}
      </v-btn>
      <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
        data-cy="FormVehicle-button-save">
          {{ $t('common.buttons.save') }}
        </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import DatePicker from '~src/components/DatePicker.vue';

export default {
  name: 'FormVehicle',
  module: 'Fleet',
  components: { DatePicker },
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Vehicle: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localVehicle
      */
      localVehicle: {},
    };
  },
  computed: {
    ...mapState('vehicles', ['fuelTypes', 'makes']),
  },
  watch: {
    Vehicle() {
      this.localVehicle = this.Vehicle;
    },
  },
  created() {
    this.localVehicle = this.Vehicle;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localVehicle);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormVehicle example

'''js
<FormVehicle :Vehicle="Vehicle" />
'''
</docs>
