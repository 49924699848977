<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8">
        <table-cart :cartItems="quoteCart" :disabled="true">
            <template v-slot:notes>
              <v-textarea
                disabled
                class="mt-10"
                outlined
                label="Notes"
                v-model="quote.order.notes"
                hint="e.g. special delivery notes"
                data-cy="Quote-notes"
              />
            </template>
        </table-cart>
      </v-col>
      <v-col cols="4">
        <v-card elevation="0" color="#e0e0e0">
          <v-card-title class="text-h5">
            Quote
          </v-card-title>
          <v-card-subtitle v-if="client">
            <active-customer />
          </v-card-subtitle>
          <v-card-text v-if="quote">
            <v-form
              lazy-validation
              v-model="valid"
              @submit.prevent="submit"
              ref="form">
              <v-row dense>
                <v-col class="black--text" cols="12">
                  Order No: <span class="float-right">{{ quote.order.orderNo }}</span>
                </v-col>
                <v-col class="black--text" cols="12">
                  Delivery Date:
                  <span class="float-right">
                    <v-btn
                      class="ml-2"
                      color="primary"
                      small
                      icon
                      :disabled="quote.order.status === 'Integrated'"
                      @click="editDeliveryDate(quote.order)"
                    >
                      <v-icon small>{{ mdiPencil }}</v-icon>
                    </v-btn>
                    {{ quote.order.deliveryDate }}
                  </span>
                </v-col>
                <v-col class="black--text" cols="12">
                  Requested By: <span class="float-right">{{ quote.createdBy }}</span>
                </v-col>
              </v-row>
              <v-divider class="mt-5 mb-5"></v-divider>
              <v-row dense v-if="quote.order.notes.length > 0">
                <v-col class="black--text" cols="12">
                  Notes:
                </v-col>
                <v-col class="black--text" cols="12">
                  {{ quote.order.notes }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" class="black--text">
                  <div v-if="quote.approvedBy">
                    This was already
                    {{ getBool(quote.approved) }} by {{ quote.approvedBy }}
                    at {{ formatDateTime(quote.dateApproved) }}
                  </div>
                  <div v-else>
                    <v-btn color="error" @click="update(false)">Decline</v-btn>
                    <v-btn class="float-right"
                      color="primary"
                      @click="update(true)"
                      :disabled="isOnHold"
                      data-cy="Quote-button-checkout"
                      type="submit" :loading="isSaving">Approve</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
            <p v-if="quoteCart.length <= 0" class="ma-5">
              <v-icon small class="primary--text mr-2 mb-1">
                fa-info
              </v-icon>
              Cart is empty
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDeliveryDate" max-width="600px">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 d-flex flex-column align-start">
          <span>
            Editing Order {{ editItem.orderNo }}
          </span>
          <span class="text-caption">
            Created by {{ editItem.createdBy }}
          </span>
        </v-card-title>
        <v-card-text>
          <DatePicker
            v-model="editItem.deliveryDate"
            label="Delivery Date"
            :filled="false"
            clearable
          />
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogDeliveryDate = false">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn color="success" :loading="isSavingItem" @click="updateDeliveryDate(editItem)">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  mapActions, mapMutations, mapState, mapGetters,
} from 'vuex';
import { mdiPencil } from '@mdi/js';
import DatePicker from '~src/components/DatePicker.vue';
import form from '../mixins/form';
import validation from '../mixins/validation';
import ActiveCustomer from '../components/ActiveCustomer.vue';
import TableCart from '../components/TableCart.vue';
import cart from '../mixins/cart';
import customerRefresh from '../mixins/customerRefresh';

export default {
  components: {
    ActiveCustomer, TableCart, DatePicker,
  },
  name: 'Quote',
  mixins: [cart, customerRefresh, form, validation],
  data() {
    return {
      mdiPencil,
      isLoading: false,
      isSavingItem: false,
      /**
      * Hold form validation state
      */
      valid: false,
      orderNo: '',
      deliveryAddressId: 0,
      deliveryDate: '',
      notes: '',
      costCentre: undefined,
      quote: undefined,
      dialogDeliveryDate: false,
      editItem: {},
    };
  },
  computed: {
    ...mapState('stockItems', ['stockItems']),
    ...mapState('customers', ['activeCustomer']),
    ...mapGetters('customers', ['isOnHold']),
    ...mapState('finance', ['client']),
    isApproval() {
      return this.costCentre?.requiresApproval ?? false;
    },
    quoteCart() {
      const { stockItems } = this;
      return this.quote?.order.cart.map((c) => {
        const result = { ...c };
        result.stockItem = stockItems.find((s) => s.itemCode === c.itemCode);
        return result;
      });
    },
    order() {
      const {
        costCentre, orderNo, deliveryAddressId, deliveryDate, orderCart, notes,
      } = this;
      return {
        cart: orderCart,
        customerId: this.activeCustomer.id,
        costCentreId: costCentre?.id,
        orderNo,
        deliveryDate,
        deliveryAddressId,
        orderDetail: this.cart.map((c) => ({
          quantity: c.amount,
          productId: c.stockItem.productId,
        })),
        quoteId: this.$route.query.quoteId,
        notes,
      };
    },
    remaining() {
      const balance = Number.parseFloat(this.client.dcBalance);
      const total = Number.parseFloat(this.total_amount);
      return balance + total;
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('stockItems', ['LOAD_stockItems']),
    ...mapMutations('app', ['SET_title', 'SET_expandedSearch']),
    editDeliveryDate(item) {
      this.editItem = item;
      this.dialogDeliveryDate = true;
    },
    // ...mapActions('finance', ['LOAD_client']),
    getBool(b) {
      if (b) {
        return 'approved';
      }
      return 'declined';
    },
    isApprover(approvers) {
      return approvers?.split(';').includes(this.userId);
    },
    /**
     * Executes refresh
     */
    async refresh() {
      this.isLoading = true;
      // await this.LOAD_client();
      const result = await this.$http.get(`/api/quotes/${this.$route.query.quoteId}`);
      this.quote = result.data;
      this.SET_title(this.quote.order.orderNo);
      await this.LOAD_stockItems();
      this.isLoading = false;
    },
    async update(approved) {
      this.isLoading = true;
      await this.$http.put('/api/quotes', {
        updateType: 'approval',
        approved,
        quoteId: this.$route.query.quoteId,
      });
      this.isLoading = false;
      this.refresh();
    },
    async updateDeliveryDate(item) {
      try {
        this.isSavingItem = true;
        await this.$http.patch(`/odata/orders/${item.id}`, { deliveryDate: item.deliveryDate });
        this.dialogDeliveryDate = false;
        await this.refresh();
        this.$root.$emit('toast:notify', 'Delivery Date Updated');
        this.editItem = {};
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Error updating delivery date');
      } finally {
        this.isSavingItem = false;
      }
    },
    /**
     * Executes checkout
     */
    async submit() {
      const isValid = await this.validateForm(this.$refs.form);
      if (isValid) {
        const { order, isApproval } = this;
        let shouldProcess = true;
        if (this.cart.filter((s) => s.stockItem.quantity === 0).length > 0) {
          shouldProcess = await this.$confirm('There are backorder items in this cart, are you sure you want to continue?');
        }
        if (shouldProcess) {
          try {
            this.isSaving = true;
            if (isApproval) {
              await this.$http.post('odata/createQuote', order);
              this.$root.$emit('toast:notify', 'Quote placed successfully');
            }
            this.RESET_CART();
          } catch (error) {
            this.$log.error(error);
            this.$root.$emit('network.error');
          } finally {
            this.isSaving = false;
            this.orderNo = '';
            this.deliveryDate = this.formatDate(new Date());
            this.$router.push({ name: 'shop-ShopHome' });
          }
        }
      }
    },
    /**
     * Executes deliveryAddressChanged
     */
    deliveryAddressChanged(item) {
      this.deliveryAddressId = item.deliveryAddressId;
    },
  },
};
</script>

<style scoped>
.field-qty {
  max-width: 180px;
}
.qty-col {
  min-width: 120px;
}
</style>
