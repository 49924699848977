<template>
  <div class="flex-container">
    <button
      class="button-blocks button-blocks-left"
      @click="countDown()"
      :disabled="localValue === 0"
      type="button">
      <span class="text-sizing">-</span>
    </button>
    <div class="quantity-block">
      <input type="number" v-model.number="localValue"
        ref="quantityInput"
        @blur="quantityBlurred"
        @focus="quantityClicked"
        @input="quantityChanged"
        @keydown.enter.prevent="$emit('add')"
      />
    </div>
    <button
      class="button-blocks button-blocks-right"
      @click="countUp()"
      type="button">
      <span class="text-sizing">+</span>
    </button>
  </div>
</template>
<style scoped>
.flex-container {
  display: flex;
  align-items: center;
}

.flex-container > * {
  flex-grow: 1;
  flex-basis: 0;
  margin: 0;
}

.quantity-block {
  flex-grow: 1;
}
</style>

<script>
export default {
  name: 'QuantityButtonBasic',
  props: {
    value: {
      type: Number,
      default: 1,
    },
    clearOnFocus: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      localValue: 1,
    };
  },
  watch: {
    value() {
      this.localValue = this.value;
    },
    localValue() {
      if (this.localValue !== '') {
        this.$emit('input', this.localValue);
      }
    },
  },
  mounted() {
    this.localValue = this.value;
  },
  methods: {
    countDown() {
      this.localValue = parseInt(this.localValue ?? 0, 10) - 1;
    },
    countUp() {
      if (this.localValue === '' || this.localValue === null) {
        this.localValue = 0;
      }
      if (this.limit > 0 && this.localValue >= this.limit) {
        this.$confirm('You have reached the purchase limit for this item', {
          title: 'Purchase limit reached',
          buttonTrueText: 'OK',
          buttonFalseText: '',
        });
        return;
      }
      this.localValue = parseInt(this.localValue ?? 0, 10) + 1;
    },
    quantityBlurred() {
      if (!this.localValue) {
        this.localValue = 1;
      }
    },
    quantityClicked() {
      if (this.clearOnFocus) {
        this.localValue = '';
      }
    },
    quantityChanged() {
      if (this.limit > 0 && this.localValue > this.limit) {
        this.localValue = this.limit;
        this.$confirm('You have reached the purchase limit for this item', {
          title: 'Purchase limit reached',
          buttonTrueText: 'OK',
          buttonFalseText: '',
        });
      }
    },
    focus() {
      setTimeout(() => {
        this.$refs.quantityInput.focus();
      }, 100);
    },
  },
};
</script>
<style scoped>
input {
  text-align: center;
  max-width: 30px;
  font-size: 14px;
}
.quantity-group {
  border: 2px solid #0067b3;
}
.text-sizing {
  font-size: 14px;
}
.quantity-block {
  height: 30px;
  width: 30px;
  text-align: center;
  border-bottom: 2px solid #0067b3;
  border-top: 2px solid #0067b3;
  color: #ffffff ;
  background-color: #0067b3;
}
.quantity-block > input {
  color: #ffffff;
}
.button-blocks {
  height: 30px;
  width: 30px;
  color: #0067b3;
  background-color: #0067b3;
  text-align: center;
  border: 3px solid #0067b3;
}
.button-blocks-left {
  background-color: #ffffff;
  border-radius: 4px 0 0 4px;
}
.button-blocks-right {
  background-color: #ffffff;
  border-radius: 0 4px 4px 0;
}
table {
  border-spacing: 0;
}
</style>
