import { Auth } from '@codehq/aurora-auth';
import authHub from '../authHub';

const useAuth = new Auth({
  baseUrl: process.env.VUE_APP_APIURL ?? 'http://localhost:3000',
  client_id: process.env.VUE_APP_CLIENT_ID ?? 'CLIENT_ID',
  client_secret: process.env.VUE_APP_CLIENT_SECRET ?? 'SECRET',
});
useAuth.onError = (error) => {
  authHub.$emit('network:unauthorized', {
    source: 'useAuth',
  });
  console.error(error);
};

export { useAuth };
