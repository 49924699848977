<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-text-field
          data-cy="FormCostCentre-name"
          :label="$$t('name')"
          v-model="localCostCentre.name"
        />
        Approvers
        <select-users
          data-cy="FormCostCentre-approvers"
          label="Approvers"
          v-model="localCostCentre.approvers"
        />
        Buyers
        <select-users
          data-cy="FormCostCentre-buyers"
          label="Buyers"
          v-model="localCostCentre.buyers"
        />
        <v-checkbox
          filled
          label="Requires Approval"
          data-cy="FormCostCentre-requiresApproval"
        v-model="localCostCentre.requiresApproval"
        />
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormCostCentre-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormCostCentre-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import SelectUsers from './SelectUsers.vue';

export default {
  name: 'FormCostCentre',
  module: 'CostCentre',
  components: { SelectUsers },
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    CostCentre: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localCostCentre
      */
      localCostCentre: {},
    };
  },
  watch: {
    CostCentre() {
      this.localCostCentre = this.CostCentre;
    },
  },
  created() {
    this.localCostCentre = this.CostCentre;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localCostCentre);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormCostCentre example

'''js
<FormCostCentre :CostCentre="CostCentre" />
'''
</docs>
