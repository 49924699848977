<template>
  <v-dialog
    v-model="dialog"
    max-width="60vw"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title primary-title>
        Upcoming
      </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6" class="text-medium" v-if="supplierBookings.length > 0">
              Please note you have the following upcoming bookings:
              <v-list>
                <v-list-item v-for="(booking, index) in supplierBookings" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ booking.date }}: {{ booking.name }} {{ booking.vehicle }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="6" class="text-medium" v-if="upcomingReminders.length > 0">
              Please note you have the following upcoming reminders:
              <v-list>
                <v-list-item v-for="(booking, index) in upcomingReminders" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ booking.date }}: {{ booking.name }} {{ booking.vehicle }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="dialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'BookingNotification',
  data() {
    return {
      dialog: true,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('fleetSupplierBookings', ['fleetSupplierBookings']),
    ...mapState('vehicles', ['vehicles']),
    nextServiceBookings() {
      return this.vehicles.filter((v) => {
        const today = new Date();
        const nextWeek = new Date();
        nextWeek.setDate(today.getDate() + 2);
        const startDate = new Date(v.nextServiceDate);
        return startDate > today && startDate <= nextWeek;
      }).map((v) => ({
        date: v.nextServiceDate,
        name: 'Next Service',
        vehicle: `(${v.licensePlate})`,
      }));
    },
    nextTireChangeBookings() {
      return this.vehicles.filter((v) => {
        const today = new Date();
        const nextWeek = new Date();
        nextWeek.setDate(today.getDate() + 2);
        const startDate = new Date(v.nextTireChangeDate);
        return startDate > today && startDate <= nextWeek;
      }).map((v) => ({
        date: this.formatDate(v.nextTireChangeDate),
        name: 'Next Tire Change',
        vehicle: `(${v.licensePlate})`,
      }));
    },
    nextLicenseRenewals() {
      return this.vehicles.filter((v) => {
        const today = new Date();
        const nextWeek = new Date();
        nextWeek.setDate(today.getDate() + 2);
        const startDate = new Date(v.licenseRenewalDate);
        return startDate > today && startDate <= nextWeek;
      }).map((v) => ({
        date: this.formatDate(v.licenseRenewalDate),
        name: 'Next License Renewal',
        vehicle: `(${v.licensePlate})`,
      }));
    },
    nextRefrigerationService() {
      return this.vehicles.filter((v) => {
        const today = new Date();
        const nextWeek = new Date();
        nextWeek.setDate(today.getDate() + 2);
        const startDate = new Date(v.refrigerationServiceDate);
        return startDate > today && startDate <= nextWeek;
      }).map((v) => ({
        date: this.formatDate(v.refrigerationServiceDate),
        name: 'Next Refrigeration Service',
        vehicle: `(${v.licensePlate})`,
      }));
    },
    nextColdStorageCertificateRenewal() {
      return this.vehicles.filter((v) => {
        const today = new Date();
        const nextWeek = new Date();
        nextWeek.setDate(today.getDate() + 2);
        const startDate = new Date(v.coldStorageCertificateRenewalDate);
        return startDate > today && startDate <= nextWeek;
      }).map((v) => ({
        date: this.formatDate(v.coldStorageCertificateRenewalDate),
        name: 'Next Cold Storage Certificate Renewal',
        vehicle: `(${v.licensePlate})`,
      }));
    },

    upcomingReminders() {
      const {
        nextServiceBookings,
        nextTireChangeBookings,
        nextLicenseRenewals,
        nextRefrigerationService,
        nextColdStorageCertificateRenewal,
      } = this;
      return [
        ...nextServiceBookings,
        ...nextTireChangeBookings,
        ...nextLicenseRenewals,
        ...nextRefrigerationService,
        ...nextColdStorageCertificateRenewal,
      ];
    },

    supplierBookings() {
      return this.fleetSupplierBookings.filter((booking) => {
        const today = new Date();
        const nextWeek = new Date();
        nextWeek.setDate(today.getDate() + 2);
        const startDate = new Date(booking.startDate);
        return startDate > today && startDate <= nextWeek;
      }).map((booking) => ({
        ...booking,
        date: booking.startDate,
        name: booking.fleetSupplier.name,
        vehicle: booking.vehicle ? `(${booking.vehicle.licensePlate})` : '',
      }));
    },
    /**
     * Get upcoming bookings
     */
    upcomingBookings() {
      const { upcomingReminders, supplierBookings } = this;
      return [...upcomingReminders, ...supplierBookings];
    },
  },
  watch: {
    upcomingBookings: {
      immediate: true,
      deep: true,
      handler(newValue) {
        console.debug('upcomingBookings', newValue);
        if (newValue.length === 0) {
          this.dialog = false;
        } else {
          this.dialog = true;
        }
      },
    },
  },
  async mounted() {
    await Promise.all([
      (async () => {
        await this.LOAD_fleetSupplierBookings();
      })(),
      (async () => {
        await this.LOAD_vehicles();
      })(),
    ]);
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('fleetSupplierBookings', ['LOAD_fleetSupplierBookings', 'DELETE_fleetSupplierBooking']),
    ...mapActions('vehicles', ['LOAD_vehicles']),
  },
};
</script>
