import { useAuth } from '@/use';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('customer', 'customers');

const customActions = {
  async LOAD_customers({ commit, rootGetters }) {
    const cache = rootGetters.getCache('LOAD_customers');
    if (cache.length === 0) {
      commit('INSERT_cache', 'LOAD_customers', { root: true });
      const token = await useAuth.getAccessToken();
      const data = await odata.o(rootGetters, token)
        .get('customers')
        .query({ $filter: 'IsDeleted eq false', $orderby: 'customerDesc' });
      commit('SET_customers', data);
    }
  },
  async LOAD_customersMin({ commit, rootGetters }) {
    const cache = rootGetters.getCache('LOAD_customers');
    if (cache.length === 0) {
      const token = await useAuth.getAccessToken();
      const data = await odata.o(rootGetters, token)
        .get('customers')
        .query({
          $filter: 'IsDeleted eq false',
          $orderby: 'customerDesc',
          $select: 'customerId,customerDesc,customerCode',
        });
      commit('SET_customers', data);
    }
  },
  async LOAD_activeCustomer({ commit }) {
    const result = await this.$http.get('/api/customer');
    const { data } = result;
    commit('SET_activeCustomer', data);
    return data;
    // const cache = rootGetters.getCache('LOAD_activeCustomer');
    // if (cache.length === 0) {
    //   commit('INSERT_cache', 'LOAD_activeCustomer', { root: true });
    //   const data = await odata.o(rootGetters)
    //     .get('activeCustomer')
    //     .query();
    //   commit('SET_activeCustomer', data);
    //   return data;
    // }
    // return state.activeCustomer;
  },
};

export default { ...actions, ...customActions };
