import Fuse from 'fuse.js';
import { sortBy } from 'lodash';

export default {
  computed: {
    searchStockItems() {
      const { stockItems } = this;
      const searchItems = stockItems.map((s) => ({
        ...s,
        searchString: s.description.replace(/-/g, ' '),
      }));

      return searchItems;
    },
  },
  methods: {
    parseCategoryResult(category, depth) {
      return {
        id: category.id,
        text: category.name,
        photo: category.photoUrl,
        webCategoryId: category.id,
        webCategoryDepth: depth,
        resultType: 'category',
      };
    },
    parseStockItemResult(stockItem) {
      return {
        id: stockItem.id,
        text: stockItem.description,
        photo: stockItem.photo,
        itemCode: stockItem.itemCode,
        stockName: stockItem.description,
        resultType: 'stockItem',
      };
    },
    async searchLocalProducts(query) {
      const fuse = new Fuse(this.stockItems, {
        keys: [{
          name: 'description',
          weight: 0.5,
        }, {
          name: 'itemCode',
          weight: 0.2,
        }, {
          name: 'barcode',
          weight: 0.1,
        }],
        includeScore: true,
        threshold: 0.7,
        useExtendedSearch: true,
      });
      const res = sortBy(fuse.search(query), 'score');
      const result = res.map((r) => ({
        ...r.item,
        score: r.score,
      }));
      return result;
    },
    async searchLocalCategories(query) {
      const fuse = new Fuse(this.allCategories, {
        keys: ['name'],
        includeScore: true,
        threshold: 0.4,
      });

      const res = sortBy(fuse.search(query), 'score')
        .reverse();
      return res.map((r) => this.parseCategoryResult(r.item));
    },
  },
};
