<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="restaurantAdmins"
      class="elevation-1"
      :loading="isLoading"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-btn
            color="success"
            data-cy="RestaurantAdmin-button-new"
            class="mr-2"
            @click="$router.push({
              name: 'shop-EditRestaurantAdmin'
            })"
          >
            <v-icon class="mr-2">
              {{ mdiPlus }}
            </v-icon>
            {{ $t('common.buttons.new') }}
          </v-btn>
          <div class="text-h5">
            Restaurant Admins
          </div>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            :append-icon="mdiMagnify"
            label="Search"
            single-line
            hide-details />
          <ExportToExcelButton
            :data="excelData"
            name="Restaurant Admins"
          />
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <TableBasicButtons :editLink="{
            name: 'shop-EditRestaurantAdmin',
            query: {id: item.id}
          }"
          @delete="remove(item)"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus, mdiMagnify } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ExportToExcelButton from '~src/components/ExportToExcelButton.vue';

export default {
  name: 'ListRestaurantAdmin',
  module: 'RestaurantAdmins',
  components: {
    ExportToExcelButton,
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      mdiMagnify,
      search: '',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('restaurantAdmins', ['restaurantAdmins']),
    excelData() {
      return this.restaurantAdmins.map((restaurantAdmin) => ({
        'User Email Address': restaurantAdmin.userName,
        'Customer Code': restaurantAdmin.customerCode,
        Created: restaurantAdmin.created,
        'Created By': restaurantAdmin.createdBy,
      }));
    },
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('userName'),
          value: 'userName',
        },
        {
          text: this.$$t('customerCode'),
          value: 'customerCode',
        },
        {
          text: 'Created By',
          value: 'createdBy',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_restaurantAdmins();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('restaurantAdmins', ['LOAD_restaurantAdmins', 'DELETE_restaurantAdmin']),
    /** Delete RestaurantAdmin from the collection
     */
    async remove(restaurantAdmin) {
      this.isLoading = true;
      await this.DELETE_restaurantAdmin(restaurantAdmin.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# RestaurantAdmin example

'''js
<RestaurantAdmin />
'''
</docs>
