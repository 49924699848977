import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('vehicle', 'vehicles');

const customActions = {
  async LOAD_vehicles({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('vehicles')
      .query({ $filter: 'IsDeleted eq false', $orderby: 'licensePlate' });
    commit('SET_vehicles', data);
    return data;
  },
};

export default { ...actions, ...customActions };
