var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.vehicleParts,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"color":"success","data-cy":"VehiclePart-button-new","to":{
          name: 'fleet-EditVehiclePart',
          query: { vehicleId: _vm.vehicleId },
        }}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.mdiPlus)+" ")]),_vm._v(" "+_vm._s(_vm.$t('common.buttons.new'))+" ")],1),_c('div',{staticClass:"text-large ml-2"},[_vm._v(" Parts ")])],1)]},proxy:true},{key:"item.date",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")]}},{key:"item.actions",fn:function({ item }){return [_c('TableBasicButtons',{attrs:{"editLink":{
        name: 'fleet-EditVehiclePart',
        query: { id: item.id, vehicleId: _vm.vehicleId }
      }},on:{"delete":function($event){return _vm.remove(item)}}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }