import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('vehiclePart', 'vehicleParts');

const customActions = {
  async LOAD_vehicleParts({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('vehicleParts')
      .query({
        $filter: 'IsDeleted eq false',
        $expand: 'Vehicle,Supplier',
      });
    commit('SET_vehicleParts', data);
    return data;
  },
};

export default { ...actions, ...customActions };
