<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormEmailLog
      :EmailLog="editEmailLog"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormEmailLog from '../components/FormEmailLog.vue';

export default {
  components: {
    FormEmailLog,
  },
  name: 'EditEmailLog',
  module: 'Shop',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing EmailLog. Default to {}.
      */
      editEmailLog: {},
      /**
       * Query Id for EmailLog
       */
      emailLogId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('emailLogs', ['emailLogs', 'emailLog']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.emailLogId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_emailLog(this.emailLogId);
      this.editEmailLog = this.emailLog;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('emailLogs', ['LOAD_emailLog', 'CREATE_emailLog', 'UPDATE_emailLog']),
    /** Method used to save the item
     */
    async save(emailLog) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_emailLog(emailLog);
      } else {
        await this.UPDATE_emailLog(emailLog);
      }
      this.isSaving = false;
      this.$router.push({ name: 'email-log-ListEmailLog' });
    },
  },
};
</script>

<docs>
# EditEmailLog example

'''js
<EditEmailLog :EmailLog="EmailLog" />
'''
</docs>
