import { mapMutations, mapState } from 'vuex';
import stores from '../stores.json';

export default {
  data() {
    return {
      text: '',
    };
  },
  computed: {
    ...mapState('app', ['isOffline', 'lastSync']),
  },
  methods: {
    ...mapMutations('app', ['SET_lastSync']),
    /**
     * Clears all the stores
     */
    async clearStores() {
      await this.message('Starting sync');
      this.$log.debug(`Clearing ${stores.length} stores`);
      stores.map(async (store) => {
        this.$log.debug('Clearing store', store.name);
        try {
          this.$db.clear(store.name);
          this.$log.info('Cleared store', store.name);
        } catch (error) {
          this.$log.error(error);
        }
      });
      this.$log.info(`Cleared ${stores.length} stores`);
    },
    /**
     * Clears all the stores
     */
    async deleteStores() {
      await this.message('Starting sync');
      // clear previous customer data
      await Promise.all(stores.map(async (store) => {
        await this.$db.deleteStore(store.name);
        this.$log.info('Cleared store', store.name);
      }));
    },
    /**
     * Executes message
     */
    async message(msg) {
      this.text = msg;
      this.$log.debug(msg);
      await this.$nextTick();
    },
    /**
     async * Executes init of db
     */
    async init() {
      try {
        await this.$db.openDB();
      } catch (error) {
        this.$root.$emit('offline:error', error);
        this.$log.error(error);
        try {
          await this.$db.deleteDb();
          await this.$db.openDB();
        } catch (innerError) {
          this.$log.error(innerError);
        }
      }
    },
    /**
     * Executes syncFavourites
     */
    async syncFavourites() {
      this.$log.info('syncing favourites');
      if (this.isOffline) {
        try {
          const { data } = await this.$http.get('api/sync/favourites');
          await new Promise((r) => setTimeout(r, 250));
          await this.$db.clear('favourites');
          await this.message(`Loading ${data.length} favourites`);
          data.forEach(async (favourite) => {
            await this.$db.upsert('favourites', favourite, 'itemCode');
          });

          const result = await this.$http.get('api/sync/customerFavourites');
          const customerFavourites = result.data;
          await this.$db.clear('customerFavourites');
          await this.message(`Loading ${customerFavourites.length} customer favourites`);
          customerFavourites.forEach(async (customerFavourite) => {
            await this.$db.upsert('customerFavourites', customerFavourite, 'id');
          });
          this.SET_lastSync(new Date());
        } catch (e) {
          this.$log.error(e);
          this.message('Sync failed');
        } finally {
          this.$log.info('sync complete');
        }
        this.$root.$emit('sync:completed');
      }
    },
    /**
     * Executes preload
     */
    async preload() {
      this.$log.debug(`Preloading ${this.stores.length}`);
      const promiseArray = this.stores.map(async (store) => {
        if (store.preload) {
          this.$log.debug(`Loading ${store.name}`);
          // eslint-disable-next-line no-await-in-loop
          const result = await this.$db.count(store.name);
          this.$log.info(`Loaded ${store.name} (${result})`);
          return result;
        }
        this.$log.debug(`Skipping preload for ${store.name}`);
        return 0;
      });
      await Promise.all(promiseArray);
    },
    /**
     async * Executes syncDb
     */
    async syncDb() {
      this.$log.info('syncing data');
      if (this.isOffline) {
        await this.message('Starting sync');
        try {
          const { data } = await this.$http.get('api/sync/full');

          await new Promise((r) => setTimeout(r, 150));

          await this.message(`Loading ${data.prices.length} prices`);
          data.prices.forEach(async (price) => {
            await this.$db.upsert('pricings', price, 'itemCode');
          });
          this.$root.$emit('offline:updated', 'pricings');

          await this.message(`Loading ${data.stockItems.length} stockItems`);
          data.stockItems.forEach(async (stockItem) => {
            await this.$db.upsert('stockItems', stockItem, 'itemCode');
          });
          this.$root.$emit('offline:updated', 'stockItems');

          await this.message(`Loading ${data.customerFavourites.length} customer favourites`);
          data.customerFavourites.forEach(async (customerFavourite) => {
            await this.$db.upsert('customerFavourites', customerFavourite, 'id');
          });
          this.$root.$emit('offline:updated', 'customerFavourites');

          await this.message(`Loading ${data.deliveryAddresses.length} deliveryAddresses`);
          data.deliveryAddresses.forEach(async (deliveryAddress) => {
            await this.$db.upsert('deliveryAddresses', deliveryAddress, 'id');
          });
          this.$root.$emit('offline:updated', 'deliveryAddresses');

          await this.message(`Loading ${data.favourites.length} favourites`);
          data.favourites.forEach(async (favourite) => {
            await this.$db.upsert('favourites', favourite, 'itemCode');
          });
          this.$root.$emit('offline:updated', 'favourites');

          await this.message(`Loading ${data.webCategory1.length} webCategory1`);
          data.webCategory1.forEach(async (webCategory1) => {
            await this.$db.upsert('webCategory1', webCategory1, 'id');
          });
          this.$root.$emit('offline:updated', 'webCategory1');

          await this.message(`Loading ${data.webCategory2.length} webCategory2`);
          data.webCategory2.forEach(async (webCategory2) => {
            await this.$db.upsert('webCategory2', webCategory2, 'id');
          });
          this.$root.$emit('offline:updated', 'webCategory2');

          await this.message(`Loading ${data.webCategory3.length} webCategory3`);
          data.webCategory3.forEach(async (webCategory3) => {
            await this.$db.upsert('webCategory3', webCategory3, 'id');
          });
          this.$root.$emit('offline:updated', 'webCategory3');

          await this.message(`Loading ${data.webCategory4.length} webCategory4`);
          data.webCategory4.forEach(async (webCategory4) => {
            await this.$db.upsert('webCategory4', webCategory4, 'id');
          });
          this.$root.$emit('offline:updated', 'webCategory4');
        } catch (e) {
          this.$log.error(e);
          this.message('Sync failed');
        } finally {
          this.SET_lastSync(new Date());
          this.$log.info('sync complete');
        }
        await this.message('Sync completed');
      }
    },
  },
};
