import axios from 'axios';

export default {
  async CREATE_organization({ commit }, organization) {
    const dbOrganization = await axios.post('/api/organization', organization);
    commit('SET_organization', dbOrganization);
    return dbOrganization;
  },
  async UPDATE_organization({ commit }, organization) {
    await axios.put('/api/organization', organization);
    commit('SET_organization', organization);
  },
  async LOAD_organizations({ commit }) {
    const result = await axios.get('/api/organization');
    commit('SET_organizations', result.data);
    commit('SET_organization', result.data[0]);
  },
  async LOAD_allOrganizations({ commit }) {
    const response = await axios.get('/api/organization/all');
    commit('SET_allOrganizations', response.data);
  },
};
