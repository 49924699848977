<template>
  <v-container fluid>
    <v-card>
      <v-card-title primary-title>
        Currently Picking
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="pickingHeaders"
          :items="activePickingLists"
          class="elevation-0"
          :loading="isLoading"
        >
          <template v-slot:item.started="{ item }">
            {{ formatDateTime(item.started) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              class="mr-2"
              @click="removeItem(item)"
              small
            >
              Continue
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card class="mt-5">
      <v-card-text>
        <v-tabs
          v-model="tab"
        >
          <v-tab>
            <v-icon class="mr-1">{{ mdiPackageVariant }}</v-icon>
            Orders
          </v-tab>
          <v-tab>
            <v-icon class="mr-1">{{ mdiStoreCheckOutline }}</v-icon>
            Awaiting Check
          </v-tab>
          <v-tab>
            <v-icon class="mr-1">{{ mdiCheck }}</v-icon>
            Completed
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-data-table
              :headers="headers"
              :items="orders"
              class="elevation-1"
              :loading="isLoading"
            >
              <template v-slot:item.orderDate="{ item }">
                {{ formatDate(item.orderDate) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  class="mr-2"
                  @click="startPickList(item)"
                  small
                >
                  Pick
                </v-btn>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item>
            <v-data-table
              :headers="headers"
              :items="orders"
              class="elevation-1"
              :loading="isLoading"
            >
              <template v-slot:item.orderDate="{ item }">
                {{ formatDate(item.orderDate) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  class="mr-2"
                  @click="checkPickList(item)"
                  small
                >
                  Check
                </v-btn>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item>
            <v-data-table
              :headers="pickingHeadersCompleted"
              :items="completedPickingLists"
              class="elevation-0"
              :loading="isLoading"
            >
              <template v-slot:item.started="{ item }">
                {{ formatDateTime(item.started) }}
              </template>
              <template v-slot:item.completed="{ item }">
                {{ formatDateTime(item.completed) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  class="mr-2"
                  @click="removeItem(item)"
                  small
                >
                  Continue
                </v-btn>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import {
  mdiDelete, mdiPencil, mdiCheck, mdiPackageVariant, mdiStoreCheckOutline,
} from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'PickingList',
  module: 'Orders',
  mixins: [loading],
  data() {
    return {
      mdiStoreCheckOutline,
      mdiDelete,
      mdiPencil,
      mdiCheck,
      mdiPackageVariant,
      tab: 0,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('orders', ['orders']),
    ...mapGetters('pickingLists', ['activePickingLists', 'completedPickingLists']),
    pickingHeadersCompleted() {
      return [
        {
          text: this.$$t('orderNo'),
          value: 'orderNo',
        },
        {
          text: this.$$t('picker'),
          value: 'createdBy',
        },
        {
          text: this.$$t('started'),
          value: 'started',
        },
        {
          text: this.$$t('completed'),
          value: 'completed',
        },
      ];
    },
    pickingHeaders() {
      return [
        {
          text: this.$$t('orderNo'),
          value: 'orderNo',
        },
        {
          text: this.$$t('picker'),
          value: 'createdBy',
        },
        {
          text: this.$$t('started'),
          value: 'started',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('status'),
          value: 'status',
        },
        {
          text: this.$$t('orderDate'),
          value: 'orderDate',
        },
        {
          text: this.$$t('deliveryDate'),
          value: 'deliveryDate',
        },
        {
          text: this.$$t('orderNo'),
          value: 'orderNo',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    this.SET_title('Picking Lists');
    this.SET_expandedSearch(false);
    await this.LOAD_pickingListOrders();
    await this.LOAD_pickingLists();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('orders', ['LOAD_pickingListOrders']),
    ...mapActions('pickingLists', ['CREATE_pickingList', 'LOAD_pickingLists']),
    ...mapMutations('app', ['SET_title', 'SET_expandedSearch']),
    async checkPickList(order) {
      this.isLoading = true;
      const pickingList = await this.CREATE_pickingList({
        orderId: order.id,
        orderNo: order.orderNo,
      });
      this.$router.push({
        name: 'shop-EditPickingList',
        query: {
          id: pickingList.id,
          type: 'check',
        },
      });
      this.isLoading = false;
    },
    async startPickList(order) {
      this.isLoading = true;
      const pickingList = await this.CREATE_pickingList({
        orderId: order.id,
        orderNo: order.orderNo,
      });
      this.$router.push({
        name: 'shop-EditPickingList',
        query: {
          id: pickingList.id,
        },
      });
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Category example

'''js
<Category />
'''
</docs>
