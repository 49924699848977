<template>
  <div data-cy="AuroraServer-Maintenance" v-if="enabled">
    <v-alert
      v-model="showWarning"
      type="warning"
      data-cy="AuroraServer-Maintenance-Alert"
      dismissible
      rounded="0">
      {{ $t('common.maintenance.warning') }} {{ $t('common.maintenance.startsIn')}}
      {{ timeRemaining }}: {{ latestMaintenance.message }}
    </v-alert>
    <v-dialog
      v-model="showMaintenance"
      persistent :overlay="true"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card data-cy="AuroraServer-Maintenance-Dialog">
        <v-toolbar
          color="warning"
          dark
        >
          <v-icon large class="mr-2">{{ mdiAlertOutline }}</v-icon>
          <div class="text-h5">
            {{ $t('common.maintenance.title') }}
          </div>
        </v-toolbar>
        <v-card-text>
          <div class="text-h6 mt-5">
            {{ latestMaintenance.message }}
          </div>
          <div class="text-h6 mt-5">
            {{ $t('common.maintenance.completesIn')}} {{ timeRemaining }}
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiAlertOutline } from '@mdi/js';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export default {
  name: 'MaintenanceNotify',
  props: {
    showMaintenanceWarning: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // Icons
      mdiAlertOutline,
      // Data
      isDismissed: false,
      now: new Date(),
      timer: undefined,
    };
  },
  computed: {
    enabled() {
      if (!this.$auroraServer) {
        return false;
      }
      const { maintenance } = this.$auroraServer;
      return maintenance?.length > 0;
    },
    /** maintenance calculations on the latest maintenance item in the list
     */
    latestMaintenance() {
      const { maintenance } = this.$auroraServer;
      return maintenance.sort((a, b) => Date.parse(b) - Date.parse(a))[0];
    },
    startInMinutes() {
      const date1 = dayjs(this.now);
      const date2 = dayjs(this.latestMaintenance.start);
      return date2.diff(date1, 'minute');
    },
    endInMinutes() {
      const date1 = dayjs(this.now);
      const date2 = dayjs(this.latestMaintenance.end);
      return date2.diff(date1, 'minute');
    },
    /** show the warning message is the maintenance is due to start in 3 hours
     */
    showWarning: {
      get() {
        if (this.isDismissed) {
          return false;
        }
        return this.startInMinutes < 180 && this.endInMinutes > 0 && this.showMaintenanceWarning;
      },
      set(value) {
        if (!value) {
          this.isDismissed = true;
        }
      },
    },
    /** show the maintenance dialog if the maintenance is active
     */
    showMaintenance() {
      return false;

      // return this.startInMinutes < 0 && this.endInMinutes > 0;
    },
    timeRemaining() {
      if (this.startInMinutes > 0) {
        return dayjs(this.latestMaintenance.start).fromNow();
      }
      return dayjs(this.latestMaintenance.end).fromNow();
    },
  },
  mounted() {
    // set a timer to update the time remaining every minute
    this.timer = setInterval(() => {
      this.$log.debug('MaintenanceNotify: updating time remaining');
      this.now = new Date();
    }, 60000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>
<style scoped>
.card-title {
  background-color: #bfbfbf;
}
</style>
