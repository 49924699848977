<template>
  <v-container fluid>
    <Breadcrumbs :history="history" />
      <v-skeleton-loader
        :loading="isLoading"
        transition="expand-transition"
        type="article"
      >
        <FormPreferredDeliveryAddress
          :PreferredDeliveryAddress=" editPreferredDeliveryAddress"
          :isSaving="isSaving"
          @save="save"
          @cancel="$router.push({name: from.name })" />
      </v-skeleton-loader>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormPreferredDeliveryAddress from '../components/FormPreferredDeliveryAddress.vue';

export default {
  components: {
    FormPreferredDeliveryAddress,
  },
  name: 'EditPreferredDeliveryAddress',
  module: 'Shop',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing PreferredDeliveryAddress. Default to {}.
      */
      editPreferredDeliveryAddress: {},
      /**
       * Query Id for PreferredDeliveryAddress
       */
      preferredDeliveryAddressId: this.$route?.query.id,
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Deliveries',
        disabled: false,
        href: '/#/shop/list-preferred-delivery-address',
        depth: 0,
      }, {
        text: 'Edit',
        disabled: true,
      }],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('preferredDeliveryAddresses', ['preferredDeliveryAddresses', 'preferredDeliveryAddress']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.preferredDeliveryAddressId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    await Promise.all([
      (async () => {
        await this.LOAD_customersMin();
      })(),
      (async () => {
        await this.loadUsers();
      })(),
    ]);
    if (this.mode === 'edit') {
      await this.LOAD_preferredDeliveryAddress(this.preferredDeliveryAddressId);
      this.editPreferredDeliveryAddress = this.preferredDeliveryAddress;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('preferredDeliveryAddresses', ['LOAD_preferredDeliveryAddress', 'CREATE_preferredDeliveryAddress', 'UPDATE_preferredDeliveryAddress']),
    ...mapActions('customers', ['LOAD_customersMin']),
    ...mapActions('userManagement', ['loadUsers']),
    /** Method used to save the item
     */
    async save(preferredDeliveryAddress) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_preferredDeliveryAddress(preferredDeliveryAddress);
      } else {
        await this.UPDATE_preferredDeliveryAddress(preferredDeliveryAddress);
      }
      this.isSaving = false;
      this.$router.push({ name: 'shop-ListPreferredDeliveryAddress' });
    },
  },
};
</script>

<docs>
# EditPreferredDeliveryAddress example

'''js
<EditPreferredDeliveryAddress :PreferredDeliveryAddress="PreferredDeliveryAddress" />
'''
</docs>
