import { useAuth } from '@/use';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('softSaleStockItem', 'softSaleStockItems');

const customActions = {
  async LOAD_softSaleStockItemsByCustomer({ commit, rootGetters }, customerCode) {
    commit('SET_softSaleStockItems', []);
    const token = await useAuth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('softSaleStockItems')
      .query({ $filter: `IsDeleted eq false AND CustomerCode eq '${customerCode}'` });
    commit('SET_softSaleStockItems', data);
    return data;
  },
};

export default { ...actions, ...customActions };
