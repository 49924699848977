<template>
  <div class="nav-list">
    <v-container fluid>
      <v-card>
        <v-card-text>
          <v-skeleton-loader
            :loading="isLoading"
            transition="expand-transition"
            type="article"
          >
            <FormVehicle
              :Vehicle="editVehicle"
              :isSaving="isSaving"
              @save="save"
              @cancel="$router.push({name: from.name })" />
          </v-skeleton-loader>
          <ListVehiclePart
            v-if="editVehicle.id"
            :vehicle-id="editVehicle.id"
          />
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormVehicle from '../components/FormVehicle.vue';
import ListVehiclePart from '../components/ListVehiclePart.vue';

export default {
  components: {
    FormVehicle,
    ListVehiclePart,
  },
  name: 'EditVehicle',
  module: 'Fleet',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing Vehicle. Default to {}.
      */
      editVehicle: {},
      /**
       * Query Id for Vehicle
       */
      vehicleId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('vehicles', ['vehicles', 'vehicle']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.vehicleId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_vehicle(this.vehicleId);
      this.editVehicle = this.vehicle;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('vehicles', ['LOAD_vehicle', 'CREATE_vehicle', 'UPDATE_vehicle']),
    /** Method used to save the item
     */
    async save(vehicle) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_vehicle(vehicle);
      } else {
        await this.UPDATE_vehicle(vehicle);
      }
      this.isSaving = false;
      this.$router.push({ name: 'fleet-FleetHome' });
    },
  },
};
</script>

<docs>
# EditVehicle example

'''js
<EditVehicle :Vehicle="Vehicle" />
'''
</docs>
