<template>
  <div v-if="activeCustomer">
    <v-text-field
      data-cy="text-deliveryAddress"
      label="Delivery Address"
      filled
      ref="textField"
      :value="activeCustomer.delAddress01"
      readonly
      background-color="white"
      :solo="solo"
      :outlined="outlined"
    ></v-text-field>
    <div data-cy="selected-address" class="ml-3">
      <p>{{ activeCustomer.delAddress02 }}</p>
      <p>{{ activeCustomer.delAddress03 }}</p>
      <p>{{ activeCustomer.delAddress04 }}</p>
      <p>{{ activeCustomer.delAddress05 }}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'DeliveryAddress',
  props: {
    solo: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('customers', ['activeCustomer']),
  },
};
</script>
