import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('accountMap', 'accountMaps');

const customActions = {
  async LOAD_accountMaps({ commit, rootGetters }, name) {
    const data = await odata.o(rootGetters)
      .get('accountMaps')
      .query({ $filter: `IsDeleted eq false AND Name eq '${name}'` });
    commit('SET_accountMaps', data);
    return data;
  },
};

export default { ...actions, ...customActions };
