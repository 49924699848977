import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('feedEmOrderLine', 'feedEmOrderLines');

const customActions = {
  async LOAD_feedEmOrderLines({ commit, rootGetters }, feedEmOrderId) {
    const data = await odata.o(rootGetters)
      .get('feedEmOrderLines')
      .query({ $filter: `IsDeleted eq false AND FeedEmOrderId eq ${feedEmOrderId}` });
    commit('SET_feedEmOrderLines', data);
    return data;
  },
};

export default { ...actions, ...customActions };
