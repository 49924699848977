import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('purchaseOrder', 'purchaseOrders');

const customActions = {
  async ACCEPT_purchaseOrder({ rootGetters }, purchaseOrderId) {
    const data = await odata.o(rootGetters)
      .post(`purchaseOrders(${purchaseOrderId})/accept`)
      .query();

    return data;
  },
  async FORCE_purchaseOrder({ rootGetters }, purchaseOrderId) {
    const data = await odata.o(rootGetters)
      .post(`purchaseOrders(${purchaseOrderId})/force`)
      .query();

    return data;
  },
  async QUEUE_purchaseOrder({ rootGetters }, purchaseOrderId) {
    const data = await odata.o(rootGetters)
      .post(`purchaseOrders(${purchaseOrderId})/queue`)
      .query();

    return data;
  },
  async LOAD_purchaseOrders({ commit, rootGetters }, query) {
    const data = await odata.o(rootGetters)
      .get('purchaseOrders')
      .query(query);

    commit('SET_purchaseOrders', data);

    return data;
  },
  async LOAD_accountNumbers({ rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('purchaseOrders')
      .query({
        $apply: 'groupby((accountNumber))',
        $orderby: 'accountNumber',
      });

    return data;
  },
};

export default { ...actions, ...customActions };
