<template>
  <v-app>
    <CartService />
    <FavouriteService />
    <NavDrawer :drawer="drawer" @close="drawer = false" />
    <SyncService v-if="isLoggedIn && !isInit" />
    <!-- Shop Home App Bar -->
    <v-app-bar
      color="white"
      class="d-flex align-center w-100 mobile-app-bar"
      height="60"
      fixed
      flat
      app
    >
      <div v-if="showBackButton" class="mr-2">
        <img class="icon" src="@/assets/icon-arrow-left.svg"
          @click="$router.go(-1)"
        />
      </div>
      <img v-else class="icon mr-2 slide-in-left" src="@/assets/icon-menu.svg"
        @click="drawer = !drawer"
      />
      <div class="d-flex justify-space-between w-100 slide-from-top">
        <v-toolbar-title
          data-cy="toolbar-title"
          class="text-h4 text-center app-bar-title flex-grow-1"
          v-if="!expandedSearch"
        >
          {{ title }}
        </v-toolbar-title>
        <div v-else class="d-flex align-center flex-grow-1">
          <GlobalSearch
            :type="isFavourites ? 'Favourites' : 'All'"
          />
        </div>
        <v-btn v-if="!expandedSearch" icon color="transparent" data-cy="expand-search">
          <v-icon color="grey" @click="openSearch">
            {{ mdiMagnify }}
          </v-icon>
        </v-btn>
        <v-btn v-if="expandedSearch" icon color="transparent" data-cy="clear-expand-search">
          <v-icon color="grey" @click="closeSearch">
            {{ mdiCloseCircle }}
          </v-icon>
        </v-btn>
      </div>
    </v-app-bar>
    <v-main class="mobile-main">
      <OnHoldCustomer />
      <MaintenanceNotify show-maintenance-warning />
      <router-view></router-view>
    </v-main>
    <div style="margin-top: 36px" />
    <v-footer color="white" padless fixed>
      <v-row no-gutters class="mobile-nav-item">
        <v-col v-for="item in menuItems" :key="item.name" cols="3">
          <router-link class="d-block h-100 black--text text-decoration-none"
            @click="selectedMenu = item.name" :to="{ name: item.pathName }">
            <div v-if="item.isCart" class="d-flex flex-column align-center">
              <v-badge right color="success" class="d-flex flex-column align-center mt-1">
                <span slot="badge">{{ total_stockItems }}</span>
                <img class="icon icon-selected" :src="item.iconSelected" />
                <img class="icon icon-unselected" :src="item.icon" />
                <span class="mt-2" :class="{ 'selected': selectedMenu === item.name }">
                  {{ $t(item.name) }}
                </span>
              </v-badge>
            </div>
            <div v-else class="d-flex flex-column align-center">
              <img class="icon icon-selected" :src="item.iconSelected" />
              <img class="icon icon-unselected" :src="item.icon" />
              <span class="mt-2" :class="{ 'selected': selectedMenu === item.name }">
                {{ $t(item.name) }}
              </span>
            </div>
          </router-link>
        </v-col>
      </v-row>
    </v-footer>

    <Auth />
    <Toasts :text="message" color="success" />
    <Toasts :text="error" color="danger" />
    <BasicToasts />
    <StickyToasts />
    <RateOurService :show="showRating" @close="showRating = false" />
    <NetworkService />
  </v-app>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import BasicToasts from '@codehq/aurora-app-core/src/components/BasicToasts.vue';
import Toasts from '@codehq/aurora-app-core/src/components/Toasts.vue';
// import { AppUpdate } from '@capawesome/capacitor-app-update';
import { mapGetters, mapMutations, mapState } from 'vuex';
import {
  mdiCog,
  mdiHome,
  mdiMagnify,
  mdiCart,
  mdiHeart,
  mdiHomeOutline,
  mdiHeartOutline,
  mdiCartOutline,
  mdiCloseCircle,
} from '@mdi/js';
import OnHoldCustomer from '../modules/shop/src/components/OnHoldCustomer.vue';
// import { SplashScreen } from '@capacitor/splash-screen';
import NavDrawer from './NavDrawer.vue';
import Auth from '../components/Auth.vue';
import CartService from '../components/CartService.vue';
import FavouriteService from '../components/FavouriteService.vue';
import MaintenanceNotify from '../components/MaintenanceNotify.vue';
import NetworkService from '../components/NetworkService.vue';
import StickyToasts from '../components/StickyToasts.vue';
import GlobalSearch from '../modules/shop/src/components/GlobalSearch.vue';
import SyncService from '../components/SyncService.vue';
import RateOurService from '../components/RateOurService.vue';
import AppVersion from '../build.txt';
import menuItems from '../mobileMenuItems';
// import PushNotifications from './components/PushNotifications.vue';
// import ServiceWorkerUpdate from './components/ServiceWorkerUpdate.vue';
import '../site.scss';

export default {
  name: 'Mobile',
  mixins: [menuItems],
  components: {
    NavDrawer,
    Auth,
    BasicToasts,
    OnHoldCustomer,
    CartService,
    MaintenanceNotify,
    NetworkService,
    // OrganizationSelect,
    // PushNotifications,
    Toasts,
    GlobalSearch,
    StickyToasts,
    FavouriteService,
    RateOurService,
    SyncService,
    // ServiceWorkerUpdate,
  },
  data() {
    return {
      mdiCog,
      mdiHome,
      mdiMagnify,
      mdiHeart,
      mdiCart,
      mdiHomeOutline,
      mdiHeartOutline,
      mdiCartOutline,
      mdiCloseCircle,
      appVersion: AppVersion,
      message: '',
      error: '',
      drawer: false,
      showRating: false,
      selectedMenu: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
    ...mapGetters('cart', ['total_stockItems']),
    ...mapState('app', ['title', 'expandedSearch']),
    isWeb() {
      return this.platform === 'web';
    },
    isIos() {
      // return true;
      return this.platform === 'ios';
    },
    isAndroid() {
      return this.platform === 'android';
    },
    isFavourites() {
      return this.$route.name === 'shop-Favourites';
    },
    searchRedirect() {
      return this.$route.name !== 'shop-Favourites';
    },
    isShopHome() {
      return this.$route.name === 'shop-ShopHome';
    },
    isInit() {
      return this.$route.name === 'app-Init';
    },
    showMenu() {
      return this.$route.name !== 'shop-ShopCategories';
    },
    platform() {
      return Capacitor.getPlatform();
    },
    showLogo() {
      return (
        this.$route.name === 'shop-ShopHome'
        || this.$route.name.startsWith('finance-')
      );
    },
    logoHeight() {
      if (this.isShopHome) {
        return 100;
      }
      return 95;
    },
    extensionHeight() {
      // if (this.isShopHome) {
      //   return 320;
      // }
      return 75;
    },
    logoUrl() {
      if (this.isShopHome) {
        // eslint-disable-next-line global-require
        return require('../assets/Logo-Wide.svg');
      }
      // eslint-disable-next-line global-require
      return require('../assets/Logo-Wide.svg');
    },
    showBackButton() {
      return this.$route.name !== 'shop-ShopCategories'
        && this.$route.name !== 'shop-Cart'
        && this.$route.name !== 'shop-CustomerCare'
        && this.$route.name !== 'shop-Favourites'
        && this.$route.name !== 'finance-FinanceHome';
    },
  },
  methods: {
    ...mapMutations('app', ['SET_title', 'SET_expandedSearch']),
    closeSearch() {
      // this.$router.back();
      this.SET_expandedSearch(false);
    },
    openSearch() {
      // this.$router.push({ name: 'shop-ShopSearch' });
      this.SET_expandedSearch(true);
    },
  },
};
</script>
<style scoped>
a.router-link-active {
  color: #EE1C25 !important;
}
.icon-selected {
  display: none !important;
}
a.router-link-active .icon-selected {
  display: block !important;
}
a.router-link-active .icon-unselected {
  display: none !important;
}
</style>
