<template>
  <v-app>
    <v-container class="fill-height" fluid>
      <v-row class="fill-height d-flex flex-column top-inset-padding"
        align="center" justify="center">
        <v-col class="fill-height d-flex flex-column align-stretch" cols="12">
          <div class="pl-5 pr-5">
            <v-img
              class="slide-from-top mb-5 pa-10"
              src="../assets/Logo-Wide.svg" style="max-height: 15vh; max-width: 100%;"
              contain alt="logo"
            />
          </div>
          <v-main>
            <transition name="fade">
              <router-view/>
            </transition>
          </v-main>
        </v-col>
      </v-row>
    </v-container>
    <NetworkService />
  </v-app>
</template>

<script>
import '../site.scss';
import NetworkService from '../components/NetworkService.vue';
import platform from '../mixins/platform';

export default {
  name: 'Minimal',
  components: {
    NetworkService,
  },
  mixins: [platform],
};
</script>
