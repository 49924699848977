<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    v-if="stockItem"
    style="align-self: end;"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        block
        :height="small ? 30 : 40"
        :small="small"
        color="primary"
        data-cy="BuyInStock-button-open-dialog"
      >
      {{ text }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Enter Amount for {{ stockItem.description }}</v-card-title>
      <v-card-text>
        <v-text-field
          data-cy="BuyInStock-quantity"
          v-model="quantity"
          label="Quantity" />
        <p>Our telesales department will be in contact with the expected available date</p>
      </v-card-text>
      <v-card-actions>
        <v-btn
          data-cy="BuyInStock-button-cancel"
          @click="dialog = false"
          text>Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          data-cy="BuyInStock-button-ok"
          color="success"
          :loading="isSaving"
          @click="buyIn">
            OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import dayjs from 'dayjs';

export default {
  name: 'BuyInStock',
  mixins: [loading],
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    stockItem: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      dialog: false,
      quantity: 1,
      deliveryDate: '',
    };
  },
  computed: {
    ...mapState('deliveryDates', ['deliveryDates']),
    text() {
      return 'Buy-in for me';
    },
  },
  async mounted() {
    if (this.deliveryDates.length === 0) {
      await this.LOAD_deliveryDatesSafe();
    }
  },
  methods: {
    ...mapActions('deliveryDates', ['LOAD_deliveryDatesSafe']),
    allowedDates(date) {
      const { deliveryDates } = this;
      const formattedDeliveryDates = deliveryDates
        .map((d) => this.formatDate(d.excludedDeliveryDate));
      const formattedDate = this.formatDate(date);
      for (let i = 1; i <= 2; i += 1) {
        const nextDate = dayjs().add(i, 'day').format('YYYY-MM-DD');
        formattedDeliveryDates.push(nextDate);
      }
      return !formattedDeliveryDates.includes(formattedDate);
    },
    async buyIn() {
      try {
        this.isSaving = true;
        const { quantity, deliveryDate } = this;
        const response = await this.$http.post('/api/stockItems/buy-in', {
          quantity,
          deliveryDate,
          itemCode: this.stockItem.itemCode,
        });
        this.$log.info(response.data);
        this.$root.$emit('toast:notify', 'Buy-in request sent');
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isSaving = false;
        this.dialog = false;
      }
    },
  },
};
</script>
