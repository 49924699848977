/* eslint-disable no-unused-vars */
import { openDB, deleteDB } from 'idb';
import Event from './Event';

/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
export default class IndexedDB extends Event {
  constructor(dbName, dbVersion, stores, indexes) {
    super();
    this.db;
    this.dbName = dbName;
    this.dbVersion = dbVersion;
    this.stores = stores;
    this.indexes = indexes;
  }

  async openDB() {
    const { dbName } = this;
    const self = this;
    this.db = await openDB(dbName, this.dbVersion, {
      upgrade(db, oldVersion, newVersion, transaction, event) {
        console.debug(`Upgrading database ${dbName} from version ${oldVersion} to ${newVersion}`);
        switch (oldVersion) {
          default:
          case 1:
            console.debug(`Creating version ${oldVersion} of database ${dbName}`);
            console.debug(`Creating ${self.stores.length} stores`);
            self.stores.map((o) => {
              const { name, keyPath, indexes } = o;
              const objStore = db.createObjectStore(name, { keyPath });
              if (indexes) {
                indexes.forEach((index) => {
                  objStore.createIndex(`index-${index}`, index, { unique: false, multiEntry: true });
                });
              }
              return o;
            });
            break;
          case 2:
            console.debug('updated to version 1');
            break;
        }
      },
      blocked(currentVersion, blockedVersion, event) {
        console.error(`DB ${dbName} blocked from version ${currentVersion} to ${blockedVersion}`, event);
      },
      blocking(currentVersion, blockedVersion, event) {
        console.error(`DB ${dbName} blocking from version ${currentVersion} to ${blockedVersion}`, event);
        event.currentTarget.close();
        console.error(`Closed DB ${dbName}`, event);
      },
      terminated() {
        console.error(`DB ${dbName} terminated`);
      },
    });
    process.nextTick(() => {
      this.emit('opened');
    });
  }

  close() {
    const { db } = this;

    if (db) {
      db.close();
    }
  }

  async deleteDb() {
    const { dbName } = this;
    this.db?.close();
    console.debug(this.db);
    this.db = undefined;
    console.debug(`Closed database ${dbName}`);
    console.debug(`Deleting database ${dbName}`);
    await deleteDB(dbName, {
      blocked(error, evt) {
        console.error('DB blocked from delete', error, evt);
      },
    });
    process.nextTick(() => {
      this.emit('deleted');
    });
    console.debug('Deleted database successfully');
  }

  deleteStore(storeName) {
    const { db } = this;

    if (db) {
      return new Promise((resolve, reject) => {
        this.db.deleteObjectStore(storeName);
        this.db.oncomplete = (e) => resolve(e.target.result);
        this.db.onabort = (e) => reject(e.target.error);
        this.db.error = (e) => reject(e.target.error);
      });
    }
    return undefined;
  }

  async upsert(storeName, data) {
    const { db } = this;

    if (db) {
      const transaction = db.transaction(storeName, 'readwrite');
      const store = transaction.objectStore(storeName);
      await store.put(data);
      await transaction.done;
    }
    return undefined;
  }

  async get(storeName, key) {
    const { db } = this;
    if (db) {
      const transaction = db.transaction(storeName, 'readonly');
      const result = await transaction.objectStore(storeName).get(key);
      transaction.done;
      return result;
    }
    return undefined;
  }

  // query(storeName, criteria, offset, limit) {
  //   const { db } = this;
  //   return new Promise((resolve, reject) => {
  //     const results = [];
  //     const transaction = db.transaction(storeName);
  //     transaction.oncomplete = (event) => resolve(results);
  //     transaction.onerror = (event) => reject(event.target);
  //     const store = transaction.objectStore(storeName);
  //     console.debug(storeName, store);
  //     const index = store.index('index-name');
  //     const request = index.openCursor(criteria);

  //     // let advanced = offset === 0;
  //     let counter = 0;

  //     request.onsuccess = (event) => {
  //       const cursor = event.target.result;
  //       if (!cursor) {
  //         return;
  //       }

  //       // if (!advanced) {
  //       //   advanced = true;
  //       //   cursor.advance(offset);
  //       // }

  //       counter += 1;
  //       results.push(cursor.value);

  //       if (counter >= limit) {
  //         return;
  //       }
  //       cursor.continue();
  //     };
  //   });
  // }

  async search(storeName, val, prop) {
    const { db } = this;
    if (db) {
      const results = [];
      const transaction = db.transaction(storeName, 'readonly');
      const keyword = val.toLowerCase();
      let cursor = await transaction.store.openCursor();
      while (cursor) {
        if (cursor) {
          if (cursor.value[prop].toLowerCase().indexOf(keyword) !== -1) {
            results.push(cursor.value);
          }
          // eslint-disable-next-line no-await-in-loop
          cursor = await cursor.continue();
        }
      }
      return results;
    }
    return undefined;
  }

  getAll(storeName) {
    const { db } = this;
    if (db) {
      const transaction = db.transaction(storeName, 'readonly');
      return transaction.objectStore(storeName).getAll();
    }
    return undefined;
  }

  remove(storeName, key) {
    const { db } = this;
    if (db) {
      return new Promise((resolve, reject) => {
        const request = this.db.transaction([storeName], 'readwrite').objectStore(storeName).delete(key);
        request.onerror = (e) => reject(e.target.error);
        request.onsuccess = (e) => resolve(e.target.result);
      });
    }
    return undefined;
  }

  clear(storeName) {
    const { db } = this;
    if (db) {
      return new Promise((resolve, reject) => {
        const request = this.db.transaction([storeName], 'readwrite').objectStore(storeName).clear();
        request.onerror = (e) => reject(e.target.error);
        request.onsuccess = (e) => resolve(e.target.result);
      });
    }
    return undefined;
  }

  async count(storeName) {
    return await this.db?.count(storeName) ?? 0;
  }
}
