import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('feedEmOrder', 'feedEmOrders');

const customActions = {
  async LOAD_feedEmOrders({ commit, rootGetters }, query) {
    const data = await odata.o(rootGetters)
      .get('feedEmOrders')
      .query(query);
    commit('SET_feedEmOrders', data);
    return data;
  },
};

export default { ...actions, ...customActions };
