<template>
  <div :class="{ 'fixed-breadcrumbs': $vuetify.breakpoint.mobile }">
    <v-breadcrumbs
      id="bread-crumbs"
      :large="!$vuetify.breakpoint.mobile"
      :items="history"
      v-if="history.length > 0 && !isLoading && !$vuetify.breakpoint.mobile"
    >
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :href="item.href"
          :disabled="item.disabled"
          class="breadcrumb"
        >
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <div v-else class="mt-2 mb-2 ml-2">
      <v-btn color="#EE1C25" small @click="$router.go(-1)">
        <v-icon color="secondary" class="mr-2">{{ mdiUndo }}</v-icon>
        <span class="secondary--text">Back</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiUndo } from '@mdi/js';

export default {
  name: 'Breadcrumbs',
  data() {
    return {
      mdiUndo,
    };
  },
  props: {
    history: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
