<template>
  <v-row>
    <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6"
      v-for="item in items"
      :key="item.name"
    >
      <v-card :to="{ name: item.to }">
        <v-img
          :src="item.photo"
          :height="height"
          width="100%"
        />
        <v-card-title primary-title>
          {{ item.name }}
        </v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CardList',
  props: ['items'],
  computed: {
    height() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return '20vh';
      }
      return '200px';
    },
  },
};
</script>
