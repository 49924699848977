<template>
  <v-card
    data-cy="stock-item"
    elevation="2"
    class="d-flex flex-column w-100 h-100"
    v-if="stockItem"
  >
    <div class="stock-item-favourite">
      <FavouriteIcon
        v-if="activeCustomer"
        :identifier="stockItem?.itemCode?.toString()"
        :name="`stockItem-${activeCustomer.customerCode}`"
        @change="$emit('favouriteChange')"
      />
    </div>
    <div class="stock-item-photo">
      <v-img
        v-if="stockItem?.photo"
        contain
        :src="detail.img"
        :width="200"
        :height="200"
        @click="editPhoto"
      />
      <v-img
        v-else
        contain
        src="../assets/default-product-image.png"
        :width="200"
        :height="200"
        @click="editPhoto"
      />
    </div>
    <v-card-title class="shop-title text-center pa-0">
      <span class="w-100">{{ stockItem?.description }}</span>
    </v-card-title>
    <v-card-text class="text-center flex-grow-1 d-flex flex-column">
      <v-row class="flex-grow-1">
        <v-col cols="12" class="pa-2">
          R {{ formatCurrency(detail.price) }}
          <span v-if="detail.tax > 0">(R {{ formatCurrency(detail.tax) }} VAT)</span>
          / {{ stockItem?.unitOfMeasure }}
        </v-col>
        <v-col v-if="!activeCustomer.hideStockOnHand" cols="12" class="pa-1">
          <span class="d-block text-center"
            v-if="detail.qty > 0">
            {{ detail.qty }} items in stock</span>
          <span v-else class="d-block red--text">Out of stock</span>
          <span class="d-block" v-if="detail.limit">
            Limit {{ detail.limit.limit }} per customer ({{ detail.limit.remaining }} left)
          </span>
        </v-col>
      </v-row>
      <v-row class="flex-grow-0">
        <v-col v-if="detail.qty === 0" class="pa-1" cols="12">
          <BuyInStock
            :stockItem="stockItem"
          />
        </v-col>
        <v-col v-if="detail.qty > 0" class="pa-1" cols="6">
          <QuantityButtonBasic ref="quantity" :limit="limit" v-model="amount" @add="add" />
        </v-col>
        <v-col v-if="detail.qty > 0" class="pa-1" cols="6">
          <v-btn
            data-cy="add-to-cart"
            height="40"
            block
            color="primary"
            :disabled="limit < 1"
            :title="limit < 1 ? 'Purchase limit reached' : ''"
            class="secondary--text w-100"
            style="font-size: 1em !important"
            @click="add"
          >
            Add to Cart
          </v-btn>
        </v-col>
        <v-col class="pa-1" cols="12">
          <v-btn
            block
            outlined
            v-if="showAlternative"
            color="primary"
            height="40"
            :to="categoryLink"
            class="mb-2 black--text"
            style="align-self: end; font-size: 1em !important"
          >
            View alternatives
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="dialog"
      max-width="350px"
      transition="dialog-transition"
    >
      <v-card>
          <v-card-title primary-title>
            Edit Photo
          </v-card-title>
          <v-card-text class="text-center d-flex justify-center">
            <v-img
              v-if="stockItem?.photo"
              contain
              :src="detail.img"
              :width="200"
              :height="200"
              @click="editPhoto"
            >
              <ImageUpload @input="updateImage(stockItem, $event)" />
            </v-img>
            <v-img
              v-else
              contain
              src="../assets/default-product-image.png"
              :width="200"
              :height="200"
              @click="editPhoto"
            >
              <ImageUpload @input="updateImage(stockItem, $event)" />
            </v-img>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="stockItem?.photo"
              color="error"
              @click="removeImage"
            >
              Delete Photo
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="dialog = false"
            >
              Done
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import BuyInStock from './BuyInStock.vue';
import ImageUpload from './ImageUpload.vue';
import FavouriteIcon from './FavouriteIcon.vue';
import QuantityButtonBasic from './QuantityButtonBasic.vue';

export default {
  name: 'StockItemCard',
  props: {
    stockItem: {
      type: Object,
      default: () => {},
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    showAlternative: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BuyInStock,
    FavouriteIcon,
    ImageUpload,
    QuantityButtonBasic,
  },
  data() {
    return {
      amount: 1,
      dialog: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
    ...mapGetters('cart', ['displayCart']),
    ...mapGetters('taxes', ['getTax']),
    ...mapGetters('stockItems', ['getStockItemDetail']),
    ...mapState('customers', ['activeCustomer']),
    ...mapState('taxes', ['taxes']),
    buttonWidth() {
      return this.$vuetify.breakpoint.mobile ? 211 : undefined;
    },
    categoryLink() {
      let categoryId = 0;
      let depth = 0;
      if (this.stockItem?.webCategory4Id) {
        categoryId = this.stockItem?.webCategory4Id;
        depth = 4;
      } else if (this.stockItem?.webCategory3Id) {
        categoryId = this.stockItem?.webCategory3Id;
        depth = 3;
      } else if (this.stockItem?.webCategory2Id) {
        categoryId = this.stockItem?.webCategory2Id;
        depth = 2;
      } else if (this.stockItem?.webCategory1Id) {
        categoryId = this.stockItem?.webCategory1Id;
        depth = 1;
      }
      return {
        name: 'shop-ShopStockItemByCategory',
        query: {
          category: categoryId,
          categoryName: 'Alternatives',
          depth,
        },
      };
    },
    detail() {
      return this.getStockItemDetail(this.stockItem);
    },
    isInCart() {
      const { displayCart, stockItem } = this;
      return displayCart.some((c) => c.stockItem?.itemCode === stockItem?.itemCode);
    },
    limit() {
      const { detail } = this;
      if (detail.limit) {
        return detail.limit.remaining;
      }
      return 9999999;
    },
  },
  mounted() {
    if (this.autoFocus && !this.$vuetify.breakpoint.mobile) {
      if (this.$refs.quantity) {
        this.$refs.quantity.focus();
      }
    }
  },
  methods: {
    ...mapMutations('cart', ['ADD_TO_CART', 'REMOVE_CART']),
    async add() {
      if (this.amount === 0) {
        this.amount = 1;
        await this.$nextTick();
      }
      const { stockItem, amount } = this;
      if (this.isInCart) {
        await this.$nextTick();
        const result = await this.$confirm(
          'This item is already in your cart, are you sure you want to add more?',
        );
        if (result) {
          this.ADD_TO_CART({ stockItem, amount });
          this.$root.$emit('toast:notify', `Added ${stockItem?.description}`);
          this.amount = 1;
        }
      } else {
        this.ADD_TO_CART({ stockItem, amount });
        this.$root.$emit('toast:notify', `Added ${stockItem?.description}`);
        this.amount = 1;
      }
      this.$emit('added', stockItem);
    },
    quantityClicked(e) {
      e.target.select();
    },
    editPhoto() {
      if (this.isInRole('Administrators') || this.isInRole('Agents')) {
        this.dialog = true;
      }
    },
    update($evt) {
      this.$emit('change', $evt);
    },
    async removeImage() {
      const confirm = await this.$confirm('Are you sure you want to remove the photo?');
      if (confirm) {
        const result = { ...this.stockItem };
        result.photo = null;
        this.$emit('change', result);
      }
    },
    updateImage(stockItem, $evt) {
      const result = { ...stockItem };
      result.photo = $evt;
      this.$emit('change', result);
    },
    save() {
      this.dialog = false;
    },
  },
};
</script>
