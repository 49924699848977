export default {
  async LOAD_client({ commit }) {
    const result = await this.$http.get('api/finance/client');
    commit('SET_client', result.data);
  },
  async LOAD_accountsReceivable({ commit }, arType) {
    const result = await this.$http.get(`api/finance/accountsReceivable/${arType}`);
    commit(`SET_${arType}`, result.data);
    return result.data;
  },
};
