export default {
  vehicles: [],
  vehicle: undefined,
  fuelTypes: [
    'Petrol',
    'Diesel',
    'LPG',
    'Electric',
  ],
  makes: [
    'Acura',
    'Alfa Romeo',
    'Aston Martin',
    'Audi',
    'Bentley',
    'BMW',
    'Bugatti',
    'Buick',
    'Cadillac',
    'Chevrolet',
    'Chrysler',
    'Citroën',
    'Dacia',
    'Daihatsu',
    'Dodge',
    'Ferrari',
    'Fiat',
    'Ford',
    'Geely',
    'General Motors',
    'GMC',
    'Haval',
    'Honda',
    'Hyundai',
    'Infiniti',
    'Jaguar',
    'Jeep',
    'Kia',
    'Lamborghini',
    'Land Rover',
    'Lexus',
    'Lotus',
    'Maserati',
    'Mazda',
    'McLaren',
    'Mercedes-Benz',
    'MG',
    'Mini',
    'Mitsubishi',
    'Nissan',
    'Opel',
    'Peugeot',
    'Porsche',
    'Ram',
    'Renault',
    'Rolls-Royce',
    'Saab',
    'SEAT',
    'Skoda',
    'Smart',
    'Subaru',
    'Suzuki',
    'Tata',
    'Tesla',
    'Toyota',
    'Volkswagen',
    'Volvo',
  ],
};
