<template>
  <ButtonProgress
    small
    data-cy="favourite-button"
    block
    color="#FED401"
    class="black--text"
    :loading="isLoading"
    @click="toggle">
      {{ text }}
  </ButtonProgress>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mdiHeart, mdiHeartOutline } from '@mdi/js';
import ButtonProgress from '@codehq/aurora-app-core/src/components/ButtonProgress.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'Favourite',
  mixins: [loading],
  components: {
    ButtonProgress,
  },
  props: {
    name: {
      type: String,
      default: 'Basic',
    },
    /**
     * Holds the identifier value, with a default of ''
     */
    identifier: {
      type: String,
      default: '',
    },
    /**
     * Holds the selectedColour value, with a default of 'primary'
     */
    selectedColour: {
      type: String,
      default: 'secondary',
    },
    /**
     * Holds the deselectedColour value, with a default of ''
     */
    deselectedColour: {
      type: String,
      default: 'secondary',
    },
    /**
     * Holds the small value, with a default of false
     */
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /** icons
       */
      mdiHeart,
      mdiHeartOutline,
    };
  },
  computed: {
    ...mapGetters('favourites', ['getFavourite']),
    /**
     * Returns the colour
     */
    colour() {
      // if (this.isSelected) {
      //   return this.selectedColour;
      // }
      return this.deselectedColour;
    },
    /**
     * Returns the text property
     */
    text() {
      if (this.isSelected) {
        if (this.small) {
          return 'Remove';
        }
        return 'Remove from Favourites';
      }
      if (this.small) {
        return 'Add';
      }
      return 'Add to Favourites';
    },
    /**
     * Returns the icon
     */
    icon() {
      if (this.isSelected) {
        return this.mdiHeart;
      }
      return this.mdiHeartOutline;
    },
    /**
     * Returns the isSelected property
     */
    isSelected() {
      return this.selectedItem !== null;
    },
    /**
     * Returns the selectedItem property
     */
    selectedItem() {
      const { name, identifier } = this;
      const ident = identifier.toString();
      const result = this.getFavourite(name, ident);
      return result;
    },
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('favourites', ['CREATE_favourite', 'DELETE_favourite']),
    /**
     * Executes add
     */
    async add() {
      const { name, identifier } = this;
      this.isLoading = true;
      await this.CREATE_favourite({ name, identifier });
      this.isLoading = false;
    },
    /**
     * Executes remove
     */
    async remove() {
      const { selectedItem } = this;
      this.isLoading = true;
      await this.DELETE_favourite(selectedItem);
      this.isLoading = false;
    },
    /**
     * Executes toggle
     */
    async toggle() {
      if (this.isSelected) {
        await this.remove();
      } else {
        await this.add();
      }
      this.$emit('change');
      this.$root.$emit('favourites:change');
    },
  },
};
</script>
