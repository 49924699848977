<template>
  <div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'CartService',
  computed: {
    ...mapGetters('cart', ['cart']),
  },
  mounted() {
    this.$root.$on('activeCustomer:change', (data) => {
      const { previousCustomerCode, newCustomerCode } = data;
      this.$log.info(`Cart service change cart for ${previousCustomerCode} to ${newCustomerCode}`);
      const { cart } = this;
      Cookies.set(`cart-${previousCustomerCode}`, JSON.stringify(cart), { expires: 7 });
      this.RESET_CART();
      const previousCart = JSON.parse(Cookies.get(`cart-${newCustomerCode}`) ?? '[]');
      if (previousCart) {
        this.SET_CART(previousCart);
      }
    });
  },
  destroyed() {
    this.$root.$off('activeCustomer:change');
  },
  methods: {
    ...mapMutations('cart', ['SET_CART', 'RESET_CART']),
  },
};
</script>
