<template>
  <v-row>
    <v-col cols="12" md="6" lg="6" xl="6"
      v-for="item in items"
      :key="item.name"
      class="col-sm-padding"
    >
      <v-card>
        <router-link :to="{ name: item.to }">
        <v-img
          :src="item.imageUrl"
          height="200"
          width="100%"
        />
        </router-link>
        <v-card-title primary-title>
          {{ item.text }}
          <ImageUpload
            v-if="isInRole('Administrators') && !$vuetify.breakpoint.mobile"
            @input="updateImage(item, $event)"
          />
        </v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import appMenuItems from '~src/mixins/appMenuItems';
import ImageUpload from '../../../shop/src/components/ImageUpload.vue';

export default {
  name: 'CardList',
  mixins: [appMenuItems],
  components: {
    ImageUpload,
  },
  props: ['items'],
};
</script>
