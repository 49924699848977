import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('purchaseOrderLine', 'purchaseOrderLines');

const customActions = {
  async LOAD_purchaseOrderLines({ commit, rootGetters }, purchaseOrderId) {
    const data = await odata.o(rootGetters)
      .get('purchaseOrderLines')
      .query({
        $filter: `IsDeleted eq false AND PurchaseOrderId eq ${purchaseOrderId}`,
      });
    commit('SET_purchaseOrderLines', data);
    return data;
  },
};

export default { ...actions, ...customActions };
