<template>
  <div>
    <Toasts :text="message" :timeout="-1" buttonText="Ok" />
  </div>
</template>

<script>
import Toasts from './Toasts.vue';

export default {
  components: {
    Toasts,
  },
  name: 'StickyToasts',
  data() {
    return {
      error: '',
      message: '',
    };
  },
  created() {
    this.$root.$on('toast:sticky', (msg) => {
      this.message = '';
      this.$nextTick(() => {
        this.message = msg;
      });
    });
  },
  destroyed() {
    this.$root.$off('toast:sticky');
  },
};
</script>
