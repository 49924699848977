/* eslint-disable max-len */
export default {
  getStockItem: (state) => state.stockItem,
  getStockItemById: (state) => (itemCode) => state.stockItems.find((s) => s.itemCode === itemCode),
  getStockItemByCategory1: (state) => (webCategory1) => state.stockItems.filter((s) => s.webCategory1 === webCategory1 && s.webCategory2 === null),
  getStockItemDetail: (state, getters, rootState, rootGetters) => (stockItem) => {
    const getPrice = rootGetters['pricings/getPrice'];
    const findCategory = rootGetters['webCategories/findCategory'];
    const limits = rootState.stockItems.stockLimits;
    const limit = limits.find((l) => l.itemCode === stockItem?.itemCode);
    const { cart } = rootState.cart;
    const cartItem = cart.find((c) => c.stockItem?.itemCode === stockItem?.itemCode);
    if (cartItem && limit) {
      limit.remaining = limit.limit - cartItem.amount;
    }
    const category = findCategory(stockItem);
    const img = `${stockItem?.photo}` ?? '';
    const qty = stockItem?.quantity ?? 0;
    const price = getPrice(stockItem?.itemCode) ?? 0;
    const { taxes } = rootState.taxes;
    const result = taxes.find((t) => t.taxId === stockItem?.taxId);
    const taxPercentage = (result?.taxPercentage ?? 0) / 100;
    const tax = (taxPercentage * price) ?? 0;
    return {
      category,
      img,
      qty,
      price,
      taxPercentage,
      tax,
      limit,
    };
  },
};
