<template>
  <v-container fluid>
    <ProgressLinearTimeout v-if="loadingHeader" />
        <v-container v-if="$vuetify.breakpoint.mobile">
          <v-row>
            <v-col v-for="item in orders" :key="item.id" cols="12">
              <v-card>
                <v-card-title>{{ item.externalOrderNo }}: {{ item.orderNo }}</v-card-title>
                <v-card-subtitle>Delivery Date: <b>{{ item.deliveryDate }}</b></v-card-subtitle>
                <v-card-text>Created By: <b>{{ item.createdBy }}</b></v-card-text>
                <v-card-text>
                  <table class="table-bordered w-100">
                    <thead>
                      <tr>
                        <th>Quantity</th>
                        <th>Stock</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="line in item.lines" :key="line.id">
                        <td class="text-center">{{ line.amount }}</td>
                        <td class="text-center">
                          {{ line.stockItem?.description ?? line.itemCode }}
                        </td>
                        <td class="text-center">{{ formatCurrency(line.total) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    small
                    color="success"
                    class="mb-2"
                    @click="reorder(item)"
                    :loading="item.isSaving"
                    block
                  >
                    Reorder
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
    <v-card v-else>
      <v-card-text>
        <v-data-table
          elevation-1
          :headers="headers"
          :items="orders"
          @refresh="refresh"
          :expanded.sync="expanded"
          :loading="isLoading"
          show-expand
          item-key="id"
          :search="search"
          sort-by="created"
          :sort-desc="true"
          no-data-text="You have not placed any orders"
        >
          <template v-slot:top>
            <v-row>
              <v-col cols="12" lg="2" offset-lg="4">
                <DatePicker
                  v-model="start"
                  label="From"
                  :filled="false"
                />
              </v-col>
              <v-col cols="12" lg="2">
                <DatePicker
                v-model="end"
                  label="To"
                  :filled="false"
                />
              </v-col>
              <v-col cols="12" lg="4">
                <v-text-field
                  clearable
                  data-cy="TableBasic-search"
                  class="mr-2"
                  v-model="search"
                  :append-icon="mdiMagnify"
                  :label="$t('common.search')"
                  single-line
                  hide-details
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.created="{ item }">
            {{ formatDateTime(item.created) }}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="padding: 10px">
              <v-data-table
                :headers="lineHeaders"
                :items="item.lines"
                :loading="item.isLoading"
                elevation-0
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:item.price="{ item }">
                  {{ formatCurrency(item.price) }}
                </template>
                <template v-slot:item.tax="{ item }">
                  {{ formatCurrency(item.tax) }}
                </template>
                <template v-slot:item.total="{ item }">
                  {{ formatCurrency(item.total) }}
                </template>
              </v-data-table>
            </td>
          </template>
          <template v-slot:item.updated="{ item }">
            {{ formatDateTime(item.updated) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex flex-column pa-2">
              <v-btn
                small
                color="success"
                class="mb-2"
                @click="reorder(item)"
                :loading="item.isSaving"
                block
              >
                Reorder
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { mdiMagnify, mdiRefresh, mdiPencil } from '@mdi/js';
import dayjs from 'dayjs';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import DatePicker from '~src/components/DatePicker.vue';

export default {
  name: 'MyOrders',
  components: {
    ProgressLinearTimeout,
    DatePicker,
  },
  mixins: [loading],
  data() {
    return {
      mdiMagnify,
      mdiRefresh,
      mdiPencil,
      editItem: {},
      isSavingItem: false,
      expanded: [],
      loadingHeader: false,
      showCompleted: false,
      search: '',
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Orders',
        disabled: true,
        depth: 0,
      }],
      orders: [],
      orderHeaders: [],
      start: dayjs().add(-3, 'month').format('YYYY-MM-DD'),
      end: dayjs().add(1, 'day').format('YYYY-MM-DD'),
      lineHeaders: [
        {
          text: 'Quantity',
          value: 'amount',
        },
        {
          text: 'Item',
          value: 'stockItem.description',
        },
        {
          text: 'Price',
          value: 'price',
        },
        {
          text: 'Tax',
          value: 'tax',
        },
        {
          text: 'Total',
          value: 'total',
        },
      ],
      headers: [
        {
          text: 'Created',
          value: 'created',
        },
        {
          text: 'Order ID',
          value: 'externalOrderNo',
        },
        {
          text: 'Order No',
          value: 'orderNo',
        },
        {
          text: 'Delivery Date',
          value: 'deliveryDate',
        },
        // {
        //   text: 'Old Order ID',
        //   value: 'oldOrderId',
        // },
        // {
        //   text: 'Customer',
        //   value: 'customerCode',
        // },
        {
          text: 'Created By',
          value: 'createdBy',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
    };
  },
  computed: {
    ...mapState('stockItems', ['stockItems']),
  },
  watch: {
    start: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (!oldValue) return;
        console.log('start date changed', newValue, oldValue);
        this.refresh();
      },
    },
    end: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (!oldValue) return;
        console.log('end date changed', newValue, oldValue);
        this.refresh();
      },
    },
  },
  async mounted() {
    this.SET_title('My Orders');
    await this.refresh();
  },
  methods: {
    ...mapActions('stockItems', ['LOAD_stockItems']),
    ...mapMutations('cart', ['ADD_TO_CART']),
    ...mapMutations('app', ['SET_title']),
    editDeliveryDate(item) {
      this.editItem = item;
      this.dialogDeliveryDate = true;
    },
    onStartDateChange() {
      console.log('start date changed');
      this.refresh();
    },
    onEndDateChange() {
      console.log('end date changed');
      this.refresh();
    },
    /**
     * Executes refresh
     */
    async refresh() {
      console.log('refreshing');
      this.isLoading = true;
      await Promise.all([
        (async () => {
          await this.LOAD_stockItems();
        })(),
        (async () => {
          const { search } = this;
          if (search?.length > 0) {
            const { data } = await this.$http.get(`/api/orders/my?search=${search}`);
            this.orders = data.map((order) => ({
              ...order,
              isSaving: false,
              isLoading: false,
            }));
          } else {
            const { start, end } = this;
            const { data } = await this.$http.get(`/api/orders/my?start=${start}&end=${end}`);
            this.orders = data.map((order) => ({
              ...order,
              isSaving: false,
              isLoading: false,
            }));
          }
        })(),
      ]);
      this.isLoading = false;
    },

    async reorder(item) {
      item.lines.forEach(async (line) => {
        const stockItem = this.stockItems.find((si) => si.itemCode === line.itemCode);
        if (!stockItem) {
          this.$emit('toast:error', `Item ${line.itemCode} not found, skipping`);
          return;
        }
        this.ADD_TO_CART({
          stockItem,
          amount: line.amount,
        });
      });
      this.$emit('toast:success', 'Order added to cart');
      this.$router.push({ name: 'shop-Cart' });
    },
  },
};
</script>
<style scoped>
.table-line {
  width: 100%;
  border-collapse: collapse;
}
</style>
