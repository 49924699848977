<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-autocomplete
          data-cy="FormRestaurantAdmin-userName"
          filled
          :label="$$t('userName')"
          item-value="username"
          item-text="username"
          :items="restaurantAdminUsers"
          v-model="localRestaurantAdmin.userName"
        ></v-autocomplete>
        <v-autocomplete
          data-cy="FormRestaurantAdmin-customerCode"
          filled
          item-text="customerDesc"
          item-value="customerCode"
          :label="$$t('customerCode')"
          :items="customers"
          v-model="localRestaurantAdmin.customerCode"
        ></v-autocomplete>
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormRestaurantAdmin-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormRestaurantAdmin-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mapState } from 'vuex';

export default {
  name: 'FormRestaurantAdmin',
  module: 'RestaurantAdmins',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    RestaurantAdmin: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localRestaurantAdmin
      */
      localRestaurantAdmin: {},
    };
  },
  computed: {
    ...mapState('userManagement', ['users']),
    ...mapState('customers', ['customers']),
    restaurantAdminUsers() {
      return this.users?.filter((u) => u.roles.filter((r) => r.name === 'Restaurant Admins').length > 0) ?? [];
    },
    usersWitRoles() {
      return this.users?.filter((u) => u.roles.length > 0) ?? [];
    },
  },
  watch: {
    RestaurantAdmin() {
      this.localRestaurantAdmin = this.RestaurantAdmin;
    },
  },
  created() {
    this.localRestaurantAdmin = this.RestaurantAdmin;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localRestaurantAdmin);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormRestaurantAdmin example

'''js
<FormRestaurantAdmin :RestaurantAdmin="RestaurantAdmin" />
'''
</docs>
