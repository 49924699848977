<template>
  <div v-if="isRestaurantAdmin">
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="300px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="isRestaurantAdmin"
          text
          icon
          v-bind="attrs"
          v-on="on"
          title="Change Restaurant"
        >
          <v-icon>{{ mdiSwapHorizontal }}</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title primary-title>
          Change to Restaurant:
        </v-card-title>
        <v-card-text>
          <v-select
            :loading="isLoading"
            :items="items"
            return-object
            :item-text="(c) => `${c.customerDesc} (${c.customerCode})`"
            item-value="customerCode"
            label="Customer"
            v-model="selectedCustomer"
            @input="inputChanged"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="update" :loading="isSaving">
            Change
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { mdiSwapHorizontal } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  module: 'auth',
  name: 'ActiveRestaurantAdmin',
  mixins: [loading],
  data() {
    return {
      mdiSwapHorizontal,
      dialog: false,
      localActiveCustomerUser: undefined,
      items: [],
      search: '',
      selectedCustomer: undefined,
      source: undefined,
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
    ...mapState('customers', ['activeCustomer']),
    isRestaurantAdmin() {
      return this.isInRole('Restaurant Admins');
    },
  },
  watch: {
    activeCustomer: {
      immediate: true,
      deep: true,
      handler() {
        this.localActiveCustomerUser = this.activeCustomer;
      },
    },
    localActiveCustomerUser() {
      this.loadData();
    },
  },
  async created() {
    this.$log.debug('ActiveRestaurantAdmin created');
    this.$root.$on('auth:login', async () => {
      this.$log.debug('auth:login received, refresh restaurant admins');
      await this.refresh();
    });
    await this.refresh();
  },
  methods: {
    ...mapActions('customers', ['LOAD_activeCustomer']),
    ...mapActions('customerUsers', ['UPDATE_customerUser']),
    ...mapActions('pricings', ['LOAD_pricings']),
    inputChanged(val) {
      if (val instanceof Object) {
        this.update(val.customerCode);
      }
    },
    async loadData() {
      try {
        if (this.source) {
          this.$log.debug('Cancelling previous request');
          this.source.cancel('Previous request cancelled');
        }
        this.source = this.$http.CancelToken?.source();
        const response = await this.$http.get('/api/restaurantAdmins/mine', { cancelToken: this.source?.token });
        this.source = undefined;
        this.items = response.data;
      } catch (error) {
        if (!this.$http.isCancel) {
          throw error;
        }
        if (!this.$http.isCancel(error)) {
          throw error;
        } else {
          this.$log.debug('Request cancelled');
        }
      }
    },
    async refresh() {
      if (this.isLoggedIn) {
        await this.loadData();
        await this.LOAD_activeCustomer();
        this.localActiveCustomerUser = this.activeCustomer;
      }
    },
    async update() {
      try {
        this.isSaving = true;
        const { activeCustomer, selectedCustomer } = this;
        const change = {
          previousCustomerCode: activeCustomer?.customerCode,
          newCustomerCode: selectedCustomer.customerCode,
        };
        await this.$http.post('/api/restaurantAdmins/change-customer', {
          customerCode: selectedCustomer.customerCode,
        });
        await new Promise((r) => setTimeout(r, 500));
        await this.LOAD_activeCustomer();
        this.$root.$emit('activeCustomer:change', change);
        this.selectedCustomer = undefined;
      } finally {
        this.isSaving = false;
      }
      this.dialog = false;
    },
  },
};
</script>
