export default {
  issues: [],
  issue: undefined,
  priorities: [
    { value: 'Low', text: 'Low' },
    { value: 'Medium', text: 'Medium' },
    { value: 'High', text: 'High' },
    { value: 'Critical', text: 'Critical' },
  ],
  statuses: [
    { value: 'Open', text: 'Open' },
    { value: 'In Progress', text: 'In Progress' },
    { value: 'Resolved', text: 'Resolved' },
    { value: 'On Hold', text: 'On Hold' },
    { value: 'Closed', text: 'Closed' },
  ],
};
