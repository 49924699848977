<template>
  <v-data-table
    :headers="headers"
    :items="softSaleStockItemAudits"
    :loading="isLoading"
    :search="search"
    sort-by="created"
    sort-desc
  >
    <template v-slot:top>
    <v-toolbar
      flat
    >
      <v-row>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          :append-icon="mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-row>
    </v-toolbar>
  </template>
    <template v-slot:item.created="{ item }">
      {{ formatDateTime(item.created)}}
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiDelete, mdiPencil, mdiMagnify } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'SoftSalesActivity',
  module: 'Shop',
  mixins: [loading],
  data() {
    return {
      mdiDelete,
      mdiPencil,
      mdiMagnify,
      search: '',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('softSaleStockItemAudits', ['softSaleStockItemAudits']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('user'),
          value: 'createdBy',
        },
        {
          text: this.$$t('created'),
          value: 'created',
        },
        {
          text: this.$$t('customerCode'),
          value: 'softSaleStockItem.customerCode',
        },
        {
          text: this.$$t('itemCode'),
          value: 'softSaleStockItem.itemCode',
        },
        {
          text: this.$$t('reason'),
          value: 'reason',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_softSaleStockItemAudits();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('softSaleStockItemAudits', ['LOAD_softSaleStockItemAudits']),
    /** Delete SoftSale from the collection
     */
    async remove(softSale) {
      this.isLoading = true;
      await this.DELETE_softSaleStockItemAudit(softSale.id);
      this.isLoading = false;
    },
  },
};
</script>
