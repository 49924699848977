<template>
  <v-btn
    v-show="data"
    @click="exportToExcel"
    :loading="isLoading"
    title="Export to Excel"
    color="success"
    :height="height"
  >
    <v-icon>{{ mdiFileExcel }}</v-icon>
  </v-btn>
</template>

<script>
import { mdiFileExcel } from '@mdi/js';
import { json2excel } from 'js2excel';

export default {
  name: 'ExportToExcelButton',
  props: ['data', 'name', 'height'],
  data() {
    return {
      isLoading: false,
      mdiFileExcel,
    };
  },
  methods: {
    async exportToExcel() {
      this.isLoading = true;
      const { data, name } = this;
      await json2excel({
        data,
        name,
        formateDate: 'yyyy/mm/dd',
      });
      this.isLoading = false;
    },
  },
};
</script>
