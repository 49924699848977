import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('costCentre', 'costCentres');

const customActions = {
  async LOAD_costCentres({ commit, rootGetters }, customerCode) {
    const data = await odata.o(rootGetters)
      .get('costCentres')
      .query({ $filter: `IsDeleted eq false AND CustomerCode eq '${customerCode}'` });
    commit('SET_costCentres', data);
    return data;
  },
};

export default { ...actions, ...customActions };
