<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              data-cy="FormPurchaseLimit-customerId"
              filled
              :label="$$t('customer')"
              item-text="customerDesc"
              item-value="customerId"
              :items="availableCustomers"
              v-model="localPurchaseLimit.customerId"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              data-cy="FormPurchaseLimit-itemCode"
              filled
              :label="$$t('itemCode')"
              :item-text="i => `${i.itemCode} - ${i.description}`"
              item-value="itemCode"
              :items="stockItems"
              v-model="localPurchaseLimit.itemCode"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormPurchaseLimit-limit"
              :label="$$t('limit')"
              v-model="localPurchaseLimit.limit"
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormPurchaseLimit-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormPurchaseLimit-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mapState } from 'vuex';

export default {
  name: 'FormPurchaseLimit',
  module: 'Shop',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    PurchaseLimit: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localPurchaseLimit
      */
      localPurchaseLimit: {},
    };
  },
  computed: {
    ...mapState('customers', ['customers']),
    ...mapState('stockItems', ['stockItems']),
    availableCustomers() {
      const result = this.customers.map((c) => ({
        customerId: c.customerId,
        customerDesc: `${c.customerId} - ${c.customerCode}`,
      }));
      return [{
        customerId: null,
        customerDesc: 'All Customers',
      }, ...result,
      ];
    },
  },
  watch: {
    PurchaseLimit() {
      this.localPurchaseLimit = this.PurchaseLimit;
    },
  },
  created() {
    this.localPurchaseLimit = this.PurchaseLimit;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localPurchaseLimit);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormPurchaseLimit example

'''js
<FormPurchaseLimit :PurchaseLimit="PurchaseLimit" />
'''
</docs>
