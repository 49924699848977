import Actions from '../../odata/actions';

const actions = new Actions('appMenuItem', 'appMenuItems');

const customActions = {
  async LOAD_appMenuItems({ commit }) {
    const { data } = await this.$http.get('/api/appMenuItems');
    commit('SET_appMenuItems', data);
    return data;
  },
  async UPDATE_appMenuItem({ commit, state }, appMenuItem) {
    const { data } = await this.$http.put('/api/appMenuItems', appMenuItem);

    const items = state.appMenuItems ?? [];
    const updatedItem = items.find((l) => l.name === data.name);
    if (updatedItem) {
      Object.assign(updatedItem, data);
    }

    commit('SET_appMenuItems', items);
    return data;
  },
};

export default { ...actions, ...customActions };
