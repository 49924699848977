<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <h1>Supplier</h1>
        <v-text-field
          filled
          data-cy="FormSupplier-name"
          :label="$$t('name')"
          v-model="localSupplier.name"
        />
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormSupplier-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="$parent.isSaving"
            data-cy="FormSupplier-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
export default {
  name: 'FormSupplier',
  module: 'Supplier',
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Supplier: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localSupplier
      */
      localSupplier: {},
    };
  },
  watch: {
    Supplier() {
      this.localSupplier = this.Supplier;
    },
  },
  created() {
    this.localSupplier = this.Supplier;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localSupplier);
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormSupplier example

'''js
<FormSupplier :Supplier="Supplier" />
'''
</docs>
