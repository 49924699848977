<template>
  <div>
    <v-list>
      <v-list-item v-for="(rating, index) in customerServiceRatings" :key="index">
        <v-list-item-content>
          <v-list-item-title>Customer Code: {{ rating.customerCode }}</v-list-item-title>
          <v-list-item-subtitle>
            <v-rating :value="rating.rating" :disabled="true"></v-rating>
            <div class="d-flex justify-space-between align-center">
              <div>
                <v-icon small>{{ mdiClock }}</v-icon>
                Created: {{ formatFromNow(rating.created) }}
              </div>
              <div>
                <v-icon color="primary" small>{{ mdiAccount }}</v-icon>
                <a class="text-decoration-none" :href="`mailto:${rating.createdBy}`">
                  {{ rating.createdBy }}
                </a>
              </div>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mdiClock, mdiAccount } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'CustomerRatings',
  mixins: [loading],
  data() {
    return {
      mdiClock,
      mdiAccount,
    };
  },
  computed: {
    ...mapState('customerServiceRatings', ['customerServiceRatings']),
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.$store.dispatch('customerServiceRatings/LOAD_customerServiceRatings');
    },
  },
};
</script>
