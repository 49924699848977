<template>
  <v-layout align-start justify-center column fill-height>
    <v-flex shrink>
      <v-container>
        <v-row>
          <v-col>
            <div class="text-h5 bold fade-in">Welcome, {{ displayNameOrUsername }}!</div>
            <div class="text-h6 fade-in">Where would you like to go?</div>
          </v-col>
        </v-row>
      </v-container>
    </v-flex>
    <v-flex grow class="w-100">
      <v-container class="blocks-container w-100">
        <v-row dense>
          <v-col cols="12" class="home-menu-item flex-grow-1"
            v-for="item in menuItems"
            :key="item.name"
          >
            <router-link :to="{ name: item.to }" class="d-flex fill-height">
              <img
                :src="item.imageUrl"
                class="fill-height w-100"
                :class="{ 'h-100': isMobile }"
              />
            </router-link>
          </v-col>
          <!-- <v-col v-if="isIos" cols="12">
            <div style="height: 80px">
            </div>
          </v-col> -->
        </v-row>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
import platform from '@/mixins/platform';
import home from '../mixins/home';

export default {
  name: 'MobileShopHome',
  mixins: [home, platform],
};
</script>
