<template>
  <!-- Dialog with v-rating -->
  <v-dialog :value="show" max-width="400">
    <v-card class="text-center">
      <v-card-title>
        <span class="headline">Rate Our Service</span>
      </v-card-title>
      <v-card-text>
        <v-rating :size="$vuetify.breakpoint.mobile ? 48 : 64" v-model="rating"></v-rating>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="$emit('close')">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn :loading="isSaving" color="primary" @click="submitRating">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'RateOurService',
  mixins: [loading],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rating: 0,
      hoverRating: 0,
    };
  },
  computed: {
    ...mapState('customers', ['activeCustomer']),
  },
  methods: {
    ...mapActions('customerServiceRatings', ['CREATE_customerServiceRating']),
    async submitRating() {
      // eslint-disable-next-line prefer-destructuring
      const customerCode = this.$store.state.customers.activeCustomer.customerCode;
      const { rating } = this;
      this.isSaving = true;
      try {
        await this.CREATE_customerServiceRating({ customerCode, rating });
        this.$root.$emit('toast:notify', 'Thank you for your feedback');
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isSaving = false;
        this.$emit('close');
      }
    },
    updateHoverRating(value) {
      this.hoverRating = value;
    },
  },
};
</script>
