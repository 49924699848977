<template>
  <v-app>
    <CartService v-if="isLoggedIn" />
    <FavouriteService v-if="isLoggedIn" />
    <NavDrawer :drawer="drawer" @close="drawer = false" />
    <SyncService v-if="isLoggedIn && !isInit" />
    <!-- Desktop App Bar -->
    <v-app-bar
      fixed
      app
      flat
      class="slide-from-top"
      color="white"
    >
      <div v-if="showBackButton" class="back-button mr-2">
        <img class="icon mt-1" src="@/assets/icon-back.svg"
          @click="$router.go(-1)"
        />
      </div>
      <v-toolbar-title data-cy="toolbar-title" class="text-h3">
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <InstallButton v-if="isWeb" />
      <div class="mr-2" />
      <FeedbackDialog />
      <div class="mr-2" />
      <div style="min-width: 20vw">
        <GlobalSearch
          :type="searchType"
        />
      </div>
      <!-- <AppStyle v-if="isInRole('Administrators')" /> -->
      <div class="ml-2" />
      <Orders />
      <MiniCart />
      <div class="font-weight-bold mr-2 ml-2">
        {{ displayNameOrUsername }}
      </div>
      <Account />
    </v-app-bar>

    <v-main>
      <MaintenanceNotify :showMaintenanceWarning="showMaintenanceWarning" />
      <OnHoldCustomer v-if="isLoggedIn" />
      <keep-alive :include="[]">
        <router-view></router-view>
      </keep-alive>
      <div style="height: 80px; width: 100%"></div>
    </v-main>
    <div style="margin-top: 36px" />
    <Auth />
    <Toasts :text="message" color="success" />
    <Toasts :text="error" color="danger" />
    <BasicToasts />
    <StickyToasts />
    <RateOurService :show="showRating" @close="showRating = false" />
    <NetworkService />
  </v-app>

</template>

<script>
import { Capacitor } from '@capacitor/core';
import BasicToasts from '@codehq/aurora-app-core/src/components/BasicToasts.vue';
import Toasts from '@codehq/aurora-app-core/src/components/Toasts.vue';
// import { AppUpdate } from '@capawesome/capacitor-app-update';
import { mapGetters, mapState } from 'vuex';
import {
  mdiCogOutline,
  mdiHome,
  mdiMagnify,
  mdiCart,
  mdiHeart,
  mdiHomeOutline,
  mdiHeartOutline,
  mdiCartOutline,
} from '@mdi/js';
import MiniCart from '../modules/shop/src/components/MiniCart.vue';
import Orders from '../modules/shop/src/components/Orders.vue';
import GlobalSearch from '../modules/shop/src/components/GlobalSearch.vue';
import OnHoldCustomer from '../modules/shop/src/components/OnHoldCustomer.vue';
// import { SplashScreen } from '@capacitor/splash-screen';
import NavDrawer from './NavDrawer.vue';
import Auth from '../components/Auth.vue';
import CartService from '../components/CartService.vue';
import Account from '../components/Account.vue';
import FavouriteService from '../components/FavouriteService.vue';
import InstallButton from '../components/InstallButton.vue';
import StickyToasts from '../components/StickyToasts.vue';
import SyncService from '../components/SyncService.vue';
import MaintenanceNotify from '../components/MaintenanceNotify.vue';
import RateOurService from '../components/RateOurService.vue';
import FeedbackDialog from '../components/FeedbackDialog.vue';
import NetworkService from '../components/NetworkService.vue';
import AppVersion from '../build.txt';
// import PushNotifications from './components/PushNotifications.vue';
// import ServiceWorkerUpdate from './components/ServiceWorkerUpdate.vue';
import '../site.scss';

export default {
  name: 'Desktop',
  components: {
    Auth,
    Account,
    BasicToasts,
    InstallButton,
    MaintenanceNotify,
    NavDrawer,
    NetworkService,
    MiniCart,
    OnHoldCustomer,
    CartService,
    Orders,
    // OrganizationSelect,
    // PushNotifications,
    Toasts,
    // eslint-disable-next-line vue/no-unused-components
    GlobalSearch,
    StickyToasts,
    FavouriteService,
    RateOurService,
    FeedbackDialog,
    SyncService,
    // ServiceWorkerUpdate,
  },
  data() {
    return {
      mdiCogOutline,
      mdiHome,
      mdiMagnify,
      mdiHeart,
      mdiCart,
      mdiHomeOutline,
      mdiHeartOutline,
      mdiCartOutline,
      appVersion: AppVersion,
      message: '',
      error: '',
      drawer: false,
      showRating: false,
    };
  },
  computed: {
    ...mapState('auth', ['username']),
    ...mapState('app', ['title']),
    ...mapGetters('app', ['displayNameOrUsername']),
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
    ...mapGetters('cart', ['total_stockItems']),
    isWeb() {
      return this.platform === 'web';
    },
    isIos() {
      // return true;
      return this.platform === 'ios';
    },
    isAndroid() {
      return this.platform === 'android';
    },
    searchType() {
      if (this.$route.name === 'shop-Favourites') {
        return 'Favourites';
      }
      if (this.$route.name === 'finance-FinanceHome') {
        return 'Documents';
      }
      return 'All';
    },
    showMaintenanceWarning() {
      if (this.$vuetify.breakpoint.mobile) {
        return false;
      }
      return true;
    },
    isFavourites() {
      return this.$route.name === 'shop-Favourites';
    },
    searchRedirect() {
      return this.$route.name !== 'shop-Favourites'
        && this.$route.name !== 'shop-ShopSearch';
    },
    isShopHome() {
      return this.$route.name === 'shop-ShopHome';
    },
    isInit() {
      return this.$route.name === 'app-Init';
    },
    platform() {
      return Capacitor.getPlatform();
    },
    showBackButton() {
      return this.$route.name !== 'shop-ShopCategories';
    },
    extensionHeight() {
      if (this.isShopHome) {
        return '20vh';
      }
      return 75;
    },
  },
};
</script>
