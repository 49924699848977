<template>
  <v-container fluid>
    <Breadcrumbs :history="history" />
      <v-skeleton-loader
        :loading="isLoading"
        transition="expand-transition"
        type="article"
      >
        <FormStockItemAlternative
          :StockItemAlternative=" editStockItemAlternative"
          :isSaving="isSaving"
          @save="save"
          @cancel="$router.push({name: from.name })" />
      </v-skeleton-loader>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormStockItemAlternative from '../components/FormStockItemAlternative.vue';

export default {
  components: {
    FormStockItemAlternative,
  },
  name: 'EditStockItemAlternative',
  module: 'Shop',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing StockItemAlternative. Default to {}.
      */
      editStockItemAlternative: {},
      /**
       * Query Id for StockItemAlternative
       */
      stockItemAlternativeId: this.$route?.query.id,
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Approved Alternatives',
        disabled: false,
        href: '/#/shop/admin-stock-item-alternatives',
        depth: 0,
      }, {
        text: 'Edit',
        disabled: true,
      }],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('stockItemAlternatives', ['stockItemAlternatives', 'stockItemAlternative']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.stockItemAlternativeId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    await Promise.all([
      (async () => {
        await this.LOAD_customersMin();
      })(),
      (async () => {
        await this.LOAD_stockItemsMin();
      })(),
    ]);
    if (this.mode === 'edit') {
      await this.LOAD_stockItemAlternative(this.stockItemAlternativeId);
      this.editStockItemAlternative = this.stockItemAlternative;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('stockItemAlternatives', ['LOAD_stockItemAlternative', 'CREATE_stockItemAlternative', 'UPDATE_stockItemAlternative']),
    ...mapActions('customers', ['LOAD_customersMin']),
    ...mapActions('stockItems', ['LOAD_stockItemsMin']),
    /** Method used to save the item
     */
    async save(stockItemAlternative) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_stockItemAlternative(stockItemAlternative);
      } else {
        await this.UPDATE_stockItemAlternative(stockItemAlternative);
      }
      this.isSaving = false;
      this.$router.push({ name: 'shop-AdminStockItemAlternatives' });
    },
  },
};
</script>

<docs>
# EditStockItemAlternative example

'''js
<EditStockItemAlternative :StockItemAlternative="StockItemAlternative" />
'''
</docs>
