/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

// Store functionality
import modules from './modules';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['app', 'cart', 'auth', 'taxes', 'deliveryDates'],
});

Vue.use(Vuex);

// Create a new store
const store = new Vuex.Store({
  // strict: process.env.NODE_ENV !== 'production',
  modules,
  plugins: [
    vuexLocal.plugin,
  ],
  getters: {
    getCache: (state) => (name) => {
      const result = state.cache
        .filter((c) => c.name === name && c.expires <= new Date().valueOf());
      return result;
    },
  },
  mutations: {
    INSERT_cache(state, value) {
      state.cache.push({
        name: value,
        created: new Date(new Date().getTime() + 10000).valueOf(),
      });
    },
  },
  state: {
    cache: [],
  },
});

export default store;
