<template>
  <v-text-field
    v-model="localValue"
    :label="label"
    :hint="hint"
    :rules="rules"
    :min="min"
    :prepend-inner-icon="mdiLock"
    @click:append="() => (mode = !mode)"
    outlined
    :type="mode ? 'password' : 'text'"
  >
    <template v-slot:append>
      <v-btn icon @click="() => (mode = !mode)">
        <v-icon>
          {{ eyeIcon }}
        </v-icon>
      </v-btn>
      <v-btn icon @click="generatePassword()">
        <v-icon>
          {{ mdiShieldRefresh }}
        </v-icon>
      </v-btn>
    </template>
  </v-text-field>
</template>

<script>
import generator from 'generate-password';

import {
  mdiEye, mdiEyeOff, mdiLock, mdiShieldRefresh,
} from '@mdi/js';
import basicModel from '@codehq/aurora-app-core/src/mixins/basicModel';

export default {
  name: 'PasswordField',
  mixins: [basicModel],
  props: ['label', 'hint', 'rules', 'min'],
  data() {
    return {
      /** Icons
       */
      mdiEye,
      mdiEyeOff,
      mdiLock,
      mdiShieldRefresh,
      /** Mode used to control the visibility of the password
      */
      mode: String,
    };
  },
  computed: {
    eyeIcon() {
      return this.mode ? this.mdiEye : this.mdiEyeOff;
    },
  },
  methods: {
    generatePassword() {
      this.localValue = generator.generate({
        length: 8,
        numbers: true,
      });
    },
  },
};
</script>
