<template>
  <div>
    <StockItemsLoading v-if="isLoading" />
    <CardListCategories
      :categories="categories"
      :depth="depth"
      @change="updateCategory"
      hide-overlay
    />
    <SoftSaleDialog v-if="!isAdmin && !isAgent" />
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import CardListCategories from '../components/CardListCategories.vue';
import SoftSaleDialog from '../components/SoftSaleDialog.vue';
import StockItemsLoading from '../components/StockItemsLoading.vue';

import stockItem from '../mixins/stockItem';

export default {
  name: 'ShopCategories',
  components: {
    CardListCategories,
    SoftSaleDialog,
    StockItemsLoading,
  },
  mixins: [loading, stockItem],
  data() {
    return {
      depth: 1,
      unCategorizedItems: [],
    };
  },
  computed: {
    ...mapGetters('webCategories', ['getWebCategory1']),
    categories() {
      const { getWebCategory1 } = this;
      return getWebCategory1;
    },
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
    isAdmin() {
      // return false;
      return this.isInRole('Administrators');
    },
    isAgent() {
      // return false;
      return this.isInRole('Agents');
    },
  },
  async mounted() {
    this.SET_title('Shop');
    this.SET_expandedSearch(false);
    try {
      this.isLoading = true;
      await Promise.all([
        (async () => {
          await this.LOAD_webCategory1();
        })(),
      ]);
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.isLoading = false;
    }
    this.LOAD_pricings();
  },
  methods: {
    ...mapMutations('app', ['SET_title', 'SET_expandedSearch']),
    ...mapActions('pricings', ['LOAD_pricings']),
    ...mapActions('webCategories', ['LOAD_webCategory1', 'UPDATE_stockItemsByWebCategory1']),
    async updateCategory($event) {
      this.isLoading = true;
      await this.UPDATE_stockItemsByWebCategory1($event);
      this.stockItems = await this.LOAD_webCategory1();
      this.isLoading = false;
    },
  },
};
</script>

<style>
.ios .nav-list {
  margin-top: 50px;
}
</style>
