export default {
  SET_CART: (state, cart) => {
    state.cart = cart;
  },
  cart_INCREASE(state, stockItem) {
    const cartItem = state.cart.find((x) => x.stockItem.itemCode === stockItem.itemCode);
    if (cartItem) {
      cartItem.amount += 1;
    } else {
      state.cart.push({
        stockItem,
        amount: 1,
      });
    }
  },
  cart_DECREASE(state, stockItem) {
    const cartItem = state.cart.find((x) => x.stockItem.itemCode === stockItem.itemCode);
    if (cartItem) {
      cartItem.amount -= 1;
    }
  },
  cart_UPDATE(state, data) {
    const { stockItem, amount } = data;
    const cartItem = state.cart.find((x) => x.stockItem.itemCode === stockItem.itemCode);
    if (cartItem) {
      cartItem.amount = amount;
    } else {
      state.cart.push(data);
    }
  },
  ADD_TO_CART: (state, data) => {
    const index = state.cart.findIndex((x) => x.stockItem.itemCode === data.stockItem.itemCode);
    if (index !== -1) {
      const previousAmount = parseFloat(state.cart[index].amount);
      state.cart[index].amount = previousAmount + parseFloat(data.amount);
    } else {
      state.cart.push(data);
    }
  },
  REMOVE_CART: (state, itemCode) => {
    state.cart = state.cart.filter((x) => x.stockItem.itemCode !== itemCode);
  },
  RESET_CART: (state) => {
    state.cart = [];
  },
  CHANGE_AMOUNT: (state, data) => {
    const { itemCode, amount } = data;
    const index = state.cart.findIndex((x) => x.stockItem.itemCode === itemCode);
    if (index !== -1) {
      state.cart[index].amount = parseFloat(amount);
    }
  },
  DECREASE_AMOUNT: (state, itemCode) => {
    const cartItem = state.cart.find((x) => x.stockItem.itemCode === itemCode);
    if (cartItem) {
      if (cartItem.amount <= 1) {
        return;
      }
      cartItem.amount -= 1;
    }
  },
  INCREASE_AMOUNT: (state, itemCode) => {
    const cartItem = state.cart.find((x) => x.stockItem.itemCode === itemCode);
    if (cartItem) {
      cartItem.amount += 1;
    }
  },
};
