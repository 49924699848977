<template>
  <v-container fluid>
    <Breadcrumbs :history="history" />
    <v-data-table
      :headers="headers"
      :items="stockItemAlternatives"
      class="elevation-1"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-btn
            color="success"
            data-cy="StockItemAlternative-button-new"
            @click="$router.push({
              name: 'shop-EditStockItemAlternative'
            })"
          >
            <v-icon class="mr-2">
              {{ mdiPlus }}
            </v-icon>
            {{ $t('common.buttons.new') }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.customerId="{ item }">
        {{ getCustomerName(item.customerId) }}
      </template>
      <template v-slot:item.itemCode="{ item }">
        {{ getItemName(item.itemCode) }}
      </template>
      <template v-slot:item.alternativeItemCode="{ item }">
        {{ getItemName(item.alternativeItemCode) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <TableBasicButtons :editLink="{
            name: 'shop-EditStockItemAlternative',
            query: {id: item.id}
          }"
          @delete="remove(item)"
        />
      </template>
    </v-data-table>
</v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListStockItemAlternative',
  module: 'Shop',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Approved Alternatives',
        disabled: true,
        depth: 0,
      }],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('stockItemAlternatives', ['stockItemAlternatives']),
    ...mapState('customers', ['customers']),
    ...mapState('stockItems', ['stockItems']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('customer'),
          value: 'customerId',
        },
        {
          text: this.$$t('itemCode'),
          value: 'itemCode',
        },
        {
          text: this.$$t('alternativeItemCode'),
          value: 'alternativeItemCode',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await Promise.all([
      (async () => {
        await this.LOAD_customersMin();
      })(),
      (async () => {
        await this.LOAD_stockItemsMin();
      })(),
      (async () => {
        await this.LOAD_stockItemAlternatives();
      })(),
    ]);
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('stockItemAlternatives', ['LOAD_stockItemAlternatives', 'DELETE_stockItemAlternative']),
    ...mapActions('customers', ['LOAD_customersMin']),
    ...mapActions('stockItems', ['LOAD_stockItemsMin']),
    /** Delete StockItemAlternative from the collection
     */
    async remove(stockItemAlternative) {
      this.isLoading = true;
      await this.DELETE_stockItemAlternative(stockItemAlternative.id);
      this.isLoading = false;
    },
    getCustomerName(customerId) {
      const customer = this.customers.find((c) => c.customerId === customerId);
      return customer ? customer.customerDesc : 'Customer not found';
    },
    getItemName(itemCode) {
      const item = this.stockItems.find((i) => i.itemCode === itemCode);
      return item ? item.description : 'Item not found';
    },
  },
};
</script>

<docs>
# StockItemAlternative example

'''js
<StockItemAlternative />
'''
</docs>
