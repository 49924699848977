<template>
  <div class="nav-list">
    <Breadcrumbs :history="history" />
    <v-container fluid>
      <v-card>
        <v-card-text>
          <v-skeleton-loader
            :loading="isLoading"
            transition="expand-transition"
            type="article"
          >
            <FormVehicleInspection
              :VehicleInspection="editVehicleInspection"
              :isSaving="isSaving"
              @save="save"
              @cancel="$router.push({ name: 'fleet-FleetHome', query: { tab: '2' } })" />
          </v-skeleton-loader>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormVehicleInspection from '../components/FormVehicleInspection.vue';

export default {
  components: {
    FormVehicleInspection,
  },
  name: 'EditVehicleInspection',
  module: 'Fleet',
  mixins: [loading],
  data() {
    return {
      /**
       * History for breadcrumbs
       */
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Fleet',
        disabled: false,
        href: '/#/fleet/fleet-home',
        depth: 0,
      }, {
        text: 'Vehicle Inspection',
        disabled: true,
        depth: 0,
      }],
      /**
      * Get/Set editing VehicleInspection. Default to {}.
      */
      editVehicleInspection: {
        created: new Date(),
      },
      /**
       * Query Id for VehicleInspection
       */
      vehicleInspectionId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('vehicleInspections', ['vehicleInspections', 'vehicleInspection']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.vehicleInspectionId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_vehicleInspection(this.vehicleInspectionId);
      this.editVehicleInspection = this.vehicleInspection;
    }
    await this.LOAD_vehicles();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('vehicleInspections', ['LOAD_vehicleInspection', 'CREATE_vehicleInspection', 'UPDATE_vehicleInspection']),
    ...mapActions('vehicles', ['LOAD_vehicles']),
    /** Method used to save the item
     */
    async save(vehicleInspection) {
      try {
        this.isSaving = true;
        if (this.mode === 'add') {
          await this.CREATE_vehicleInspection(vehicleInspection);
        } else {
          await this.UPDATE_vehicleInspection(vehicleInspection);
        }
        this.isSaving = false;
        this.$router.push({ name: 'fleet-FleetHome', query: { tab: '2' } });
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Errror saving vehicle inspection');
      }
    },
  },
};
</script>

<docs>
# EditVehicleInspection example

'''js
<EditVehicleInspection :VehicleInspection="VehicleInspection" />
'''
</docs>
