/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-console */
// eslint-disable-next-line import/no-extraneous-dependencies
import { Workbox } from 'workbox-window';
import { registerRoute, Route } from 'workbox-routing';
import { StaleWhileRevalidate } from 'workbox-strategies';

// eslint-disable-next-line import/no-mutable-exports
let wb;

if ('serviceWorker' in navigator) {
  wb = new Workbox(`${process.env.BASE_URL}sw.js`);

  wb.addEventListener('controlling', () => {
    console.log('service worker: controlling');
    window.location.reload();
  });

  wb.addEventListener('message', (event) => {
    console.log(event);
    if (event.data && event.data.type === 'SKIP_WAITING') {
      wb.skipWaiting();
    }
  });

  wb.addEventListener('waiting', (registration) => {
    document.dispatchEvent(new CustomEvent('serviceWorkerUpdateEvent', { detail: registration }));
  });

  // Handle images:
  // eslint-disable-next-line max-len
  const fontRoute = new Route(({ request }) => request.destination === 'image', new StaleWhileRevalidate({
    cacheName: 'images',
  }));

  // // Register routes
  registerRoute(fontRoute);
  wb.register();
} else {
  console.log('no service worker found in navigator');
  wb = null;
}

export default wb;
