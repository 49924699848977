import { uniqBy } from 'lodash';

export default {
  getCategory: (state) => state.category,
  getCategory1: (state) => uniqBy(state.categories.map((c) => ({
    webCategory1: c.webCategory1,
    webCategory1Photo: c.webCategory1Photo,
  })), (u) => u.webCategory1),
  getWebCategory2: (state) => (parent) => uniqBy(state.categories
    .filter((c) => c.webCategory1 === parent)
    .map((c) => ({
      webCategory2: c.webCategory2,
      webCategory2Photo: c.webCategory2Photo,
    })), (u) => u.webCategory2),
  getWebCategory3: (state) => (parent) => uniqBy(state.categories
    .filter((c) => c.webCategory2 === parent)
    .map((c) => ({
      webCategory3: c.webCategory3,
      webCategory3Photo: c.webCategory3Photo,
    })), (u) => u.webCategory3),
  getWebCategory4: (state) => (parent) => uniqBy(state.categories
    .filter((c) => c.webCategory3 === parent)
    .map((c) => ({
      webCategory4: c.webCategory4,
      webCategory4Photo: c.webCategory4Photo,
    })), (u) => u.webCategory4),
};
