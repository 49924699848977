<template>
  <div>
    {{ imageInfo[display] }}
    <!-- <img :src="imageSrc" :onload="onImageLoad" class="my-4" max-width="300" />
    <v-row v-if="imageInfo">
      <v-col cols="12">
        <v-chip class="mr-2">Size: {{ imageInfo.size }} bytes</v-chip>
        <v-chip>Dimensions: {{ imageInfo.width }}x{{ imageInfo.height }} pixels</v-chip>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
    display: {
      type: String,
      default: 'size',
    },
  },
  data() {
    return {
      imageInfo: null,
    };
  },
  mounted() {
    const image = new Image();
    image.onload = async (e) => {
      const img = e.currentTarget;
      if (img) {
        const width = img?.naturalWidth ?? 0;
        const height = img?.naturalHeight ?? 0;

        // Fetch image size in bytes
        const response = await fetch(this.imageSrc);
        const blob = await response.blob();
        const { size } = blob;

        this.imageInfo = {
          width,
          height,
          size,
        };
      }
    };
    image.src = this.imageSrc;
  },
  methods: {
    async onImageLoad(event) {
      const img = event.target;
      if (img) {
        const width = img?.naturalWidth ?? 0;
        const height = img?.naturalHeight ?? 0;

        // Fetch image size in bytes
        const response = await fetch(this.imageSrc);
        const blob = await response.blob();
        const { size } = blob;

        this.imageInfo = {
          width,
          height,
          size,
        };
      }
    },
  },
};
</script>

<style scoped>
/* You can add your CSS styling here */
</style>
