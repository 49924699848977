<template>
  <v-container fill-height fluid>
    <component :is="resolveLayout" />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import home from '../mixins/home';
import MobileShopHome from '../components/MobileShopHome.vue';
import DesktopShopHome from '../components/DesktopShopHome.vue';

export default {
  name: 'ShopHome',
  components: {
    MobileShopHome,
    DesktopShopHome,
  },
  meta: {
    minimalLayout: true,
  },
  mixins: [loading, home],
  computed: {
    resolveLayout() {
      return this.isMobile ? 'MobileShopHome' : 'DesktopShopHome';
    },
  },
  async mounted() {
    this.$log.debug('ShopHome mounted');
    this.$log.info('warming up cache');
    Promise.all([
      (async () => {
        await this.LOAD_pricings();
      })(),
      (async () => {
        await this.LOAD_profile();
      })(),
      (async () => {
        await this.LOAD_webCategory1();
      })(),
      (async () => {
        await this.LOAD_webCategory2();
      })(),
      (async () => {
        await this.LOAD_webCategory3();
      })(),
      (async () => {
        await this.LOAD_webCategory4();
      })(),
      (async () => {
        await this.LOAD_stockLimits();
      })(),
    ]);
    this.$log.info('loading web categories');
    // await this.LOAD_stockDataSafe();
    // eslint-disable-next-line max-len
    // const images = this.categories.map((category) => ({ url: category.photoUrl, revision: null }));
    // await this.$workbox?.messageSW({ type: 'SYNC_IMAGES', payload: images });
  },
  methods: {
    ...mapActions('app', ['LOAD_profile']),
    ...mapActions('stockItems', ['LOAD_stockDataSafe', 'LOAD_stockLimits']),
    ...mapActions('pricings', ['LOAD_pricings']),
    ...mapActions('webCategories', [
      'LOAD_webCategory1',
      'LOAD_webCategory2',
      'LOAD_webCategory3',
      'LOAD_webCategory4',
    ]),
  },
};
</script>
<style scoped>
.title {
  word-break: normal;
}
</style>
