import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('quote', 'quotes');

const customActions = {
  async LOAD_quote({ commit, rootGetters }, id) {
    const i = odata.parseId(id);
    const data = await odata.o(rootGetters)
      .get(`quotes(${i})`)
      .query({
        // $expand: 'Order/Cart',
      });
    commit('SET_quote', data);
    return data;
  },
  async LOAD_quotes({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('quotes')
      .query({
        $expand: 'Order',
      });
    commit('SET_quotes', data);
    return data;
  },
};

export default { ...actions, ...customActions };
