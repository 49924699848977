<template>
  <v-container fluid>
    <Breadcrumbs :history="history" />
    <ProgressLinearTimeout v-if="isLoading" />
    <v-row class="nav-list">
      <v-col class="d-flex flex-column h-100" cols="12" lg="8">
        <v-card class="h-100 mb-10">
          <v-card-title primary-title>
            Soft Sales Activity
          </v-card-title>
          <v-card-text>
            <SoftSalesActivity />
          </v-card-text>
        </v-card>
        <v-card class="h-100">
          <v-card-title primary-title>
            Soft Sales
          </v-card-title>
          <v-card-text>
            <SoftSales />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="d-flex flex-column h-100" cols="12" lg="4">
        <v-card class="h-100 mb-10">
          <v-card-title primary-title>
            Customer Ratings
          </v-card-title>
          <v-card-text>
            <CustomerRatings />
          </v-card-text>
        </v-card>
        <v-card class="h-100">
          <v-card-title primary-title>
            Push Notfications
          </v-card-title>
          <v-card-text>
            <SendPushNotifications />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="mb-10 h-100">
          <v-card-title primary-title>
            Slow Customers Report
          </v-card-title>
          <v-card-text>
            <ReportSlowCustomers />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import CustomerRatings from '@/components/CustomerRatings.vue';
import SendPushNotifications from '@/components/SendPushNotifications.vue';
import ReportSlowCustomers from '../components/ReportSlowCustomers.vue';
import SoftSales from '../components/SoftSales.vue';
import SoftSalesActivity from '../components/SoftSalesActivity.vue';

export default {
  name: 'SalesDashboard',
  mixins: [loading],
  components: {
    CustomerRatings,
    SendPushNotifications,
    ReportSlowCustomers,
    SoftSales,
    SoftSalesActivity,
  },
  data() {
    return {
      history: [
        {
          text: 'Home',
          disabled: false,
          href: '/#/members',
          depth: 0,
          // }, {
          //   text: 'Orders',
          //   disabled: false,
          //   href: '/#/shop/orders',
          //   depth: 0,
        },
        {
          text: 'Sales Dashboard',
          disabled: true,
          depth: 0,
        },
      ],
    };
  },
};
</script>
