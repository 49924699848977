<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <h1>StockItem</h1>
        <v-select
          data-cy="FormStockItem-supplierId"
          filled
          :label="$$t('supplierId')"
          :items="items"
          v-model="localStockItem.supplierId"
        ></v-select>
        <v-text-field
          filled
          data-cy="FormStockItem-itemCode"
          :label="$$t('itemCode')"
          v-model="localStockItem.itemCode"
        />
        <v-text-field
          filled
          data-cy="FormStockItem-description"
          :label="$$t('description')"
          v-model="localStockItem.description"
        />
        <v-select
          data-cy="FormStockItem-categoryId"
          filled
          :label="$$t('categoryId')"
          :items="items"
          v-model="localStockItem.categoryId"
        ></v-select>
        <v-text-field
          filled
          data-cy="FormStockItem-barcode"
          :label="$$t('barcode')"
          v-model="localStockItem.barcode"
        />
        <ImageUpload
          data-cy="FormStockItem-photo-image-upload"
          v-model="localStockItem.photo"
          :label="$$t('photo')" />
        <v-img
          data-cy="FormStockItem-photo-image-display"
          v-if="localStockItem.photo"
          :src="localStockItem.photo" />
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormStockItem-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="$parent.isSaving"
            data-cy="FormStockItem-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
export default {
  name: 'FormStockItem',
  module: 'StockItem',
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    StockItem: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localStockItem
      */
      localStockItem: {},
    };
  },
  watch: {
    StockItem() {
      this.localStockItem = this.StockItem;
    },
  },
  created() {
    this.localStockItem = this.StockItem;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localStockItem);
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormStockItem example

'''js
<FormStockItem :StockItem="StockItem" />
'''
</docs>
