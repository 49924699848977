var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('Breadcrumbs',{attrs:{"history":_vm.history}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.emails,"loading":_vm.isLoading,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"color":"success","data-cy":"Email-button-new"},on:{"click":function($event){return _vm.$router.push({
            name: 'shop-EditEmail',
            query: {customerCode: _vm.$route.query.customerCode}
          })}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.mdiPlus)+" ")]),_vm._v(" "+_vm._s(_vm.$t('common.buttons.new'))+" ")],1),_c('v-text-field',{attrs:{"append-icon":_vm.mdiMagnify},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.actions",fn:function({ item }){return [_c('TableBasicButtons',{attrs:{"editLink":{
          name: 'shop-EditEmail',
          query: {id: item.id}
        }},on:{"delete":function($event){return _vm.remove(item)}}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }