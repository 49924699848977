<template>
  <v-container fluid class="pa-6" fill-height>
    <v-row class="d-flex">
      <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="2"
        class="d-flex align-stretch col-sm-padding stock-item-card"
        v-for="(stockItem, index) in stockItems"
        :data-cy="`stock-item-card-${index}`"
        :key="`stockItem-${index}`"
      >
        <StockItemCard :stockItem="stockItem" @add="add" @change="$emit('change', $event)" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import cartMethods from '../mixins/cartMethods';
import StockItemCard from './StockItemCard.vue';

export default {
  name: 'CardListStockItems',
  props: ['stockItems'],
  mixins: [cartMethods],
  components: {
    StockItemCard,
  },
  async mounted() {
    // await this.LOAD_stockDataSafe();
  },
  methods: {
    ...mapActions('stockItems', ['LOAD_stockLimits', 'LOAD_stockDataSafe']),
  },
};
</script>
