<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormFleetSupplier
      :FleetSupplier="editFleetSupplier"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormFleetSupplier from '../components/FormFleetSupplier.vue';

export default {
  components: {
    FormFleetSupplier,
  },
  name: 'EditFleetSupplier',
  module: 'Fleet',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing FleetSupplier. Default to {}.
      */
      editFleetSupplier: {},
      /**
       * Query Id for FleetSupplier
       */
      fleetSupplierId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('fleetSuppliers', ['fleetSuppliers', 'fleetSupplier']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.fleetSupplierId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_fleetSupplier(this.fleetSupplierId);
      this.editFleetSupplier = this.fleetSupplier;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('fleetSuppliers', ['LOAD_fleetSupplier', 'CREATE_fleetSupplier', 'UPDATE_fleetSupplier']),
    /** Method used to save the item
     */
    async save(fleetSupplier) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_fleetSupplier(fleetSupplier);
      } else {
        await this.UPDATE_fleetSupplier(fleetSupplier);
      }
      this.isSaving = false;
      this.$router.push({ name: 'fleet-FleetHome', tab: 2 });
    },
  },
};
</script>

<docs>
# EditFleetSupplier example

'''js
<EditFleetSupplier :FleetSupplier="FleetSupplier" />
'''
</docs>
