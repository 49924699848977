export default {
  SET_error(state, value) {
    state.error = value;
  },
  SET_loading(state, value) {
    state.loading = value;
  },
  SET_token(state, value) {
    state.token = value;
  },
  SET_username(state, value) {
    state.username = value;
  },
  SET_locationRequested(state, value) {
    state.locationRequested = value;
  },
  SET_roles(state, value) {
    if (value) {
      state.roles = value?.split(',');
    } else {
      state.roles = [];
    }
  },
  SET_favouriteListMode(state, value) {
    state.favouriteListMode = value;
  },
  SET_isOffline(state, value) {
    state.isOffline = value;
  },
  SET_lastSync(state, value) {
    state.lastSync = value;
  },
  SET_appStyle(state, value) {
    state.appStyle = value;
  },
  SET_divisionId(state, value) {
    state.divisionId = value;
  },
  SET_deliveryAddress(state, value) {
    state.deliveryAddress = value;
  },
  SET_title(state, value) {
    state.title = value;
  },
  SET_expandedSearch(state, value) {
    state.expandedSearch = value;
  },
  SET_displayName(state, value) {
    state.displayName = value;
  },
};
