export default {
  getToken: (state) => state.token,
  getUsername: (state) => state.username,
  getRoles: (state) => state.roles,
  isOffline: (state) => state.isOffline,
  isLoggedIn: (state) => state.username?.length > 0,
  isInRole: (state) => (role) => state.roles.some((r) => r === role),
  // eslint-disable-next-line max-len
  displayNameOrUsername: (state, getters, rootState, rootGetters) => state.displayName || rootGetters['auth/getUsername'],
};
