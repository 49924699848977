<template>
  <v-simple-table class="mb-4">
    <template v-slot:default>
      <thead>
        <tr>
          <th />
          <th>
            <h3>Product</h3>
          </th>
          <th>
            <h3>Stock</h3>
          </th>
          <th>
            <h3>Quantity</h3>
          </th>
          <th>
            <h3>Price</h3>
          </th>
          <th>
            <h3>VAT</h3>
          </th>
          <th>
            <h3>Total</h3>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(cart, index) in cartItems" :key="cart.id"
          :data-cy="`table-cart-row-${index}`">
          <td>
            <v-img
              width="92"
              contain
              :src="resizeImage(cart.stockItem)"
            />
          </td>
          <td><h3>{{ cart.stockItem.description }}</h3></td>
          <td class="price-col">
              <h4 v-if="cart.stockItem.quantity > 0">{{ cart.stockItem.quantity}}</h4>
              <h4 v-else class="red--text">Out of stock</h4>
          </td>
          <td class="qty-col">
            <!-- <v-text-field
              outlined
              filled
              v-model="cart.amount"
              type="number"
              class="field-qty pt-5 text-center"
              :rules="[rules.non_zero]"
              :append-icon="mdiPlus"
              @click:append="INCREASE_AMOUNT(cart.stockItem.itemCode)"
              :prepend-inner-icon="getDecreaseIcon(cart.amount)"
              @click:prepend-inner="DECREASE_AMOUNT(cart.stockItem.itemCode)">
            </v-text-field> -->
            <v-text-field
              v-if="!disabled"
              data-cy="quantity-input"
              class="quantity-input"
              v-model="cart.amount"
              :rules="[rules.non_zero]"
              @input="$emit('change', {
                itemCode: cart.stockItem.itemCode,
                amount: $event,
                id: cart.id,
              })"
              :append-icon="mdiPlus"
              @click:append="$emit('change', {
                itemCode: cart.stockItem.itemCode,
                amount: cart.amount + 1,
                id: cart.id,
              })"
              :prepend-inner-icon="getDecreaseIcon(cart.amount)"
              @click:prepend-inner="$emit('change', {
                itemCode: cart.stockItem.itemCode,
                amount: cart.amount - 1,
                id: cart.id,
              })"
            ></v-text-field>
            <span v-else>
              {{ cart.amount }}
            </span>
          </td>
          <td class="price-col">
              <h4>R {{formatCurrency(cart.price)}}</h4>
          </td>
          <td class="price-col">
              <h4>R {{formatCurrency(cart.tax)}}</h4>
          </td>
          <td class="price-col">
              <h4>R {{formatCurrency(cart.total)}}
              </h4>
          </td>
          <td>
            <v-btn style="float: right"
              v-if="!disabled"
              icon
              @click="$emit('remove', cart)"
            >
              <v-icon color="error">{{ mdiTrashCan }}</v-icon>
            </v-btn>
          </td>
        </tr>
        <tr v-if="cart.length > 0">
          <td colspan="8">
            <slot name="notes" />
          </td>
        </tr>
        <tr v-if="cart.length > 0">
          <td colspan="8" class="small-td text-right">
            <h2 class="mt-9">
              <span>Total:</span>
              R {{ formatCurrency(total_amount) }}
            </h2>
          </td>
        </tr>
        <tr v-if="cart.length > 0">
          <td colspan="7">
          </td>
          <td>
            <slot name="costCentre" />
          </td>
        </tr>
        <tr v-if="cartItems.length > 0">
          <td colspan="7">
            <slot name="buttons-left" />
          </td>
          <td>
            <slot name="buttons" />
          </td>
        </tr>
        <tr v-if="cartItems.length > 0 && client">
          <td colspan="8" class="small-td text-right">
            <h2 class="mt-9">
              <span>Current Balance:</span>
              R {{ formatCurrency(client.dcBalance) }}
            </h2>
          </td>
        </tr>
        <tr v-if="cartItems.length > 0 && client">
          <td colspan="3">
            <h2 class="mt-9">
              {{ client.cDescription }}
            </h2>
          </td>
          <td colspan="5" class="small-td text-right">
            <h2 class="mt-9">
              <span>Balance after Order:</span>
              R {{ formatCurrency(remaining) }}
            </h2>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mdiPlus, mdiTrashCan } from '@mdi/js';
import cart from '../mixins/cart';
import validation from '../mixins/validation';

export default {
  name: 'TableQuoteCart',
  props: {
    cartItems: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [cart, validation],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      mdiTrashCan,
    };
  },
  computed: {
    ...mapGetters('auth', ['isInRole']),
    ...mapState('finance', ['client']),
    /**
     * Returns true if the user is in the buyers role
     */
    isApproval() {
      return this.isInRole('Buyers');
    },
  },
  methods: {
    resizeImage(stockItem) {
      const result = `${stockItem?.photo}` ?? '';
      return result;
    },
  },
};
</script>
