<template>
  <div>
    <StockItemsLoading v-if="isLoading" />
    <CardListFavouriteItems
      v-if="!isLoading && !$vuetify.breakpoint.mobile"
      :isLoading="isLoading"
      :stockItems="availableStockItems"
      @change="update"
      @refresh="refresh"
    />
    <!-- <ListFavouriteItems
      v-if="listToggle === 1 && !$vuetify.breakpoint.mobile && !isLoading"
      :isLoading="isLoading"
      :stockItems="availableStockItems"
      @change="update"
      @refresh="refresh"
    /> -->
    <MobileListFavouriteItems
      v-else-if="!isLoading && $vuetify.breakpoint.mobile"
      :stockItems="availableStockItems"
      @change="update"
      @refresh="refresh"
    />
  </div>
</template>
<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { mdiCardBulleted, mdiTable } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import CardListFavouriteItems from '../components/CardListFavouriteItems.vue';
import StockItemsLoading from '../components/StockItemsLoading.vue';
import MobileListFavouriteItems from '../components/MobileListFavouriteItems.vue';
import customerRefresh from '../mixins/customerRefresh';
import syncRefresh from '../mixins/syncRefresh';
import stockItem from '../mixins/stockItem';

export default {
  name: 'Favourites',
  module: 'shop',
  components: {
    CardListFavouriteItems,
    MobileListFavouriteItems,
    StockItemsLoading,
  },
  mixins: [loading, customerRefresh, stockItem, syncRefresh],
  data() {
    return {
      mdiCardBulleted,
      mdiTable,
      stockItems: [],
      search: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
    ...mapGetters('stockItems', ['getStockItemDetail']),
    ...mapState('stockItems', ['favouriteStockItems', 'divisionStockItems']),
    ...mapState('app', ['favouriteListMode', 'divisionId']),
    availableStockItems() {
      const { divisionId, favouriteStockItems, divisionStockItems } = this;
      let results = favouriteStockItems;
      if (divisionId) {
        results = divisionStockItems;
      }

      if (this.$route.query.itemCode) {
        results = favouriteStockItems
          .filter((item) => item.itemCode === this.$route.query.itemCode);
      }
      if (this.search?.length > 0) {
        results = results
          .filter((item) => item.description.toLowerCase()
            .includes(this.search.toLowerCase()));
      }
      // sort items by category
      return results;
    },
    exportFavourites() {
      return this.availableStockItems.map((item) => ({
        ItemCode: item.itemCode,
        Description: item.description,
        'Price exVAT': this.getStockItemDetail(item)?.price,
        UOM: item.unitOfMeasure,
      }));
    },
    listToggle: {
      get() {
        return this.favouriteListMode;
      },
      set(newValue) {
        if (newValue <= 1) {
          return this.SET_favouriteListMode(newValue);
        }
        return this.favouriteListMode;
      },
    },
  },
  watch: {
    async divisionId() {
      await this.refresh();
    },
  },
  async mounted() {
    this.SET_title('Favourites');
    this.refresh();
    this.$root.$on('search', ({ text, type }) => {
      if (type === 'Favourites') {
        this.search = text;
      }
    });
  },
  destroyed() {
    this.$root.$off('search');
  },
  methods: {
    ...mapActions('stockItems', [
      'LOAD_stockDataSafe',
      'LOAD_divisionFavouriteStockItems',
      'LOAD_favouriteStockItems',
      'UPDATE_stockItem']),
    ...mapMutations('app', ['SET_favouriteListMode', 'SET_title', 'SET_expandedSearch']),
    ...mapMutations('stockItems', ['SET_favouriteStockItems']),
    ...mapActions('pricings', ['LOAD_pricings']),
    ...mapActions('favourites', ['LOAD_favourites']),
    async refresh() {
      this.isLoading = true;
      this.$log.debug('loading favourites');
      try {
        await Promise.all([
          (async () => {
            if (this.divisionId) {
              await this.LOAD_divisionFavouriteStockItems(this.divisionId);
              const results = await this.$http.get(`odata/divisionStockItems?division=${this.divisionId}`);
              this.SET_favouriteStockItems(results.data.value);
            } else {
              await this.LOAD_favouriteStockItems();
            }
          })(),
          (async () => {
            await this.LOAD_pricings();
            // await this.LOAD_stockDataSafe();
          })(),
        ]);
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
