<template>
  <v-data-table
    :loading="isLoading"
    :headers="headers"
    :items="productImages"
    :elevation="0"
    :search="search"
  >
    <template v-slot:top>
      <v-row no-gutters>
        <v-col class="d-flex align-end" offset="6" cols="6">
          <v-text-field
            v-model="search"
            class="mr-2"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <ExportToExcelButton
            :data="excelData"
            name="Products Images"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.image="{ item }">
      <v-img max-width="300" contain :src="item.image" />
    </template>
    <template v-slot:item.imageSize="{ item }">
      {{ formatNumber(item.imageSize) }}
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ExportToExcelButton from '~src/components/ExportToExcelButton.vue';

export default {
  name: 'ReportProductImages',
  components: {
    ExportToExcelButton,
  },
  mixins: [loading],
  data() {
    return {
      productImages: [],
      search: '',
      processing: 0,
      completed: 0,
    };
  },
  computed: {
    ...mapState('stockItems', ['stockItems']),
    excelData() {
      return this.productImages.map((p) => ({
        'Item Code': p.itemCode,
        Description: p.description,
        Photo: p.image,
        Width: p.imageWidth,
        Height: p.imageHeight,
        Size: p.imageSize,
      }));
    },
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: 'Item Code',
          value: 'itemCode',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Photo',
          value: 'image',
        },
        {
          text: 'Width',
          value: 'imageWidth',
        },
        {
          text: 'Height',
          value: 'imageHeight',
        },
        {
          text: 'Size (bytes)',
          value: 'imageSize',
        },
      ];
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('stockItems', ['LOAD_stockItemsWithNoPhoto', 'LOAD_stockItems']),
    getImageInfo(item) {
      const image = new Image();
      const updatedItem = { ...item };
      updatedItem.height = 0;
      updatedItem.width = 0;
      updatedItem.size = 0;
      this.processing += 1;
      image.onload = async (e) => {
        const img = e.currentTarget;
        if (img) {
          const width = img?.naturalWidth ?? 0;
          const height = img?.naturalHeight ?? 0;

          // Fetch image size in bytes
          const { data } = await fetch(`${this.imageSrc}/properties`);
          // const blob = await response.blob();
          // const { size } = blob;
          updatedItem.height = height;
          updatedItem.width = width;
          updatedItem.size = data.size;
          this.productImages = this.productImages.map((p) => {
            if (p.itemCode === item.itemCode) {
              return updatedItem;
            }
            return p;
          });
          this.completed += 1;
        }
      };
      image.src = item.photo;
    },
    /**
     * Executes refresh data from api
     */
    async refresh() {
      this.isLoading = true;
      const { data } = await this.$http.get('api/reports/images');
      this.productImages = data;
      // await this.LOAD_stockItems();
      // this.productImages = this.stockItems
      //   .filter((s) => s.statusId === 1);
      // this.productImages.forEach((item) => {
      //   this.getImageInfo(item);
      // });
      this.isLoading = false;
    },
    /**
     * Get image height
     * @param {string} url
     */
    async getImageHeight(url) {
      const image = new Image();
      image.onload = async (e) => {
        const img = e.currentTarget;
        if (img) {
          const height = img?.naturalHeight ?? 0;
          return height;
        }
        return 0;
      };
      image.src = url;
    },
  },
};
</script>
