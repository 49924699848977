<template>
  <v-row no-gutters>
  <v-col cols="6">
    <v-text-field
      ref="quantity"
      type="number"
      class="input-cart-qty"
      outlined
      v-model="amount"
      @keydown.enter.prevent="add"
      dense
      @blur="quantityBlurred"
      @focus="amount = ''"
    >
      <template v-slot:append>
      </template>
    </v-text-field>
  </v-col>
  <v-col cols="6">
    <v-btn
      class="rounded-l-sm rounded-l-xs rounded-l-md inline-btn"
      style="color: white;"
      depressed
      small
      color="#EE1C25"
      @click.stop="add">
      Add
    </v-btn>
  </v-col>
  </v-row>
</template>

<style>
.input-cart-qty .v-input__slot {
  min-height: 28px !important;
  height: 28px !important;
  padding: 0 !important;
  margin-right: -2px;
  /* padding-right: 8px !important; */
}
.inline-btn {
  /* margin-right: -11px; */
  width: 100%;
  height: 28px;
  display: block;
}
.input-cart-qty .v-text-field__slot {
  flex-basis: 47% !important;
}
.v-text-field--outlined .v-input__control {
  border-radius: 0 !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.input-cart-qty .v-input__append-inner {
  flex-basis: 53% !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
}
.input-cart-qty .v-text-field__slot {
  padding-left: 8px;
}
</style>
<style scoped>
.v-input  {
  display: block;
  height: 35px;
}
</style>

<script>
import { mapGetters } from 'vuex';
import { mdiCheck } from '@mdi/js';

export default {
  name: 'MobileButtonAddToCart',
  props: {
    stockItem: {
      type: Object,
      default: () => {},
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiCheck,
      amount: 1,
    };
  },
  computed: {
    ...mapGetters('cart', ['displayCart']),
    isInCart() {
      const { displayCart, stockItem } = this;
      return displayCart.some((c) => c.stockItem.itemCode === stockItem.itemCode);
    },
  },
  mounted() {
    if (this.autoFocus) {
      this.$refs.quantity.focus();
    }
  },
  methods: {
    async add() {
      if (this.amount === 0 || this.amount === undefined) {
        this.amount = 1;
        await this.$nextTick();
      }
      const { stockItem, amount } = this;
      if (this.isInCart) {
        await this.$nextTick();
        const result = await this.$confirm(
          'This item is already in your cart, are you sure you want to add more?',
        );
        if (result) {
          this.$emit('add', { stockItem, amount });
          this.amount = 1;
        }
      } else {
        this.$emit('add', { stockItem, amount });
        this.amount = 1;
      }
    },
    quantityBlurred() {
      if (!this.localValue) {
        this.localValue = 1;
      }
    },
    quantityClicked() {
      this.amount = undefined;
    },
  },
};
</script>
