/* eslint-disable no-param-reassign */
import IndexedDB from '../IndexedDB';
import stores from '../stores.json';

export default {
  /**
  * Install the plugin
  */
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    const dbName = 'gourmet-foods-shop';
    const version = 1;
    const db = new IndexedDB(dbName, version, stores);
    Vue.prototype.$db = db;
    if (options?.store) {
      options.store.$db = db;
    }
  },
};
