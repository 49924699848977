<template>
  <v-data-table
    :headers="headers"
    :items="vehicleInspections"
    class="elevation-1"
    :search="search"
    :loading="isLoading"
    sort-by="created"
    sort-desc
  >
    <template v-slot:top>
      <v-row :class="{ 'p-16': $vuetify.breakpoint.mobile }">
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-btn
            color="primary"
            data-cy="VehicleInspection-button-new"
            @click="$router.push({
              name: 'fleet-EditVehicleInspection'
            })"
          >
            <v-icon class="mr-2">
              {{ mdiPlus }}
            </v-icon>
            {{ $t('common.buttons.new') }}
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-text-field
            v-model="search"
            :append-icon="mdiMagnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.created="{ item }">
      {{ formatDateTime(item.created) }}
    </template>
    <template v-slot:item.vehicle="{ item }">
      <span v-if="item.vehicle">
        {{ `${item.vehicle.make} ${item.vehicle.model} - ${item.vehicle.licensePlate}` }}
      </span>
    </template>
    <template v-slot:item.actions="{ item }">
      <TableBasicButtons :editLink="{
          name: 'fleet-EditVehicleInspection',
          query: {id: item.id}
        }"
        @delete="remove(item)"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus, mdiMagnify } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListVehicleInspection',
  module: 'Fleet',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      mdiMagnify,
      /**
       * Search string
       */
      search: '',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('vehicleInspections', ['vehicleInspections']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: 'Time Completed',
          value: 'created',
        },
        {
          text: this.$$t('vehicleId'),
          value: 'vehicle',
        },
        {
          text: 'Completed By',
          value: 'createdBy',
        },
        {
          text: this.$$t('interiorCondition'),
          value: 'interiorCondition',
        },
        {
          text: this.$$t('exteriorCondition'),
          value: 'exteriorCondition',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_vehicleInspections();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('vehicleInspections', ['LOAD_vehicleInspections', 'DELETE_vehicleInspection']),
    /** Delete VehicleInspection from the collection
     */
    async remove(vehicleInspection) {
      this.isLoading = true;
      await this.DELETE_vehicleInspection(vehicleInspection.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# VehicleInspection example

'''js
<VehicleInspection />
'''
</docs>
