<template>
  <v-data-table
    :headers="headers"
    :items="vehicleParts"
    class="elevation-1"
    :loading="isLoading"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-btn
          color="success"
          data-cy="VehiclePart-button-new"
          :to="{
            name: 'fleet-EditVehiclePart',
            query: { vehicleId },
          }"
        >
          <v-icon class="mr-2">
            {{ mdiPlus }}
          </v-icon>
          {{ $t('common.buttons.new') }}
        </v-btn>
        <div class="text-large ml-2">
          Parts
        </div>
      </v-toolbar>
    </template>
    <template v-slot:item.date="{ item }">
      {{ formatDate(item.date) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <TableBasicButtons :editLink="{
          name: 'fleet-EditVehiclePart',
          query: { id: item.id, vehicleId }
        }"
        @delete="remove(item)"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListVehiclePart',
  module: 'Fleet',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  props: {
    vehicleId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('vehicleParts', ['vehicleParts']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('date'),
          value: 'date',
        },
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('partNumber'),
          value: 'partNumber',
        },
        {
          text: this.$$t('price'),
          value: 'price',
        },
        {
          text: this.$$t('serialNumber'),
          value: 'serialNumber',
        },
        {
          text: this.$$t('supplierId'),
          value: 'supplier.name',
        },
        {
          text: this.$$t('vehicleId'),
          value: 'vehicle.licensePlate',
        },
        {
          text: this.$$t('state'),
          value: 'state',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_vehicleParts();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('vehicleParts', ['LOAD_vehicleParts', 'DELETE_vehiclePart']),
    /** Delete VehiclePart from the collection
     */
    async remove(vehiclePart) {
      this.isLoading = true;
      await this.DELETE_vehiclePart(vehiclePart.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# VehiclePart example

'''js
<VehiclePart />
'''
</docs>
