<template>
  <div :data-cy="`mobileStockItem-row-${index}`" class="d-flex align-stretch justify-space-between">
    <div class="d-flex" style="padding: 5px">
      <v-img
        v-if="stockItem.photo"
        contain :src="stockItem.photo"
        style="max-width: 20vw; max-height: 95px;"
      />
      <v-img
        v-else
        contain
        src="../assets/default-product-image.png"
        style="max-width: 20vw; max-height: 95px;"
      />
    </div>
    <div class="d-flex flex-column justify-center w-100" :data-cy="`stock-item-card-${index}`">
      <div class="text-h6">
        {{ stockItem.description }}
      </div>
      <div>
        <div class="font-smaller">
          R {{ formatCurrency(detail.price) }} / {{ stockItem.unitOfMeasure }}
        </div>
      </div>
      <div v-if="detail.qty > 0" class="font-smaller">
        {{ detail.qty }} items in stock
      </div>
      <div v-else class="font-smaller mt-2">
        <span class="red--text">Out of stock</span>
      </div>
      <div v-if="detail.qty > 0" class="d-flex justify-space-between">
        <div class="w-100 pr-2">
          <QuantityButtonSmall
            :stockItem="stockItem"
            @add="add"
            v-model.number="localAmount"
            :height="30"
          />
        </div>
        <div class="w-100 pl-2 pr-2">
          <v-btn data-cy="add-to-cart"
            color="primary" @click="add"
            small
            :disabled="limit < 1"
            :title="limit < 1 ? 'Purchase limit reached' : ''"
            class="secondary--text"
            block
          >
            Add to Cart
          </v-btn>
        </div>
        <div class="pr-1">
          <v-btn
            class="mr-1"
            color="success"
            small
            @click="removeFavourite"
          >
            <v-img
              height="18"
              width="18"
              contain
              src="../../../../assets/icon-trash-white.svg"
            />
          </v-btn>
        </div>
      </div>
      <div v-else class="d-flex justify-space-between">
        <div class="w-100 pr-2">
          <BuyInStock
            :stockItem="stockItem"
            class="mt-2"
            :small="true"
          />
        </div>
        <v-btn
            color="success"
            class="mr-2"
            small
            title="Remove favourite"
            @click="removeFavourite"
          >
            <v-img
              height="18"
              width="18"
              contain
              src="../../../../assets/icon-trash-white.svg"
            />
          </v-btn>
      </div>
      <div class="pr-2">
        <v-btn
          v-if="showAlternative"
          block
          color="accent"
          height="28"
          :to="categoryLink"
          class="mt-2 font-small black--text mr-2"
        >
          View alternatives
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { mdiTrashCan } from '@mdi/js';
import BuyInStock from './BuyInStock.vue';
import QuantityButtonSmall from './QuantityButtonSmall.vue';
import cartMethods from '../mixins/cartMethods';

export default {
  name: 'MobileFavouriteStockItem',
  mixins: [cartMethods],
  components: {
    BuyInStock,
    QuantityButtonSmall,
  },
  props: {
    amount: {
      type: Number,
      default: 1,
    },
    isCheckout: {
      type: Boolean,
      default: false,
    },
    stockItem: {
      type: Object,
      default: () => {},
    },
    showAlternative: {
      type: Boolean,
      default: true,
    },
    showFavourites: {
      type: Boolean,
      default: false,
    },
    showRemove: {
      type: Boolean,
      default: false,
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      localAmount: undefined,
      mdiTrashCan,
    };
  },
  computed: {
    ...mapGetters('stockItems', ['getStockItemDetail']),
    ...mapGetters('cart', ['getQuantity']),
    ...mapState('customers', ['activeCustomer']),
    ...mapState('favourites', ['favourites']),
    categoryLink() {
      let categoryId = 0;
      let depth = 0;
      if (this.stockItem.webCategory4Id) {
        categoryId = this.stockItem.webCategory4Id;
        depth = 4;
      } else if (this.stockItem.webCategory3Id) {
        categoryId = this.stockItem.webCategory3Id;
        depth = 3;
      } else if (this.stockItem.webCategory2Id) {
        categoryId = this.stockItem.webCategory2Id;
        depth = 2;
      } else if (this.stockItem.webCategory1Id) {
        categoryId = this.stockItem.webCategory1Id;
        depth = 1;
      }
      return {
        name: 'shop-ShopStockItemByCategory',
        query: {
          category: categoryId,
          categoryName: 'Alternatives',
          depth,
        },
      };
    },
    detail() {
      return this.getStockItemDetail(this.stockItem);
    },
    favourite() {
      const { favouriteName, favouriteIdentifier } = this;
      return this.favourites
        .find((f) => f.identifier === favouriteIdentifier
          && f.name === favouriteName);
    },
    favouriteName() {
      const { activeCustomer } = this;
      return `stockItem-${activeCustomer.customerCode}`;
    },
    favouriteIdentifier() {
      const { stockItem } = this;
      return `${stockItem.itemCode}`;
    },
    limit() {
      const { detail } = this;
      if (detail.limit) {
        return detail.limit.remaining;
      }
      return 9999999;
    },
    total() {
      return this.detail.price * this.getQuantity(this.stockItem);
    },
  },
  watch: {
    amount() {
      this.localAmount = this.amount;
    },
  },
  mounted() {
    this.localAmount = this.amount;
  },
  methods: {
    ...mapActions('favourites', ['DELETE_favourite']),
    ...mapMutations('cart', ['cart_UPDATE']),
    async add() {
      const { stockItem, localAmount } = this;
      if (this.isInCart) {
        await this.$nextTick();
        const result = await this.$confirm('This item is already in your cart, are you sure you want to add more?');
        if (result) {
          this.$emit('add', { stockItem, amount: localAmount });
          this.localAmount = 1;
        }
      } else {
        this.$emit('add', { stockItem, amount: localAmount });
        this.localAmount = 1;
      }
    },
    async removeFavourite() {
      const { favourite } = this;
      if (!favourite) {
        return;
      }
      const result = await this.$confirm('Are you sure you want to remove this item from your favourites?');
      if (!result) {
        return;
      }
      await this.DELETE_favourite(favourite);
    },
    update(value) {
      this.cart_UPDATE({
        stockItem: this.stockItem,
        amount: value,
      });
    },
  },
};
</script>
<style scoped>
.align-mobile-bottom {
  margin-top: 25px;
}
</style>
