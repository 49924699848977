<template>
  <v-container fluid>
    <Breadcrumbs :history="history" />
    <v-skeleton-loader
      :loading="isLoading"
      transition="expand-transition"
      type="article"
    >
      <FormPurchaseLimit
        :PurchaseLimit="editPurchaseLimit"
        :isSaving="isSaving"
        @save="save"
        @cancel="$router.push({name: from.name })" />
    </v-skeleton-loader>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormPurchaseLimit from '../components/FormPurchaseLimit.vue';

export default {
  components: {
    FormPurchaseLimit,
  },
  name: 'EditPurchaseLimit',
  module: 'Shop',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing PurchaseLimit. Default to {}.
      */
      editPurchaseLimit: {},
      /**
       * Query Id for PurchaseLimit
       */
      purchaseLimitId: this.$route?.query.id,
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Purchase Limits',
        disabled: false,
        href: '/#/shop/list-purchase-limit',
        depth: 0,
      }, {
        text: 'Edit',
        disabled: true,
      }],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('purchaseLimits', ['purchaseLimits', 'purchaseLimit']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.purchaseLimitId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_purchaseLimit(this.purchaseLimitId);
      this.editPurchaseLimit = this.purchaseLimit;
    }
    await Promise.all([
      (async () => {
        await this.LOAD_customersMin();
      })(),
      (async () => {
        await this.LOAD_stockItemsMin();
      })(),
    ]);
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('purchaseLimits', ['LOAD_purchaseLimit', 'CREATE_purchaseLimit', 'UPDATE_purchaseLimit']),
    ...mapActions('customers', ['LOAD_customersMin']),
    ...mapActions('stockItems', ['LOAD_stockItemsMin']),
    /** Method used to save the item
     */
    async save(purchaseLimit) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_purchaseLimit(purchaseLimit);
      } else {
        await this.UPDATE_purchaseLimit(purchaseLimit);
      }
      this.isSaving = false;
      this.$router.push({ name: 'shop-ListPurchaseLimit' });
    },
  },
};
</script>

<docs>
# EditPurchaseLimit example

'''js
<EditPurchaseLimit :PurchaseLimit="PurchaseLimit" />
'''
</docs>
