<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title primary-title>
            {{ title }}
          </v-card-title>
          <v-card-text>
            <v-skeleton-loader
              :loading="isLoading"
              transition="expand-transition"
              type="article"
            >
              <FormDivision
                :Division="editDivision"
                :isSaving="isSaving"
                @save="save"
                @cancel="$router.push({ name: 'shop-ListDivision' })" />
            </v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormDivision from '../components/FormDivision.vue';

export default {
  components: {
    FormDivision,
  },
  name: 'EditDivision',
  module: 'Division',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing Division. Default to {}.
      */
      editDivision: {},
      /**
       * Query Id for Division
       */
      divisionId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('divisions', ['divisions', 'division']),
    ...mapState('customers', ['activeCustomer']),
    title() {
      if (this.mode === 'add') {
        return 'New Division';
      }
      return 'Edit Division';
    },
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.divisionId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_division(this.divisionId);
      this.editDivision = this.division;
    } else {
      this.editDivision = {
        customerCode: this.activeCustomer?.customerCode,
      };
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('divisions', ['LOAD_division', 'CREATE_division', 'UPDATE_division']),
    /** Method used to save the item
     */
    async save(division) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_division(division);
      } else {
        await this.UPDATE_division(division);
      }
      this.isSaving = false;
      this.$router.push({ name: 'shop-ListDivision' });
    },
  },
};
</script>

<docs>
# EditDivision example

'''js
<EditDivision :Division="Division" />
'''
</docs>
