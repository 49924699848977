<template>
  <span>
    {{ displayUsers }}
  </span>
</template>

<script>
import { mdiMail } from '@mdi/js';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'DisplayUsers',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mdiMail,
      localUsers: [],
    };
  },
  computed: {
    ...mapState('userManagement', ['users']),
    selectedUsers: {
    // getter
      get() {
        const { localUsers, value } = this;
        if (value?.length > 0 && this.localUsers?.length > 0) {
          const arr = value.split(';');
          const result = arr.map((a) => {
            const user = localUsers.find((u) => u.id === a);
            return user;
          });
          return result;
        }
        return [];
      },
      // setter
      set(newValue) {
        const arr = newValue.map((v) => v.id);
        const value = arr.join(';');
        this.$emit('input', value);
      },
    },
    displayUsers() {
      return this.selectedUsers?.map((u) => u.email).join(', ') ?? '';
    },
  },
  async mounted() {
    await this.loadUsers();
    await this.$nextTick();
    this.localUsers = this.users;
  },
  methods: {
    ...mapActions('userManagement', ['loadUsers']),
    remove(item) {
      this.localUsers.splice(this.localUsers.indexOf(item), 1);
      this.localUsers = [...this.localUsers];
    },
  },
};
</script>
