import { useAuth } from '@/use';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('tax', 'taxes');

const customActions = {
  async LOAD_taxes({ commit, rootGetters }) {
    const token = await useAuth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('taxes')
      .query({ $filter: 'IsDeleted eq false' });
    commit('SET_taxes', data);
    return data;
  },
};

export default { ...actions, ...customActions };
