<template>
 <v-navigation-drawer
    v-model="localDrawer"
    app
    color="primary"
    :permanent="!$vuetify.breakpoint.mdAndDown"
  >
    <v-list-item :to="{ name: 'shop-ShopHome' }">
      <v-list-item-content>
        <img src="@/assets/nav-logo.png" width="100%" />
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item>
      <v-list-item-content>
        <active-customer v-if="showCustomerSelector" />
        <active-restaurant-admin v-else />
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <!-- prefetch all menu icons -->
    <div style="display: none">
      <div v-for="menuItem in menuItems" :key="menuItem.name">
        <img :src="menuItem.icon" />
        <img :src="menuItem.iconSelected" />
      </div>
    </div>
    <MenuDrawer @close="$emit('close')" />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import ActiveCustomer from '../modules/shop/src/components/ActiveCustomer.vue';
import ActiveRestaurantAdmin from '../modules/shop/src/components/ActiveRestaurantAdmin.vue';
import MenuDrawer from '../components/MenuDrawer.vue';
import menuItems from '../menuItems';

export default {
  name: 'NavDrawer.vue',
  mixins: [menuItems],
  components: {
    MenuDrawer,
    ActiveCustomer,
    ActiveRestaurantAdmin,
  },
  props: {
    drawer: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localDrawer: this.drawer,
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
    showCustomerSelector() {
      return this.isInRole('Agents') || this.isInRole('Administrators');
    },
  },
  watch: {
    drawer() {
      this.localDrawer = this.drawer;
    },
  },
};
</script>
