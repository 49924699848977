import { useAuth } from '@/use';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('deliveryDate', 'deliveryDates');

const customActions = {
  async LOAD_deliveryDatesSafe({ commit, rootGetters, state }) {
    const token = await useAuth.getAccessToken();
    if (state.deliveryDates?.length === 0) {
      const data = await odata.o(rootGetters, token)
        .get('deliveryDates')
        .query({ $filter: 'IsDeleted eq false' });
      commit('SET_deliveryDates', data);
      return data;
    }

    return state.deliveryDates;
  },
};

export default { ...actions, ...customActions };
