<template>
  <v-container fluid>
    <Breadcrumbs :history="history" />
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      class="elevation-1"
      :loading="isLoading"
      sort-by="created"
      sort-desc
      show-select
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 50, 100, 250, 500, 1000, -1],
        'items-per-page-text': $t('common.itemsPerPage'),
        'show-current-page': true,
        'show-first-last-page': true,
        'show-previous-next-page': true,
      }"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-row dense>
            <v-col cols="4">
              <div class="d-flex">
                <v-dialog v-model="dialog" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                      >
                        Import
                      </v-btn>
                    </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Import Favourites</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="6">
                            <v-autocomplete
                              v-model="importCustomers"
                              multiple
                              :items="customers"
                              item-text="customerDesc"
                              item-value="customerCode"
                              label="Customer(s)"
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-autocomplete
                              v-model="importStockItem"
                              :items="stockItems"
                              item-text="description"
                              item-value="itemCode"
                              label="Stock Item"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn @click="dialog = false" text>Cancel</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="importFavourites" color="primary">Import</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogReplace" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :disabled="selected.length === 0"
                        color="primary"
                        class="ml-2"
                      >
                      Replace All
                      <span v-if="itemsToReplace.length > 0">
                        ({{ itemsToReplace.length }})
                      </span>
                      </v-btn>
                    </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Replace Favourites</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="6">
                            <v-autocomplete
                              v-model="replaceStockItem"
                              :items="stockItems"
                              item-text="description"
                              item-value="itemCode"
                              label="Stock Item"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn @click="dialogReplace = false" text>Cancel</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="replaceAll" color="primary">Replace</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-btn
                  color="success"
                  class="ml-2"
                  :disabled="selected.length === 0"
                  data-cy="StockItemAlternative-button-deleteAll"
                  @click="removeAll"
                >
                  <v-icon class="mr-2">
                    {{ mdiTrashCan }}
                  </v-icon>
                  Delete All
                  <span v-if="itemsToRemove.length > 0">
                    ({{ itemsToRemove.length }})
                  </span>
                </v-btn>
                <v-btn
                  color="primary"
                  class="ml-2"
                  @click="exportFavourites"
                >
                  Export
                </v-btn>
              </div>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="selectedCustomer"
                :disabled="selectedStockItem?.length > 0"
                clearable
                :items="customers"
                item-text="customerDesc" item-value="customerCode" label="Filter by Customer"
              />
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="selectedStockItem"
                :disabled="selectedCustomer?.length > 0"
                clearable
                :items="stockItems"
                item-text="description" item-value="itemCode" label="Filter by Stock Item"
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.name="{ item }">
        {{ getCustomerName(item.name) }}
      </template>
      <template v-slot:item.identifier="{ item }">
        {{ getItemName(item.identifier) }}
      </template>
      <template v-slot:item.created="{ item }">
        {{ formatDateTime(item.created) }}
      </template>
        <template v-slot:item.actions="{ item }">
          <v-btn small color="error" @click="remove(item)">Remove</v-btn>
        </template>
    </v-data-table>
</v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus, mdiTrashCan } from '@mdi/js';
import axios from 'axios';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'AdminFavourites',
  module: 'Shop',
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      mdiTrashCan,
      dialog: false,
      dialogReplace: false,
      favourites: [],
      importCustomers: [],
      importStockItem: undefined,
      replaceStockItem: undefined,
      selected: [],
      selectedCustomer: undefined,
      selectedStockItem: undefined,
      itemsToImport: [],
      itemsToRemove: [],
      itemsToReplace: [],
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Favourites Admin',
        disabled: true,
        depth: 0,
      }],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('customers', ['customers']),
    ...mapState('stockItems', ['stockItems']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: 'Stock Item',
          value: 'identifier',
        },
        {
          text: this.$$t('customer'),
          value: 'name',
        },
        {
          text: 'Created by',
          value: 'createdById',
        },
        {
          text: this.$$t('created'),
          value: 'created',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
    items() {
      if (this.selectedCustomer) {
        return this.favourites
          .filter((f) => f.name.includes(this.selectedCustomer));
      }

      if (this.selectedStockItem) {
        return this.favourites
          .filter((f) => f.identifier === this.selectedStockItem);
      }
      return this.favourites;
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('stockItemAlternatives', ['LOAD_stockItemAlternatives', 'DELETE_stockItemAlternative']),
    ...mapActions('customers', ['LOAD_customersMin']),
    ...mapActions('favourites', ['CREATE_favourite', 'DELETE_favourite', 'UPDATE_favourite']),
    ...mapActions('stockItems', ['LOAD_stockItemsMin']),
    /** Delete StockItemAlternative from the collection
     */
    async remove(favourite) {
      this.isLoading = true;
      await this.DELETE_favourite(favourite);
      this.isLoading = false;
      await this.refresh();
    },
    async refresh() {
      this.isLoading = true;
      await Promise.all([
        (async () => {
          await this.LOAD_customersMin();
        })(),
        (async () => {
          await this.LOAD_stockItemsMin();
        })(),
        (async () => {
          const { data } = await this.$http.get('api/favourites');
          this.favourites = data;
        })(),
      ]);
      this.isLoading = false;
    },
    getCustomerName(name) {
      const names = name.split('-');
      if (names.length !== 2) {
        return 'Invalid customer';
      }
      const customer = this.customers.find((c) => c.customerCode === names[1]);
      return customer ? customer.customerDesc : 'Customer not found';
    },
    getItemName(itemCode) {
      const item = this.stockItems.find((i) => i.itemCode === itemCode);
      return item ? item.description : 'Item not found';
    },
    async removeAll() {
      const res = await this.$confirm(`Are you sure you want to delete all (${this.selected.length}) selected items?`);
      if (!res) {
        return;
      }
      this.dialog = false;
      try {
        this.isLoading = true;
        this.itemsToRemove = this.selected;
        await Promise.all(this.selected.map(async (item) => {
          await this.DELETE_favourite(item);
          this.itemsToRemove = this.itemsToRemove.filter((i) => i.id !== item.id);
        }));
      } catch (e) {
        this.$error(e);
      } finally {
        this.isLoading = false;
        this.itemsToRemove = [];
      }
      await this.refresh();
    },
    async replaceAll() {
      const res = await this.$confirm(`Are you sure you want to replace all (${this.selected.length}) selected items?`);
      if (!res) {
        return;
      }
      this.dialogReplace = false;
      try {
        this.isLoading = true;
        this.itemsToReplace = this.selected;
        const { replaceStockItem } = this;
        await Promise.all(this.selected.map(async (item) => {
          try {
            await this.DELETE_favourite(item);
            const updatedItem = { ...item };
            updatedItem.identifier = replaceStockItem;
            await this.CREATE_favourite(updatedItem);
            this.itemsToReplace = this.itemsToReplace.filter((i) => i.id !== item.id);
          } catch (e) {
            this.$log.error(e);
          }
        }));
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.isLoading = false;
        this.itemsToReplace = [];
      }
      await this.refresh();
    },
    async exportFavourites() {
      try {
        this.isLoading = true;
        const token = this.$auth.getToken();
        let url = 'api/favourites/export';
        if (this.selectedCustomer) {
          url += `?customer=${this.selectedCustomer}`;
        }
        if (this.selectedStockItem) {
          url += `?stockCode=${this.selectedStockItem}`;
        }
        const response = await axios({
          url,
          method: 'GET',
          responseType: 'blob', // important
          headers: {
            Authorization: `Bearer ${token.access_token}`,
          },
        });
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'favourites.xlsx');
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        this.$error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async importFavourites() {
      try {
        this.isLoading = true;
        this.itemsToImport = this.importCustomers;
        await Promise.all(this.importCustomers.map(async (customer) => {
          await this.CREATE_favourite({
            name: `stockItem-${customer}`,
            identifier: this.importStockItem,
          });
          this.itemsToImport = this.itemsToImport
            .filter((i) => i.customerCode !== customer.customerCode);
        }));
      } catch (e) {
        this.$error(e);
      } finally {
        this.isLoading = false;
        this.dialog = false;
        this.itemsToImport = [];
      }
      await this.refresh();
    },
  },
};
</script>

<docs>
# StockItemAlternative example

'''js
<StockItemAlternative />
'''
</docs>
