<template>
  <v-data-table
    :headers="headers"
    :items="categories"
    class="elevation-1"
    :loading="isLoading"
  >
    <template v-slot:item.photo="{ item }">
      <v-img :src="item.photo" contain height="128" />
    </template>
    <template v-slot:item.actions="{ item }">
      <TableBasicButtons :editLink="{
          name: 'shop-EditCategory',
          query: {id: item.id}
        }"
        @delete="remove(item)"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiDelete, mdiPencil } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListCategory',
  module: 'Category',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      mdiDelete,
      mdiPencil,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('categories', ['categories']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: '',
          value: 'photo',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_categories();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('categories', ['LOAD_categories', 'DELETE_category']),
    /** Delete Category from the collection
     */
    async remove(Category) {
      this.isLoading = true;
      await this.DELETE_Category(Category);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Category example

'''js
<Category />
'''
</docs>
