<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import Blank from './layouts/Blank.vue';
import Empty from './layouts/Empty.vue';
import Minimal from './layouts/Minimal.vue';
import Desktop from './layouts/Desktop.vue';
import Mobile from './layouts/Mobile.vue';

export default {
  name: 'App',
  components: {
    Blank,
    Empty,
    Minimal,
    Desktop,
    Mobile,
  },
  computed: {
    resolveLayout() {
      // defaulting to minimal layout when the route is not defined
      if (this.$route.meta?.minimalLayout || !this.$route?.name) {
        this.$log.info('loading minimal layout');
        return 'Minimal';
      }
      if (this.$route.meta?.emptyLayout) {
        this.$log.info('loading empty layout');
        return 'Empty';
      }
      if (this.$route.meta?.blankLayout) {
        this.$log.info('loading blank layout');
        return 'Blank';
      }
      if (this.$vuetify.breakpoint.mobile) {
        return 'Mobile';
      }
      this.$log.info('loading desktop layout');
      return 'Desktop';
    },
  },
  async created() {
    // Show the splash for an indefinite amount of time:
    // await SplashScreen.show({
    //   autoHide: false,
    // });
    this.$root.$on('organizations.loaded', (organizations) => {
      if (organizations.length === 0 && this.isLoggedIn) {
        this.$router.push({
          name: 'auth-CreateOrg',
        });
      }
    });
    this.$root.$on('auth:logoff', () => {
      this.LOGOUT_auth();
      this.SET_customer(undefined);
      this.SET_activeCustomer(undefined);
      this.SET_username(undefined);
      if (this.$route.path !== '/auth/login') {
        this.$router.push('/auth/login');
      }
    });
    this.$root.$on('auth:login', async (user) => {
      const authToken = JSON.parse(window.localStorage.getItem('auth-token'));
      this.SET_username(user.username);
      this.SET_roles(authToken.roles);
      this.$router.push({
        name: 'app-Init',
      });
      this.$root.$emit('toast:notify', 'Logged in successfully');
    });
  },
  destroyed() {
    this.$root.$off('auth:login');
    this.$root.$off('auth:logoff');
  },
  methods: {
    ...mapActions('auth', ['INIT_auth', 'LOGOUT_auth']),
    ...mapMutations('auth', ['SET_username', 'SET_roles']),
    ...mapMutations('organizations', ['SET_organization']),
    ...mapMutations('customers', ['SET_customer', 'SET_activeCustomer']),
  },
};
</script>
