<template>
  <v-container fluid>
    <Breadcrumbs :history="history" />
    <v-data-table
      :headers="headers"
      :items="costCentres"
      class="elevation-1"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-btn
            color="success"
            data-cy="CostCentre-button-new"
            @click="$router.push({
              name: 'shop-EditCostCentre',
              query: {customerCode: $route.query.customerCode}
            })"
          >
            <v-icon class="mr-2">
              {{ mdiPlus }}
            </v-icon>
            {{ $t('common.buttons.new') }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.requiresApproval="{ item }">
          <v-checkbox
            disabled
            data-cy="FormCostCentre-requiresApproval"
            v-model="item.requiresApproval"
          />
      </template>
      <template v-slot:item.approvers="{ item }">
        <display-users v-model="item.approvers" />
      </template>
      <template v-slot:item.buyers="{ item }">
        <display-users v-model="item.buyers" />
      </template>
      <template v-slot:item.actions="{ item }">
        <TableBasicButtons :editLink="{
            name: 'shop-EditCostCentre',
            query: {id: item.id, customerCode: $route.query.customerCode}
          }"
          @delete="remove(item)"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiArrowLeft, mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import DisplayUsers from '../components/DisplayUsers.vue';

export default {
  name: 'ListCostCentre',
  module: 'CostCentre',
  components: {
    DisplayUsers,
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiArrowLeft,
      mdiPlus,
      costCentres: [],
      /**
      * Breadcrumbs
      */
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: `Customer (${this.$route.query.customerCode})`,
        disabled: false,
        href: '/#/admin/manage-customers',
        depth: 0,
      }, {
        text: 'Cost Centres',
        disabled: true,
        depth: 0,
      }],
    };
  },
  computed: {
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: 'Requires Approval',
          value: 'requiresApproval',
        },
        {
          text: this.$$t('approvers'),
          value: 'approvers',
        },
        {
          text: this.$$t('buyers'),
          value: 'buyers',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async created() {
    await this.refresh();
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('costCentres', ['LOAD_costCentres', 'DELETE_costCentre']),
    /** Refresh the collection
     */
    async refresh() {
      this.isLoading = true;
      this.costCentres = await this.LOAD_costCentres(this.$route.query.customerCode);
      this.isLoading = false;
    },
    /** Delete CostCentre from the collection
     */
    async remove(costCentre) {
      this.isLoading = true;
      await this.DELETE_costCentre(costCentre.id);
      this.isLoading = false;
      await this.refresh();
    },
  },
};
</script>

<docs>
# CostCentre example

'''js
<CostCentre />
'''
</docs>
