<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
      <v-skeleton-loader
        :loading="isLoading"
        transition="expand-transition"
        type="article"
      >
        <FormSoftSaleStockItem
          :SoftSaleStockItem="editSoftSaleStockItem"
          :isSaving="isSaving"
          @save="save"
          @cancel="$router.push({
            name: 'shop-ListSoftSaleStockItem',
            query: { customerCode: editSoftSaleStockItem.customerCode },
          })" />
      </v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormSoftSaleStockItem from '../components/FormSoftSaleStockItem.vue';

export default {
  components: {
    FormSoftSaleStockItem,
  },
  name: 'EditSoftSaleStockItem',
  module: 'Shop',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing SoftSaleStockItem. Default to {}.
      */
      editSoftSaleStockItem: {
        customerCode: this.$route?.query.customerCode,
      },
      /**
       * Query Id for SoftSaleStockItem
       */
      softSaleStockItemId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('softSaleStockItems', ['softSaleStockItems', 'softSaleStockItem']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.softSaleStockItemId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    await Promise.all([
      (async () => {
        await this.LOAD_customersMin();
      })(),
      (async () => {
        await this.LOAD_stockItemsMin();
      })(),
    ]);
    if (this.mode === 'edit') {
      await this.LOAD_softSaleStockItem(this.softSaleStockItemId);
      this.editSoftSaleStockItem = this.softSaleStockItem;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('softSaleStockItems', ['LOAD_softSaleStockItem', 'CREATE_softSaleStockItem', 'UPDATE_softSaleStockItem']),
    ...mapActions('customers', ['LOAD_customersMin']),
    ...mapActions('stockItems', ['LOAD_stockItemsMin']),
    /** Method used to save the item
     */
    async save(data) {
      const { selectedStockItems, selectedCustomers } = data;
      this.isSaving = true;
      for (let index = 0; index < selectedCustomers.length; index += 1) {
        const customerCode = selectedCustomers[index];
        for (let i = 0; i < selectedStockItems.length; i += 1) {
          const itemCode = selectedStockItems[i];
          // eslint-disable-next-line no-await-in-loop
          await this.CREATE_softSaleStockItem({
            customerCode,
            itemCode,
          });
        }
      }
      this.isSaving = false;
      this.$router.push({ name: 'shop-SalesDashboard', query: { customerCode: this.editSoftSaleStockItem.customerCode } });
    },
  },
};
</script>

<docs>
# EditSoftSaleStockItem example

'''js
<EditSoftSaleStockItem :SoftSaleStockItem="SoftSaleStockItem" />
'''
</docs>
