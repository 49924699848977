<template>
  <v-select
    v-if="divisions.length > 0"
    clearable
    flat
    hide-details
    item-value="id"
    item-text="name"
    v-model="divisionId"
    :items="divisions"
    label="Departments"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'DivisionFilter',
  mixins: [loading],
  computed: {
    ...mapState('divisions', ['divisions']),
    divisionId: {
      get() {
        return this.$store.state.app.divisionId;
      },
      set(newValue) {
        this.$store.commit('app/SET_divisionId', newValue);
      },
    },
  },
  async mounted() {
    await this.LOAD_divisions();
  },
  methods: {
    ...mapActions('divisions', ['LOAD_divisions']),
  },
};
</script>
