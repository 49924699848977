import { sortBy } from 'lodash';
import { useAuth } from '@/use';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('webCategory1', 'webCategory2', 'webCategory3', 'webCategory4');

const getData = async (rootGetters, commit, $db, name, query) => {
  const token = await useAuth.getAccessToken();
  const localData = await $db.getAll(name);
  if (localData?.length > 0) {
    commit(`SET_${name}`, sortBy(localData, 'name'));
    return localData;
  }
  const data = await odata.o(rootGetters, token)
    .get(name)
    .query(query);
  commit(`SET_${name}`, data);
  return data;
};

const customActions = {
  async LOAD_webCategory1({ rootGetters, commit }) {
    await getData(rootGetters, commit, this.$db, 'webCategory1', { $orderby: 'name' });
  },
  async LOAD_webCategory2({ rootGetters, commit }) {
    getData(rootGetters, commit, this.$db, 'webCategory2', { $orderby: 'name' });
  },
  async LOAD_webCategory2ByParent({ rootGetters, commit }) {
    getData(rootGetters, commit, this.$db, 'webCategory2', { $orderby: 'name' });
  },
  async LOAD_webCategory3({ rootGetters, commit }) {
    getData(rootGetters, commit, this.$db, 'webCategory3', { $orderby: 'name' });
  },
  async LOAD_webCategory4({ rootGetters, commit }) {
    getData(rootGetters, commit, this.$db, 'webCategory4', { $orderby: 'name' });
  },
  async UPDATE_stockItemsByWebCategory1({ rootGetters }, data) {
    const i = odata.parseId(data.id);
    await odata.o(rootGetters)
      .patch(`webCategory1(${i})`, data)
      .query();
  },
  async UPDATE_stockItemsByWebCategory2({ rootGetters }, data) {
    const i = odata.parseId(data.id);
    await odata.o(rootGetters)
      .patch(`webCategory2(${i})`, data)
      .query();
  },
  async UPDATE_stockItemsByWebCategory3({ rootGetters }, data) {
    const i = odata.parseId(data.id);
    await odata.o(rootGetters)
      .patch(`webCategory3(${i})`, data)
      .query();
  },
  async UPDATE_stockItemsByWebCategory4({ rootGetters }, data) {
    const i = odata.parseId(data.id);
    await odata.o(rootGetters)
      .patch(`webCategory4(${i})`, data)
      .query();
  },
};

export default { ...actions, ...customActions };
