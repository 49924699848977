import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('deliveryOrder', 'deliveryOrders');

const customActions = {
  async LOAD_deliveryOrders({ commit, rootGetters }, deliveryId) {
    const data = await odata.o(rootGetters)
      .get('deliveryOrders')
      .query({ $filter: `IsDeleted eq false AND DeliveryId eq ${deliveryId}` });
    commit('SET_deliveryOrders', data);
    return data;
  },
};

export default { ...actions, ...customActions };
