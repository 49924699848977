<template>
  <div class="card-container d-flex mb-5" style="overflow-x: scroll">
    <v-card
      class="shop-card mr-2 fill-height" rounded="0" elevation="1"
      v-for="(c, index) in products" :key="c.id">
      <v-img
        :data-cy="`category-link-${index}`"
        style="cursor: pointer;"
        :src="c.photoUrl"
        @click="selectCategory(category)"
        class="d-flex align-end"
        height="150px"
        stretch
      >
        <v-card-title @click="selectCategory(category)">
          {{ c.name }}
        </v-card-title>
      </v-img>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import categories from '../mixins/categories';

export default {
  name: 'ProductCardList',
  mixins: [categories],
  props: {
    category: {
      type: Object,
      required: true,
    },
    depth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      products: [],
      stockItems: [],
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('stockItems', [
      'LOAD_stockItemsByWebCategory1',
      'LOAD_stockItemsByWebCategory2',
      'LOAD_stockItemsByWebCategory3',
      'LOAD_stockItemsByWebCategory4',
      'LOAD_stockItemsWithNoCategory']),
    ...mapActions('webCategories', [
      'LOAD_webCategory1',
      'LOAD_webCategory2',
      'LOAD_webCategory3',
      'LOAD_webCategory4',
    ]),
    async refresh() {
      const { category, depth } = this;
      this.products = await this.LOAD_categoryProducts({ category, depth });
    },
    selectCategory(category) {
      this.$emit('input', category);
    },
  },
};
</script>
