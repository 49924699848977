<template>
  <v-container fluid>
    <Breadcrumbs :history="history" />
    <v-card>
      <v-card-text>
        <v-skeleton-loader
          :loading="isLoading"
          transition="expand-transition"
          type="article"
        >
          <FormDeliveryDate
            :DeliveryDate="editDeliveryDate"
            :isSaving="isSaving"
            @save="save"
            @cancel="$router.push({name: 'shop-ListDeliveryDate' })" />
        </v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormDeliveryDate from '../components/FormDeliveryDate.vue';

export default {
  components: {
    FormDeliveryDate,
  },
  name: 'EditDeliveryDate',
  module: 'Shop',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing DeliveryDate. Default to {}.
      */
      editDeliveryDate: {},
      /**
       * Query Id for DeliveryDate
       */
      deliveryDateId: this.$route?.query.id,
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Delivery Dates',
        href: '/#/shop/list-delivery-date',
        disabled: false,
        depth: 0,
      }, {
        text: 'Edit',
        disabled: true,
        depth: 0,
      }],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('deliveryDates', ['deliveryDates', 'deliveryDate']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.deliveryDateId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_deliveryDate(this.deliveryDateId);
      this.editDeliveryDate = this.deliveryDate;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('deliveryDates', ['LOAD_deliveryDate', 'CREATE_deliveryDate', 'UPDATE_deliveryDate']),
    /** Method used to save the item
     */
    async save(deliveryDate) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_deliveryDate(deliveryDate);
      } else {
        await this.UPDATE_deliveryDate(deliveryDate);
      }
      this.isSaving = false;
      this.$router.push({ name: 'shop-ListDeliveryDate' });
    },
  },
};
</script>

<docs>
# EditDeliveryDate example

'''js
<EditDeliveryDate :DeliveryDate="DeliveryDate" />
'''
</docs>
