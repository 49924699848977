<template>
  <div>
    <Breadcrumbs :history="history" />
    <card-list :items="items" />
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mdiArrowLeft } from '@mdi/js';
import CardList from '../components/CardList.vue';

export default {
  components: { CardList },
  name: 'Orders',
  data() {
    return {
      mdiArrowLeft,
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Orders',
        disabled: true,
        depth: 0,
      }],
    };
  },
  computed: {
    items() {
      return [{
        name: 'Products',
        // photo: require('../assets/photo-1605371924599-2d0365da1ae0.jpg'),
        to: 'shop-ShopCategories',
      }, {
        name: 'Favourites',
        // photo: require('../assets/photo-1590698933947-a202b069a861.png'),
        to: 'shop-Favourites',
      },
      // {
      //   name: 'History',
      //   photo: require('../assets/mr-cup-fabien-barral-o6GEPQXnqMY-unsplash.jpg'),
      //   to: 'shop-ListOrder',
      // }, {
      //   name: 'Specials',
      //   photo: require('../assets/kate-trysh-s8u1Gv2uF3o-unsplash.jpg'),
      // }, {
      //   name: 'Order Tracking',
      //   photo: require('../assets/henry-perks-BJXAxQ1L7dI-unsplash.jpg'),
      // }
      ];
    },
  },
};
</script>
