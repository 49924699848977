<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormDelivery
      :Delivery="editDelivery"
      :isSaving="isSaving"
      @add="addOrder"
      @create="create"
      @save="save"
      @cancel="$router.push({name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormDelivery from '../components/FormDelivery.vue';

export default {
  components: {
    FormDelivery,
  },
  name: 'EditDelivery',
  module: 'Shop',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing Delivery. Default to {}.
      */
      editDelivery: {},
      /**
       * Query Id for Delivery
       */
      deliveryId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('deliveries', ['deliveries', 'delivery']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.deliveryId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('deliveries', ['LOAD_delivery', 'CREATE_delivery', 'UPDATE_delivery']),
    ...mapActions('drivers', ['LOAD_drivers']),
    ...mapActions('deliveryOrders', ['CREATE_deliveryOrder', 'LOAD_deliveryOrders', 'DELETE_deliveryOrder']),
    ...mapActions('orders', ['LOAD_pickingListOrders']),
    async addOrder(order) {
      await this.CREATE_deliveryOrder({
        deliveryId: this.deliveryId,
        orderId: order.id,
        status: 'Scheduling',
      });
      await this.refresh();
    },
    async create(delivery) {
      const newDelivery = await this.CREATE_delivery(delivery);
      await this.$router.push({
        name: 'shop-EditDelivery',
        query: { id: newDelivery.id },
      });
      this.deliveryId = newDelivery.id;
      await this.refresh();
    },
    async refresh() {
      this.isLoading = true;
      if (this.mode === 'edit') {
        await this.LOAD_delivery(this.deliveryId);
        this.editDelivery = this.delivery;
        await this.LOAD_deliveryOrders(this.deliveryId);
      }
      await Promise.all([
        (async () => {
          await this.LOAD_pickingListOrders();
        })(),
        (async () => {
          await this.LOAD_drivers();
        })(),
      ]);
      this.isLoading = false;
    },
    /** Method used to save the item
     */
    async save(delivery) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_delivery(delivery);
      } else {
        await this.UPDATE_delivery(delivery);
      }
      this.isSaving = false;
      this.$router.push({ name: 'delivery-ListDelivery' });
    },
  },
};
</script>

<docs>
# EditDelivery example

'''js
<EditDelivery :Delivery="Delivery" />
'''
</docs>
