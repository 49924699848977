<template>
  <div>
    <v-btn @click="captureImage">
      <v-icon v-if="icon">{{ icon }}</v-icon>
      {{ label }}
    </v-btn>
  </div>
</template>

<script>
import { Camera, CameraResultType } from '@capacitor/camera';
import { mdiCamera } from '@mdi/js';

export default {
  props: {
    icon: {
      type: String,
      default: mdiCamera,
    },
    label: {
      type: String,
      default: 'Add Photo',
    },
  },
  methods: {
    /**
     * Uploads a base64 string as a file via HTTP POST.
     *
     * @param {string} base64String The Base64-encoded string representation of the file.
     * @param {string} mimeType The MIME type of the file (e.g., 'image/jpeg').
     * @returns {Promise<Response>} The file blob
     */
    async convertBase64File(base64String, mimeType) {
      // Convert the base64 string to a Blob
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i += 1) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });

      return blob;
    },
    async captureImage() {
      try {
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: false,
          resultType: CameraResultType.Base64,
        });
        if (image && image.base64String) {
          const blob = await this.convertBase64File(image.base64String, `image/${image.format}`);
          const file = new File([blob], 'phone-photo.png', { type: `image/${image.format}` });
          this.$emit('newImage', file);
        }
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Error capturing photo');
      }
    },
  },
};
</script>
