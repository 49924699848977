<template>
  <v-container fluid class="pa-6">
    <v-row>
      <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="2"
        class="d-flex align-stretch col-sm-padding stock-item-card"
      >
        <StockItemsLoading v-if="isLoading" />
        <StockItemCard
          :stockItem="stockItem"
          :autoFocus="true"
          @add="add" @change="update"
          :showAlternative="true"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { mdiMagnify, mdiInformationOutline } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import StockItemsLoading from '../components/StockItemsLoading.vue';
import cartMethods from '../mixins/cartMethods';
import StockItemCard from '../components/StockItemCard.vue';
import stockItemMixin from '../mixins/stockItem';

export default {
  name: 'StockItemDetail',
  mixins: [loading, cartMethods, stockItemMixin],
  components: {
    StockItemCard,
    StockItemsLoading,
  },
  data() {
    return {
      stockItem: undefined,
      itemCode: undefined,
      mdiMagnify,
      mdiInformationOutline,
    };
  },
  computed: {
    ...mapState('pricings', ['pricings']),
    history() {
      return [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Products',
        disabled: false,
        href: '/#/shop/shop-categories',
        depth: 0,
      }, {
        text: 'Search',
        disabled: true,
        href: '',
        depth: 0,
      }];
    },
  },
  async beforeRouteUpdate(to, from, next) {
    this.itemCode = this.$route?.query?.itemCode;
    next();
  },
  watch: {
    $route() {
      this.itemCode = this.$route?.query?.itemCode;
      this.refresh();
    },
  },
  async mounted() {
    this.itemCode = this.$route?.query?.itemCode;
    this.SET_title('Search');
    this.SET_expandedSearch(false);
    if (this.pricings?.length === 0) {
      await this.LOAD_pricings();
    }
    await this.refresh();
    this.$root.$on('activeCustomer:change', async () => {
      await this.LOAD_pricings();
      await this.LOAD_stockLimits();
      await this.refresh();
    });
    await this.LOAD_stockLimits();
  },
  destroyed() {
    this.$root.$off('activeCustomer:change');
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('stockItems', ['LOAD_stockItem', 'LOAD_stockLimits']),
    ...mapActions('pricings', ['LOAD_pricings']),
    ...mapMutations('app', ['SET_title', 'SET_expandedSearch']),
    /**
     * Executes refresh
     */
    async refresh() {
      this.isLoading = true;
      this.stockItem = await this.LOAD_stockItem(this.itemCode);
      this.isLoading = false;
      this.$forceUpdate();
    },
  },
};
</script>
