<template>
  <div class="home">
    <v-icon>{{ mdiHome }}</v-icon> Home Page
  </div>
</template>

<script>
import { mdiHome } from '@mdi/js';

export default {
  name: 'Home',
  data() {
    return {
      mdiHome,
    };
  },
};
</script>
