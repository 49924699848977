import { useAuth } from '@/use';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('favourite', 'favourites');

const customActions = {
  async LOAD_favourites({ commit, rootGetters }) {
    const token = await useAuth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('customerFavourites')
      .query();
    commit('SET_favourites', data);
    return data;
  },
  async DELETE_favourite({
    commit, dispatch, rootGetters, rootState,
  }, favourite) {
    const i = odata.parseId(favourite.id);
    await odata.o(rootGetters)
      .delete(`favourites(${i})`)
      .query();
    const items = rootState.stockItems.favouriteStockItems
      .filter((item) => item.itemCode !== favourite.identifier.toString());
    dispatch('LOAD_favourites');
    commit('stockItems/SET_favouriteStockItems', items, { root: true });
  },
};

export default { ...actions, ...customActions };
