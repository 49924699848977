<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormFleetSupplierBooking
      :FleetSupplierBooking="editFleetSupplierBooking"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormFleetSupplierBooking from '../components/FormFleetSupplierBooking.vue';

export default {
  components: {
    FormFleetSupplierBooking,
  },
  name: 'EditFleetSupplierBooking',
  module: 'Fleet',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing FleetSupplierBooking. Default to {}.
      */
      editFleetSupplierBooking: {},
      /**
       * Query Id for FleetSupplierBooking
       */
      fleetSupplierBookingId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('fleetSupplierBookings', ['fleetSupplierBookings', 'fleetSupplierBooking']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.fleetSupplierBookingId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_fleetSupplierBooking(this.fleetSupplierBookingId);
      this.editFleetSupplierBooking = this.fleetSupplierBooking;
    }
    await Promise.all([
      (async () => {
        await this.LOAD_fleetSuppliers();
      })(),
      (async () => {
        await this.LOAD_vehicles();
      })(),
    ]);
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('fleetSupplierBookings', ['LOAD_fleetSupplierBooking', 'CREATE_fleetSupplierBooking', 'UPDATE_fleetSupplierBooking']),
    ...mapActions('fleetSuppliers', ['LOAD_fleetSuppliers']),
    ...mapActions('vehicles', ['LOAD_vehicles']),
    /** Method used to save the item
     */
    async save(fleetSupplierBooking) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_fleetSupplierBooking(fleetSupplierBooking);
      } else {
        await this.UPDATE_fleetSupplierBooking(fleetSupplierBooking);
      }
      this.isSaving = false;
      this.$router.push({ name: 'fleet-FleetHome', tab: 4 });
    },
  },
};
</script>

<docs>
# EditFleetSupplierBooking example

'''js
<EditFleetSupplierBooking :FleetSupplierBooking="FleetSupplierBooking" />
'''
</docs>
