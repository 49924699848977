import { Capacitor } from '@capacitor/core';

export default {
  computed: {
    isIos() {
      // return true;
      return this.platform === 'ios';
    },
    platform() {
      return Capacitor.getPlatform();
    },
  },
};
