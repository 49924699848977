import Vue from 'vue';
import VueRouter from 'vue-router';
import { kebabCase } from 'case-anything';

import modules from '../modules';

const About = () => import(/* webpackChunkName: "route-About" */'../views/About.vue');
const Init = () => import(/* webpackChunkName: "route-Init" */'../views/Init.vue');
const FeedbackItems = () => import(/* webpackChunkName: "route-FeedbackItems" */'../views/FeedbackItems.vue');
const ManageCustomers = () => import(/* webpackChunkName: "route-ManageCustomers" */'../views/ManageCustomers.vue');
const Account = () => import(/* webpackChunkName: "route-Account" */'../views/Account.vue');
const Login = () => import(/* webpackChunkName: "route-Login" */'../views/Login.vue');
const Logoff = () => import(/* webpackChunkName: "route-Logoff" */'../views/Logoff.vue');
const Register = () => import(/* webpackChunkName: "route-Register" */'../views/Register.vue');
const PasswordReset = () => import(/* webpackChunkName: "route-PasswordReset" */'../views/PasswordReset.vue');
const ReportsHome = () => import(/* webpackChunkName: "route-ReportsHome" */'../views/ReportsHome.vue');
const UserManagementHome = () => import(/* webpackChunkName: "route-UserManagementHome" */'../views/UserManagementHome.vue');
const Settings = () => import(/* webpackChunkName: "route-Settings" */'../views/Settings.vue');
const Welcome = () => import(/* webpackChunkName: "route-Welcome" */'../views/Welcome.vue');
const ListIssue = () => import(/* webpackChunkName: "route-Issues" */'../views/ListIssue.vue');
const HangfireListJobs = () => import(/* webpackChunkName: "route-HangfireListJobs" */'../views/HangfireListJobs.vue');
const EventLogs = () => import(/* webpackChunkName: "route-EventLogs" */'../views/EventLogs.vue');
const ImportExport = () => import(/* webpackChunkName: "route-ImportExport" */'../views/ImportExport.vue');
const AdminPage = () => import(/* webpackChunkName: "route-AdminPage" */'../views/AdminPage.vue');
const AdminCategories = () => import(/* webpackChunkName: "route-AdminCategories" */'../views/admin/Categories.vue');
const AdminStockItems = () => import(/* webpackChunkName: "route-AdminStockItems" */'../views/admin/StockItems.vue');

Vue.use(VueRouter);

const mapViews = (prefix, views) => {
  const viewsArr = Object.keys(views)
    .filter((v) => views[v].name !== 'Home')
    .map((v) => ({
      path: `/${prefix}/${kebabCase(v)}`,
      name: `${prefix}-${v}`,
      component: views[v],
      meta: views[v].meta,
    }));
  return viewsArr;
};

const appRoutes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
    meta: {
      blankLayout: true,
    },
  },
  {
    path: '/auth/login',
    alias: '/login',
    name: 'auth-Login',
    component: Login,
    meta: {
      blankLayout: true,
    },
  },
  {
    path: '/auth/log-off',
    name: 'auth-Logoff',
    component: Logoff,
  },
  {
    path: '/my-account',
    name: 'app-Account',
    component: Account,
  },
  {
    path: '/init',
    name: 'app-Init',
    component: Init,
    meta: {
      emptyLayout: true,
    },
  },
  {
    path: '/about',
    name: 'app-About',
    component: About,
  },
  {
    path: '/auth/register',
    alias: '/register',
    name: 'auth-Register',
    component: Register,
    meta: {
      blankLayout: true,
    },
  },
  {
    path: '/auth/password-reset',
    alias: '/password-reset',
    name: 'auth-PasswordReset',
    component: PasswordReset,
  },
  {
    path: '/reports',
    name: 'reports-ReportsHome',
    component: ReportsHome,
  },
  {
    path: '/admin',
    name: 'app-Admin',
    component: AdminPage,
  },
  {
    path: '/admin/categories',
    name: 'admin-Categories',
    component: AdminCategories,
  },
  {
    path: '/admin/stock-items',
    name: 'admin-StockItems',
    component: AdminStockItems,
  },
  {
    path: '/admin/manage-customers',
    name: 'admin-ManageCustomers',
    component: ManageCustomers,
  },
  {
    path: '/admin/users',
    name: 'admin-UserManagementHome',
    component: UserManagementHome,
  },
  {
    path: '/admin/issues',
    name: 'admin-ListIssue',
    component: ListIssue,
  },
  {
    path: '/admin/feedback-items',
    name: 'admin-FeedbackItems',
    component: FeedbackItems,
  },
  {
    path: '/admin/import-export',
    name: 'admin-ImportExport',
    component: ImportExport,
  },
  {
    path: '/settings',
    name: 'app-Settings',
    component: Settings,
  },
  {
    path: '/admin/hangfire/jobs',
    name: 'admin-HangfireListJobs',
    component: HangfireListJobs,
  },
  {
    path: '/admin/event-logs',
    name: 'admin-EventLogs',
    component: EventLogs,
  },
];
const moduleRoutes = modules.map((m) => mapViews(m.config.path, m.views));

const routes = [...appRoutes, ...moduleRoutes.flat()];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  },
});

export { routes };
export { router };
