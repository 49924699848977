<template>
  <v-data-table
    :headers="headers"
    :items="softSaleStockItems"
    :loading="isLoading"
    :search="search"
    sort-by="created"
    sort-desc
  >
    <template v-slot:top>
    <v-toolbar
      flat
    >
      <v-row>
        <v-btn color="primary" :to="{ name: 'shop-EditSoftSaleStockItem'} ">
          New Soft Sale
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          :append-icon="mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-row>
    </v-toolbar>
  </template>
    <template v-slot:item.created="{ item }">
      {{ formatDateTime(item.created)}}
    </template>
    <template v-slot:item.photo="{ item }">
      <v-img :src="item.photo" contain height="128" />
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        @click="remove(item)"
        color="error"
        :data-cy="`button-ListSoftSale-remove-${item.id}`"
      >
        {{ mdiDelete }}
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiDelete, mdiPencil, mdiMagnify } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListSoftSale',
  module: 'Shop',
  mixins: [loading],
  data() {
    return {
      mdiDelete,
      mdiPencil,
      mdiMagnify,
      search: '',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('softSaleStockItems', ['softSaleStockItems']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('customerCode'),
          value: 'customerCode',
        },
        {
          text: this.$$t('itemCode'),
          value: 'itemCode',
        },
        {
          text: this.$$t('created'),
          value: 'created',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_softSaleStockItems();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('softSaleStockItems', ['LOAD_softSaleStockItems', 'DELETE_softSaleStockItem']),
    /** Delete SoftSale from the collection
     */
    async remove(softSale) {
      const result = await this.$confirm('Are you sure you want to delete this Soft Sale?');
      if (!result) {
        return;
      }
      this.isLoading = true;
      await this.DELETE_softSaleStockItem(softSale.id);
      this.isLoading = false;
    },
  },
};
</script>
