import { mapActions, mapMutations } from 'vuex';

export default {
  methods: {
    ...mapActions('stockItems', ['LOAD_stockItem', 'LOAD_stockLimits']),
    ...mapMutations('cart', ['ADD_TO_CART', 'REMOVE_CART']),
    add(item) {
      const { stockItem, amount } = item;
      this.ADD_TO_CART({ stockItem, amount });
      this.$root.$emit('toast:notify', `Added ${stockItem.description}`);
      this.LOAD_stockLimits();
    },
    remove(stockItem) {
      this.REMOVE_CART(stockItem.itemCode);
      this.$root.$emit('toast:notify', `Removed ${stockItem.description}`);
    },
    change(item) {
      this.$emit('change', item);
    },
  },
};
