import components from './components';
import config from './config';
import locales from './locales/index';
import views from './views';

export { config };
export { locales };
export { components };
export { views };

export default {
  components,
  config,
  locales,
  views,
};
