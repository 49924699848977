<template>
  <v-data-table
    class="h-100"
    :items="items"
    item-key="customerAccount"
    :headers="headers"
    sort-by="created"
    :sort-desc="true"
    :search="search"
    :loading="isLoading"
    :items-per-page="25"
    :footer-props="{ 'items-per-page-options': [15, 25, 50, 100, 500, -1] }"
  >
    <template v-slot:top>
      <v-row>
        <v-col class="d-flex align-end" offset="6" cols="6">
          <v-text-field
            class="mr-2"
            v-model="search"
            :append-icon="mdiMagnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <ExportToExcelButton name="Customers" :data="exportItems" />
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" persistent max-width="600">
        <v-card>
          <v-card-title>Copy and paste the link to the customer</v-card-title>
          <v-card-text v-if="item">
            <v-text-field
              :value="url"
              single-line
              hide-details
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click.native="dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:item.created="{ item }">
      {{ formatDate(item.created) }}
    </template>
    <template v-slot:item.emailAddress="{ item }">
      <a :href="'mailto:' + item.emailAddress">{{ item.emailAddress }}</a>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn @click="showLink(item)" color="primary" dark small>Link</v-btn>
    </template>
    <template v-slot:item.edit="{ item }">
      <CustomerEditDialog :customer="item" @save="saveCustomer" />
    </template>
    <template v-slot:item.costCentre="{ item }">
      <v-btn
        small
        color="default"
        :to="{
          name: 'shop-ListCostCentre',
          query: { customerCode: item.customerCode },
        }"
        :loading="isSaving"
      >
        Cost Centres
      </v-btn>
    </template>
    <template v-slot:item.emails="{ item }">
      <v-btn
        small
        color="default"
        :to="{
          name: 'shop-ListEmail',
          query: { customerCode: item.customerCode },
        }"
        :loading="isSaving"
      >
        Emails
      </v-btn>
    </template>
    <template v-slot:item.customerCode="{ item }">
      <div class="d-flex">
        <span>{{ item.customerCode }}</span>
        <v-icon v-if="item.hideStockOnHand" class="ml-2" title="Stock on Hand not visible">
          {{ mdiPackageVariantRemove }}
        </v-icon>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mdiMagnify, mdiPackageVariantRemove } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ExportToExcelButton from './ExportToExcelButton.vue';
import CustomerEditDialog from './CustomerEditDialog.vue';

export default {
  name: 'CustomerList',
  mixins: [loading],
  components: {
    ExportToExcelButton,
    CustomerEditDialog,
  },
  data() {
    return {
      mdiMagnify,
      mdiPackageVariantRemove,
      dialog: false,
      item: undefined,
      search: '',
      editCustomer: undefined,
    };
  },
  computed: {
    exportItems() {
      return this.items?.map((c) => ({
        'Customer Account': c.customerCode,
        'Customer Name': c.customerDesc,
        'Contact Name': c.contactName,
        'Email Address': c.emailAddress,
        Telephone: c.telephone,
        'Register Code': c.ref,
        Created: this.formatDate(c.created),
      })) ?? [];
    },
    headers() {
      const headers = [
        { text: 'Customer ID', value: 'customerId' },
        { text: 'Customer Account', value: 'customerCode' },
        { text: 'Customer Name', value: 'customerDesc' },
        { text: 'Contact Name', value: 'contactName' },
        { text: 'Email Address', value: 'emailAddress' },
        { text: 'Register Code', value: 'ref' },
        { text: 'Created', value: 'created' },
      ];
      if (this.showButtons) {
        headers.push({ value: 'actions' });
        headers.push({ value: 'costCentre' });
        headers.push({ value: 'emails' });
        headers.push({ value: 'edit' });
      }

      return headers;
    },
    url() {
      return `${window.location.origin}/auth/register?code=${this.item.ref}`;
    },
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    showLink(item) {
      this.item = item;
      this.dialog = true;
    },
    async invite(item) {
      const msg = `Are you sure you want email an invite to ${item.emailAddress}?`;
      const result = await this.$confirm(msg);
      if (result) {
        this.isLoading = true;
        try {
          await this.$http.post(`api/invites/email/${item.customerCode}`);
          this.$root.$emit('toast:notify', 'Invite sent successfully');
        } catch (error) {
          this.$log.error(error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    formatDate(date) {
      return date?.toString().substring(0, 10);
    },
    async saveCustomer(customer) {
      this.isLoading = true;
      try {
        const { hideStockOnHand, customerCode } = customer;
        await this.$http.patch(`/odata/customers/${customerCode}`, {
          hideStockOnHand,
        });
        this.$root.$emit('toast:notify', 'Customer updated successfully');
        this.$emit('refresh');
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
