import Vue from 'vue';
import axios from 'axios';
import dayjs from 'dayjs';
import VuetifyConfirm from 'vuetify-confirm';
import VuePrintNb from 'vue-print-nb';
import auroraModule from '@codehq/aurora-app-core/src/plugins/auroraModule';
import { App as capacitorApp } from '@capacitor/app';
import { components } from '@codehq/aurora-app-core';
import LocaleSwitcher from '@codehq/aurora-app-core/src/components/LocaleSwitcher.vue';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import { Auth, AuthPlugin } from '@codehq/aurora-auth';
import { Client, ClientPlugin } from '@codehq/aurora-net';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as VueGoogleMaps from 'vue2-google-maps';
import { o } from 'odata';
import { mapState } from 'vuex';
import VueMask from 'v-mask';
import vueDebounce from 'vue-debounce';
import vueShortkey from 'vue-shortkey';
import utc from 'dayjs/plugin/utc';
import database from './plugins/database';
import Breadcrumbs from './modules/shop/src/components/Breadcrumbs.vue';
import FileUpload from './components/FileUpload.vue';

import App from './App.vue';
import auroraServer from './plugins/auroraServer';
import maintenance from './maintenance.json';
import formatting from './plugins/formatting';
import authMixin from './mixins/auth-mixin';
import vueWb from './plugins/vue-wb';
import vuetify from './plugins/vuetify';
import store from './store';
import { router, routes } from './router';
import { i18n, messages } from './i18n';

// Setup API
axios.defaults.baseURL = process.env.VUE_APP_APIURL;

// PWA Service worker
if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line global-require
  const wb = require('./registerServiceWorker');
  // Setup plugins
  Vue.use(vueWb, { wb });
}

Vue.config.productionTip = false;

// register global components
Vue.use(AuthPlugin, {
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
});
Vue.use(ClientPlugin, {
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
  apiClient: 'axios',
  timeout: 30000,
});

Vue.component('Breadcrumbs', Breadcrumbs);

Vue.use(VuetifyConfirm, { vuetify });

Vue.use(VuePrintNb);

Vue.use(VueMask);

Vue.use(vueDebounce);

Vue.use(vueShortkey);

Vue.use(database, { store });

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: 'places,drawing,visualization',
  },
  installComponents: true,
});

Vue.component(FileUpload.name, FileUpload);

const { ImageUpload } = components;
// register aurora components and plugin
Vue.component(LocaleSwitcher.name, LocaleSwitcher);
Vue.component(TableBasicButtons.name, TableBasicButtons);
Vue.component(ImageUpload.name, ImageUpload);
Vue.use(auroraModule, {
  baseUrl: process.env.VUE_APP_APIURL.toString(),
  store,
});
Vue.use(auroraServer, {
  maintenance,
});

// setup store
const client = new Client({
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
  apiClient: 'axios',
  timeout: 30000,
});
store.$http = client;

const auth = new Auth({
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
});
store.$auth = auth;

dayjs.extend(utc);

// format
Vue.use(formatting);

// Setup API
o(process.env.VUE_APP_APIURL);
axios.defaults.baseURL = process.env.VUE_APP_APIURL;

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
  mixins: [authMixin],
  computed: {
    ...mapState('auth', ['token']),
  },

  i18n,
  mounted() {
    this.$log.debug('Locales:');
    this.$log.debug(messages);
    this.$log.debug('Routes:');
    this.$log.debug(routes);
    capacitorApp.addListener('backButton', () => {
      this.$log.debug('Back button pressed');
      this.$router.go(-1);
    });
  },
  methods: {
    async accept() {
      // this.showUpgradeUI = false;
      await this.$workbox.messageSW({ type: 'SKIP_WAITING' });
    },
  },
}).$mount('#app');

// Call the element loader after the app has been bootstrapped
defineCustomElements(window);
