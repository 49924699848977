<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormPickingListItem
      :PickingListItem="editPickingListItem"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormPickingListItem from '../components/FormPickingListItem.vue';

export default {
  components: {
    FormPickingListItem,
  },
  name: 'EditPickingListItem',
  module: 'Shop',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing PickingListItem. Default to {}.
      */
      editPickingListItem: {},
      /**
       * Query Id for PickingListItem
       */
      pickingListItemId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('pickingListItem', ['pickingListItem', 'pickingListItem']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.pickingListItemId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_pickingListItem(this.pickingListItemId);
      this.editPickingListItem = this.pickingListItem;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('pickingListItem', ['LOAD_pickingListItem', 'CREATE_pickingListItem', 'UPDATE_pickingListItem']),
    /** Method used to save the item
     */
    async save(pickingListItem) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_pickingListItem(pickingListItem);
      } else {
        await this.UPDATE_pickingListItem(pickingListItem);
      }
      this.isSaving = false;
      this.$router.push({ name: 'picking-list-item-ListPickingListItem' });
    },
  },
};
</script>

<docs>
# EditPickingListItem example

'''js
<EditPickingListItem :PickingListItem="PickingListItem" />
'''
</docs>
