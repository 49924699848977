import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('applicationSetting', 'applicationSettings');

const customActions = {
  async LOAD_applicationSettingsByNameSpace({ commit, rootGetters }, ns) {
    const data = await odata.o(rootGetters)
      .get('applicationSettings')
      .query({ $filter: `IsDeleted eq false AND NameSpace eq ${ns}` });
    commit('SET_applicationSettings', data);
    return data;
  },
};

export default { ...actions, ...customActions };
