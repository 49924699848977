// import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('category', 'categories');

const customActions = {
  // async LOAD_categories({ rootGetters, commit }) {
  //   commit('SET_categories', []);
  //   const data = await odata.o(rootGetters)
  //     .get('categories')
  //     .query();
  //   commit('SET_categories', data);
  //   return data;
  // },
};

export default { ...actions, ...customActions };
