/* eslint-disable max-len */
/* eslint-disable global-require */
export default {
  data() {
    return {
      menuItems: [{
        name: 'menu.home',
        icon: require('@/assets/icon-home.svg'),
        pathName: 'shop-ShopHome',
        iconSelected: require('@/assets/icon-home-selected.svg'),
        isCart: false,
      },
      {
        name: 'menu.favourites',
        pathName: 'shop-Favourites',
        icon: require('@/assets/icon-favourite.svg'),
        iconSelected: require('@/assets/icon-favourites-selected-mobile.svg'),
        isCart: false,
      },
      {
        name: 'menu.cart',
        pathName: 'shop-Cart',
        icon: require('@/assets/icon-cart.svg'),
        iconSelected: require('@/assets/icon-cart-selected-mobile.svg'),
        isCart: true,
      },
      {
        name: 'menu.profile',
        pathName: 'app-Account',
        icon: require('@/assets/icon-account.svg'),
        iconSelected: require('@/assets/icon-account-selected-mobile.svg'),
        isCart: false,
      },
      ],
    };
  },
};
