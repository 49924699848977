<template>
  <v-container fluid>
    <v-card>
      <v-card-title primary-title>
        Deliveries
      </v-card-title>
      <v-card-text>
        <v-tabs
          v-model="tab"
        >
          <v-tab>
            Schedule
          </v-tab>
          <v-tab>
            History
          </v-tab>
          <v-tab>
            Delivery Dates
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item class="mt-5">
            <v-btn class="primary" :to="{
              name: 'shop-EditDelivery',
            }">
              Schedule Delivery
            </v-btn>
          </v-tab-item>
          <v-tab-item>
          </v-tab-item>
          <v-tab-item>
            <ListDeliveryDate />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ListDeliveryDate from './ListDeliveryDate.vue';

export default {
  name: 'Deliveries',
  module: 'Shop',
  components: {
    ListDeliveryDate,
  },
  data() {
    return {
      tab: 0,
    };
  },
};
</script>
