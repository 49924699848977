<template>
  <v-container fluid>
    <StockItemsLoading v-if="isLoading" />
    <v-row class="d-flex align-center justify-center" v-if="displayCart.length === 0">
      <v-col cols="12" class="text-center">
        <img src="@/assets/icon-empty-cart.svg" alt="Empty cart" />
        <div class="text-h5 font-weight-bold">
          Your cart is empty
        </div>
        <div class="text-subtitle">
          Looks like you haven't added anything to your cart yet.
        </div>
        <v-btn
          class="black--text mt-5"
          color="accent"
          elevation="0"
          @click="$router.push({ name: 'shop-ShopCategories' })"
        >
          Continue shopping
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" lg="8">
        <TableCart :cartItems="displayCart" v-if="!$vuetify.breakpoint.mobile">
        </TableCart>
        <v-row v-else>
          <v-col cols="12">
            <div style="border-bottom: 1px solid grey;" />
          </v-col>
          <v-col cols="12"
            v-for="item in displayCart"
            :key="item.name"
          >
            <MobileStockItem
              :isCheckout="true"
              :stockItem="item.stockItem"
              :showTotal="true"
              :showAlternatives="false"
              :showRemove="true"
              @remove="removeItem(item)"
              :amount="parseFloat(item.amount, 10)"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="4">
        <v-card
          :color="$vuetify.breakpoint.mobile ? '' : 'accent'"
          :class="{ 'pa-5': !$vuetify.breakpoint.mobile }"
          flat
        >
          <v-card-title v-if="!$vuetify.breakpoint.mobile" primary-title class="text-h5">
            Checkout
          </v-card-title>
          <v-card-text
            :class="{ 'pa-0': $vuetify.breakpoint.mobile }"
          >
            <v-form
              lazy-validation
              v-model="valid"
              @submit.prevent="submit"
              ref="form">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    :solo="isDesktop"
                    :outlined="isMobile"
                    label="Order No"
                    v-model="orderNo"
                    data-cy="Cart-orderNo"
                    :rules="[rules.required]"
                    background-color="white"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <date-picker
                    label="Delivery Date"
                    v-model="deliveryDate"
                    data-cy="Cart-deliveryDate"
                    :filled="false"
                    :rules="[rules.required]"
                    :allowed-dates="allowedDates"
                    :disableEdit="true"
                    :oneWeekRule="!isAdmin"
                    :solo="isDesktop"
                    :outlined="isMobile"
                  />
                </v-col>
                <v-col v-if="activeCustomer" cols="12">
                  <DeliveryAddresses
                    v-if="activeCustomer.multipleDeliveries"
                    background-color="white"
                    :rules="[rules.required]"
                    data-cy="Cart-DeliveryAddresses"
                    @input="deliveryAddressChanged"
                    :solo="isDesktop"
                    :outlined="isMobile"
                  />
                  <DeliveryAddress
                    :solo="isDesktop"
                    :outlined="isMobile"
                    v-else
                    data-cy="Cart-DeliveryAddress"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    :solo="isDesktop"
                    :outlined="isMobile"
                    label="Notes"
                    v-model="notes"
                    hint="e.g. special delivery notes"
                    data-cy="Cart-notes"
                    :rules="[]"
                    counter="true"
                    length="2000"
                    background-color="white"
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    :solo="isDesktop"
                    :outlined="isMobile"
                    :items="activeCustomer.costCentres"
                    clearable
                    background-color="white"
                    item-text="name"
                    return-object
                    v-model="costCentre"
                    label="Cost Centre"
                    data-cy="Cart-costCentre"
                    max-width="300"
                  ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <div class="d-flex justify-space-between">
                      <span class="text-h6 font-weight-bold black--text">
                        Total
                      </span>
                      <span class="text-h6 font-weight-bold black--text">
                        R {{ formatCurrency(total_amount) }}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-btn class="float-right"
                      :disabled="isOnHold"
                      v-if="isApproval"
                      type="submit"
                      data-cy="Cart-button-quote"
                      block
                      height="48"
                      color="primary" :loading="isSaving"
                    >
                      Quote
                    </v-btn>
                    <v-btn class="float-right"
                      v-else
                      :disabled="isOnHold"
                      block
                      height="48"
                      data-cy="Cart-button-checkout"
                      color="primary" type="submit" :loading="isSaving"
                    >
                      Checkout
                    </v-btn>
                  </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mapActions, mapMutations, mapState, mapGetters,
} from 'vuex';
// import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import DatePicker from '~src/components/DatePicker.vue';
import form from '../mixins/form';
import validation from '../mixins/validation';
import DeliveryAddress from '../components/DeliveryAddress.vue';
import DeliveryAddresses from '../components/DeliveryAddresses.vue';
import TableCart from '../components/TableCart.vue';
import cart from '../mixins/cart';
import customerRefresh from '../mixins/customerRefresh';
import MobileStockItem from '../components/MobileStockItem.vue';
import StockItemsLoading from '../components/StockItemsLoading.vue';

export default {
  components: {
    DeliveryAddresses,
    DatePicker,
    TableCart,
    MobileStockItem,
    DeliveryAddress,
    StockItemsLoading,
  },
  name: 'Cart',
  mixins: [cart, customerRefresh, form, validation],
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      orderNo: '',
      deliveryAddressId: 0,
      deliveryDate: '',
      notes: '',
      costCentre: undefined,
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
    ...mapState('customers', ['activeCustomer']),
    ...mapGetters('customers', ['isOnHold']),
    ...mapState('finance', ['client']),
    ...mapState('app', ['isOffline']),
    ...mapState('deliveryAddresses', ['deliveryAddresses']),
    ...mapState('deliveryDates', ['deliveryDates']),
    isAdmin() {
      return this.isInRole('Administrators');
    },
    isDesktop() {
      return !this.$vuetify.breakpoint.mobile;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    elevation() {
      return this.$vuetify.breakpoint.mobile ? 0 : 3;
    },
    isApproval() {
      return this.costCentre?.requiresApproval ?? false;
    },
    orderCart() {
      const {
        displayCart,
      } = this;
      return displayCart.map((c) => {
        const result = { ...c };
        result.stockItem = undefined;
        result.itemCode = c.stockItem.itemCode;
        return result;
      });
    },
    order() {
      const {
        costCentre, orderNo, deliveryAddressId, deliveryDate, orderCart, notes,
      } = this;
      return {
        cart: orderCart,
        customerId: this.activeCustomer.id,
        costCentreId: costCentre?.id,
        orderNo,
        deliveryDate,
        deliveryAddressId,
        orderDetail: this.cart.map((c) => ({
          quantity: c.amount,
          productId: c.stockItem.productId,
        })),
        notes,
      };
    },
    remaining() {
      const balance = Number.parseFloat(this.client.dcBalance);
      const total = Number.parseFloat(this.total_amount);
      return balance + total;
    },
    today() {
      return new Date();
    },
  },
  async mounted() {
    this.SET_title('Cart');
    await this.refresh();
  },
  methods: {
    ...mapActions('preferredDeliveryAddresses', ['LOAD_preferredDeliveryAddresses']),
    ...mapActions('deliveryAddresses', ['LOAD_deliveryAddresses']),
    ...mapActions('deliveryDates', ['LOAD_deliveryDates']),
    ...mapActions('stockItems', ['LOAD_stockItem', 'LOAD_stockDataSafe']),
    ...mapActions('pricings', ['LOAD_pricings']),
    ...mapActions('stockItems', ['LOAD_stockLimits']),
    ...mapMutations('app', ['SET_title', 'SET_expandedSearch']),
    allowedDates(date) {
      const { deliveryDates } = this;
      const formattedDeliveryDates = deliveryDates
        ?.map((d) => this.formatDate(d.excludedDeliveryDate));
      const formattedDate = this.formatDate(date);
      return !formattedDeliveryDates.includes(formattedDate);
    },
    /**
     * Executes refresh
     */
    async refresh() {
      this.isLoading = true;
      await this.LOAD_deliveryAddresses();
      await Promise.all([
        (async () => {
          await this.LOAD_pricings();
        })(),
        (async () => {
          await this.LOAD_stockLimits();
        })(),
        (async () => {
          await this.LOAD_deliveryDates();
          // await this.LOAD_stockDataSafe();
        })(),
        (async () => {
          await this.LOAD_preferredDeliveryAddresses();
        })(),
      ]);
      const { today } = this;
      if (today.getHours() >= 3) {
        let counter = 1;
        let nextDeliveryDate = dayjs(today).add(counter, 'day');
        // check if the next day is a delivery day in the allowed dates
        // and keep adding days until it is
        while (!this.allowedDates(nextDeliveryDate.format('YYYY-MM-DD')) && counter < 30) {
          counter += 1;
          nextDeliveryDate = dayjs(today).add(counter, 'day');
        }
        this.deliveryDate = nextDeliveryDate.format('YYYY-MM-DD');
      } else {
        this.deliveryDate = this.formatDate(new Date());
      }
      // await this.LOAD_client();
      this.isLoading = false;
    },
    /**
     * Removes an item from the cart
     */
    async removeItem(item) {
      const result = await this.$confirm('Are you sure you want to remove this item from your cart?');
      if (result) {
        this.REMOVE_CART(item.stockItem.itemCode);
      }
    },
    /**
     * Executes checkout
     */
    async submit() {
      const isValid = await this.validateForm(this.$refs.form);
      let orderId;
      if (isValid) {
        const { order, isApproval } = this;
        let shouldProcess = true;
        if (this.cart.filter((s) => s.stockItem.quantity === 0).length > 0) {
          shouldProcess = await this.$confirm('There are backorder items in this cart, are you sure you want to continue?');
        }
        if (shouldProcess) {
          try {
            this.isSaving = true;
            if (isApproval) {
              await this.$http.post('api/quotes', order);
              this.$root.$emit('toast:notify', 'Quote placed successfully');
            } else {
              // if (this.isOffline) {
              //   order.id = uuidv4();
              //   orderId = order.id;
              //   await this.$db.upsert('orders', order, 'id');
              //   this.$root.$emit('toast:notify', 'Order placed successfully');
              //   this.$root.$emit('order:created');
              // } else {
              // }
              const { data } = await this.$http.post('api/orders', order);
              orderId = data.id;
              this.$root.$emit('order:created');
              this.$root.$emit('toast:notify', 'Order placed successfully');
              this.$root.$emit('order:completed');
              this.$router.push({ name: 'shop-OrderConfirmed', query: { orderId } });
            }
            this.RESET_CART();
          } catch (error) {
            this.$log.error(error);
            this.$root.$emit('network.error');
          } finally {
            this.isSaving = false;
            this.orderNo = '';
            this.deliveryDate = this.formatDate(new Date());
          }
        }
      }
    },
    /**
     * Executes deliveryAddressChanged
     */
    deliveryAddressChanged(item) {
      this.deliveryAddressId = item.deliveryAddressId;
    },
  },
};
</script>

<style scoped>
.field-qty {
  max-width: 180px;
}
.qty-col {
  min-width: 120px;
}
</style>
