import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('xmlAudit', 'xmlAudits');

const customActions = {
  async LOAD_xmlAuditForOrder({ commit, rootGetters }, orderId) {
    const data = await odata.o(rootGetters)
      .get('xmlAudits')
      .query({ $filter: `IsDeleted eq false AND OrderId eq ${orderId}` });
    commit('SET_xmlAudits', data);
    return data;
  },
};

export default { ...actions, ...customActions };
