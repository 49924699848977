<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
    <v-row>
      <v-col cols="12" md="6" sm="6" xs="6">
        <div class="headline">
          <b>Inspection Date:</b> {{ formatDate(localVehicleInspection.created) }}
        </div>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="6" class="text-right">
        <div class="headline">
          <b>Inspection Done by:</b> {{ username }}
        </div>
      </v-col>
      <v-col cols="12" md="4" sm="4" xs="4">
        <v-select
          filled
          :items="vehicles"
          :item-text="v => `${v.make} ${v.model} ${v.licensePlate}`"
          item-value="id"
          data-cy="FormVehicleInspection-vehicleId"
          :label="$$t('vehicleId')"
          v-model="localVehicleInspection.vehicleId"
        />
      </v-col>
      <v-col cols="12" md="4" sm="4" xs="4">
        <v-text-field
          filled
          data-cy="FormVehicleInspection-odometerReading"
          :label="$$t('odometerReading')"
          v-model="localVehicleInspection.odometerReading"
        />
      </v-col>
      <v-col cols="12" md="4" sm="4" xs="4">
        <v-text-field
          filled
          data-cy="FormVehicleInspection-fuelLevel"
          :label="$$t('fuelLevel')"
          v-model="localVehicleInspection.fuelLevel"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-engineOil"
          filled
          :label="$$t('engineOil')"
          :items="items"
          v-model="localVehicleInspection.engineOil"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-coolant"
          filled
          :label="$$t('coolant')"
          :items="items"
          v-model="localVehicleInspection.coolant"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-brakeFluid"
          filled
          :label="$$t('brakeFluid')"
          :items="items"
          v-model="localVehicleInspection.brakeFluid"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-tireCondition"
          filled
          :label="$$t('tireCondition')"
          :items="items"
          v-model="localVehicleInspection.tireCondition"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-brakeCondition"
          filled
          :label="$$t('brakeCondition')"
          :items="items"
          v-model="localVehicleInspection.brakeCondition"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-headlights"
          filled
          :label="$$t('headlights')"
          :items="items"
          v-model="localVehicleInspection.headlights"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-taillights"
          filled
          :label="$$t('taillights')"
          :items="items"
          v-model="localVehicleInspection.taillights"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-turnSignals"
          filled
          :label="$$t('turnSignals')"
          :items="items"
          v-model="localVehicleInspection.turnSignals"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-brakeLights"
          filled
          :label="$$t('brakeLights')"
          :items="items"
          v-model="localVehicleInspection.brakeLights"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-horn"
          filled
          :label="$$t('horn')"
          :items="items"
          v-model="localVehicleInspection.horn"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-windshieldCondition"
          filled
          :label="$$t('windshieldCondition')"
          :items="items"
          v-model="localVehicleInspection.windshieldCondition"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-wipers"
          filled
          :label="$$t('wipers')"
          :items="items"
          v-model="localVehicleInspection.wipers"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-mirrors"
          filled
          :label="$$t('mirrors')"
          :items="items"
          v-model="localVehicleInspection.mirrors"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-seatbelts"
          filled
          :label="$$t('seatbelts')"
          :items="items"
          v-model="localVehicleInspection.seatbelts"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-interiorCondition"
          filled
          :label="$$t('interiorCondition')"
          :items="items"
          v-model="localVehicleInspection.interiorCondition"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-exteriorCondition"
          filled
          :label="$$t('exteriorCondition')"
          :items="items"
          v-model="localVehicleInspection.exteriorCondition"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-cabin"
          filled
          :label="$$t('cabin')"
          :items="items"
          v-model="localVehicleInspection.cabin"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-fireExtinguisher"
          filled
          :label="$$t('fireExtinguisher')"
          :items="items"
          v-model="localVehicleInspection.fireExtinguisher"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-fridge"
          filled
          :label="$$t('fridge')"
          :items="items"
          v-model="localVehicleInspection.fridge"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-clean"
          filled
          :label="$$t('clean')"
          :items="items"
          v-model="localVehicleInspection.clean"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3">
        <v-select
          data-cy="FormVehicleInspection-cratesReturned"
          filled
          :label="$$t('cratesReturned')"
          :items="items"
          v-model="localVehicleInspection.cratesReturned"
        />
      </v-col>
      <v-col cols="12">
        <div class="text-h6">Photos</div>
        <MultipleImageField
          rows="4"
          filled
          data-cy="FormVehicleInspection-photos"
          :label="$$t('photos')"
          v-model="localVehicleInspection.photos"
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          rows="4"
          filled
          data-cy="FormVehicleInspection-comments"
          :label="$$t('comments')"
          v-model="localVehicleInspection.comments"
          counter="2000"
        />
      </v-col>
    </v-row>
    <div class="d-flex justify-end mb-6">
      <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
        data-cy="FormVehicleInspection-button-cancel">
        {{ $t('common.buttons.cancel') }}
      </v-btn>
      <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
        data-cy="FormVehicleInspection-button-save">
          {{ $t('common.buttons.save') }}
        </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import MultipleImageField from './MultipleImageField.vue';

export default {
  name: 'FormVehicleInspection',
  module: 'Fleet',
  mixins: [loading],
  components: {
    MultipleImageField,
  },
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    VehicleInspection: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localVehicleInspection
      */
      localVehicleInspection: {
        created: new Date(),
      },
      items: [
        'Good',
        'Fair',
        'Poor',
        'Needs Repair',
        'Needs Replacement',
        'N/A',
      ],
    };
  },
  computed: {
    ...mapState('vehicles', ['vehicles']),
    ...mapState('auth', ['username']),
  },
  watch: {
    VehicleInspection() {
      this.localVehicleInspection = this.VehicleInspection;
    },
  },
  created() {
    this.localVehicleInspection = this.VehicleInspection;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localVehicleInspection);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormVehicleInspection example

'''js
<FormVehicleInspection :VehicleInspection="VehicleInspection" />
'''
</docs>
