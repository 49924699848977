<template>
  <v-btn class="grey--text"
    elevation="0"
    title="Cart"
    data-cy="btn-mini-cart"
    height="48"
    color="accent"
    :to="{ name: 'shop-Cart' }"
  >
    <img class="icon" src="@/assets/icon-cart.svg" />
    <span class="black--text" v-if="total_items > 0">
      R {{ formatCurrency(total_amount) }}
    </span>
  </v-btn>
</template>

<script>
import { mdiCartOutline, mdiTrashCanOutline } from '@mdi/js';
import cart from '../mixins/cart';

export default {
  mixins: [cart],
  data() {
    return {
      mdiCartOutline,
      mdiTrashCanOutline,
      isOpen: false,
    };
  },
};
</script>
