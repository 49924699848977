<template>
  <v-data-table
    :headers="headers"
    :items="drivers"
    class="elevation-1"
    :loading="isLoading"
  >
    <template v-slot:top>
      <v-row :class="{ 'p-16': $vuetify.breakpoint.mobile }">
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-btn
            color="primary"
            data-cy="Driver-button-new"
            @click="$router.push({
              name: 'fleet-EditDriver'
            })"
          >
            <v-icon class="mr-2">
              {{ mdiPlus }}
            </v-icon>
            {{ $t('common.buttons.new') }}
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-text-field
            v-model="search"
            :append-icon="mdiMagnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.actions="{ item }">
      <TableBasicButtons :editLink="{
          name: 'fleet-EditDriver',
          query: {id: item.id}
        }"
        @delete="remove(item)"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiMagnify, mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListDriver',
  module: 'Fleet',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
       * History for breadcrumbs
       */
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Drivers',
        disabled: true,
        depth: 0,
      }],
      /**
      * Icons
      */
      mdiMagnify,
      mdiPlus,
      /**
      * table data
      */
      search: '',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('drivers', ['drivers']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('idNumber'),
          value: 'idNumber',
        },
        {
          text: this.$$t('licenseExpirationDate'),
          value: 'licenseExpirationDate',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_drivers();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('drivers', ['LOAD_drivers', 'DELETE_driver']),
    /** Delete Driver from the collection
     */
    async remove(driver) {
      this.isLoading = true;
      await this.DELETE_driver(driver.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Driver example

'''js
<Driver />
'''
</docs>
