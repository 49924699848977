<template>
  <v-container fluid>
    <v-card :loading="isLoading || isSaving">
      <v-card-title primary-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-row
          v-for="(item, index) in availableItems"
          :key="item.id"
          dense
          class="d-flex justify-start align-center border-bottom-1"
          :class="{ 'border-top-1': index === 0 }"
        >
          <v-col cols="2">
            <v-img :src="item.stockItem?.photo" contain :height="100" :width="100" />
          </v-col>
          <v-col cols="1">
            <div class="text-h6 mr-5">
              {{ item.amount }}
              x
            </div>
          </v-col>
          <v-col cols="3">
            <div class="text-h6 mr-5">
              {{ item.itemCode }}
            </div>
          </v-col>
          <v-col cols="4">
            <div class="text-h6 mr-5">
              {{ item.stockItem?.description }}
            </div>
          </v-col>
          <v-col cols="2">
            <v-btn color="primary" @click="pick(item)">
              {{ type }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card :loading="isLoading || isSaving" class="mt-5">
      <v-card-title primary-title>
        Completed Items
      </v-card-title>
      <v-card-text>
        <v-row
          v-for="(item, index) in picked"
          :key="item.id"
          dense
          class="d-flex justify-start align-center border-bottom-1"
          :class="{ 'border-top-1': index === 0 }"
        >
          <v-col cols="2">
            <v-img :src="item.stockItem?.photo" contain :height="100" :width="100" />
          </v-col>
          <v-col cols="1">
            <div class="text-h6 mr-5">
              {{ item.amount }}
              x
            </div>
          </v-col>
          <v-col cols="3">
            <div class="text-h6 mr-5">
              {{ item.itemCode }}
            </div>
          </v-col>
          <v-col cols="4">
            <div class="text-h6 mr-5">
              {{ item.stockItem?.description }}
            </div>
          </v-col>
          <v-col cols="2">
            <div class="text-h6 mr-5">
              Picked at {{ formatDateTime(item.pickedItem.created)}}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'EditPickingList',
  module: 'Shop',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing PickingList. Default to {}.
      */
      editPickingList: {},
      /**
       * Query Id for PickingList
       */
      pickingListId: this.$route?.query.id,
      order: undefined,
      pickItems: [],
      type: this.$route.query?.type ?? 'Pick',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('pickingLists', ['pickingList', 'pickingList']),
    ...mapState('pickingListItems', ['pickingListItems']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.pickingListId) {
        return 'edit';
      }
      return 'add';
    },
    availableItems() {
      const pickedItems = this.pickingListItems.map((item) => item.itemCode);
      return this.pickItems.filter((item) => !pickedItems.includes(item.itemCode));
    },
    picked() {
      const pickedItems = this.pickingListItems.map((item) => item.itemCode);
      const picked = this.pickItems.filter((item) => pickedItems.includes(item.itemCode));

      return picked.map((item) => {
        const pickedItem = this.pickingListItems.find((i) => i.itemCode === item.itemCode);
        return {
          ...item,
          pickedItem,
        };
      });
    },
    title() {
      if (this.order) {
        return `Picking List for Order ${this.order.orderNo}`;
      }
      return '';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_pickingList(this.pickingListId);
      this.editPickingList = this.pickingList;
      this.order = await this.LOAD_order(this.pickingList.orderId);
      this.pickItems = [];
      this.order.cart.forEach(async (item) => {
        const stockItem = await this.LOAD_stockItem(item.itemCode);
        this.pickItems.push({
          id: item.id,
          itemCode: item.itemCode,
          amount: item.amount,
          stockItem,
          item,
        });
      });
      await this.loadPickingListItems();
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('pickingLists', ['LOAD_pickingList', 'CREATE_pickingList', 'UPDATE_pickingList']),
    ...mapActions('pickingListItems', ['CREATE_pickingListItem', 'LOAD_pickingListItems']),
    ...mapActions('orders', ['LOAD_order']),
    ...mapActions('stockItems', ['LOAD_stockItem']),
    async pick(item) {
      const { pickingListId } = this;
      const picklingListItem = {
        state: 'Picked',
        pickingListId,
        itemCode: item.itemCode,
        quantity: item.amount,
      };
      await this.CREATE_pickingListItem(picklingListItem);
      await this.loadPickingListItems();
      await this.$nextTick();
      if (this.availableItems.length === 0) {
        this.$router.push({ name: 'shop-PickingList' });
        this.$root.$emit('toast:notify', 'Picking List Completed');
      }
    },
    async loadPickingListItems() {
      this.isLoading = true;
      await this.LOAD_pickingListItems(this.pickingListId);
      this.isLoading = false;
    },
    /** Method used to save the item
     */
    async save(pickingList) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_pickingList(pickingList);
      } else {
        await this.UPDATE_pickingList(pickingList);
      }
      this.isSaving = false;
      this.$router.push({ name: 'shop-ListPickingList' });
    },
  },
};
</script>

<docs>
# EditPickingList example

'''js
<EditPickingList :PickingList="PickingList" />
'''
</docs>
