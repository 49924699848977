<template>
<div>
  <table>
    <tr data-cy="quantity-input">
      <td>
        <button
          class="button-blocks button-blocks-left"
          @click="decrease"
          @touchstart="longDecrease"
          @touchrelease="releaseDecrease"
          @mousedown="longDecrease"
          @mouseup="releaseDecrease"
          :disabled="quantity === 0"
          type="button">
          <span class="text-sizing">-</span>
        </button>
      </td>
      <td>
        <div class="quantity-block">
          <input type="number" v-model.number="localValue"
          @focus="quantityClicked"
          @keydown.enter.prevent="updateItem(localValue)"
          @change="updateItem(localValue)"
        />
        </div>
      </td>
      <td>
        <button class="button-blocks button-blocks-right" @click="increase"
          @touchstart="longIncrease"
          @touchrelease="releaseIncrease"
          @mousedown="longIncrease"
          @mouseup="releaseIncrease"
          type="button">
          <span class="text-sizing">+</span>
        </button>
      </td>
    </tr>
  </table>
</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import cartMethods from '../mixins/cartMethods';

export default {
  name: 'QuantityButton',
  mixins: [cartMethods],
  props: {
    stockItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localValue: 0,
      isDecreasing: undefined,
      isIncreasing: undefined,
    };
  },
  computed: {
    ...mapGetters('cart', ['getQuantity']),
    quantity() {
      return this.getQuantity(this.stockItem);
    },
  },
  watch: {
    quantity() {
      this.localValue = this.quantity;
    },
  },
  mounted() {
    this.localValue = this.quantity;
  },
  methods: {
    ...mapMutations('cart', ['cart_INCREASE', 'cart_DECREASE', 'cart_UPDATE']),
    releaseDecrease() {
      clearTimeout(this.isDecreasing);
    },
    releaseIncrease() {
      clearTimeout(this.isIncreasing);
    },
    longDecrease() {
      this.isDecreasing = setTimeout(() => this.decrease, 150);
    },
    longIncrease() {
      this.isIncreasing = setTimeout(() => this.increase, 150);
    },
    async decrease() {
      const amount = this.quantity - 1;
      if (amount > 0) {
        this.cart_DECREASE(this.stockItem, amount);
      } else {
        const result = await this.$confirm('This will remove the item from your cart. Are you sure?');
        if (result) {
          this.REMOVE_CART(this.stockItem.itemCode);
        }
      }
    },
    increase() {
      this.cart_INCREASE(this.stockItem, this.quantity + 1);
    },
    quantityClicked() {
      this.localValue = '';
    },
    updateItem(amount) {
      const { stockItem } = this;
      if (amount === 0) {
        this.REMOVE_CART(stockItem.itemCode);
      } else {
        this.cart_UPDATE({ stockItem, amount });
      }
    },
  },
};
</script>
<style scoped>
input {
  text-align: center;
  max-width: 30px;
}
.quantity-group {
  border: 2px solid #bfbfbf;
}
.text-sizing {
  font-size: 21px;
}
.quantity-block {
  height: 40px;
  width: 40px;
  padding-top: 7px;
  text-align: center;
  border-bottom: 2px solid #bfbfbf;
  border-top: 2px solid #bfbfbf;
}
.button-blocks {
  height: 40px;
  width: 40px;
  color: white;
  background-color: #bfbfbf;
  text-align: center;
  border: 3px solid #bfbfbf;
}
.button-blocks-left {
  border-radius: 7px 0 0 7px;
}
.button-blocks-right {
  border-radius: 0 7px 7px 0;
}
table {
  border-spacing: 0;
}
</style>
