import { mapActions, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('webCategories', ['webCategory1', 'webCategory2', 'webCategory3', 'webCategory4']),
  },
  methods: {
    ...mapActions('stockItems', [
      'LOAD_stockItemsByWebCategory1',
      'LOAD_stockItemsByWebCategory2',
      'LOAD_stockItemsByWebCategory3',
      'LOAD_stockItemsByWebCategory4',
      'LOAD_stockItemsWithNoCategory']),
    async LOAD_categories({ depth, category }) {
      const stockItems = await this[`LOAD_stockItemsByWebCategory${depth}`](category.id);

      const cat = `webCategory${depth + 1}Id`;
      const categories = [...new Set(stockItems
        .map((s) => s[cat]))];
      return this[`webCategory${depth + 1}`].filter((c) => categories.includes(c.id));
    },
    async LOAD_categoryProducts({ depth, category }) {
      const stockItems = await this[`LOAD_stockItemsByWebCategory${depth}`](category.id);
      return stockItems;
    },
  },
};
