import dayjs from 'dayjs';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('order', 'orders');

const customActions = {
  async LOAD_orders({ commit }) {
    const result = await this.$http.get('api/orders');
    commit('SET_orders', result.data);
    return result.data;
  },
  async LOAD_order({ rootGetters }, id) {
    const data = await odata.o(rootGetters)
      .get('orders')
      .query({
        $expand: 'Cart,OrderDetail',
        $filter: `Id eq ${id}`,
        $orderBy: 'Created desc',
        $top: 1,
      });
    return data[0];
  },
  async LOAD_pickingListOrders({ commit, rootGetters }) {
    const startDate = dayjs().subtract(2, 'day').format('YYYY-MM-DD');
    const data = await odata.o(rootGetters)
      .get('orders')
      .query({
        $filter: `IsDeleted eq false AND Created gt ${startDate}`,
        $orderby: 'ExternalOrderNo desc',
        $expand: 'Cart',
      });
    commit('SET_orders', data);

    return data;
  },
};

export default { ...actions, ...customActions };
