<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="quotes"
      class="elevation-0 basic-table"
      :loading="isLoading"
      sort-by="order.created"
      :sort-desc="true"
    >
      <template v-slot:item.order.created="{ item }">
        {{ formatDate(item.order.created) }}
      </template>
      <template v-slot:item.dateApproved="{ item }">
        <span v-if="item.dateApproved">
          {{ formatDate(item.dateApproved) }}
        </span>
      </template>
      <template v-slot:item.approved="{ item }">
        {{ formatBoolean(item.approved) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn-toggle>
          <v-btn
            v-if="item && isApprover(item)"
            small
            class="secondary--text mr-2"
            color="#EE1C25"
            :to="{ name: 'shop-Quote', query: { quoteId: item.id } }"
          >
            View
          </v-btn>
          <v-btn
            v-if="item && isApprover(item) && !item.approved"
            small
              class="secondary--text"
              color="primary"
            :to="{ name: 'shop-EditQuote', query: { quoteId: item.id } }"
          >
            Edit
          </v-btn>
        </v-btn-toggle>
        <!-- <v-btn
          small
          class="mr-2"
          color="primary"
          dark
          @click="approve(item, true)"
          v-if="isApprover(item.approvers)"
        >
          Approve
        </v-btn>
        <v-btn
          small
          color="error"
          dark
          @click="approve(item, false)"
          v-if="isApprover(item.approvers)"
        >
          Decline
        </v-btn> -->
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex';
import { mdiDelete, mdiPencil } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import quotes from '../mixins/quotes';

export default {
  name: 'ListQuote',
  module: 'Orders',
  mixins: [loading, quotes],
  data() {
    return {
      mdiDelete,
      mdiPencil,
      quotes: [],
      history: [
        {
          text: 'Home',
          disabled: false,
          href: '/#/members',
          depth: 0,
          // }, {
          //   text: 'Orders',
          //   disabled: false,
          //   href: '/#/shop/orders',
          //   depth: 0,
        },
        {
          text: 'Quotes',
          disabled: true,
          depth: 0,
        },
      ],
    };
  },
  computed: {
    /**
     * Get table column headers
     */
    headers() {
      return [
        {
          text: 'Order Date',
          value: 'order.created',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Approved',
          value: 'approved',
        },
        {
          text: 'Approved By',
          value: 'approvedBy',
        },
        {
          text: 'Date Approved',
          value: 'dateApproved',
        },
        {
          text: 'Order No.',
          value: 'order.orderNo',
        },
        {
          text: 'Approvers',
          value: 'approverNames',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.SET_title('Quotes');
    this.SET_expandedSearch(false);
    await this.refresh();
  },
  methods: {
    ...mapMutations('app', ['SET_title', 'SET_expandedSearch']),
    async approve(item, approved) {
      this.isLoading = true;
      try {
        let msg = 'Are you sure you want to approve this order?';
        if (!approved) {
          msg = 'Are you sure you want to decline this order?';
        }
        const result = await this.$confirm(msg);
        if (result) {
          await this.$http.put('/api/quotes', {
            quoteId: item.id,
            approved,
            updateType: 'approval',
          });
          await this.LOAD_quotes();
        }
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async refresh() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get('/api/quotes/all');
        this.quotes = data;
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<docs>
# Category example

'''js
<Category />
'''
</docs>
