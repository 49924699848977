<template>
  <v-dialog
    v-model="dialog"
    width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>{{ mdiPencilBoxOutline }}</v-icon>
      </v-btn>
    </template>
    <v-form @submit.prevent="update">
    <v-card>
      <v-card-title primary-title>
        Item Code: {{ stockItem.itemCode }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Product Description"
          v-model="localValue.name"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="cancel">
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" type="submit">
          {{ $t('common.buttons.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiPencilBoxOutline } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'StockItemMerchandise.vue',
  mixins: [loading],
  props: {
    /**
     * Holds the value stockItem, with a default of {}
     */
    stockItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      /** icons
       */
      mdiPencilBoxOutline,
      /** show or hide modal dialog
       */
      dialog: false,
      localValue: { ...this.stockItem },
    };
  },
  methods: {
    ...mapActions('stockItems', [
      'UPDATE_stockItem']),
    cancel() {
      this.dialog = false;
      this.localValue = { ...this.stockItem };
    },
    async update() {
      const { localValue } = this;
      this.$emit('change', localValue);
      this.dialog = false;
    },
  },
};
</script>
