import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('softSaleStockItemAudit', 'softSaleStockItemAudits');

const customActions = {
  async LOAD_softSaleStockItemAudits({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('softSaleStockItemAudits')
      .query({ $filter: 'IsDeleted eq false', $expand: 'SoftSaleStockItem' });
    commit('SET_softSaleStockItemAudits', data);
    return data;
  },
};

export default { ...actions, ...customActions };
